import { takeLatest, put } from 'redux-saga/effects';
import {
  fetchDesignAction,
  fetchDesignSuccessAction,
  updateDesignAction,
  UpdateDesignParams,
  updateDesignSuccessAction,
  DeleteDesignLogoParam,
  deleteDesignLogoAction,
} from '../actions/design';
import { designService } from '../../libs/design/design.service';
import {
  showNotificationErrorAction,
  showNotificationSuccessAction,
} from '../actions';
import { PayloadAction } from '@reduxjs/toolkit';

function* fetchDesignActionSaga() {
  try {
    const response = yield designService.getDesign();
    yield put(fetchDesignSuccessAction(response));
  } catch (e) {
    yield put(
      showNotificationErrorAction({
        content: 'Server error, try later.',
      }),
    );
  }
}

function* updateDesignActionSaga(action: PayloadAction<UpdateDesignParams>) {
  try {
    const response = yield designService.updateDesign(action.payload);
    yield put(updateDesignSuccessAction(response));
    yield put(
      showNotificationSuccessAction({
        content: 'Design updated.',
      }),
    );
  } catch (e) {
    yield put(
      showNotificationErrorAction({
        content: 'Server error, try later.',
      }),
    );
  }
}

function* deleteUserAvatarActionSaga(
  action: PayloadAction<DeleteDesignLogoParam>,
) {
  try {
    yield designService.updateDesign(action.payload);
    yield put(
      showNotificationSuccessAction({
        content: 'Logo deleted.',
      }),
    );
  } catch (e) {
    yield put(
      showNotificationErrorAction({
        content: 'Server error, try later.',
      }),
    );
  }
}

export function* designWatchSaga(): Generator<any> {
  yield takeLatest(fetchDesignAction, fetchDesignActionSaga);
  yield takeLatest(updateDesignAction, updateDesignActionSaga);
  yield takeLatest(deleteDesignLogoAction, deleteUserAvatarActionSaga);
}
