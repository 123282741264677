import React from 'react';
import { Button } from 'antd';
import { ButtonProps as ButtonPropsAnt } from 'antd/lib/button';
import cn from 'classnames';
import './index.scss';

export type ButtonProps = ButtonPropsAnt;

export const ButtonMenuStyled: React.FC<ButtonProps> = ({
  className,
  ...rest
}) => {
  return <Button {...rest} className={cn(className, 'styled-menu-button')} />;
};
