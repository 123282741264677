import React, { useEffect } from 'react';
import { ArrowLeftIcon } from '../icons/arrowLeft';
import { ArrowRightIcon } from '../icons/arrowRight';
import { week, timesWeekPreview } from './previewData';

interface Props {
  color?: string | null;
  setColor(): void;
}

export const WeekPreview: React.FC<Props> = ({ color, setColor }) => {
  useEffect(() => {
    setColor();
  }, [color, setColor]);
  return (
    <>
      <div className="weekPreview-container">
        <ArrowLeftIcon />
        <div className="weekPreview-calendar">
          {week.map((item) => (
            <div className="weekPreview-calendar-item" key={item.weekDay}>
              <div className={`weekPreview-calendar-day${item.style}`}>
                <div>{item.weekDay}</div>
                <div>{item.month}</div>
                <div>{item.day}</div>
              </div>
            </div>
          ))}
        </div>
        <ArrowRightIcon />
      </div>
      <div className="weekPreview-times">
        {timesWeekPreview.map((item) => (
          <div className="weekPreview-times-item" key={item}>
            {item}
          </div>
        ))}
      </div>
      <div className="weekPreview-shadow"></div>
    </>
  );
};
