import React, { useMemo } from 'react';
import { Slider } from 'antd';
import './index.scss';

export interface IAvailableTime {
  onChange?: (value: [number, number]) => void;
  onAfterChange?: (value: [number, number]) => void;
  className?: string;
  tipFormatter?:
    | ((value?: number | undefined) => React.ReactNode)
    | null
    | undefined;
  defaultValue?: [number, number];
  tooltipVisible?: boolean;
  value?: [number, number];
  max: number;
  min: number;
  step: number;
  tooltipClass?: string;
}

enum TIME {
  PM = 'PM',
  AM = 'AM',
}

export const AvailableTime: React.FC<IAvailableTime> = (props) => {
  const formatter = (value: number | undefined) => {
    if (!value && typeof value !== 'number') {
      return null;
    }
    const hoursByPMAM = value > 720 ? Math.abs(720 - value) : value;
    let hours = Math.trunc(hoursByPMAM / 60);
    const minutes = (value / 60 - Math.floor(value / 60)) * 60;
    let isAMOrPM = value / 60 <= 12 ? TIME.AM : TIME.PM;
    if (value === 1440) {
      isAMOrPM = TIME.AM;
    }
    if (value === 720) {
      isAMOrPM = TIME.PM;
    }
    if (value === 0) {
      hours = 12;
    }
    return `${hours}:${minutes > 0 ? minutes : '00'} ${isAMOrPM}`;
  };

  const wrapper = useMemo(() => {
    const container = document.getElementsByClassName(
      'slider-tooltip-container',
    );
    if (container.length === 0) {
      const tooltipContainer = document.createElement('div');
      const root = document.getElementsByClassName('ant-layout');
      tooltipContainer.classList.add('slider-tooltip-container');
      return root[0].appendChild(tooltipContainer);
    } else return container[0] as HTMLElement;
  }, []);

  return (
    <>
      {wrapper && (
        <Slider
          range
          {...props}
          tipFormatter={formatter}
          onChange={props.onChange}
          onAfterChange={props.onAfterChange}
          className={`available-time-slider ${props.className || ''}`}
          getTooltipPopupContainer={() => wrapper}
        />
      )}
    </>
  );
};
