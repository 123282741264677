import React, { useCallback, useState } from 'react';
import {
  CalendarInvitation,
  SetCalendarInvitationParams,
} from '../../../redux/actions';
import { InputWithLabel } from '../../../components/input/inputWithLabel';
import { Form } from 'antd';
import { Input } from '../../../components/input';
import TextArea from 'antd/lib/input/TextArea';
import { ButtonMenuStyled } from '../../../components/button/styledButton';
import { BaseRenderProps } from '../../../components/modal/components';
import copy from 'copy-to-clipboard';
import './calendarInvitation.scss';

type Props = BaseRenderProps & SetCalendarInvitationParams;

interface Variable {
  name: string;
  buffer: string;
}

const variables = [
  {
    name: 'Guest Name',
    buffer: '{{guest_name}}',
  },
  {
    name: 'My Name',
    buffer: '{{my_name}}',
  },
  {
    name: 'Meeting Name',
    buffer: '{{meeting_name}}',
  },
  {
    name: 'Meeting Description',
    buffer: '{{meeting_description}}',
  },
  {
    name: 'Meeting Location',
    buffer: '{{meeting_location}}',
  },
  {
    name: 'Guest Phone',
    buffer: '{{guest_phone}}',
  },
];

const VariableItem: React.FC<Variable> = (item) => {
  const [copied, setCopied] = useState(false);
  const copyHandler = useCallback(() => {
    copy(item.buffer);
    setCopied(true);
    const timer = setTimeout(() => {
      setCopied(false);
      clearTimeout(timer);
    }, 1000);
  }, [item]);
  return (
    <>
      <div className="variable-name">{item.name}</div>
      <div
        className={`variable-copy${copied ? ' copied' : ''}`}
        onClick={copyHandler}
      >
        {copied ? 'Copied' : 'Copy'}
      </div>
    </>
  );
};

export const CalendarInvitationModal: React.FC<Props> = (props) => {
  return (
    <div className="calendarInvitation-container">
      <div className="calendarInvitation-edit">
        <div className="edit-header">Edit Calendar Invitations</div>
        <Form
          onFinish={(e: CalendarInvitation) => {
            props.onSubmit(e);
            props.onCancel();
          }}
        >
          <InputWithLabel label="Title" tooltip="Title tooltip">
            <Form.Item name="title" initialValue={props.default.title}>
              <Input placeholder="Enter title" />
            </Form.Item>
          </InputWithLabel>
          <InputWithLabel label="Body" tooltip="Body tooltip">
            <Form.Item name="body" initialValue={props.default.body}>
              <TextArea
                placeholder="Enter body"
                autoSize={{ minRows: 4, maxRows: 6 }}
              />
            </Form.Item>
          </InputWithLabel>
          <div className="edit-buttons">
            <div className="edit-cancel-button" onClick={props.onCancel}>
              Cancel
            </div>
            <ButtonMenuStyled htmlType="submit">Update</ButtonMenuStyled>
          </div>
        </Form>
      </div>
      <div className="calendarInvitation-variables">
        <div className="variables-header">Available Variables</div>
        <div className="variables-items">
          {variables.map((item, index) => (
            <div key={index} className="variables-item">
              <VariableItem name={item.name} buffer={item.buffer} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
