import { LoadableData } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import {
  setLoadedStatusToLoadableData,
  setLoadingStatusToLoadableData,
} from '../utils';
import { OrganizationUserList } from '../../models/organization.mogel';
import {
  deleteOrganizationUserSuccessAction,
  fetchOrganizationUsersAction,
  checkOrganizationExistAction,
  fetchOrganizationUsersSuccessAction,
  sendInvitesToUsersErrorAction,
  sendInvitesToUsersAction,
  sendInvitesToUsersSuccessAction,
} from '../actions/organization-users';
import { OffsetPaginationMetaDto } from '../../libs/common/dtos/offset-pagination.dto';
import { OrganizationDataCountDto } from '../../libs/organization-users/dtos/organization-users.dto';

export interface ListOfOrganizationUsersState extends LoadableData {
  data: OrganizationUserList[];
  pagination: OffsetPaginationMetaDto;
  counts: OrganizationDataCountDto;
}

export interface MetaOrganizationUsersState extends LoadableData {
  emails: string[];
}

export interface OrganizationUsersState {
  list: ListOfOrganizationUsersState;
  meta: MetaOrganizationUsersState;
  isNotExistOrganization: boolean;
}

const initialOrganizationUsersState: OrganizationUsersState = {
  list: {
    isLoaded: false,
    isLoading: false,
    data: [],
    pagination: { totalCount: 0, totalPages: 0 },
    counts: {
      acceptedCount: 0,
      pendingCount: 0,
    },
  },
  meta: {
    emails: [],
    isLoading: false,
    isLoaded: false,
  },
  isNotExistOrganization: false,
};

export const organizationUsersReducer = createReducer<OrganizationUsersState>(
  initialOrganizationUsersState,
  (builder) => {
    builder.addCase(fetchOrganizationUsersAction, (state) => {
      setLoadingStatusToLoadableData(state.list);
    });
    builder.addCase(fetchOrganizationUsersSuccessAction, (state, action) => {
      setLoadedStatusToLoadableData(state.list);
      state.list.data = action.payload.data;
      state.list.pagination = action.payload.meta;
      state.list.counts = action.payload.counts;
    });
    builder.addCase(deleteOrganizationUserSuccessAction, (state, action) => {
      setLoadedStatusToLoadableData(state.list);
      state.list.data = action.payload.data;
    });
    builder.addCase(sendInvitesToUsersAction, (state) => {
      setLoadingStatusToLoadableData(state.meta);
    });
    builder.addCase(sendInvitesToUsersSuccessAction, (state) => {
      setLoadedStatusToLoadableData(state.meta);
    });
    builder.addCase(sendInvitesToUsersErrorAction, (state, action) => {
      setLoadedStatusToLoadableData(state.meta);
      state.meta.emails = action.payload.meta.emails;
    });
    builder.addCase(checkOrganizationExistAction, (state, action) => {
      state.isNotExistOrganization = action.payload.isNotExistOrganization;
    });
  },
);
