import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BookingRangeInfo } from '../../../redux/reducers/booking-meeting-event';
import moment, { Moment } from 'moment-timezone';
import { MonthSelectDay } from '../../../components/selectDayMonthView';
import { MonthSelectTime } from '../../../components/selectTimeMonthView';
import './monthView.scss';
import { EventData } from '../index';

export interface Month {
  isCurrent: boolean;
  isSelected: boolean;
  day: Moment;
  class: string;
  isAvailable: boolean;
}

interface props {
  color?: string | null;
  range: BookingRangeInfo;
  eventData?: EventData;
  selectEventTimeHandler(value: string): void;
  timezoneValue: string;
  getTimes(
    day: Moment | undefined,
    bookingRange: BookingRangeInfo,
  ): string[] | undefined;
}

export const MonthView: React.FC<props> = (props) => {
  const [selectedDay, setSelectedDay] = useState<Moment | undefined>(undefined);

  useEffect(() => {
    const firstAvailable = props.range.dates.find(
      (item) => item.status === 'available',
    );
    if (firstAvailable) {
      setSelectedDay(moment(firstAvailable.date));
    }
  }, [props.range.dates]);

  const firstMonth = useMemo(
    () =>
      props.range.dates[0].date
        ? moment(props.range.dates[0].date).month()
        : undefined,
    [props.range.dates],
  );
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>(
    firstMonth,
  );

  const lastMonth = useMemo(
    () =>
      props.range.dates[props.range.dates.length - 1].date
        ? moment(props.range.dates[props.range.dates.length - 1].date).month()
        : undefined,
    [props.range.dates],
  );

  const upMonthHandler = () => {
    if (selectedMonth && lastMonth && selectedMonth < lastMonth) {
      setSelectedMonth(selectedMonth + 1);
    }
  };

  const downMonthHandler = () => {
    if (selectedMonth && firstMonth && selectedMonth > firstMonth) {
      setSelectedMonth(selectedMonth - 1);
    }
  };

  const setTimeHandler = (value: string) => {
    props.selectEventTimeHandler(value);
  };

  const getDaysArrayByMonth = useCallback(
    (month: number | undefined) => {
      if (month === undefined) {
        return undefined;
      }
      const daysInMonth = moment().month(month).daysInMonth();
      let index = 1;
      const arrDays: (Month | undefined)[] = [];
      while (index <= daysInMonth) {
        const current = moment().month(month).date(index);
        const available = props.range.dates.find(
          (item) =>
            moment(item.date).isSame(current, 'day') &&
            item.status === 'available',
        );
        const getClass = (value: Moment) => {
          const available = props.range.dates.find(
            (item) =>
              moment(item.date).isSame(current, 'day') &&
              item.status === 'available',
          );
          const currentDay = value.isSame(moment(), 'day');

          const selectedClass = selectedDay
            ? value.isSame(selectedDay, 'day')
            : '';
          return `${!available ? ' unavailable' : ' available'}${
            currentDay ? ' currentDay' : ''
          }${selectedClass && available ? ' selected' : ''}`;
        };

        arrDays.push({
          day: current,
          isSelected: selectedDay ? current.isSame(selectedDay, 'day') : false,
          isCurrent: current.isSame(moment(), 'day'),
          class: getClass(current),
          isAvailable: !!available,
        });
        index++;
      }
      const getBeforeShift = arrDays[0] ? arrDays[0].day.isoWeekday() : 0;
      let getAfterShift;
      if (arrDays[arrDays.length - 1]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        getAfterShift = 6 - arrDays[arrDays.length - 1].day?.isoWeekday();
      }

      const beforeArr = new Array(getBeforeShift).fill({
        day: undefined,
        class: '',
      });
      const afterArr = new Array(getAfterShift).fill({
        day: undefined,
        class: '',
      });
      return [...beforeArr, ...arrDays, ...afterArr];
    },
    [selectedDay, props.range.dates],
  );

  return (
    <div className="monthView-container">
      <MonthSelectDay
        color={props.color}
        upMonthHandler={upMonthHandler}
        downMonthHandler={downMonthHandler}
        month={getDaysArrayByMonth(selectedMonth)}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        monthTitle={
          selectedMonth
            ? moment().month(selectedMonth).format('MMMM YYYY')
            : undefined
        }
      />
      <MonthSelectTime
        color={props.color}
        times={props.getTimes(selectedDay, props.range)}
        setSelectedTime={setTimeHandler}
      />
    </div>
  );
};
