import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useSubscriptionPlanSelector,
  useUserInfoAfterAuthSelector,
} from '../../redux/selectors/auth';
import Form from 'antd/lib/form';
import {
  CalendarInvitation,
  loadTimezonesAction,
  setCalendarInvitation,
} from '../../redux/actions';
import { DaysOfWeek } from '../../models/days-of-week.model';
import {
  LocationConfigurationsModel,
  MeetingEditModel,
} from '../../models/meeting.model';
import * as _ from 'lodash';
import CalendarIcon from '../../components/icons/calendar';
import { InputWithLabel } from '../../components/input/inputWithLabel';
import { Input } from '../../components/input';
import { LocationInput } from '../../components/locationsInput';
import appConfig from '../../constants/appConfig';
import ClockIcon from '../../components/icons/clock';
import { MeetingDuration } from '../../components/meeting-duration';
import { AvailableTime } from '../../components/availableTime';
import { TimezoneSelector } from '../../components/select/timezone';
import EarthIcon from '../../components/icons/earth';
import { AvailableDays } from '../../components/availableDays';
import BellIcon from '../../components/icons/bell';
import { Switch } from '../../components/switch';
import { NavLink } from 'react-router-dom';
import { routers } from '../../router';
import { BackArrow } from '../../components/icons/backArrow/backArrow';
import { ButtonMenuStyled } from '../../components/button/styledButton';
import './index.scss';
import { meetingSettingsFormMapper } from './interface';
import { SubscriptionsEnum } from '../../models/subscription.model';

interface props {
  activeMeeting: MeetingEditModel;
  submitHandler(value: any): void;
  submitName: string;
}

export const SettingsForm: React.FC<props> = (props) => {
  const { activeMeeting, submitHandler, submitName } = props;
  const role = useSubscriptionPlanSelector();
  const dispatch = useDispatch();
  const user = useUserInfoAfterAuthSelector();
  const [changed, setChanged] = useState(false);
  const [form] = Form.useForm();
  const [dayOfWeek, setDayOfWeek] = useState<DaysOfWeek[]>([]);
  useEffect(() => {
    dispatch(loadTimezonesAction());
  }, [dispatch]);

  useEffect(() => {
    if (
      activeMeeting.availabilityTime.days &&
      activeMeeting.availabilityTime.hours
    ) {
      setDayOfWeek(activeMeeting.availabilityTime.days);
      form.setFieldsValue({ days: activeMeeting.availabilityTime.days });
    }
  }, [activeMeeting, form]);

  const resetHandler = useCallback(() => {
    form.resetFields();
    setChanged(false);
  }, [form]);
  const locationHandler = (value: LocationConfigurationsModel) => {
    form.setFieldsValue({ location: value });
    setChanged(true);
  };
  const durationHandler = (value: number) => {
    form.setFieldsValue({ duration: value });
  };
  const hoursHandler = (value: [number, number]) => {
    form.setFieldsValue({ hours: value });
  };
  const timezoneHandler = (value: string) => {
    form.setFieldsValue({ timezone: value });
  };

  const calendarInvitationsHandler = (value: CalendarInvitation) => {
    form.setFieldsValue({ calendarInvitations: value });
    setChanged(true);
  };

  const daysHandler = (day: DaysOfWeek) => {
    const isActive = _.some(dayOfWeek, (el) => el === day);
    if (isActive) {
      setDayOfWeek(_.without(dayOfWeek, day));
      form.setFieldsValue({ days: _.without(dayOfWeek, day) });
    } else {
      setDayOfWeek([...dayOfWeek, day]);
      form.setFieldsValue({ days: [...dayOfWeek, day] });
    }
    setChanged(true);
  };

  return (
    <Form
      form={form}
      onFinish={(e) => {
        submitHandler(meetingSettingsFormMapper(e, activeMeeting));
      }}
      onFieldsChange={() => setChanged(true)}
      initialValues={{
        // eslint-disable-next-line
        ['name']: activeMeeting.name,
        // eslint-disable-next-line
        ['description']: activeMeeting.description,
        // eslint-disable-next-line
        ['url']: activeMeeting.slug,
        // eslint-disable-next-line
        ['location']: activeMeeting.locationConfigurations,
        // eslint-disable-next-line
        ['duration']: activeMeeting.duration,
        // eslint-disable-next-line
        ['hours']: [
          activeMeeting.availabilityTime.hours.from,
          activeMeeting.availabilityTime.hours.to,
        ],
        // eslint-disable-next-line
        ['timezone']: activeMeeting?.timezone || 'utc',
        // eslint-disable-next-line
        ['days']: dayOfWeek,
        // eslint-disable-next-line
        ['calendarInvitations']: activeMeeting.calendarInvitations,
      }}
    >
      <div className="meetingSettings-container">
        <div className="meetingSettings-section">
          <div className="meetingSettings-section-header">
            <CalendarIcon />
            <div>Meeting Details</div>
          </div>

          <div className="meetingSettings-section-content">
            <div className="row">
              <InputWithLabel
                label="Meeting Name"
                tooltip="The title of your meeting, only visible to you on Jammer."
              >
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: 'please input meeting name' },
                  ]}
                >
                  <Input placeholder="Enter meeting name" />
                </Form.Item>
              </InputWithLabel>
              <InputWithLabel
                label="Meeting Description/Instructions"
                tooltip="Any important notes you want invitees to know about the meeting."
              >
                <Form.Item name="description">
                  <Input placeholder="Enter meeting description" />
                </Form.Item>
              </InputWithLabel>
            </div>
            <div className="row">
              <InputWithLabel
                label="Meeting Location"
                tooltip='Use the "location" field to specify how and where both parties will connect at the scheduled time. The location entered here will appear on the confirmation page after events are scheduled and in the calendar event added to both you and your invitees calendars.'
              >
                <Form.Item name="location">
                  <LocationInput
                    initialValue={activeMeeting.locationConfigurations}
                    locationHandler={locationHandler}
                  />
                </Form.Item>
              </InputWithLabel>

              <InputWithLabel
                label="Meeting Scheduling URL"
                tooltip="Meeting URL is the link you can share with your invitees if you want them to bypass the “Choose Your Meeting” step on your Jammer page and go directly to the “Choose Your Date & Time” step. The meeting URL is circled in the sample below. We’ll automatically generate a Meeting URL for you if you don’t specify one."
              >
                <Form.Item
                  name="url"
                  rules={[
                    { required: true, message: 'please input meeting url' },
                  ]}
                >
                  <Input
                    className="url-input"
                    addonBefore={`${appConfig.appHostName}${user.slug}/`}
                    placeholder="Enter meeting scheduling url"
                  />
                </Form.Item>
              </InputWithLabel>
            </div>
          </div>
        </div>
        <div className="meetingSettings-section">
          <div className="meetingSettings-section-header">
            <ClockIcon />
            <div>Meeting Scheduling & Availability</div>
          </div>
          <div className="meetingSettings-section-content">
            <div className="meetingSettings-section-content-duration">
              <InputWithLabel
                label="Meeting Length"
                tooltip="Define how long you want your meeting to last."
              >
                <Form.Item name="duration">
                  <MeetingDuration
                    value={activeMeeting.duration}
                    onChange={durationHandler}
                  />
                </Form.Item>
              </InputWithLabel>
            </div>
            <div className="meetingSettings-section-content-hours">
              <InputWithLabel
                label="Available Hours"
                tooltip="Between which hours of the day are you available to take meetings?"
              >
                <div className="hours-grope">
                  <Form.Item name="hours">
                    <AvailableTime
                      onChange={hoursHandler}
                      max={appConfig.availableTime.max}
                      min={appConfig.availableTime.min}
                      step={appConfig.availableTime.step}
                      tooltipVisible={true}
                    />
                  </Form.Item>
                  <Form.Item name="timezone">
                    <TimezoneSelector
                      wrapperClassName="timezone-selector-wrapper"
                      className="timezone-selector"
                      dropdownClassName="timezone-selector-dropdown"
                      onChange={timezoneHandler}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      getPopupContainer={() =>
                        document.getElementsByTagName('section')[0]!
                      }
                      icon={<EarthIcon className="timezone-selector-icon" />}
                    />
                  </Form.Item>
                </div>
              </InputWithLabel>
            </div>
            <div className="meetingSettings-section-content-days">
              <InputWithLabel
                label="Available Days"
                tooltip="Which days do you want to leave open for meetings?"
              >
                <Form.Item name="days">
                  <AvailableDays activeDays={dayOfWeek} onClick={daysHandler} />
                </Form.Item>
              </InputWithLabel>
            </div>
          </div>
        </div>
        <div className="meetingSettings-section">
          <div className="meetingSettings-section-header">
            <BellIcon />
            <div>Calendar Invitation & Guest Reminders</div>
          </div>
          <div className="meetingSettings-section-content">
            <InputWithLabel
              label="Calendar Invitations"
              tooltip="After scheduling their meeting, your invitee will receive an invitation to join the same meeting as the one published on your calendar. Changes made to the event within your calendar will be seen by both parties."
            >
              <div className="meetingSettings-section-content-calendar">
                <div className="calendar-header">
                  <span>
                    A meeting will be created in your calendar and your invitees
                    will be added as an attendee.
                  </span>
                  <span>
                    Note: Requires a Google calendar connection with the ability
                    to add new Jammer meetings.
                  </span>
                </div>
                <Form.Item name="calendarInvitations">
                  <div
                    className="calendar-button"
                    onClick={() =>
                      dispatch(
                        setCalendarInvitation({
                          default: form.getFieldValue('calendarInvitations'),
                          onSubmit(value: CalendarInvitation): void {
                            calendarInvitationsHandler(value);
                          },
                        }),
                      )
                    }
                  >
                    <p>Edit</p>
                  </div>
                </Form.Item>
              </div>
            </InputWithLabel>
            <InputWithLabel
              label="Email Reminders"
              tooltip="Enable reminder emails to send notifications to your guests before your meeting's start time."
            >
              <div className="meetingSettings-section-content-calendar">
                <div className="calendar-header">
                  <span>
                    Email reminder sent 30 minutes before a scheduled meeting.
                  </span>
                  <span>Sent to your guests.</span>
                </div>
                <div className="email-button">
                  <Form.Item
                    name={['notificationsPolicy', 'isEmailReminder']}
                    valuePropName="checked"
                    initialValue={
                      activeMeeting.notificationsPolicy.isEmailReminder
                    }
                  >
                    <Switch size="small" />
                  </Form.Item>
                </div>
              </div>
            </InputWithLabel>
            <InputWithLabel
              label="Text Reminders"
              tooltip="Enable reminder texts to send SMS notifications to your guests before your meeting's start time."
            >
              <div className="meetingSettings-section-content-calendar">
                <div className="calendar-header">
                  <span>
                    Text reminder sent 5 minutes before a scheduled meeting.
                  </span>
                  <span>Sent to your guests.</span>
                </div>
                <div className="email-button">
                  <Form.Item
                    name={['notificationsPolicy', 'isSMSReminder']}
                    valuePropName="checked"
                    initialValue={
                      role !== SubscriptionsEnum.free
                        ? activeMeeting.notificationsPolicy.isSMSReminder
                        : false
                    }
                  >
                    <Switch
                      size="small"
                      disabled={role === SubscriptionsEnum.free}
                    />
                  </Form.Item>
                </div>
              </div>
            </InputWithLabel>
          </div>
        </div>
      </div>
      <div className="meetingSettings-footer">
        <div className="meetingSettings-footer-container">
          <NavLink className="meetingSettings-footer-back" to={routers.HOME}>
            <BackArrow />
            Back
          </NavLink>
          <div className="meetingSettings-footer-buttonGroup">
            {changed && <span onClick={resetHandler}>Revert Changes</span>}
            <ButtonMenuStyled disabled={!changed} htmlType="submit">
              {submitName}
            </ButtonMenuStyled>
          </div>
        </div>
      </div>
    </Form>
  );
};
