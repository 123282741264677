import React, { useEffect, useState } from 'react';
import { Dashboard } from '../../components/dashboardLayout';
import { InputWithLabel } from '../../components/input/inputWithLabel';
import { AvailableTime } from '../../components/availableTime';
import { TimezoneSelector } from '../../components/select/timezone';
import appConfig from '../../constants/appConfig';
import {
  changeAvailabilityAction,
  loadTimezonesAction,
} from '../../redux/actions';
import { useDispatch } from 'react-redux';
import EarthIcon from '../../components/icons/earth';
import { ButtonMenuStyled } from '../../components/button/styledButton';
import Form from 'antd/lib/form';
import { useUserInfoSelector } from '../../redux/selectors/auth';
import { AvailableDays } from '../../components/availableDays';
import { DaysOfWeek } from '../../models/days-of-week.model';
import * as _ from 'lodash';
import './index.scss';
import { appHistory, routers } from '../../router';

export const Availability: React.FC = () => {
  const dispatch = useDispatch();
  const user = useUserInfoSelector();
  const [form] = Form.useForm();
  const [dayOfWeek, setDayOfWeek] = useState<DaysOfWeek[]>(
    user?.defaultAvailabilityTime.days || [],
  );
  useEffect(() => {
    dispatch(loadTimezonesAction());
  }, [dispatch]);

  const daysHandler = (day: DaysOfWeek) => {
    const isActive = _.some(dayOfWeek, (el) => el === day);
    if (isActive) {
      setDayOfWeek(_.without(dayOfWeek, day));
      form.setFieldsValue({ days: _.without(dayOfWeek, day) });
    } else {
      setDayOfWeek([...dayOfWeek, day]);
      form.setFieldsValue({ days: [...dayOfWeek, day] });
    }
  };

  const submitHandler = (e: any) => {
    const dto = {
      availability: {
        hours: {
          from: e.hours[0],
          to: e.hours[1],
        },
        days: e.days,
      },
      timezone: e.timezone,
    };
    dispatch(changeAvailabilityAction({ updateAvailability: dto }));
  };

  return (
    <Dashboard>
      <div className="availability-page-container">
        <Form form={form} onFinish={submitHandler}>
          <div className="availability-page-body">
            <div className="availability-page-body-header">
              Edit your default availability and apply updates to all your
              meeting types.
            </div>
            <InputWithLabel
              label="Available Hours"
              tooltip="Set the hours that would be available for meetings to be scheduled on your calendar."
            >
              <div className="time-selector">
                <Form.Item
                  name="hours"
                  initialValue={[
                    user?.defaultAvailabilityTime.hours.from,
                    user?.defaultAvailabilityTime.hours.to,
                  ]}
                >
                  <AvailableTime
                    max={appConfig.availableTime.max}
                    min={appConfig.availableTime.min}
                    step={appConfig.availableTime.step}
                    tooltipVisible={true}
                  />
                </Form.Item>
                <Form.Item
                  name="timezone"
                  initialValue={user?.timezone || 'utc'}
                >
                  <TimezoneSelector
                    wrapperClassName="timezone-selector-wrapper"
                    className="timezone-selector"
                    dropdownClassName="timezone-selector-dropdown"
                    //onChange={timezoneHandler}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    getPopupContainer={() =>
                      document.getElementsByTagName('section')[0]!
                    }
                    icon={<EarthIcon className="timezone-selector-icon" />}
                  />
                </Form.Item>
              </div>
            </InputWithLabel>
            <InputWithLabel
              label="Available Days"
              tooltip="Set the days that would be available for meetings to be scheduled on your calendar."
              className="availableDays"
            >
              <Form.Item name="days" initialValue={dayOfWeek}>
                <AvailableDays activeDays={dayOfWeek} onClick={daysHandler} />
              </Form.Item>
            </InputWithLabel>
          </div>
          <div className="availability-page-buttons">
            <span onClick={() => appHistory.push(routers.HOME)}>Cancel</span>
            <ButtonMenuStyled htmlType="submit">
              Save & Update All Meeting Types
            </ButtonMenuStyled>
          </div>
        </Form>
      </div>
    </Dashboard>
  );
};
