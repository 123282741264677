import { TimezoneApiResponseDto } from './dtos/timezone.api.dto';
import { left, right, Either } from '@sweet-monads/either';
import { UnknownError } from './errors';
import BaseAxiosInstance from '../../utils/axios-config';

export type GetTimezonesResult = Either<UnknownError, TimezoneApiResponseDto[]>;

export class TimezoneService {
  async getTimezones(): Promise<GetTimezonesResult> {
    try {
      const result = await BaseAxiosInstance.get<TimezoneApiResponseDto[]>(
        '/api/settings/timezones',
      );
      return right(result.data);
    } catch (e) {
      return left(new UnknownError(e));
    }
  }
}

export const timezoneService = new TimezoneService();
