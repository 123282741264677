import React from 'react';
import { Dashboard } from '../../components/dashboardLayout';
import { useDispatch } from 'react-redux';
import { SettingsForm } from '../meetingSettings/form';
import { LocationTypeEnum } from '../../models/meeting.model';
import { useUserInfoSelector } from '../../redux/selectors/auth';
import {
  createNewMeetingAction,
  CreateNewMeetingParams,
} from '../../redux/actions';

export const NewMeeting: React.FC = () => {
  const dispatch = useDispatch();
  const user = useUserInfoSelector();

  const submitHandler = (e: CreateNewMeetingParams) => {
    dispatch(createNewMeetingAction(e));
  };

  const defaultMeeting = {
    duration: 30,
    name: '',
    slug: '',
    timezone: user?.timezone ? user?.timezone : 'utc',
    isActive: true,
    availabilityTime: {
      days: user?.defaultAvailabilityTime.days
        ? user?.defaultAvailabilityTime.days
        : [],
      hours: {
        to: user?.defaultAvailabilityTime.hours.to
          ? user?.defaultAvailabilityTime.hours.to
          : 1080,
        from: user?.defaultAvailabilityTime.hours.from
          ? user?.defaultAvailabilityTime.hours.from
          : 420,
      },
    },
    notificationsPolicy: {
      isEmailReminder: true,
      isSMSReminder: true,
    },
    locationConfigurations: {
      additionalInfo: null,
      kind: LocationTypeEnum.jammerConference,
    },
    calendarInvitations: {
      body:
        'Event Name: {{meeting_name}}\nEvent Description: {{event_description}}\nEvent location: {{location}}\nGuest phone: {{guest_phone}}',
      title: '{{guest_name}} and {{my_name}}',
    },
  };

  return (
    <Dashboard>
      <SettingsForm
        activeMeeting={defaultMeeting}
        submitHandler={submitHandler}
        submitName="Create meeting"
      />
    </Dashboard>
  );
};
