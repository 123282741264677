import React from 'react';

const ColorPaletteIcon: React.FC<{ className?: string }> = (props) => {
  return (
    <svg
      className={`${props.className || ''}`}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.74999 5.41667C8.74999 4.72667 9.30999 4.16667 9.99999 4.16667C10.69 4.16667 11.25 4.72667 11.25 5.41667C11.25 6.10667 10.69 6.66667 9.99999 6.66667C9.30999 6.66667 8.74999 6.10667 8.74999 5.41667ZM12.2508 7.7085C11.9058 7.111 12.1108 6.346 12.7083 6.001C13.3067 5.656 14.0708 5.861 14.4158 6.4585C14.7608 7.05684 14.5558 7.821 13.9583 8.166C13.36 8.511 12.5958 8.30684 12.2508 7.7085ZM7.29166 6.001C6.69416 5.656 5.92916 5.861 5.58416 6.4585C5.23916 7.05684 5.44416 7.821 6.04166 8.166C6.63999 8.511 7.40416 8.30684 7.74916 7.7085C8.09416 7.111 7.88999 6.346 7.29166 6.001ZM4.79758 11.1157C4.41091 10.544 4.56174 9.7665 5.13424 9.38067C5.70674 8.994 6.48341 9.14567 6.86924 9.71733C7.25591 10.2898 7.10424 11.0665 6.53258 11.4523C5.96008 11.839 5.18341 11.6882 4.79758 11.1157ZM13.2341 12.5008H11.8591C10.7099 12.5008 9.77575 13.435 9.77575 14.5842C9.77575 15.0892 9.96241 15.5783 10.3016 15.9617C10.4049 16.0783 10.4391 16.2158 10.4024 16.3683C10.3616 16.5392 10.1649 16.65 9.90741 16.6667C8.00491 16.6408 6.19908 15.7983 4.95241 14.3558C3.68991 12.8933 3.13658 11.0192 3.39491 9.07917C3.82075 5.885 6.69325 3.36084 9.93575 3.33334H9.99825C11.9624 3.33334 13.8132 4.08334 15.0874 5.39834C16.1607 6.50584 16.7207 7.91834 16.6624 9.375C16.5966 11.04 14.9941 12.5008 13.2341 12.5008ZM16.2832 4.2375C14.6807 2.58417 12.3457 1.66084 9.92158 1.66667H9.92074C5.80075 1.70167 2.28408 4.79334 1.74325 8.85917C1.42491 11.2425 2.13575 13.6433 3.69158 15.445C5.24825 17.2483 7.50574 18.3008 9.88408 18.3333H9.91574C10.9157 18.3333 11.7974 17.6775 12.0216 16.7617C12.1874 16.0767 12.0149 15.3817 11.5491 14.855C11.4807 14.7792 11.4424 14.68 11.4424 14.5842C11.4424 14.355 11.6291 14.1675 11.8591 14.1675H13.2341C15.8941 14.1675 18.2266 12.0025 18.3274 9.44084C18.4041 7.52417 17.6782 5.67667 16.2832 4.2375Z"
        fill="#231F20"
      />
    </svg>
  );
};

export default ColorPaletteIcon;
