import React from 'react';
import { Button as ButtonAnt } from 'antd';
import { ButtonProps as ButtonPropsAnt } from 'antd/lib/button';
import cn from 'classnames';
import './index.scss';

export type ButtonProps = ButtonPropsAnt;

export const Button: React.FC<ButtonProps> = ({ className, ...rest }) => {
  return <ButtonAnt {...rest} className={cn(className, 'styled-button')} />;
};
