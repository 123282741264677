import React from 'react';
import './confirmed.scss';
import { EventData } from '../index';
import { MeetingToBookEventModel } from '../../../models/meeting.model';
import { UserLogo } from '../../../components/userLogo';
import { ScheduleSelectedTime } from '../../../components/scheduleSelectedTime';
import { Button } from '../../../components/button';
import { routers } from '../../../router';

interface props {
  eventData: EventData;
  meetingBookInfo: MeetingToBookEventModel | null;
}

export const ConfirmedSchedule: React.FC<props> = (props) => {
  return (
    <div className="schedule-confirmed">
      <div className="schedule-confirmed-header">
        <UserLogo avatar={props.meetingBookInfo?.design.logo} />
        <div className="schedule-confirmed-username">
          Meeting Confirmed With {props.meetingBookInfo?.owner.name}
        </div>
        <div className="header-title">
          A calendar invitation has been sent to your email address.
        </div>
        <ScheduleSelectedTime
          color={props.meetingBookInfo?.design.color}
          time={props.eventData.date}
          duration={props.meetingBookInfo?.duration}
          timezone={props.eventData.timezone}
        />
      </div>
      <div className="schedule-confirmed-body">
        <div className="body-title">What Happens Next?</div>
        <ul>
          <li>
            <span
              style={
                props.meetingBookInfo?.design.color
                  ? {
                      color: props.meetingBookInfo?.design.color,
                      border: `1px solid ${props.meetingBookInfo?.design.color}`,
                    }
                  : undefined
              }
            >
              1
            </span>
            Get a reminder right before your meeting.
          </li>
          <li>
            <span
              style={
                props.meetingBookInfo?.design.color
                  ? {
                      color: props.meetingBookInfo?.design.color,
                      border: `1px solid ${props.meetingBookInfo?.design.color}`,
                    }
                  : undefined
              }
            >
              2
            </span>
            Click the meeting location link to join.
          </li>
          <li>
            <span
              style={
                props.meetingBookInfo?.design.color
                  ? {
                      color: props.meetingBookInfo?.design.color,
                      border: `1px solid ${props.meetingBookInfo?.design.color}`,
                    }
                  : undefined
              }
            >
              3
            </span>
            It’s that easy.
          </li>
        </ul>
      </div>
      <div className="schedule-confirmed-footer">
        <div className="footer-title">The simpliest way to meet.</div>
        <a href={routers.LANDING_PAGE}>
          <Button
            style={
              props.meetingBookInfo?.design.color
                ? {
                    backgroundColor: props.meetingBookInfo?.design.color,
                    borderColor: props.meetingBookInfo?.design.color,
                  }
                : undefined
            }
          >
            Try Jammer For Free
          </Button>
        </a>
      </div>
    </div>
  );
};
