import { AuthApi } from './auth.api';
import {
  ApproveUserEmailResult,
  ChangeUserCredentialsResult,
  ForgotPasswordResult,
  GetAuthMethodByEmailResultDto,
  GetProfileResultDto,
  LoginByPasswordDto,
  LoginByPasswordResultDto,
  ResetForgotPasswordResult,
  SingUpByPasswordDto,
  SingUpByPasswordResultDto,
  SubscriptionResultDto,
} from './dtos/auth.dto';
import { left, right } from '@sweet-monads/either';
import { AuthTokenExpiredError, AuthTokenInvalidError } from './errors';
import { UnknownError } from '../common/errors';
import { SessionService } from './session.service';
import { ChangeUserCredentialsModel } from '../../models/user.model';
import { serverUrl } from '../../utils/axios-config';
import { routers } from '../../router';

export class AuthService {
  constructor(
    private readonly authApi: AuthApi,
    private readonly sessionService: SessionService,
  ) {}

  async getProfile(): Promise<GetProfileResultDto> {
    const accessToken = this.sessionService.getAccessToken();
    if (!accessToken) {
      return left(new AuthTokenInvalidError());
    }
    if (this.sessionService.isAccessTokenExpired(accessToken)) {
      return left(new AuthTokenExpiredError());
    }

    return this.authApi.getProfile(accessToken.token);
  }

  async getSubscriptions(): Promise<SubscriptionResultDto> {
    const accessToken = this.sessionService.getAccessToken();
    if (!accessToken) {
      return left(new AuthTokenInvalidError());
    }
    if (this.sessionService.isAccessTokenExpired(accessToken)) {
      return left(new AuthTokenExpiredError());
    }

    return this.authApi.getSubscriptions(accessToken.token);
  }

  async logout(): Promise<any> {
    const accessToken = this.sessionService.getAccessToken();
    if (!accessToken) {
      return;
    }
    if (this.sessionService.isAccessTokenExpired(accessToken)) {
      this.sessionService.deleteAccessToken();
      return;
    }

    await this.authApi.logout(accessToken.token);
    this.sessionService.deleteAccessToken();
  }

  async loginByPassword(
    props: LoginByPasswordDto,
  ): Promise<LoginByPasswordResultDto> {
    const result = await this.authApi.loginByPassword(props);
    if (result.isLeft()) {
      return result;
    }

    this.sessionService.saveAccessToken({
      token: result.value.token,
      expiredAt: result.value.tokenExpiredAt,
    });
    return result;
  }

  async loginByGoogle(): Promise<any> {
    await this.openSignInGoogleWindow();
  }

  async loginForGoogleCalendar(): Promise<any> {
    const profileResult = await this.getProfile();

    if (profileResult.isLeft()) {
      this.sessionService.deleteAccessToken();
      return left(new UnknownError(profileResult.value.originalError));
    }
    const userId = profileResult.value.user.id;
    const state = JSON.stringify({ state: 'calendar', userId: userId });
    const url = `${serverUrl}${routers.GOOGLE_CALENDAR_CALLBACK}?state=${state}`;
    document.cookie = `userId=${userId};max-age=604800;`;
    window.location.href = url;
  }

  async singUpByPassword(
    dto: SingUpByPasswordDto,
  ): Promise<SingUpByPasswordResultDto> {
    return this.authApi.singUpByPassword(dto);
  }

  async getAuthMethodByEmail(
    email: string,
  ): Promise<GetAuthMethodByEmailResultDto> {
    return this.authApi.getAuthMethodByEmail(email);
  }
  //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  private async openSignInGoogleWindow(state = 'login'): Promise<void> {
    const urlState = JSON.stringify({ state: state, userId: null });
    window.location.href = `${serverUrl}${routers.GOOGLE_CALENDAR_CALLBACK}?state=${urlState}`;
  }

  forgotPassword(email: string): Promise<ForgotPasswordResult> {
    return this.authApi.forgotPassword(email);
  }

  resetForgotPassword(props: {
    token: string;
    password: string;
  }): Promise<ResetForgotPasswordResult> {
    return this.authApi.resetForgotPassword(props);
  }

  async approveUserEmail(params: {
    token: string;
    password: string;
  }): Promise<ApproveUserEmailResult> {
    const result = await this.authApi.approveUserEmail(params);
    if (result.isRight()) {
      this.sessionService.saveAccessToken({
        token: result.value.token,
        expiredAt: result.value.tokenExpiredAt,
      });
    }

    return result;
  }

  async changeUserCredentials(
    params: ChangeUserCredentialsModel,
  ): Promise<ChangeUserCredentialsResult> {
    const token = this.sessionService.getAccessToken();

    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }

    return this.authApi.changeUserCredentials(token, params);
  }

  async changeUserGoogleEmail(state: string): Promise<any> {
    this.openSignInGoogleWindow(state);

    const token = this.sessionService.getAccessToken();

    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }

    const profileResult = await this.getProfile();

    if (profileResult.isLeft()) {
      this.sessionService.deleteAccessToken();
      return left(new UnknownError(profileResult.value.originalError));
    }
    const userId = profileResult.value.user.id;
    document.cookie = `userId=${userId};max-age=604800;`;

    return right(profileResult.value);
  }

  async acceptUser(inviteToken: string): Promise<any> {
    return await this.authApi.acceptUser(inviteToken);
  }

  async inviteCheckUser(inviteToken: string): Promise<any> {
    return await this.authApi.inviteCheckUser(inviteToken);
  }
}

export const authService = new AuthService(new AuthApi(), new SessionService());
