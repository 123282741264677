import { MeetingDateRangeModel } from '../../models/meeting-date-range.model';
import { ModalTypes } from '../interfaces/modal';
import { showModalDialogAction } from './modal';
import { TimeRangeModel } from '../../models/time-range.model';
import { MeetingRulesAvailabilityModel } from '../../models/meeting-rules-availability.model';
import { actionBuilder } from '../utils';
import * as ActionTypes from '../constants';
import { OffsetPaginationDto } from '../../libs/common/dtos/offset-pagination.dto';
import {
  AvailabilityTimeModel,
  LocationConfigurationsModel,
  LocationKindEnum,
  MeetingDataBookEmailModel,
  MeetingEditModel,
  MeetingEventNotificationsPolicyModel,
  MeetingModel,
  MeetingOnListModel,
  NotificationPolicyEnum,
} from '../../models/meeting.model';
import { ExceptionDetails } from '../../utils/errors';
import { createAction, PayloadAction } from '@reduxjs/toolkit';
//event Notifications

export interface ShowMeetingDateRangeModalDialog {
  onSubmit(dateRange: MeetingDateRangeModel): void;
}
export const showMeetingDateRangeModalDialogAction = (
  props: ShowMeetingDateRangeModalDialog,
): PayloadAction<unknown> => {
  return showModalDialogAction({
    payload: props,
    type: ModalTypes.meetingDateRange,
  });
};

export interface ShowMeetingTimezoneModalDialog {
  timezone: string;
  onSubmit(timezone: string): void;
}
export const showMeetingTimezoneModalDialogAction = (
  props: ShowMeetingTimezoneModalDialog,
): PayloadAction<unknown> => {
  return showModalDialogAction({
    payload: props,
    type: ModalTypes.meetingTimezone,
  });
};

export interface ShowMeetingRuleAvailabilityModalDialog {
  date: Date;
  intervals: TimeRangeModel[];
  duration: number;
  onSubmit(
    dtos: MeetingRulesAvailabilityModel[],
    shouldOverrideDateSpecificRule: boolean,
  ): void;
  rulesAvailability: MeetingRulesAvailabilityModel[];
}
export const showMeetingRuleAvailabilityModalDialogAction = (
  props: ShowMeetingRuleAvailabilityModalDialog,
): PayloadAction<unknown> => {
  return showModalDialogAction({
    payload: props,
    type: ModalTypes.meetingRuleAvailability,
  });
};

export type FetchMeetingParamAction = {
  page?: number;
};
export const {
  request: fetchMeetingsAction,
  success: fetchMeetingsSuccessAction,
  error: fetchMeetingsErrorAction,
} = actionBuilder<
  FetchMeetingParamAction,
  OffsetPaginationDto<MeetingOnListModel>,
  null
>(ActionTypes.FETCH_MEETINGS);

export const updateMeetingsOrderAction = createAction<MeetingOnListModel[]>(
  ActionTypes.UPDATE_MEETINGS_ORDER,
);

export interface FetchMeetingByIdToEditParams {
  id: string;
}
export const {
  request: fetchMeetingByIdToEditAction,
  success: fetchMeetingByIdToEditSuccessAction,
  error: fetchMeetingByIdToEditErrorAction,
} = actionBuilder<
  FetchMeetingByIdToEditParams,
  { meeting: MeetingModel },
  void
>(ActionTypes.FETCH_MEETING_BY_ID_TO_EDIT);

export interface EditMeetingByIdParams {
  id: string;
  fields: MeetingEditModel;
}
export const {
  request: editMeetingByIdAction,
  success: editMeetingByIdSuccessAction,
  error: editMeetingByIdErrorAction,
} = actionBuilder<
  EditMeetingByIdParams,
  { meeting: MeetingModel | null },
  { details: ExceptionDetails }
>(ActionTypes.EDIT_MEETING_BY_ID);
export const clearErrorsOnEditMeetingById = createAction<string[]>(
  ActionTypes.EDIT_MEETING_BY_ID.CLEAR_ERRORS,
);

export interface ChangeMeetingParams {
  id: string;
  fields: { isActive: boolean };
}

export const changeMeetingActiveById = createAction<ChangeMeetingParams>(
  ActionTypes.CHANGE_MEETING_ACTIVE_BY_ID,
);

export const clearErrorsNewMeeting = createAction(
  ActionTypes.CLEAR_ERRORS_NEW_MEETING,
);

export interface CreateNewMeetingParams {
  name: string;
  slug: string;
  description?: string;
  timezone: string;
  isActive: boolean;
  duration: number;
  availabilityTime: AvailabilityTimeModel;
  notificationsPolicy: MeetingEventNotificationsPolicyModel;
  locationConfigurations: LocationConfigurationsModel;
}
export const {
  request: createNewMeetingAction,
  success: createNewMeetingSuccessAction,
  error: createNewMeetingErrorAction,
} = actionBuilder<
  CreateNewMeetingParams,
  { meeting: MeetingModel },
  { details: ExceptionDetails }
>(ActionTypes.CREATE_NEW_MEETING);

export interface ShowBookByEmailModalParam {
  meeting: MeetingOnListModel;
  userSlug: string;
  getMeetingBookEmailData(meeting: MeetingOnListModel): void;
}

export const showBookByEmailModalDialogAction = (
  props: ShowBookByEmailModalParam,
): PayloadAction<unknown> => {
  return showModalDialogAction({
    payload: props,
    type: ModalTypes.bookByEmail,
  });
};

export interface FetchMeetingBookEmailDataParams {
  meetingId: string;
}

export const {
  request: fetchMeetingBookEmailDataAction,
  success: fetchMeetingBookEmailDataSuccessAction,
  error: fetchMeetingBookEmailDataErrorAction,
} = actionBuilder<
  FetchMeetingBookEmailDataParams,
  { bookByEmailData: MeetingDataBookEmailModel },
  { details: ExceptionDetails }
>(ActionTypes.FETCH_MEETING_BOOK_EMAIL_DATA);

export type ChangeNotificationsPolicyParams = {
  meetingId: string;
  notificationType?: NotificationPolicyEnum;
  isEmailReminder?: boolean;
  isSMSReminder?: boolean;
  emailConfirmation?: string;
  emailCancellation?: string;
  calendarInvitation?: string;
};

export const {
  request: changeNotificationRemindersAction,
  success: changeNotificationsPolicySuccessAction,
  error: changeNotificationRemindersErrorAction,
} = actionBuilder<
  ChangeNotificationsPolicyParams,
  { notificationPolicy: ChangeNotificationsPolicyParams },
  { details: ExceptionDetails }
>(ActionTypes.CHANGE_NOTIFICATION_REMINDERS);

export interface ShowMeetingLocationModalDialog {
  selectedKey: LocationKindEnum;
  conferencePhone: string | null;
  locationConfigurations: LocationConfigurationsModel;
  onSubmit(dto: LocationConfigurationsModel): void;
}
export const showMeetingLocationModalDialogAction = (
  props: ShowMeetingLocationModalDialog,
): PayloadAction<unknown> => {
  return showModalDialogAction({
    payload: props,
    type: ModalTypes.meetingLocation,
  });
};

export interface ShowSuccessEventModalDialog {
  title: string;
  message: string;
}
export const showSuccessEventModalDialogAction = (
  props: ShowSuccessEventModalDialog,
): PayloadAction<unknown> => {
  return showModalDialogAction({
    payload: props,
    type: ModalTypes.successEventModal,
  });
};
export type DeleteEventParams = {
  meetingId: string;
};

export const {
  request: deleteMeetingAction,
  success: deleteMeetingSuccessAction,
  error: deleteMeetingErrorAction,
} = actionBuilder<
  DeleteEventParams,
  { meetingId: string },
  { details: ExceptionDetails }
>(ActionTypes.DELETE_MEETING);

export interface deleteMeetingModalParam {
  onSubmit(): void;
}

export interface setLocationParam {
  onSubmit(value: LocationConfigurationsModel): void;
  default: LocationConfigurationsModel;
}

export const setLocationModalDialogAction = (
  props: setLocationParam,
): PayloadAction<unknown> => {
  return showModalDialogAction({
    payload: props,
    type: ModalTypes.setLocation,
  });
};

export interface CalendarInvitation {
  title: string | null;
  body: string | null;
}

export interface SetCalendarInvitationParams {
  default: CalendarInvitation;
  onSubmit(value: CalendarInvitation): void;
}

export const setCalendarInvitation = (
  props: SetCalendarInvitationParams,
): PayloadAction<unknown> => {
  return showModalDialogAction({
    payload: props,
    type: ModalTypes.setCalendarInvitation,
  });
};
