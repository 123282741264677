import React, { useState } from 'react';
import './index.scss';
import moment from 'moment-timezone';

interface props {
  color?: string | null;
  times?: string[];
  setSelectedTime(value: string): void;
}

export const MonthSelectTime: React.FC<props> = (props) => {
  const [hover, setHover] = useState<number | undefined>(undefined);
  const hoverHandler = (index: any) => {
    setHover(index);
  };
  const hoverHandlerOut = () => {
    setHover(undefined);
  };
  return (
    <>
      <div className="monthSelectTime-container">
        {props.times &&
          props.times.map((item, index) => {
            return (
              <div
                key={index}
                onPointerEnter={() => hoverHandler(index)}
                onPointerLeave={hoverHandlerOut}
                style={
                  props.color && hover === index
                    ? { backgroundColor: props.color }
                    : undefined
                }
                className="time-item"
                onClick={() => props.setSelectedTime(item)}
              >
                {moment(item).utc().format('LT')}
              </div>
            );
          })}
      </div>
    </>
  );
};
