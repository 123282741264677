import React from 'react';
import { Button } from '../../../components/button';
import './trial.scss';
import { AwardIcon } from '../../../components/icons/award';
import { CheckIcon } from '../../../components/icons/check/ checkIcon';
import { Badge } from '../../../components/badge';
import { PlusIcon } from '../../../components/icons/plus';
import { Subscription } from '../../../models/user.model';
import { SubscriptionsEnum } from '../../../models/subscription.model';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import {
  buySubscription,
  enterCardModalDialogAction,
} from '../../../redux/actions/profile';

interface Props {
  subscription: Subscription;
}

const basic = [
  'Unlimited Scheduling',
  'Personalized Jammer Link',
  'Email Reminders',
  'Automated Calendar Invitations',
  'Dark Mode',
];

const platinum = [
  {
    text: 'Pinless Conference Line',
    label: '',
  },
  {
    text: 'Up to 100 Participants',
    label: '',
  },
  {
    text: 'Unlimited Conference Meetings',
    label: '',
  },
  {
    text: 'Unlimited Talk Time',
    label: '',
  },
  {
    text: 'Text Reminders',
    label: '',
  },
  {
    text: 'Unlimited Meeting Types',
    label: '',
  },
  {
    text: 'Custom Branding',
    label: '',
  },
  {
    text: 'Running Late Notifications',
    label: 'COMING SOON',
  },
  {
    text: 'Add Team Members',
    label: 'COMING SOON',
  },
];
export const BillingTrial: React.FC<Props> = ({ subscription }) => {
  const dispatch = useDispatch();
  const buyHandler = (e: any) => {
    e.preventDefault();
    dispatch(
      enterCardModalDialogAction({
        onSubmit: buySubscription,
        header: 'Upgrade To Pro!',
      }),
    );
  };

  return (
    <div className="billingTrial">
      <div className="billingTrial-header">
        <div className="header-title">
          You are currently enjoying the{' '}
          {subscription.typeName === SubscriptionsEnum.trial
            ? 'platinum trial'
            : subscription.typeName}
          .
        </div>
        <div className="header-subtitle">
          {subscription.typeName === SubscriptionsEnum.trial &&
            `On ${moment(subscription.expiredAt).format(
              'MMMM D, YYYY',
            )} you'll be downgraded to the free plan.`}
        </div>
        <Button onClick={buyHandler}>
          <AwardIcon />
          Purchase Platinum
        </Button>
        <div className="header-price">Just $19/month</div>
      </div>
      <div className="billingTrial-body">
        <div className="body-header">
          Keep all features when you purchase Platinum
        </div>
        <div className="body-content">
          <div className="col">
            <div className="col-title">Basic Features</div>
            <ul>
              {basic.map((item, index) => (
                <li key={index}>
                  <CheckIcon />
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="col platinum">
            <div className="col-title">Platinum Features</div>
            <ul>
              {platinum.map((item, index) => (
                <li key={index}>
                  <AwardIcon />
                  {item.text}
                  {item.label ? <Badge>{item.label}</Badge> : null}
                </li>
              ))}
              <li>
                <PlusIcon />
                All Basic Features
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
