import * as _ from 'lodash';
import { AccessTokenDto } from './dtos/access-token.dto';
import appConfig from '../../constants/appConfig';

export class SessionService {
  getProjectNameInLowerCase(): string {
    return appConfig.projectName.toLowerCase();
  }

  deleteAccessToken(): void {
    localStorage.removeItem(`${this.getProjectNameInLowerCase()}.at`);
    localStorage.removeItem(`${this.getProjectNameInLowerCase()}.at_exp_t`);
  }

  saveAccessToken(props: AccessTokenDto): void {
    localStorage.setItem(`${this.getProjectNameInLowerCase()}.at`, props.token);
    localStorage.setItem(
      `${this.getProjectNameInLowerCase()}.at_exp_t`,
      props.expiredAt.toString(),
    );
  }

  getAccessToken(): AccessTokenDto | null {
    const token = localStorage.getItem(
      `${this.getProjectNameInLowerCase()}.at`,
    );
    const expiredAt = localStorage.getItem(
      `${this.getProjectNameInLowerCase()}.at_exp_t`,
    );

    if (token && expiredAt) {
      return {
        token,
        expiredAt: parseInt(expiredAt, 10),
      };
    }

    return null;
  }

  isAccessTokenExpired(params: AccessTokenDto | null): params is null {
    if (!params) {
      return true;
    }

    if (_.isNaN(params.expiredAt)) {
      return true;
    }
    return params.expiredAt < Date.now();
  }
}

export const sessionService = new SessionService();
