import React from 'react';

const ArrowDownIcon: React.FC<{ className?: string }> = (props) => {
  return (
    <div
      className={`arrow-user-logo${props.className || ''}`}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 16C11.772 16 11.545 15.923 11.36 15.768L5.35997 10.768C4.93597 10.415 4.87797 9.78398 5.23197 9.35998C5.58497 8.93598 6.21497 8.87898 6.63997 9.23198L12.011 13.708L17.373 9.39298C17.803 9.04698 18.433 9.11498 18.779 9.54498C19.125 9.97498 19.057 10.604 18.627 10.951L12.627 15.779C12.444 15.926 12.222 16 12 16Z"
          fill="#8C8C8C"
        />
      </svg>
    </div>
  );
};

export default ArrowDownIcon;
