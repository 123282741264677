import { actionBuilder } from '../utils';
import * as ActionTypes from '../constants';
import {
  EventDataModel,
  MeetingDateSlotsToBookEventModel,
  MeetingToBookEventModel,
} from '../../models/meeting.model';

export interface FetchMeetingByPublicLinkParams {
  userSlug: string;
  meetingSlug: string;
}

export const {
  request: fetchMeetingByPublicLinkAction,
  error: fetchMeetingByPublicLinkErrorAction,
  success: fetchMeetingByPublicLinkSuccessAction,
} = actionBuilder<
  FetchMeetingByPublicLinkParams,
  MeetingToBookEventModel,
  void
>(ActionTypes.FETCH_MEETING_BY_PUBLIC_LINK);

export interface FetchMeetingSlotsToBookEventParams {
  meetingId: string;
  rangeFrom: string;
  rangeTo: string;
  timezone: string;
}
export const {
  request: fetchMeetingSlotsToBookEventAction,
  error: fetchMeetingSlotsToBookEventErrorAction,
  success: fetchMeetingSlotsToBookEventSuccessAction,
} = actionBuilder<
  FetchMeetingSlotsToBookEventParams,
  MeetingDateSlotsToBookEventModel,
  void
>(ActionTypes.FETCH_MEETING_SLOT_TO_BOOK_EVENT);

export interface ScheduleMeetingBookEventParams {
  meetingId: string;
  eventStartDate: string;
  name: string;
  timezone: string;
  email: string;
  phone: string;
  guests?: string[];
}
export const {
  request: scheduleMeetingBookEventAction,
  error: scheduleMeetingBookEventErrorAction,
  success: scheduleMeetingBookEventSuccessAction,
} = actionBuilder<
  ScheduleMeetingBookEventParams,
  { eventId: string | null },
  void
>(ActionTypes.SCHEDULE_MEETING_BOOK_EVENT);

export interface RescheduleMeetingBookEventParams
  extends ScheduleMeetingBookEventParams {
  eventId: string;
  token: string;
}

export const {
  request: rescheduleMeetingBookEventAction,
  error: rescheduleMeetingBookEventErrorAction,
  success: rescheduleMeetingBookEventSuccessAction,
} = actionBuilder<
  RescheduleMeetingBookEventParams,
  { eventId: string | null },
  void
>(ActionTypes.RESCHEDULE_MEETING_BOOK_EVENT);

export interface FetchBookEventInfoParams
  extends FetchMeetingByPublicLinkParams {
  eventId: string;
}

export const {
  request: fetchBookCompletedEventAction,
  success: fetchBookCompletedEventSuccessAction,
  error: fetchBookCompletedErrorAction,
} = actionBuilder<FetchBookEventInfoParams, EventDataModel, any>(
  ActionTypes.FETCH_BOOK_COMPLETED_EVENT,
);

export interface FetchBookEventParams {
  id: string;
}

export const {
  request: fetchBookEventAction,
  success: fetchBookEventSuccessAction,
  error: fetchBookErrorAction,
} = actionBuilder<FetchBookEventParams, EventDataModel, any>(
  ActionTypes.FETCH_BOOK_EVENT,
);

export interface CancellationEventParams extends FetchBookEventParams {
  reason: string;
}

export const {
  request: cancellationEventAction,
  success: cancellationEventSuccessAction,
  error: cancellationEventErrorAction,
} = actionBuilder<CancellationEventParams, any, any>(
  ActionTypes.CANCELLATION_EVENT,
);

export interface ReschedulingEventParams extends FetchBookEventParams {
  eventId: string;
  reason: string;
  eventStartDate: string;
}

export const {
  request: reschedulingEventAction,
  success: reschedulingEventSuccessAction,
  error: reschedulingEventErrorAction,
} = actionBuilder<ReschedulingEventParams, any, any>(
  ActionTypes.RESCHEDULING_EVENT,
);
