import React from 'react';
import { ReactComponent as PhoneLogo } from './Mask.svg';

export const PhoneIcon: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <PhoneLogo />
    </div>
  );
};
