import React from 'react';
import { ReactComponent as QuestionLogo } from './Mask.svg';

export const QuestionOutlined: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <QuestionLogo />
    </div>
  );
};
