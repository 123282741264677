import React from 'react';
import './index.scss';

import { ReactComponent as DefaultLogo } from '../icons/userlogo.svg';

interface UserLogoProps {
  avatar?: string | null;
}

export const UserLogo: React.FC<UserLogoProps> = ({ avatar }) => {
  return (
    <div className="user-logo">
      {avatar ? (
        <img className="user-photo" src={avatar} alt="userLogo" />
      ) : (
        <DefaultLogo />
      )}
    </div>
  );
};
