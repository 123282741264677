import { useSelector } from './common';
import { TimezoneState } from '../reducers/timezones';
import { LoadableData } from '../interfaces';

export const useTimezoneInfoSelector = (): TimezoneState => {
  return useSelector((state) => state.timezone);
};

export const useTimezoneLoadingInfoSelector = (): LoadableData => {
  return useSelector((state) => ({
    isLoading: state.timezone.isLoading,
    isLoaded: state.timezone.isLoaded,
  }));
};
