import React, { useCallback } from 'react';
import './index.scss';
import { AvailableTime } from '../../../../../components/availableTime';
import { Button } from '../../../../../components/button';
import { TimezoneSelector } from '../../../../../components/select/timezone';
import EarthIcon from '../../../../../components/icons/earth';
import appConfig from '../../../../../constants/appConfig';

interface CompleteProfileHoursProps {
  availableTime: [number, number];
  onTimeChange(value: [number, number]): void;
  next(): void;
  className: string;
  setTimezone?(timezone: string): void;
  timezone: string;
}

export const CompleteProfileHours: React.FC<CompleteProfileHoursProps> = (
  props,
) => {
  const {
    availableTime,
    onTimeChange,
    next,
    className,
    setTimezone,
    timezone,
  } = props;

  const tooltipsAddClass = useCallback(() => {
    const tooltip = document.getElementsByClassName('ant-tooltip');
    if (tooltip) {
      const arrayOfElements = Array.from(tooltip);
      arrayOfElements.forEach((item) => {
        if (className) {
          item.classList.add(className.trim());
        }
      });
    }
    // eslint-disable-next-line
  }, [className, document.getElementsByClassName('ant-tooltip')]);

  tooltipsAddClass();

  const nextHandler = () => {
    next();
    const tooltips = document.getElementsByClassName(
      'slider-tooltip-container',
    );
    const tooltipsArray = Array.from(tooltips);
    if (tooltipsArray.length > 0) {
      tooltipsArray.forEach((item) => item.remove());
    }
  };

  return (
    <>
      <div className="complete-profile-container">
        <div className="complete-profile-header">What hours do you work?</div>
        <div className="complete-profile-title">
          Guests will be able to schedule meetings during these hours only when
          you’re available. You can always adjust your hours later.
        </div>
        <TimezoneSelector
          wrapperClassName="timezone-selector-wrapper"
          className="timezone-selector"
          dropdownClassName="timezone-selector-dropdown"
          value={timezone}
          onChange={setTimezone}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          getPopupContainer={() => document.getElementsByTagName('section')[0]!}
          icon={<EarthIcon className="timezone-selector-icon" />}
        />
        <AvailableTime
          className={`complete-profile-time-slider`}
          tooltipClass={className}
          value={availableTime}
          onChange={onTimeChange}
          max={appConfig.availableTime.max}
          min={appConfig.availableTime.min}
          step={appConfig.availableTime.step}
          tooltipVisible={true}
        />
        <Button onClick={nextHandler}>Continue</Button>
      </div>
    </>
  );
};
