import { createReducer } from '@reduxjs/toolkit';
import { getSubscriptionPlansActionSuccess } from '../actions';

enum Intervals {
  day = 'day',
  month = 'month',
  year = 'year',
}

export interface SubscriptionPlansState {
  id: number;
  name: string;
  inviteLimit: number;
  chargePerHost: number;
  trialPeriodValue: number;
  trialPeriodInterval: Intervals;
  expirePeriodValue: number;
  expirePeriodInterval: Intervals;
}

const initialSubscriptionPlansReducer: SubscriptionPlansState[] | [] = [];

export const subscriptionPlansReducer = createReducer<SubscriptionPlansState[]>(
  initialSubscriptionPlansReducer,
  (builder) => {
    builder.addCase(getSubscriptionPlansActionSuccess, (state, action) => {
      return action.payload.subscriptions;
    });
  },
);
