import React from 'react';
import { ReactComponent as AwardLogo } from './Mask.svg';

export const AwardIcon: React.FC = () => {
  return (
    <div
      className="award-icon"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <AwardLogo />
    </div>
  );
};
