import React, { useState } from 'react';
import cn from 'classnames';
import './index.scss';
import { Input } from '../input';

interface Props {
  value?: number;
  onChange(v: number | undefined): void;
}

const LIST = [15, 30, 45, 60];

export const MeetingDuration: React.FC<Props> = (props) => {
  const [customMin, setCustomMin] = useState<number | undefined>(props.value);

  const [customMinActive, setCustomMinActive] = useState<boolean>(
    !LIST.some((value) => value === props.value),
  );
  return (
    <div className="meeting-duration-input">
      <div className="meeting-duration-input-list">
        {LIST.map((v) => {
          return (
            <div
              onClick={() => {
                props.onChange(v);
                setCustomMinActive(false);
              }}
              className={cn('list-element cursor-pointer', {
                active: v === props.value && !customMinActive,
              })}
              key={v}
            >
              <div
              //className="value gray-text-color"
              >
                {v}
              </div>
              <div className="unit">min</div>
            </div>
          );
        })}
        <div
          className={cn('list-element cursor-pointer', {
            active: customMinActive,
          })}
          onClick={() => {
            setCustomMinActive(true);
            props.onChange(customMin);
          }}
        >
          <div
          //className="value gray-text-color"
          >
            {customMinActive ? (
              <Input
                requiredLabel
                min={5}
                max={2000}
                value={customMin?.toString()}
                className="meeting-duration-input-custom-min"
                onChange={(e) => {
                  const result = parseInt(e.target.value) || 0;
                  setCustomMin(result);
                  props.onChange(result);
                }}
              />
            ) : (
              '--'
            )}
          </div>
          <div className="unit">custom min</div>
        </div>
      </div>
    </div>
  );
};
