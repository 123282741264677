import { authReducer } from './auth';
import { combineReducers } from 'redux';
import { StoreState } from '../store/interface';
import { sessionReducer } from './session';
import { timezoneReducer } from './timezones';
import { modelReducer } from './modal';
import { meetingsReducer } from './meetings';
import { bookingMeetingEventReducer } from './booking-meeting-event';
import { organizationUsersReducer } from './organization-users';
import { subscriptionPlansReducer } from './subscription-plans';
import { themeReducer } from './theme';
import { designReducer } from './design';
import { notificationReducer } from './notification';

export const reducers = combineReducers<StoreState>({
  auth: authReducer,
  session: sessionReducer,
  timezone: timezoneReducer,
  modal: modelReducer,
  meetings: meetingsReducer,
  bookingMeetingEvent: bookingMeetingEventReducer,
  organizationUsers: organizationUsersReducer,
  subscriptionPlans: subscriptionPlansReducer,
  theme: themeReducer,
  design: designReducer,
  notification: notificationReducer,
});
