import { LoadableData } from '../interfaces';
import { UserModel } from '../../models/user.model';
import { ExceptionDetails } from '../../utils/errors';
import { createReducer } from '@reduxjs/toolkit';
import {
  approveUserEmailSuccessAction,
  getProfileAction,
  getProfileActionError,
  getProfileActionSuccess,
  loginByGoogleAction,
  loginByGoogleActionError,
  loginByGoogleActionSuccess,
  loginByPasswordActionSuccess,
  logoutActionSuccess,
  moveToNextProfileWizardStepAction,
  moveToNextProfileWizardStepErrorAction,
  moveToNextProfileWizardStepSuccessAction,
  updateAccountProfileErrorAction,
  updateAccountProfileSuccessAction,
  updateAccountProfileAction,
  deleteUserAccountAction,
  deleteUserAccountErrorAction,
  changeUserEmailAction,
  changeUserEmailErrorAction,
  changeUserEmailSuccessAction,
  changeUserPasswordAction,
  changeUserPasswordErrorAction,
  changeUserPasswordSuccessAction,
  getCalendarAccountsSuccessAction,
  fireLoginForGoogleCalendarMessageAction,
  loginByPasswordActionSetIsRememberMe,
  removePasswordErrorAction,
  setAvatarSizeError,
  changeAvailabilityAction,
  changeAvailabilitySuccessAction,
  changeAvailabilityErrorAction,
  buySubscription,
  delSubscription,
  updateCardInfo,
  buySubscriptionErrorAction,
  updateCardInfoErrorAction,
  updateStat,
  BillingStat,
  updateCardStat,
} from '../actions';
import {
  setLoadedStatusToLoadableData,
  setLoadingStatusToLoadableData,
} from '../utils';
import { CompleteProfileStepEnum } from '../../libs/user/utils';

export interface SessionProfileUpdatingState extends LoadableData {
  errors: ExceptionDetails;
  step: CompleteProfileStepEnum | null;
}

export interface SessionProfileDeletingState extends LoadableData {
  errors: ExceptionDetails;
}

interface ProfileCredentialsChangingState extends LoadableData {
  errors: ExceptionDetails;
}
export interface SessionProfileCredentialsChangingState {
  passwordChanging: ProfileCredentialsChangingState;
  emailChanging: ProfileCredentialsChangingState;
}

export interface SessionCalendarAccountsState {
  l?: string;
}

export interface SessionState extends LoadableData {
  user: UserModel | null;
  billingStat: BillingStat;
  isRememberMe: boolean;
  errors: ExceptionDetails;
  profileUpdating: SessionProfileUpdatingState;
  profileDeleting: SessionProfileDeletingState;
  profileCredentials: SessionProfileCredentialsChangingState;
  calendarAccounts: any;
  calendarAccountsMessage: string;
}

const initialSessionReducer: SessionState = {
  user: null,
  billingStat: { charges: null, card: null },
  isLoading: false,
  isLoaded: false,
  errors: [],
  isRememberMe: false,
  profileUpdating: {
    isLoading: false,
    isLoaded: false,
    errors: [],
    step: CompleteProfileStepEnum.slug,
  },
  profileDeleting: {
    isLoading: false,
    isLoaded: false,
    errors: [],
  },
  profileCredentials: {
    passwordChanging: {
      isLoading: false,
      isLoaded: false,
      errors: [],
    },
    emailChanging: {
      isLoading: false,
      isLoaded: false,
      errors: [],
    },
  },
  calendarAccounts: [],
  calendarAccountsMessage: '',
};

export const sessionReducer = createReducer<SessionState>(
  initialSessionReducer,
  (builder) => {
    builder
      .addCase(loginByGoogleAction, (state) => {
        state.user = null;
        state.errors = [];
        setLoadingStatusToLoadableData(state);
      })
      .addCase(loginByGoogleActionError, (state) => {
        setLoadedStatusToLoadableData(state);
      })
      .addCase(loginByGoogleActionSuccess, (state, action) => {
        state.user = action.payload.user;
        setLoadedStatusToLoadableData(state);
      })

      .addCase(loginByPasswordActionSuccess, (state, action) => {
        state.user = action.payload.user;
        setLoadedStatusToLoadableData(state);
      })

      .addCase(getProfileAction, (state) => {
        state.user = null;
        state.errors = [];
        setLoadingStatusToLoadableData(state);
      })
      .addCase(getProfileActionError, (state) => {
        state.errors = [];
        setLoadedStatusToLoadableData(state);
      })
      .addCase(getProfileActionSuccess, (state, action) => {
        state.user = action.payload.user;
        const profile = {
          name: action.payload.user.name,
          avatar: action.payload.user.avatar,
        };
        localStorage.setItem('profile', JSON.stringify(profile));
        setLoadedStatusToLoadableData(state);
      })
      .addCase(buySubscription, (state) => {
        setLoadingStatusToLoadableData(state);
      })
      .addCase(buySubscriptionErrorAction, (state) => {
        setLoadedStatusToLoadableData(state);
      })
      .addCase(updateCardInfo, (state) => {
        setLoadingStatusToLoadableData(state);
      })
      .addCase(updateCardInfoErrorAction, (state) => {
        setLoadedStatusToLoadableData(state);
      })
      .addCase(updateCardStat, (state, action) => {
        setLoadedStatusToLoadableData(state);
        state.billingStat.card = action.payload.card;
        if (state.user?.subscription) {
          state.user.subscription.isCardValid = action.payload.isCardValid;
        }
      })
      .addCase(updateStat, (state, action) => {
        state.billingStat = action.payload;
      })
      .addCase(delSubscription, (state) => {
        setLoadingStatusToLoadableData(state);
      })
      .addCase(logoutActionSuccess, (state) => {
        state.user = null;
        setLoadedStatusToLoadableData(state);
      })

      .addCase(approveUserEmailSuccessAction, (state, action) => {
        setLoadedStatusToLoadableData(state);
        state.user = action.payload.user;
      })

      .addCase(moveToNextProfileWizardStepAction, (state) => {
        setLoadingStatusToLoadableData(state.profileUpdating);
        state.profileUpdating.errors = [];
      })
      .addCase(moveToNextProfileWizardStepSuccessAction, (state, action) => {
        setLoadedStatusToLoadableData(state.profileUpdating);
        if (state.user && action.payload.updatedUser) {
          state.user = {
            ...action.payload.updatedUser,
            isFilledProfile: state.user.isFilledProfile,
          };
        }
        if (state.user && action.payload.nextStep === null) {
          state.user.isFilledProfile = true;
        }
        state.profileUpdating.step = action.payload.nextStep;
      })
      .addCase(moveToNextProfileWizardStepErrorAction, (state, action) => {
        setLoadedStatusToLoadableData(state.profileUpdating);
        state.profileUpdating.errors = action.payload.details;
      })
      .addCase(deleteUserAccountAction, (state) => {
        setLoadingStatusToLoadableData(state.profileDeleting);
      })
      .addCase(deleteUserAccountErrorAction, (state, action) => {
        setLoadedStatusToLoadableData(state.profileDeleting);
        state.profileDeleting.errors = action.payload.details;
      })
      .addCase(updateAccountProfileAction, (state) => {
        setLoadingStatusToLoadableData(state.profileUpdating);
        state.profileUpdating.errors = [];
      })
      .addCase(updateAccountProfileSuccessAction, (state, action) => {
        setLoadedStatusToLoadableData(state.profileUpdating);
        if (action.payload.updatedUser) {
          state.user = action.payload.updatedUser;
        }
      })
      .addCase(updateAccountProfileErrorAction, (state, action) => {
        setLoadedStatusToLoadableData(state.profileUpdating);
        state.profileUpdating.errors = action.payload.details;
      })
      .addCase(changeAvailabilityAction, (state) => {
        setLoadingStatusToLoadableData(state.profileUpdating);
        state.profileUpdating.errors = [];
      })
      .addCase(changeAvailabilitySuccessAction, (state, action) => {
        setLoadedStatusToLoadableData(state.profileUpdating);
        if (action.payload.updatedAvailability && state.user) {
          state.user.defaultAvailabilityTime.days =
            action.payload.updatedAvailability.availability.days;
          state.user.defaultAvailabilityTime.hours =
            action.payload.updatedAvailability.availability.hours;
          state.user.timezone = action.payload.updatedAvailability.timezone;
        }
      })
      .addCase(changeAvailabilityErrorAction, (state, action) => {
        setLoadedStatusToLoadableData(state.profileUpdating);
        state.profileUpdating.errors = action.payload.details;
      })

      .addCase(changeUserEmailAction, (state) => {
        setLoadingStatusToLoadableData(state.profileCredentials.emailChanging);
      })
      .addCase(changeUserEmailSuccessAction, (state, action) => {
        setLoadedStatusToLoadableData(state.profileCredentials.emailChanging);
        state.profileCredentials.emailChanging.errors = [];
        if (state.user?.hasOwnProperty('email')) {
          state.user.email = action.payload.email;
        }
      })
      .addCase(changeUserEmailErrorAction, (state, action) => {
        setLoadedStatusToLoadableData(state.profileCredentials.emailChanging);
        state.profileCredentials.emailChanging.errors = action.payload.details;
      })

      .addCase(changeUserPasswordAction, (state) => {
        setLoadingStatusToLoadableData(
          state.profileCredentials.passwordChanging,
        );
      })
      .addCase(changeUserPasswordSuccessAction, (state) => {
        setLoadedStatusToLoadableData(
          state.profileCredentials.passwordChanging,
        );
        state.profileCredentials.passwordChanging.errors = [];
      })
      .addCase(changeUserPasswordErrorAction, (state, action) => {
        setLoadedStatusToLoadableData(
          state.profileCredentials.passwordChanging,
        );
        state.profileCredentials.passwordChanging.errors =
          action.payload.details;
      })
      .addCase(getCalendarAccountsSuccessAction, (state, action) => {
        state.calendarAccounts = action.payload;
      })
      .addCase(fireLoginForGoogleCalendarMessageAction, (state, action) => {
        state.calendarAccountsMessage = action.payload;
      })
      .addCase(loginByPasswordActionSetIsRememberMe, (state, action) => {
        state.isRememberMe = action.payload.isRememberMe;
      })
      .addCase(removePasswordErrorAction, (state) => {
        state.profileCredentials.passwordChanging.errors = [];
      })
      .addCase(setAvatarSizeError, (state, action) => {
        const avatarErrorIndex = state.profileUpdating.errors.findIndex(
          (error) => error.path === 'avatar',
        );
        if (avatarErrorIndex !== -1) {
          state.profileUpdating.errors[avatarErrorIndex] = {
            message: action.payload.message,
            code: 'invalid.designLogo',
            path: 'avatar',
          };
        } else {
          state.profileUpdating.errors.push({
            message: action.payload.message,
            code: 'invalid.designLogo',
            path: 'avatar',
          });
        }
      });
  },
);
