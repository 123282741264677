import { put, select, takeLatest, all } from 'redux-saga/effects';
import {
  moveToNextProfileWizardStepAction,
  moveToNextProfileWizardStepErrorAction,
  MoveToNextProfileWizardStepParams,
  updateAccountProfileAction,
  UpdateAccountProfileParams,
  updateAccountProfileSuccessAction,
  deleteUserAccountAction,
  deleteUserAccountErrorAction,
  DeleteUserAvatarParam,
  deleteUserAvatarAction,
  showNotificationAction,
  getCalendarAccountsAction,
  disconnectCalendarAccountActionParams,
  disconnectCalendarAccountAction,
  getCalendarAccountsSuccessAction,
  postPayment,
  IPayment,
  showNotificationErrorAction,
  showNotificationSuccessAction,
  getProfileActionSuccess,
  changeAvailabilityAction,
  ChangeAvailabilityParams,
  changeAvailabilitySuccessAction,
  buySubscription,
  delSubscription,
  CongratulationsModalDialogAction,
  buySubscriptionErrorAction,
  updateCardInfo,
  updateCardInfoErrorAction,
  updateCardStat,
} from '../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { userService } from '../../libs/user/user.service';
import {
  DeleteUserAccountResult,
  UpdateUserProfileResult,
  ChangeAvailabilityResult,
} from '../../libs/user/dtos/user.dto';
import { appHistory, routers } from '../../router';
import { sessionService } from '../../libs/auth/session.service';

import { getProfileSagaAction } from './auth.saga';
import { StoreState } from '../store/interface';
import { CardInfoDto } from '../../libs/user/dtos/user.api.dto';

function* moveToNextProfileWizardStepSagaAction(
  action: PayloadAction<MoveToNextProfileWizardStepParams>,
) {
  const result: UpdateUserProfileResult = yield userService.updateProfile({
    timezone: action.payload.timezone,
    slug: action.payload.slug,
    defaultTimeAvailability: action.payload.availableTime,
    phone: action.payload.phone,
  });
  if (result.isLeft()) {
    if (
      result.value.code === 'validationError' ||
      result.value.code === 'userSlugConflictError'
    ) {
      yield put(
        moveToNextProfileWizardStepErrorAction({
          details: result.value.details,
        }),
      );
      return;
    }
    yield put(
      moveToNextProfileWizardStepErrorAction({
        details: [],
      }),
    );

    if (result.value.code === 'unknown') {
      alert(result.value.message);
    }
    return;
  }

  const completed = yield userService.markProfileAsCompleted();
  if (completed.isLeft()) {
    alert(completed.value.message);
    return;
  } else {
    yield getProfileSagaAction();
    appHistory.push(routers.ME_PAGE);
  }
}

function* updateAccountProfileSagaAction(
  action: PayloadAction<UpdateAccountProfileParams>,
) {
  const result = yield userService.updateProfile({
    timezone: action.payload.timezone,
    slug: action.payload.slug,
    name: action.payload.name,
    welcomeMessage: action.payload.welcomeMessage,
    dateNotation: action.payload.dateFormat,
    timeNotation: action.payload.timeFormat,
    phone: action.payload.phone,
  });

  if (result.isLeft()) {
    if (result.value.details) {
      yield all(
        result.value.details.map(function* (item: any) {
          yield put(
            showNotificationErrorAction({
              content: item.message ? item.message : 'Server error. Try later',
            }),
          );
        }),
      );
    }
    return;
  }

  yield put(
    updateAccountProfileSuccessAction({
      updatedUser: result.value?.user || null,
    }),
  );
  yield put(
    showNotificationSuccessAction({
      content: 'Account updated.',
      duration: null,
    }),
  );
}

function* changeAvailabilitySagaAction(
  action: PayloadAction<ChangeAvailabilityParams>,
) {
  const result: ChangeAvailabilityResult = yield userService.updateAvailability(
    action.payload.updateAvailability,
  );

  if (result.isLeft()) {
    yield put(
      showNotificationErrorAction({
        content: result.value.message,
        duration: null,
      }),
    );
    return;
  }
  yield put(
    changeAvailabilitySuccessAction({
      updatedAvailability: result.value || null,
    }),
  );
  yield put(
    showNotificationSuccessAction({
      content: 'Availability updated.',
      duration: null,
    }),
  );
}

function* getCalendarAccountsSagaAction(action: PayloadAction<any>) {
  const result: any = yield userService.getCalendarAccounts(action.payload);

  if (result.isLeft()) {
    alert(result.value.message);
    return;
  }

  yield put(getCalendarAccountsSuccessAction(result.value?.data || null));
}

function* disconnectCalendarAccountSagaAction(
  action: PayloadAction<disconnectCalendarAccountActionParams>,
) {
  const result = yield userService.disconnectCalendarAccount(
    action.payload.accountId,
  );

  if (result.isLeft()) {
    alert(result.value.message);
    return;
  }

  const newList = yield userService.getCalendarAccounts(action.payload.user);

  yield put(getCalendarAccountsSuccessAction(newList.value?.data || null));

  yield put(
    showNotificationAction({
      type: 'success',
      content: 'Your Google Calendar is disconnected.',
      duration: null,
    }),
  );
}

function* deleteUserAccountSagaAction() {
  const result: DeleteUserAccountResult = yield userService.deleteUserAccount();

  if (result.isLeft()) {
    if (result.value.code === 'validationError') {
      yield put(
        deleteUserAccountErrorAction({
          details: result.value.details,
        }),
      );
      return;
    }
    yield put(
      deleteUserAccountErrorAction({
        details: [],
      }),
    );

    if (result.value.code === 'unknown') {
      alert(result.value.message);
    }
    return;
  }

  sessionService.deleteAccessToken();
  localStorage.removeItem('profile');

  window.location.href = routers.LANDING_PAGE;
}

function* deleteUserAvatarSagaAction(
  action: PayloadAction<DeleteUserAvatarParam>,
) {
  const result = yield userService.deleteUserAvatar(action.payload);
  const state: StoreState = yield select();

  if (result.isLeft()) {
    if (result.value.code === 'validationError') {
      yield put(
        showNotificationErrorAction({
          content: result.value.message,
          duration: null,
        }),
      );
      return;
    }
    yield put(
      showNotificationErrorAction({
        content: '',
      }),
    );

    if (result.value.code === 'unknown') {
      yield put(
        showNotificationErrorAction({
          content: result.value.message,
          duration: null,
        }),
      );
    }
    return;
  }
  if (state.session.user) {
    yield put(
      getProfileActionSuccess({ user: { ...state.session.user, avatar: '' } }),
    );
  }
  yield put(
    showNotificationSuccessAction({
      content: 'Avatar deleted.',
      duration: 2,
    }),
  );
}

function* createPayment(action: PayloadAction<IPayment>) {
  const res = yield userService.changeType(action);
  let resPayment;
  if (action.payload.token.id && res) {
    resPayment = yield userService.postPayment(action.payload.token.id);
  }
  if (resPayment) {
    const win = window.open(resPayment.value.link, '_blank');
    if (win != null) {
      win.focus();
    }
    yield put(
      showNotificationAction({
        type: 'success',
        content: 'Payment success.',
        duration: null,
      }),
    );
  }
}
function* buySubscriptionSaga(action: PayloadAction<IPayment>) {
  const user = yield select((state) => state.session.user);
  const response = yield userService.buySubscription(action.payload);
  if (response.isLeft()) {
    yield put(buySubscriptionErrorAction(null));
    yield put(
      showNotificationErrorAction({
        content: 'Payment has not been made. Try later.',
        duration: 2,
      }),
    );
  } else {
    const { brand, last4, ...other } = response.value;
    const updatedUser = { ...user, subscription: other };
    if (user) {
      yield put(getProfileActionSuccess({ user: updatedUser }));
      yield put(
        CongratulationsModalDialogAction({
          expiredAt: other.expiredAt,
          brand,
          last4,
        }),
      );
    }
  }
}

function* updateCardInfoSaga(action: PayloadAction<IPayment>) {
  const user = yield select((state) => state.session.user);
  const response = yield userService.updateCardInfo(action.payload);
  if (response.isLeft()) {
    yield put(updateCardInfoErrorAction(null));
    yield put(
      showNotificationErrorAction({
        content: 'Update card has not been made. Try later.',
        duration: 2,
      }),
    );
  } else {
    if (user) {
      const { isCardValid, ...cardInfo } = response.value;
      yield put(
        updateCardStat({
          card: cardInfo as CardInfoDto,
          isCardValid: isCardValid as boolean,
        }),
      );
      yield put(
        showNotificationSuccessAction({
          content: 'Update card successful.',
          duration: 2,
        }),
      );
    }
  }
}

function* delSubscriptionSaga() {
  const user = yield select((state) => state.session.user);
  const response = yield userService.delSubscription();
  if (response.isLeft()) {
    yield put(
      showNotificationErrorAction({
        content: 'Stripe service error. Try later.',
        duration: 2,
      }),
    );
  } else {
    const updatedUser = { ...user, subscription: response.value };
    if (user) {
      yield put(getProfileActionSuccess({ user: updatedUser }));
    }
  }
}

export function* profileWatchSaga(): Generator<any> {
  yield takeLatest(
    moveToNextProfileWizardStepAction,
    moveToNextProfileWizardStepSagaAction,
  );
  yield takeLatest(updateAccountProfileAction, updateAccountProfileSagaAction);
  yield takeLatest(changeAvailabilityAction, changeAvailabilitySagaAction);
  yield takeLatest(deleteUserAccountAction, deleteUserAccountSagaAction);
  yield takeLatest(deleteUserAvatarAction, deleteUserAvatarSagaAction);
  yield takeLatest(getCalendarAccountsAction, getCalendarAccountsSagaAction);
  yield takeLatest(
    disconnectCalendarAccountAction,
    disconnectCalendarAccountSagaAction,
  );
  yield takeLatest(postPayment, createPayment);
  yield takeLatest(buySubscription, buySubscriptionSaga);
  yield takeLatest(updateCardInfo, updateCardInfoSaga);
  yield takeLatest(delSubscription, delSubscriptionSaga);
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function* profileRunSaga(): Generator<any> {}
