import { LoadableData } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import { ExceptionDetail, ExceptionDetails } from '../../utils/errors';
import {
  fetchMeetingByPublicLinkAction,
  fetchMeetingByPublicLinkSuccessAction,
  fetchMeetingByPublicLinkErrorAction,
  fetchMeetingSlotsToBookEventSuccessAction,
  fetchMeetingSlotsToBookEventAction,
  fetchMeetingSlotsToBookEventErrorAction,
  scheduleMeetingBookEventSuccessAction,
  fetchBookCompletedEventSuccessAction,
  fetchBookEventSuccessAction,
  fetchBookErrorAction,
  scheduleMeetingBookEventAction,
  scheduleMeetingBookEventErrorAction,
  rescheduleMeetingBookEventAction,
  rescheduleMeetingBookEventSuccessAction,
  rescheduleMeetingBookEventErrorAction,
} from '../actions';
import {
  setLoadedStatusToLoadableData,
  setLoadingStatusToLoadableData,
} from '../utils';
import {
  EventDataModel,
  MeetingSlotsToBookEventModel,
  MeetingToBookEventModel,
} from '../../models/meeting.model';

export interface BookingRangeInfo extends LoadableData {
  dates: MeetingSlotsToBookEventModel[];
  timezone: string;
}

export interface MeetingInfo extends LoadableData {
  meeting: MeetingToBookEventModel | null;
}

export interface NewMeetingEventInfo extends LoadableData {
  event: null;
  errors: ExceptionDetails;
}

export interface ScheduledEvent extends LoadableData {
  eventId: string | null;
}

export interface ScheduledMeetingEventInfo extends LoadableData {
  eventData: EventDataModel | null;
  error: ExceptionDetail | null;
}

export interface BookingMeetingEventState {
  meetingInfo: MeetingInfo;
  bookingRangeInfo: BookingRangeInfo;
  newMeetingEventInfo: NewMeetingEventInfo;
  scheduledEvent: ScheduledEvent;
  eventInfo: ScheduledMeetingEventInfo;
}

const initialBookingMeetingEventState: BookingMeetingEventState = {
  meetingInfo: {
    isLoaded: false,
    isLoading: false,
    meeting: null,
  },
  bookingRangeInfo: {
    timezone: 'UTC',
    dates: [],
    isLoading: false,
    isLoaded: false,
  },
  newMeetingEventInfo: {
    isLoading: false,
    isLoaded: false,
    errors: [],
    event: null,
  },
  scheduledEvent: {
    isLoading: false,
    isLoaded: false,
    eventId: null,
  },
  eventInfo: {
    isLoading: false,
    isLoaded: false,
    eventData: null,
    error: null,
  },
};

export const bookingMeetingEventReducer = createReducer<
  BookingMeetingEventState
>(initialBookingMeetingEventState, (builder) => {
  builder
    .addCase(fetchMeetingByPublicLinkAction, (state) => {
      setLoadingStatusToLoadableData(state.meetingInfo);
    })
    .addCase(fetchMeetingByPublicLinkSuccessAction, (state, action) => {
      setLoadedStatusToLoadableData(state.meetingInfo);
      state.meetingInfo.meeting = action.payload;
    })
    .addCase(fetchMeetingByPublicLinkErrorAction, (state) => {
      setLoadedStatusToLoadableData(state.meetingInfo);
    })

    .addCase(fetchMeetingSlotsToBookEventAction, (state) => {
      setLoadingStatusToLoadableData(state.bookingRangeInfo);
    })
    .addCase(fetchMeetingSlotsToBookEventSuccessAction, (state, action) => {
      setLoadedStatusToLoadableData(state.bookingRangeInfo);
      state.bookingRangeInfo.timezone = action.payload.timezone;
      state.bookingRangeInfo.dates = action.payload.days;
    })
    .addCase(fetchMeetingSlotsToBookEventErrorAction, (state) => {
      setLoadingStatusToLoadableData(state.bookingRangeInfo);
    })
    .addCase(scheduleMeetingBookEventAction, (state) => {
      setLoadingStatusToLoadableData(state.scheduledEvent);
    })
    .addCase(scheduleMeetingBookEventSuccessAction, (state, action) => {
      setLoadedStatusToLoadableData(state.scheduledEvent);
      state.scheduledEvent.eventId = action.payload.eventId;
    })
    .addCase(scheduleMeetingBookEventErrorAction, (state) => {
      setLoadedStatusToLoadableData(state.scheduledEvent);
    })
    .addCase(rescheduleMeetingBookEventAction, (state) => {
      setLoadingStatusToLoadableData(state.scheduledEvent);
    })
    .addCase(rescheduleMeetingBookEventSuccessAction, (state, action) => {
      setLoadedStatusToLoadableData(state.scheduledEvent);
      state.scheduledEvent.eventId = action.payload.eventId;
    })
    .addCase(rescheduleMeetingBookEventErrorAction, (state) => {
      setLoadedStatusToLoadableData(state.scheduledEvent);
    })
    .addCase(fetchBookCompletedEventSuccessAction, (state, action) => {
      setLoadedStatusToLoadableData(state.eventInfo);
      state.eventInfo.eventData = action.payload;
    })
    .addCase(fetchBookEventSuccessAction, (state, action) => {
      setLoadedStatusToLoadableData(state.eventInfo);
      state.eventInfo.eventData = action.payload;
    })
    .addCase(fetchBookErrorAction, (state, action) => {
      setLoadedStatusToLoadableData(state.eventInfo);
      state.eventInfo.error = action.payload;
    });
});
