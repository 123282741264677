import React from 'react';
import { Tooltip } from 'antd';
import { QuestionIcon } from '../icons/questionIcon';
import './index.scss';

interface props {
  text?: string;
}

export const QuestionTooltip: React.FC<props> = ({ text }) => {
  return (
    <>
      {text ? (
        <Tooltip
          placement="top"
          title={text}
          getPopupContainer={() => {
            const tooltipContainer = document.createElement('div');
            const root = document.getElementsByClassName('ant-layout');
            tooltipContainer.classList.add('tooltip-container');
            return root ? root[0].appendChild(tooltipContainer) : document.body;
          }}
        >
          <span className="tooltip-span">
            <QuestionIcon />
          </span>
        </Tooltip>
      ) : (
        <QuestionIcon />
      )}
    </>
  );
};
