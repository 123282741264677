import { createAction, PayloadAction } from '@reduxjs/toolkit';
import * as constants from '../constants/organization-users';
import { OrganizationUserList } from '../../models/organization.mogel';
import { showModalDialogAction } from './modal';
import { ModalTypes } from '../interfaces/modal';
import { OrganizationDataDto } from '../../libs/organization-users/dtos/organization-users.dto';

export type FetchOrganizationUsersParams = {
  sortOrder?: string;
  sortField?: string;
  status?: string;
  searchField?: string;
  page?: number;
};

export const fetchOrganizationUsersAction = createAction<
  FetchOrganizationUsersParams
>(constants.FETCH_ORGANIZATION_USERS.REQUEST);

export const fetchOrganizationUsersSuccessAction = createAction<
  OrganizationDataDto<OrganizationUserList>
>(constants.FETCH_ORGANIZATION_USERS.SUCCESS);

type CheckOrganizationExistActionParam = {
  isNotExistOrganization: boolean;
};

export const checkOrganizationExistAction = createAction<
  CheckOrganizationExistActionParam
>(constants.FETCH_ORGANIZATION_USERS.ERROR);

export type DeleteOrganizationUserParam = {
  userId: string;
  status: string;
  method?: string;
};

export const deleteOrganizationUserAction = createAction<
  DeleteOrganizationUserParam
>(constants.DELETE_ORGANIZATION_USER.REQUEST);

export interface ShowDeletePendingUserModalDialog
  extends DeleteOrganizationUserParam {
  onDeleteOrganizationPendingUser(param: DeleteOrganizationUserParam): void;
  email: string;
}

export interface ShowDeleteAcceptedUserModalDialog
  extends DeleteOrganizationUserParam {
  userName: string | undefined;
  onDeleteOrganizationAcceptedUser(param: DeleteOrganizationUserParam): void;
}

type DeleteOrganizationUserSuccessActionParam = {
  data: OrganizationUserList[];
};

export const deleteOrganizationUserSuccessAction = createAction<
  DeleteOrganizationUserSuccessActionParam
>(constants.DELETE_ORGANIZATION_USER.SUCCESS);

export type SendInvitesToUserParam = {
  emails: string[];
};

export const sendInvitesToUsersAction = createAction<SendInvitesToUserParam>(
  constants.SEND_INVITES_TO_USERS.REQUEST,
);

export const sendInvitesToUsersSuccessAction = createAction(
  constants.SEND_INVITES_TO_USERS.SUCCESS,
);

export type SendInvitesToUserErrorParam = {
  meta: {
    emails: string[];
  };
};

export const sendInvitesToUsersErrorAction = createAction<
  SendInvitesToUserErrorParam
>(constants.SEND_INVITES_TO_USERS.ERROR);

export interface ShowInviteUserSuccessModalDialog {
  sendCount: number;
}

export const showInviteUserSuccessModalDialogAction = (
  props: ShowInviteUserSuccessModalDialog,
): PayloadAction<unknown> => {
  return showModalDialogAction({
    payload: props,
    type: ModalTypes.inviteSendSuccess,
  });
};

export type ReSendInviteToUserParam = {
  memberId: string;
};

export const reSendOrganizationUserInviteAction = createAction<
  ReSendInviteToUserParam
>(constants.RESEND_INVITE_TO_USER.REQUEST);
