import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { ArgsProps as MessageInfoArgsProps } from 'antd/lib/message';
import * as constants from '../constants';
import { AuthMethodType } from '../../models/auth.model';
import { ExceptionDetails } from '../../utils/errors';
import { UserModel } from '../../models/user.model';
import { SubscriptionResponseDto } from '../../libs/auth/dtos/api.dto';
import { showModalDialogAction } from './modal';
import { ModalTypes } from '../interfaces/modal';

// GetAuthMethod
export interface GetAuthMethodParams {
  email: string;
}
export const getAuthMethodByEmailAction = createAction<GetAuthMethodParams>(
  constants.GET_AUTH_METHOD.REQUEST,
);
export interface GetAuthMethodSuccessParams {
  method: AuthMethodType | null;
}
export const getAuthMethodByEmailActionSuccess = createAction<
  GetAuthMethodSuccessParams
>(constants.GET_AUTH_METHOD.SUCCESS);
export interface GetAuthMethodErrorParams {
  details: ExceptionDetails;
}
export const getAuthMethodByEmailActionError = createAction<
  GetAuthMethodErrorParams
>(constants.GET_AUTH_METHOD.ERROR);

// CleanupAuthMethod
export const cleanupAuthMethodAction = createAction(
  constants.CLEANUP_AUTH_METHOD,
);

// ShowNotificationInfo
export type ShowNotificationParam = MessageInfoArgsProps;

export interface MessageParams {
  content: React.ReactNode;
  duration?: number | null;
}

export const showNotificationAction = createAction<ShowNotificationParam>(
  constants.SHOW_NOTIFICATION,
);

export const showNotificationSuccessAction = createAction<MessageParams>(
  constants.SHOW_NOTIFICATION_SUCCESS,
);

export const showNotificationErrorAction = createAction<MessageParams>(
  constants.SHOW_NOTIFICATION_ERROR,
);

// ChangeAuthMethod
interface ChangeAuthMethodParam {
  method: AuthMethodType;
}
export const changeAuthMethodAction = createAction<ChangeAuthMethodParam>(
  constants.CHANGE_AUTH_METHOD,
);

// LoginByPassword
export interface LoginByPasswordParams {
  email: string;
  password: string;
  isRememberMe: boolean;
}
export const loginByPasswordAction = createAction<LoginByPasswordParams>(
  constants.LOGIN_BY_PASSWORD.REQUEST,
);
export interface LoginByPasswordSuccessParams {
  user: UserModel;
}
export const loginByPasswordActionSuccess = createAction<
  LoginByPasswordSuccessParams
>(constants.LOGIN_BY_PASSWORD.SUCCESS);
export interface LoginByPasswordErrorParams {
  details: ExceptionDetails;
}
export const loginByPasswordActionError = createAction<
  LoginByPasswordErrorParams
>(constants.LOGIN_BY_PASSWORD.ERROR);

export interface LoginByPasswordIsRememberMe {
  isRememberMe: boolean;
}
export const loginByPasswordActionSetIsRememberMe = createAction<
  LoginByPasswordIsRememberMe
>(constants.CHANGE_IS_REMEMBER_ME);

// LoginByGoogle
export interface LoginByGoogleParams {
  email: string;
}
export const loginByGoogleAction = createAction(
  constants.LOGIN_BY_GOOGLE.REQUEST,
);
export interface LoginByGoogleSuccessParams {
  user: UserModel;
}
export const loginByGoogleActionSuccess = createAction<
  LoginByGoogleSuccessParams
>(constants.LOGIN_BY_GOOGLE.SUCCESS);
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LoginByGoogleErrorParams {}
export const loginByGoogleActionError = createAction<LoginByGoogleErrorParams>(
  constants.LOGIN_BY_GOOGLE.ERROR,
);

//Change Login By Google
export interface ChangeLoginByGoogleParams {
  state: string;
}
export const changeUserEmailGoogleAction = createAction<
  ChangeLoginByGoogleParams
>(constants.CHANGE_USER_EMAIL_BY_GOOGLE.REQUEST);

export interface ChangeLoginByGoogleSuccessParams {
  user: UserModel;
}
export const changeLoginByGoogleActionSuccess = createAction<
  ChangeLoginByGoogleSuccessParams
>(constants.CHANGE_USER_EMAIL_BY_GOOGLE.SUCCESS);
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ChangeLoginByGoogleErrorParams {}
export const changeLoginByGoogleActionError = createAction<
  ChangeLoginByGoogleErrorParams
>(constants.CHANGE_USER_EMAIL_BY_GOOGLE.ERROR);

// GetProfile
export const getProfileAction = createAction(constants.GET_PROFILE.REQUEST);
interface GetProfileSuccessParams {
  user: UserModel;
}
export const getProfileActionSuccess = createAction<GetProfileSuccessParams>(
  constants.GET_PROFILE.SUCCESS,
);
interface GetSubscriptionPlansSuccessParams {
  subscriptions: SubscriptionResponseDto[];
}
export const getSubscriptionPlansActionSuccess = createAction<
  GetSubscriptionPlansSuccessParams
>(constants.GET_SUBSCRIPTION_PLANS.SUCCESS);
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GetProfileErrorParams {}
export const getProfileActionError = createAction<GetProfileErrorParams>(
  constants.GET_PROFILE.ERROR,
);

// Logout
export const logoutAction = createAction(constants.LOGOUT.REQUEST);
export const logoutActionSuccess = createAction(constants.LOGOUT.SUCCESS);
export const logoutActionError = createAction(constants.LOGOUT.SUCCESS);

export const logoutModalDialogAction = (): PayloadAction<unknown> => {
  return showModalDialogAction({
    payload: '',
    type: ModalTypes.logout,
  });
};

export const downgradeModalDialogAction = (): PayloadAction<unknown> => {
  return showModalDialogAction({
    payload: '',
    type: ModalTypes.downgradeSubscription,
  });
};

// SignUpByEmail
export interface SignUpByPasswordParams {
  email: string;
  password: string;
  name: string;
}
export const signUpByPassword = createAction<SignUpByPasswordParams>(
  constants.SIGN_UP_BY_PASSWORD.REQUEST,
);
type SignUpByPasswordParamsSuccess = void;
export const signUpByPasswordSuccess = createAction<
  SignUpByPasswordParamsSuccess
>(constants.SIGN_UP_BY_PASSWORD.SUCCESS);
interface SignUpByPasswordParamsError {
  details: ExceptionDetails;
}
export const signUpByPasswordError = createAction<SignUpByPasswordParamsError>(
  constants.SIGN_UP_BY_PASSWORD.ERROR,
);

// ForgotPassword
export interface ForgotPasswordParams {
  email: string;
}
export const forgotPasswordAction = createAction<ForgotPasswordParams>(
  constants.FORGOT_PASSWORD.REQUEST,
);
export const forgotPasswordActionSuccess = createAction(
  constants.FORGOT_PASSWORD.SUCCESS,
);
export interface ForgotPasswordParamsError {
  details: ExceptionDetails;
}
export const forgotPasswordActionError = createAction<
  ForgotPasswordParamsError
>(constants.FORGOT_PASSWORD.ERROR);

// cleanupForgotPasswordInfo
export const cleanupForgotPasswordInfo = createAction(
  constants.CLEANUP_FORGOT_PASSWORD_INFO,
);

// resetNewPassword
export interface ResetForgotPasswordParams {
  token: string;
  password: string;
}
export const resetForgotPasswordAction = createAction<
  ResetForgotPasswordParams
>(constants.RESET_FORGOT_PASSWORD.REQUEST);
interface ResetForgotPasswordParamError {
  details: ExceptionDetails;
}
export const resetForgotPasswordActionError = createAction<
  ResetForgotPasswordParamError
>(constants.RESET_FORGOT_PASSWORD.ERROR);
interface ResetForgotPasswordParamError {
  details: ExceptionDetails;
}
export const resetForgotPasswordActionSuccess = createAction(
  constants.RESET_FORGOT_PASSWORD.SUCCESS,
);

// approveUserEmail
export interface ApproveUserEmailParams {
  token: string;
  password: string;
}
export const approveUserEmailAction = createAction<ApproveUserEmailParams>(
  constants.APPROVE_USER_EMAIL.REQUEST,
);
interface ApproveUserEmailParamsSuccess {
  user: UserModel;
}
export const approveUserEmailSuccessAction = createAction<
  ApproveUserEmailParamsSuccess
>(constants.APPROVE_USER_EMAIL.SUCCESS);
interface ApproveUserEmailParamsError {
  details: ExceptionDetails;
}
export const approveUserEmailErrorAction = createAction<
  ApproveUserEmailParamsError
>(constants.APPROVE_USER_EMAIL.ERROR);

export type InviteCheckUserActionParam = {
  inviteToken: string;
};

export const inviteCheckUserAction = createAction<InviteCheckUserActionParam>(
  constants.INVITE_CHECK_USER.REQUEST,
);

export type InviteCheckUserSuccessParams = {
  haveToRegistration: boolean;
  userEmail: string;
  adminName: string;
};

export const inviteCheckUserSuccessAction = createAction<
  InviteCheckUserSuccessParams
>(constants.INVITE_CHECK_USER.SUCCESS);

export type InviteCheckUserErrorParam = {
  message: string;
  type: string;
};

export const inviteCheckUserErrorAction = createAction<
  InviteCheckUserErrorParam
>(constants.INVITE_CHECK_USER.ERROR);

export type InviteUserAcceptParam = InviteCheckUserActionParam;

export const inviteUserAcceptAction = createAction<InviteUserAcceptParam>(
  constants.INVITE_ACCEPT.REQUEST,
);

export type InviteUserAcceptSuccessParam = {
  message: string;
  type: string;
};

export const inviteUserAcceptSuccessAction = createAction<
  InviteUserAcceptSuccessParam
>(constants.INVITE_ACCEPT.SUCCESS);

export type InviteUserAcceptErrorParam = {
  message: string;
  type: null | string;
};

export const inviteUserAcceptErrorAction = createAction<
  InviteUserAcceptErrorParam
>(constants.INVITE_ACCEPT.ERROR);

// loginForGoogleCalendar
export const loginForGoogleCalendarAction = createAction(
  constants.LOGIN_FOR_GOOGLE_CALENDAR.REQUEST,
);
export interface loginForGoogleCalendarParams {
  user: UserModel;
}
export const loginForGoogleCalendarActionSuccessAction = createAction<
  loginForGoogleCalendarParams
>(constants.LOGIN_FOR_GOOGLE_CALENDAR.SUCCESS);
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export const loginForGoogleCalendarActionErrorAction = createAction(
  constants.LOGIN_FOR_GOOGLE_CALENDAR.ERROR,
);

export const getCalendarAccountsAction = createAction<UserModel>(
  constants.GET_CALENDAR_ACCOUNTS.REQUEST,
);

export const getCalendarAccountsSuccessAction = createAction<any>(
  constants.GET_CALENDAR_ACCOUNTS.SUCCESS,
);

export const getCalendarAccountsErrorAction = createAction<any>(
  constants.GET_CALENDAR_ACCOUNTS.ERROR,
);

export interface disconnectCalendarAccountActionParams {
  accountId: string;
  user: UserModel;
}

export const disconnectCalendarAccountAction = createAction<
  disconnectCalendarAccountActionParams
>(constants.DISCONNECT_CALENDAR_ACCOUNT.REQUEST);

export interface SetAvatarSizeErrorParams {
  message: string;
}

export const setAvatarSizeError = createAction<SetAvatarSizeErrorParams>(
  constants.UPDATE_AVATAR_ACCOUNT_PROFILE.ERROR,
);

export * from './profile';
export * from './timezone';
export * from './meeting';
export * from './modal';
export * from './booking-meeting-event';
export * from './notification';
