import React, { useState } from 'react';
import './index.scss';
import { InputStyled } from '../input';
import appConfig from '../../../constants/appConfig';

export interface ICopyUrl {
  value: string;
  onClick(value: string): void;
}

export const CopyUrl: React.FC<ICopyUrl> = (props) => {
  const [copyState, setCopyState] = useState(false);
  const handle = () => {
    setCopyState(true);
    props.onClick(props.value);
    setTimeout(() => {
      setCopyState(false);
    }, 3000);
  };
  return (
    <div className="copy-url" onClick={handle}>
      <InputStyled
        value={props.value}
        addonBefore={appConfig.appHostName}
        disabled
      />
      {/*<Input className="copy-url-input url-input" value={props.value} addonBefore="jammer.co/" />*/}
      <button className={`copy-url-button${copyState ? ' copied' : ''}`}>
        <span>{!copyState ? 'Copy' : 'Copied'}</span>
      </button>
    </div>
  );
};
