import React from 'react';
import { BillingMobileIcon } from '../../../components/icons/billingMobile/billingMobile';

export const MobileBillingPage: React.FC = () => {
  return (
    <div className="billingMain-mobile">
      <BillingMobileIcon />
      <div className="billingMain-mobile-title">
        Please Manage Billing Preferences From A Desktop Device
      </div>
    </div>
  );
};
