import React, { useCallback, useEffect, useState } from 'react';
import { Dashboard } from '../../components/dashboardLayout';
import Form from 'antd/lib/form';
import { appHistory, routers } from '../../router';
import { ButtonMenuStyled } from '../../components/button/styledButton';
import { LogoCropper } from '../../components/designLogo/upload-logo';
import { InputWithLabel } from '../../components/input/inputWithLabel';
import { Switch } from '../../components/switch';
import './index.scss';
import { ColorPicker } from '../../components/colorPicker';
import { CalendarStyleSelect } from '../../components/calendarStyleSelect';
import { PreviewDesign } from '../../components/previewDesign';
import { useDispatch } from 'react-redux';
import {
  fetchDesignAction,
  updateDesignAction,
} from '../../redux/actions/design';
import { useDesignSelector } from '../../redux/selectors/design';
import { Loader } from '../../components/loader';

export enum CalendarStyleType {
  month = 'month',
  week = 'week',
}

export const Design: React.FC = () => {
  const design = useDesignSelector();
  const [style, setStyle] = useState(CalendarStyleType.week);
  const [color, setColor] = useState<string | undefined | null>(
    design.data.color,
  );
  const [logo, setLogo] = useState<string | null>(null);
  const [darkMode, setDarkMode] = useState(design.data.isDarkMode);
  const [designForm] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDesignAction(null));
  }, [dispatch]);

  useEffect(() => {
    setStyle(design.data.calendarStyle);
    setColor(design.data.color);
    setLogo(design.data.logo);
    setDarkMode(design.data.isDarkMode);
    designForm.setFieldsValue({ color: design.data.color });
  }, [design.data, designForm]);

  const darkHandler = useCallback(
    (value: boolean) => {
      setDarkMode(value);
      designForm.setFieldsValue({ dark: value });
    },
    [setDarkMode, designForm],
  );

  const lightOrDark = useCallback(
    (color: string) => {
      const colorRgb = +('0x' + color.slice(1));
      const r = colorRgb >> 16;
      const g = (colorRgb >> 8) & 255;
      const b = colorRgb & 255;
      const hsp = Math.sqrt(
        0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b),
      );
      if (hsp > 127.5) {
        darkHandler(true);
      } else {
        darkHandler(false);
      }
    },
    [darkHandler],
  );

  const colorHandler = (value: string) => {
    setColor(value);
    if (value) {
      lightOrDark(value);
    }
    designForm.setFieldsValue({ color: value });
  };

  const submitHandler = (e: { color: string; dark: boolean }) => {
    dispatch(
      updateDesignAction({
        calendarStyle: style,
        isDarkMode: e.dark,
        color: e.color ? e.color : null,
      }),
    );
  };

  return (
    <Dashboard>
      <div className="design-page-container">
        <Form
          form={designForm}
          onFinish={(e) => {
            submitHandler(e);
          }}
        >
          {design.isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="design-page-body">
                <div className="design-page-body-selectors">
                  <div className="design-page-body-header">
                    Edit your design and branding and apply it to all your
                    meeting types.
                  </div>
                  <div className="color-selectors">
                    <InputWithLabel
                      label="Logo or Photo"
                      tooltip="Use this seeting to add a photo or logo to all your Jammer scheduling pages. Your Logo or Photo will appear on the top center if your scheduling pages. We recommend using an image that is 400px x 400px for the best display."
                    >
                      <LogoCropper logo={logo} setLogo={setLogo} />
                    </InputWithLabel>
                    <InputWithLabel
                      label="Brand Color"
                      tooltip="This allows you to change the background, text, and button color of your scheduling pages to better match your brand. If the color you choose does not make it easy for guests to schedule a meeting then you will not be able to save the color and have to choose a new one. "
                    >
                      <Form.Item
                        name="color"
                        initialValue={design.data.color}
                        valuePropName="color"
                      >
                        <ColorPicker onChange={colorHandler} />
                      </Form.Item>
                    </InputWithLabel>
                  </div>
                  <div className="darkMode-switch">
                    <Form.Item
                      name={'dark'}
                      valuePropName="checked"
                      initialValue={darkMode}
                    >
                      <Switch
                        size="small"
                        disabled={!!color}
                        onChange={darkHandler}
                      />
                    </Form.Item>
                    <p>
                      <span>Dark mode</span>&nbsp;for your scheduling links
                      including your main Jammer link.
                    </p>
                  </div>
                  <div className="calendar-style-selector">
                    <InputWithLabel
                      label="Calendar Style"
                      tooltip="This allows you to change the calendar style guests can see on all your scheudling pages. Month view shows your availability one month at a time. Week view shows your availablilty one week at at time. "
                    >
                      <CalendarStyleSelect onChange={setStyle} value={style} />
                    </InputWithLabel>
                  </div>
                </div>
                <div className="design-page-body-preview-component">
                  <InputWithLabel
                    label="Design Preview"
                    tooltip="This is a preview on how your scheduling pages would look like to your guests when any design changes are made."
                  >
                    <PreviewDesign
                      logo={logo}
                      style={style}
                      color={color}
                      darkMode={darkMode}
                    />
                  </InputWithLabel>
                </div>
              </div>
              <div className="design-page-buttons">
                <span onClick={() => appHistory.push(routers.HOME)}>
                  Cancel
                </span>
                <ButtonMenuStyled htmlType="submit">
                  Save & Update All Meeting Types
                </ButtonMenuStyled>
              </div>
            </>
          )}
        </Form>
      </div>
    </Dashboard>
  );
};
