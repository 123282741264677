import React, { useEffect, useState } from 'react';
import './index.scss';
import { useDispatch } from 'react-redux';
import {
  downgradeModalDialogAction,
  enterCardModalDialogAction,
  updateCardInfo,
  updateStat,
} from '../../../redux/actions';
import { ButtonStyledOutlined } from '../../../components/button/styledButtonOutlined';
import './index.scss';
import { userService } from '../../../libs/user/user.service';
import { LoaderPage } from '../../../components/loader/loaderPage';
import { MobileBillingPage } from './mobileBilling';
import { LabelWithTooltip } from '../../../components/labelWithTooltip';
import { CardLogo } from '../../../components/icons/card';
import { BillingTable } from './billingTable';
import moment from 'moment-timezone';
import { useBillingInfo } from '../../../redux/selectors/auth';

export const BillingMainPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const billingStat = useBillingInfo();

  const dispatch = useDispatch();
  useEffect(() => {
    const getStat = async () => {
      const chargesList = await userService.getCustomerStat();
      if (chargesList.isRight()) {
        dispatch(
          updateStat(
            chargesList.value
              ? chargesList.value
              : { charges: null, card: null },
          ),
        );
      }
      setIsLoading(false);
    };
    getStat().then();
  }, [dispatch]);

  const deleteHandler = () => {
    dispatch(downgradeModalDialogAction());
  };

  const updateCardHandler = (e: any) => {
    e.preventDefault();
    dispatch(
      enterCardModalDialogAction({
        onSubmit: updateCardInfo,
        header: 'Update Card Info!',
      }),
    );
  };

  return (
    <>
      {isLoading ? (
        <LoaderPage />
      ) : (
        <div className="billingMain-container">
          <div className="billingMain-desktop">
            <div className="billingMain-title">
              Manage your account, payments, and billing information.
            </div>
            <div className="billingMain-card-container">
              <LabelWithTooltip label="Card On File" />
              <div className="billingMain-card-body">
                <div className="card-name">
                  <CardLogo brand={billingStat.card?.brand} />
                  <div className="card-number">
                    ****-****-****-{billingStat.card?.last4}
                  </div>
                  <div className="card-expire">
                    {billingStat.card?.exp_month}/{billingStat.card?.exp_year}
                  </div>
                  {billingStat.charges &&
                    billingStat?.charges[0].status !== 'succeeded' && (
                      <div className="card-failed-badge">
                        <span>Payment Failed</span>
                      </div>
                    )}
                  {billingStat.card?.exp_year &&
                    billingStat.card?.exp_month &&
                    moment([
                      billingStat.card?.exp_year,
                      billingStat.card?.exp_month - 1,
                      1,
                    ]).isBefore(moment(Date.now())) && (
                      <div className="card-failed-badge">
                        <span>Expired</span>
                      </div>
                    )}
                </div>
                <div
                  className="billingMain-card-update"
                  onClick={updateCardHandler}
                >
                  Update Card
                </div>
              </div>
            </div>
            <LabelWithTooltip label="Payment History" />
            <div className="billingMain-stat-container">
              {billingStat.charges && (
                <BillingTable charges={billingStat.charges} />
              )}
            </div>
            <ButtonStyledOutlined onClick={deleteHandler}>
              Downgrade To Basic
            </ButtonStyledOutlined>
          </div>
          <MobileBillingPage />
        </div>
      )}
    </>
  );
};
