import { actionBuilder } from '../utils';
import * as ActionTypes from '../constants';
import { CalendarStyleType } from '../../pages/design';
import { createAction } from '@reduxjs/toolkit';

export interface FetchDesignDto {
  color: string;
  logo: string;
  calendarStyle: CalendarStyleType;
  isDarkMode: boolean;
}

export interface UpdateDesignParams {
  color?: string | null;
  logo?: string;
  calendarStyle?: CalendarStyleType;
  isDarkMode?: boolean;
}

export const {
  request: fetchDesignAction,
  success: fetchDesignSuccessAction,
  error: fetchDesignErrorAction,
} = actionBuilder<null, FetchDesignDto, null>(ActionTypes.FETCH_DESIGN);

export const {
  request: updateDesignAction,
  success: updateDesignSuccessAction,
  error: updateDesignErrorAction,
} = actionBuilder<UpdateDesignParams, FetchDesignDto, null>(
  ActionTypes.EDIT_DESIGN,
);

export interface DeleteDesignLogoParam {
  removeLogo: boolean;
}

export const deleteDesignLogoAction = createAction<DeleteDesignLogoParam>(
  ActionTypes.DELETE_DESIGN_LOGO.REQUEST,
);
