const appConfig = {
  projectName: 'Jammer',
  host: window.location.origin,
  appHostName: 'jammer.co/',
  availableTime: {
    max: 1440,
    min: 0,
    step: 15,
  },
};

export default appConfig;
