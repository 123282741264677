import React from 'react';
import { ReactComponent as MarkerLogo } from './Mask.svg';

export const MarkerIcon: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <MarkerLogo />
    </div>
  );
};
