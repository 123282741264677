import React from 'react';

const CheckMarkIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={`${className ? className : ''}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.10035 16.243L19.3699 4.97351C19.9557 4.38772 20.9054 4.38772 21.4912 4.97352C22.077 5.5593 22.077 6.50903 21.4912 7.09481L8.49809 20.0879C8.38825 20.1977 8.2443 20.2526 8.10035 20.2526C7.95638 20.2527 7.81241 20.1977 7.70257 20.0879L2.50913 14.8945C1.92334 14.3087 1.92334 13.3589 2.50913 12.7731C3.09492 12.1873 4.04467 12.1873 4.63046 12.7731L8.10035 16.243Z"
        fill="#08979C"
      />
    </svg>
  );
};

export default CheckMarkIcon;
