import React, { useCallback, useMemo } from 'react';
import { Dropdown, Menu } from 'antd';
import { LocationArray, LocationTypeEnum } from '../../models/meeting.model';
import {
  useSubscriptionPlanSelector,
  useUserInfoSelector,
} from '../../redux/selectors/auth';
import { Button } from '../button';
import './index.scss';
import ArrowDownIcon from '../icons/arrow-down';
import { SubscriptionsEnum } from '../../models/subscription.model';

interface LocationSelectProps {
  default?: LocationTypeEnum;
  onSelect(value: LocationTypeEnum): void;
}

export const LocationSelect: React.FC<LocationSelectProps> = (props) => {
  const user = useUserInfoSelector();
  const subscriptionPlan = useSubscriptionPlanSelector();

  const permission = useMemo(() => {
    return subscriptionPlan !== SubscriptionsEnum.free;
  }, [subscriptionPlan]);

  const selectHandler = useCallback(
    (value: LocationTypeEnum) => {
      if (!permission && value === LocationTypeEnum.jammerConference) {
        return;
      } else {
        props.onSelect(value);
      }
    },
    [permission, props],
  );

  const menu = useMemo(
    () => (
      <Menu className="location-dropdown">
        {LocationArray.map((location) => (
          <Menu.Item
            className={`locations-dropdown-container${
              !permission && location.type === LocationTypeEnum.jammerConference
                ? ' disabled'
                : ''
            }`}
            key={location.type}
            onClick={() => selectHandler(location.type)}
          >
            <div className={`locations-input-element`}>
              {<location.logo />}
              <div className="locations-input-element-name">
                <div className="locations-input-header">{location.name}</div>
                <div className="locations-input-description">
                  {location.type === LocationTypeEnum.jammerConference
                    ? user?.twilio_phone
                    : location.description}
                </div>
              </div>
            </div>
          </Menu.Item>
        ))}
      </Menu>
    ),
    [user, permission, selectHandler],
  );

  const getSelected = useCallback(() => {
    const location = props.default
      ? LocationArray.find((item) => item.type === props.default)
      : LocationArray[0];
    return (
      <div className="locations-input-container">
        <div className="locations-input-element">
          {location?.logo && <location.logo />}
          <div className="locations-input-element-name">
            <div className="locations-input-header">{location?.name}</div>
            <div className="locations-input-description">
              {location?.type === LocationTypeEnum.jammerConference
                ? user?.twilio_phone
                : location?.description}
            </div>
          </div>
        </div>
      </div>
    );
  }, [props.default, user]);

  return (
    <div className="location-select-block">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        getPopupContainer={() => {
          const tooltipContainer = document.createElement('div');
          const root = document.getElementsByClassName('ant-layout');
          tooltipContainer.classList.add('dropdown-set-location');
          return root ? root[0].appendChild(tooltipContainer) : document.body;
        }}
      >
        <Button
          className="location-select-btn"
          onClick={(e) => e.preventDefault()}
        >
          {getSelected()}
          <ArrowDownIcon />
        </Button>
      </Dropdown>
    </div>
  );
};
