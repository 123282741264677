import React from 'react';
import { ReactComponent as ClockLogo } from './Group.svg';

export const ClockIcon: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ClockLogo />
    </div>
  );
};
