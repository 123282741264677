import { Input as InputAnt } from 'antd';
import { InputProps as InputPropsAnt } from 'antd/lib/input';
import * as React from 'react';
import './index.scss';
import appConfig from '../../../constants/appConfig';

export const InputStyled: React.FC<InputPropsAnt> = (props) => {
  return (
    <div className="styled-input">
      <InputAnt {...props} addonBefore={appConfig.appHostName} />
    </div>
  );
};
