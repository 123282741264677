import { MeetingApi } from './meeting.api';
import { SessionService } from '../auth/session.service';
import {
  CreateMeetingScheduleEventResult,
  DeleteMeetingResult,
  FetchMainLinkResult,
  FetchMeetingDataBookEmailResult,
  FetchMeetingEventInfoResult,
  FetchMeetingSlotsToBookEventResult,
  FetchMeetingToBookEventResult,
  FindMeetingByIdToEditResult,
  FindMyMeetingsResult,
  RescheduleDataResponseDto,
  UpdateMeetingByIdResult,
} from './dtos/meeting.dto';
import { left } from '@sweet-monads/either';
import { UnknownError } from '../common/errors';
import {
  MeetingEditModel,
  MeetingOnListModel,
  MeetingScheduleEventModel,
} from '../../models/meeting.model';
import {
  CancellationEventRequestDto,
  ChangeNotificationsPolicyRequest,
  CreateNewMeetingRequest,
  CreateScheduleMeetingEventRequest,
  DeleteMeetingDto,
  FetchBookEventByIdRequestDto,
  FetchBookEventRequestDto,
  FetchMeetingInfoBySlugRequest,
  FetchMeetingSlotsRequest,
  ReschedulingEventRequestDto,
} from './dtos/api.dto';

export class MeetingService {
  constructor(
    private readonly meetingApi: MeetingApi,
    private readonly sessionService: SessionService,
  ) {}

  async findMyMeetings(page?: number): Promise<FindMyMeetingsResult> {
    const token = this.sessionService.getAccessToken();
    if (!token) {
      return left(new UnknownError());
    }
    return this.meetingApi.findMyMeetings(token, page);
  }

  async fetchMainLink(userSlug: string): Promise<FetchMainLinkResult> {
    return this.meetingApi.fetchMainLink({ userSlug });
  }

  async findMeetingByIdToEdit(
    meetingId: string,
  ): Promise<FindMeetingByIdToEditResult> {
    const token = this.sessionService.getAccessToken();
    if (!token) {
      return left(new UnknownError());
    }
    return this.meetingApi.findMeetingId(token, meetingId);
  }

  async updateActiveById(
    meetingId: string,
    dto: { isActive: boolean },
  ): Promise<UpdateMeetingByIdResult> {
    const token = this.sessionService.getAccessToken();
    if (!token) {
      return left(new UnknownError());
    }
    return this.meetingApi.updateMeetingId(token, meetingId, dto);
  }

  async updateMeetingsOrder(
    dto: MeetingOnListModel[],
  ): Promise<FindMyMeetingsResult> {
    const token = this.sessionService.getAccessToken();
    if (!token) {
      return left(new UnknownError());
    }
    return this.meetingApi.updateMeetingsOrder(token, dto);
  }

  async updateById(
    meetingId: string,
    dto: MeetingEditModel,
  ): Promise<UpdateMeetingByIdResult> {
    const token = this.sessionService.getAccessToken();
    if (!token) {
      return left(new UnknownError());
    }
    return this.meetingApi.updateMeetingId(token, meetingId, dto);
  }

  async createNewMeeting(dto: CreateNewMeetingRequest): Promise<any> {
    const token = this.sessionService.getAccessToken();
    if (!token) {
      return left(new UnknownError());
    }

    return this.meetingApi.createNewMeeting(token, dto);
  }

  async findByPublicLink(
    dto: FetchMeetingInfoBySlugRequest,
  ): Promise<FetchMeetingToBookEventResult> {
    return this.meetingApi.findByPublicLink(dto);
  }

  async fetchMeetingSlotsToBookEvent(
    dto: FetchMeetingSlotsRequest,
  ): Promise<FetchMeetingSlotsToBookEventResult> {
    return this.meetingApi.fetchMeetingSlotsToBookEvent(dto);
  }

  async fetchMeetingDataBookEmail(
    meetingId: string,
  ): Promise<FetchMeetingDataBookEmailResult> {
    const token = this.sessionService.getAccessToken();
    if (!token) {
      return left(new UnknownError());
    }

    return this.meetingApi.fetchMeetingDataBookEmail(token, meetingId);
  }

  async changeNotificationReminders(
    dto: ChangeNotificationsPolicyRequest,
  ): Promise<any> {
    const token = this.sessionService.getAccessToken();
    if (!token) {
      return left(new UnknownError());
    }
    return await this.meetingApi.changeNotificationReminders(token, dto);
  }

  async createScheduleMeetingEvent(
    dto: MeetingScheduleEventModel,
  ): Promise<CreateMeetingScheduleEventResult> {
    return await this.meetingApi.createScheduleMeetingEvent(dto);
  }

  async createReScheduleMeetingEvent(
    dto: CreateScheduleMeetingEventRequest,
    eventId: string,
    token: string,
  ): Promise<CreateMeetingScheduleEventResult> {
    return await this.meetingApi.createReScheduleMeetingEvent(
      dto,
      eventId,
      token,
    );
  }

  async fetchBookEventInfo(
    dto: FetchBookEventRequestDto,
  ): Promise<FetchMeetingEventInfoResult> {
    return await this.meetingApi.fetchBookEventInfo(dto);
  }

  async fetchBookEvent(
    dto: FetchBookEventByIdRequestDto,
  ): Promise<FetchMeetingEventInfoResult> {
    return await this.meetingApi.fetchBookEventById(dto);
  }

  async cancellationEvent(
    dto: CancellationEventRequestDto,
  ): Promise<FetchMeetingEventInfoResult> {
    return await this.meetingApi.cancellationEvent(dto);
  }

  async reschedulingEvent(dto: ReschedulingEventRequestDto): Promise<any> {
    return await this.meetingApi.reschedulingEvent(dto);
  }

  async getRescheduleData(
    eventId: string,
    token: string,
  ): Promise<RescheduleDataResponseDto | undefined> {
    return await this.meetingApi.getRescheduleData(eventId, token);
  }

  async deleteMeeting(dto: DeleteMeetingDto): Promise<DeleteMeetingResult> {
    const token = this.sessionService.getAccessToken();
    if (!token) {
      return left(new UnknownError());
    }
    return await this.meetingApi.deleteEvent(token, dto);
  }
}

export const meetingService = new MeetingService(
  new MeetingApi(),
  new SessionService(),
);
