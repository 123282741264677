import { BaseRenderProps, FCModal } from '../components';
import React, { useState, useMemo } from 'react';
import { Form } from 'antd';
import { setLocationParam } from '../../../redux/actions';
import { ButtonMenuStyled } from '../../button/styledButton';
import './index.scss';
import { LocationSelect } from '../../locationSelect';
import { LocationTypeEnum } from '../../../models/meeting.model';
import { InputWithLabel } from '../../input/inputWithLabel';
import TextArea from 'antd/es/input/TextArea';
import { Input } from '../../input';

type Props = BaseRenderProps & setLocationParam;

export const SetLocationModal: FCModal<Props> = (props) => {
  const [selected, setSelected] = useState(props.default.kind);

  const onSubmitHandler = (event: any) => {
    if (selected) {
      switch (selected) {
        case LocationTypeEnum.person: {
          props.onSubmit({
            additionalInfo: event.address ? event.address : null,
            kind: selected,
          });
          break;
        }
        case LocationTypeEnum.webConference: {
          props.onSubmit({
            additionalInfo: event.url ? event.url : null,
            kind: selected,
          });
          break;
        }
        case LocationTypeEnum.custom: {
          props.onSubmit({
            additionalInfo: event.custom ? event.custom : null,
            kind: selected,
          });
          break;
        }
        default:
          props.onSubmit({
            additionalInfo: null,
            kind: selected,
          });
      }
    }
    props.onCancel();
  };

  const renderContent = useMemo(() => {
    switch (selected) {
      case LocationTypeEnum.jammerConference:
        return (
          <div className="location-set-content-twilio">
            <p>
              Dedicated pinless conference line. All meeting particiapants
              simply dial your line to connect.
            </p>
            <ul>
              <li>One-click dial in</li>
              <li>No pins, downloads, logins</li>
              <li>Text reminders</li>
            </ul>
          </div>
        );
      case LocationTypeEnum.person:
        return (
          <div className="location-set-content-person">
            <InputWithLabel
              label="Address or Place"
              tooltip="Address or Place Tooltip"
            >
              <Form.Item
                name="address"
                initialValue={
                  props.default.kind === LocationTypeEnum.person
                    ? props.default.additionalInfo
                    : null
                }
                rules={[
                  { required: true, message: 'please input address or place' },
                ]}
              >
                <TextArea
                  placeholder="Please enter an address or place"
                  rows={4}
                />
              </Form.Item>
            </InputWithLabel>
          </div>
        );
      case LocationTypeEnum.phoneCall:
        return (
          <div className="location-set-content-call">
            <p>
              Jammer will require your invitees to submit their phone number
              when they schedule a meeting.
            </p>
            <p>
              The phone number to calll will be in the meeting location details
              on the calendar invitation.
            </p>
          </div>
        );
      case LocationTypeEnum.webConference:
        return (
          <div className="location-set-content-web">
            <InputWithLabel label="Web Conference URL">
              <Form.Item
                name="url"
                initialValue={
                  props.default.kind === LocationTypeEnum.webConference
                    ? props.default.additionalInfo
                    : null
                }
                rules={[
                  {
                    required: true,
                    message: 'please input web conference url',
                  },
                ]}
              >
                <Input placeholder="Please enter an web conference URL" />
              </Form.Item>
            </InputWithLabel>
          </div>
        );
      case LocationTypeEnum.custom:
        return (
          <div className="location-set-content-custom">
            <InputWithLabel label="Custom Location">
              <Form.Item
                name="custom"
                initialValue={
                  props.default.kind === LocationTypeEnum.custom
                    ? props.default.additionalInfo
                    : null
                }
                rules={[
                  {
                    required: true,
                    message: 'please input custom location',
                  },
                ]}
              >
                <Input placeholder="Please enter your custom location details" />
              </Form.Item>
            </InputWithLabel>
          </div>
        );
      default:
        return <></>;
    }
  }, [selected, props.default]);

  return (
    <div className="location-set-form">
      <div className="location-set-label">Edit Location</div>
      <Form onFinish={onSubmitHandler} requiredMark>
        <LocationSelect onSelect={setSelected} default={selected} />
        <div className="location-set-content">{renderContent}</div>
        <div className="location-set-buttons">
          <div className="location-set-cancel" onClick={props.onCancel}>
            Cancel
          </div>
          <ButtonMenuStyled htmlType="submit">Update</ButtonMenuStyled>
        </div>
      </Form>
    </div>
  );
};
