import { LoadableData } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import {
  loadTimezonesAction,
  loadTimezonesErrorAction,
  loadTimezonesSuccessAction,
} from '../actions';
import {
  setLoadedStatusToLoadableData,
  setLoadingStatusToLoadableData,
} from '../utils';
import { TimezoneModel } from '../../models/timezone.model';

export interface TimezoneState extends LoadableData {
  data: Record<string, TimezoneModel>;
  isError: boolean;
  groups: Record<string, string[]>;
}
const initialTimezoneReducer: TimezoneState = {
  isLoaded: false,
  isLoading: false,
  data: {},
  groups: {},
  isError: false,
};

export const timezoneReducer = createReducer<TimezoneState>(
  initialTimezoneReducer,
  (builder) => {
    builder
      .addCase(loadTimezonesAction, (state) => {
        setLoadingStatusToLoadableData(state);
        state.isError = false;
      })
      .addCase(loadTimezonesErrorAction, (state) => {
        setLoadedStatusToLoadableData(state);
        state.isError = true;
      })
      .addCase(loadTimezonesSuccessAction, (state, action) => {
        setLoadedStatusToLoadableData(state);
        const dataMap: Record<string, TimezoneModel> = {};
        const groupMap: Record<string, string[]> = {};
        action.payload.data.forEach((el) => {
          dataMap[el.identifier] = el;
          const ell = groupMap[el.group];
          if (ell) {
            ell.push(el.identifier);
          } else {
            groupMap[el.group] = [el.identifier];
          }
        });
        state.data = dataMap;
        state.groups = groupMap;
      });
  },
);
