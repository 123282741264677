import { actionBuilder } from '../utils';
import * as ActionTypes from '../constants';

export interface FetchNotificationDto {
  guest: {
    emailReminder: boolean;
    textReminder: boolean;
  };
  user: {
    emailReminder: boolean;
    textReminder: boolean;
    textJoinReminder: boolean;
  };
}

export const {
  request: fetchNotificationAction,
  success: fetchNotificationSuccessAction,
  error: fetchNotificationErrorAction,
} = actionBuilder<null, FetchNotificationDto, null>(
  ActionTypes.FETCH_NOTIFICATION,
);

export const {
  request: updateNotificationAction,
  success: updateNotificationSuccessAction,
  error: updateNotificationErrorAction,
} = actionBuilder<FetchNotificationDto, FetchNotificationDto, null>(
  ActionTypes.UPDATE_NOTIFICATION,
);
