import { all } from 'redux-saga/effects';
import { authRunSaga, authWatchSaga } from './auth.saga';
import { profileRunSaga, profileWatchSaga } from './profile.saga';
import { timezoneRunSaga, timezoneWatchSaga } from './timezone.saga';
import { meetingRunSaga, meetingWatchSaga } from './meeting.saga';

import {
  notificationsRunSaga,
  notificationsWatchSaga,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from './notifications.saga.tsx';
import {
  bookingMeetingEventRunSaga,
  bookingMeetingEventWatchSaga,
} from './booking-meeting-event.saga';
import {
  organizationUsersRunSaga,
  organizationUsersWatchSaga,
} from './organization-users.saga';
import { designWatchSaga } from './design.saga';
import { remindersWatchSaga } from './reminders.saga';

function* runSaga() {
  yield all([
    authRunSaga(),
    profileRunSaga(),
    timezoneRunSaga(),
    meetingRunSaga(),
    bookingMeetingEventRunSaga(),
    notificationsRunSaga(),
    organizationUsersRunSaga(),
  ]);
}

export function* rootSaga(): Generator<any> {
  yield all([
    runSaga(),
    authWatchSaga(),
    profileWatchSaga(),
    timezoneWatchSaga(),
    meetingWatchSaga(),
    bookingMeetingEventWatchSaga(),
    notificationsWatchSaga(),
    organizationUsersWatchSaga(),
    designWatchSaga(),
    remindersWatchSaga(),
  ]);
}
