import React, { useMemo, useState } from 'react';
import {
  LocationTypeEnum,
  LocationArray,
  LocationConfigurationsModel,
} from '../../models/meeting.model';
import { useUserInfoSelector } from '../../redux/selectors/auth';
import './index.scss';
import { setLocationModalDialogAction } from '../../redux/actions';
import { useDispatch } from 'react-redux';

interface props {
  initialValue: LocationConfigurationsModel;
  locationHandler(value: LocationConfigurationsModel): void;
}

export const LocationInput: React.FC<props> = ({
  initialValue,
  locationHandler,
}) => {
  const dispatch = useDispatch();
  const user = useUserInfoSelector();
  const [locationValue, setLocationValue] = useState(initialValue);
  const location = useMemo(
    () => LocationArray.find((item) => item.type === locationValue?.kind),
    [locationValue],
  );
  return (
    <>
      {location && (
        <div className="locations-input-container">
          <div className="locations-input-element">
            {<location.logo />}
            <div className="locations-input-element-name">
              <div className="locations-input-header">{location.name}</div>
              <div className="locations-input-description">
                {location.type === LocationTypeEnum.jammerConference
                  ? user?.twilio_phone
                  : location.description}
              </div>
            </div>
          </div>
          <div
            className="locations-input-edit"
            onClick={() =>
              dispatch(
                setLocationModalDialogAction({
                  onSubmit(value): void {
                    locationHandler(value);
                    setLocationValue(value);
                  },
                  default: locationValue,
                }),
              )
            }
          >
            Edit
          </div>
        </div>
      )}
    </>
  );
};
