import _ from 'lodash';
import React from 'react';
import { arrayOfDaysOfWeek, DaysOfWeek } from '../../models/days-of-week.model';
import CheckMarkIcon from '../icons/checkMark';
import CloseIcon from '../icons/close';
import './index.scss';

export interface IAvailableDays {
  className?: string;
  activeDays: DaysOfWeek[];
  onClick?(day: DaysOfWeek): void;
}

export const AvailableDays: React.FC<IAvailableDays> = (props) => {
  return (
    <ul className={`available-days ${props.className ? props.className : ''}`}>
      {arrayOfDaysOfWeek.map((day) => {
        const isActive = _.some(props.activeDays, (el) => el === day);
        return (
          <AvailableDay
            day={day}
            key={day}
            active={isActive}
            onClick={props.onClick ? props.onClick : undefined}
          />
        );
      })}
    </ul>
  );
};

export interface IAvailableDay {
  day: DaysOfWeek;
  onClick?(day: DaysOfWeek): void;
  active?: boolean;
}

export const AvailableDay: React.FC<IAvailableDay> = (props) => {
  const handler = () => {
    if (props.onClick) {
      props.onClick(props.day);
    }
  };
  return (
    <li
      className={`item ${props.active ? 'item-active' : ''}`}
      onClick={handler}
    >
      {props.active ? (
        <CheckMarkIcon className="item-active-icon" />
      ) : (
        <CloseIcon className="item-inactive-icon" />
      )}
      <span className="item-name">{props.day}</span>
    </li>
  );
};
