import React from 'react';
import { Moment } from 'moment-timezone';
import './index.scss';
import { ArrowLeftIcon } from '../icons/arrowLeft';
import { ArrowRightIcon } from '../icons/arrowRight';
import { Month } from '../../pages/schedule/selectTime/monthView';

interface props {
  color?: string | null;
  month?: (Month | undefined)[];
  monthTitle?: string;
  upMonthHandler(): void;
  downMonthHandler(): void;
  selectedDay?: Moment;
  setSelectedDay(value: Moment): void;
}

const weekDayNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export const MonthSelectDay: React.FC<props> = (props) => {
  return (
    <div className="monthSelectDay-container">
      {props.monthTitle ? (
        <div className="monthSelectDay-title-container">
          <div
            className="monthSelectDay-leftArrow"
            onClick={props.downMonthHandler}
          >
            <ArrowLeftIcon />
          </div>
          <p className="monthSelectDay-title">{props.monthTitle}</p>
          <div
            className="monthSelectDay-rightArrow"
            onClick={props.upMonthHandler}
          >
            <ArrowRightIcon />
          </div>
        </div>
      ) : null}
      <div className="monthSelectDay-calendar-weekDays">
        {weekDayNames.map((item, index) => (
          <div className="monthSelectDay-calendar-item" key={index}>
            {item}
          </div>
        ))}
      </div>
      <div className="monthSelectDay-calendar">
        {props?.month &&
          props?.month.map((item, index) =>
            item?.day ? (
              <div className="monthSelectDay-calendar-item" key={index}>
                <div
                  className={`monthSelectDay-item${item.class}`}
                  style={
                    item.isSelected && props.color
                      ? { backgroundColor: props.color }
                      : undefined
                  }
                  onClick={() => {
                    item?.isAvailable && props.setSelectedDay(item.day);
                  }}
                >
                  <p
                    style={
                      item.isCurrent && !item.isSelected && props.color
                        ? { color: props.color }
                        : undefined
                    }
                  >
                    {item.day.format('D')}
                  </p>
                </div>
              </div>
            ) : (
              <div className="monthSelectDay-calendar-item" key={index}>
                <div className="monthSelectDay-item"></div>
              </div>
            ),
          )}
      </div>
    </div>
  );
};
