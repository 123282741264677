export enum ModalTypes {
  meetingLocation = 'meeting-location',
  meetingDateRange = 'meeting-date-range',
  meetingTimezone = 'meeting-timezone',
  meetingRuleAvailability = 'meeting-rule-availability',
  eventNotifications = 'event-notifications',
  bookByEmail = 'book-by-email',

  changeUserEmail = 'change-user-email',
  changeUserPassword = 'change-user-password',
  deleteOrganizationPendingUser = 'delete-organization-pending-user',
  deleteOrganizationAcceptedUser = 'delete-organization-accepted-user',
  inviteSendSuccess = 'invite-send-success',
  calendarConnectionFailed = 'calendar-connections-failed',
  successEventModal = 'event=success',
  deleteMeeting = 'delete-meeting',
  //newModals
  logout = 'logout',
  setLocation = 'set-location',
  deleteUserAccount = 'delete-user-account',
  enterCard = 'enter-card',
  congratulations = 'congratulations',
  downgradeSubscription = 'downgradeSubscription',
  setCalendarInvitation = 'setCalendarInvitation',
}
