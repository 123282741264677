import React from 'react';
import { ReactComponent as LeftArrowLogo } from './Vector.svg';

interface Props {
  style?: any;
}

export const ArrowLeftIcon: React.FC<Props> = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LeftArrowLogo style={{ ...props.style }} />
    </div>
  );
};
