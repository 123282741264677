import React, { useState } from 'react';
import { Dashboard } from '../../components/dashboardLayout';
import { useUserInfoSelector } from '../../redux/selectors/auth';
import { InputWithLabel } from '../../components/input/inputWithLabel';
import { Input } from '../../components/input';
import { PhoneInputComponent } from '../../components/phoneInput';
import { InputStyled } from '../../components/input/input';
import appConfig from '../../constants/appConfig';
import './index.scss';
import { ButtonStyledOutlined } from '../../components/button/styledButtonOutlined';
import Form from 'antd/lib/form';
import { AvatarCropper } from '../../components/userAvatarLoad/upload-avatar';
import {
  deleteUserAccountAction,
  showDeleteUserAccountModalDialogAction,
  updateAccountProfileAction,
} from '../../redux/actions';
import { useDispatch } from 'react-redux';

export const AccountSettings: React.FC = () => {
  const dispatch = useDispatch();
  const user = useUserInfoSelector();
  const [valid, setValid] = useState(true);
  const [phone, setPhone] = useState(user?.phone);

  const deleteHandler = () => {
    dispatch(
      showDeleteUserAccountModalDialogAction({
        onSubmit() {
          dispatch(deleteUserAccountAction());
        },
      }),
    );
  };
  return (
    <Dashboard>
      <div className="account-settings-page-container">
        <div className="account-settings-page-body">
          <div className="account-settings-page-body-header">
            Edit your default availability and apply updates to all your meeting
            types.
          </div>
          <InputWithLabel
            label="Profile Photo"
            tooltip="Use this setting to add a photo to all your Jammer dashboard. Your photo will appear on the top left if your Jammer dashboard."
          >
            <AvatarCropper />
          </InputWithLabel>
          <InputWithLabel
            label="Name"
            tooltip="This is the name that apprears on your Jammer scheudling pages, email notification, and calendar invitations."
          >
            <div className="input-row">
              <Form onFinish={(e) => dispatch(updateAccountProfileAction(e))}>
                <Form.Item
                  initialValue={user?.name}
                  name="name"
                  rules={[{ required: true, message: 'name is required!' }]}
                >
                  <Input />
                </Form.Item>
                <ButtonStyledOutlined htmlType="submit">
                  Save
                </ButtonStyledOutlined>
              </Form>
            </div>
          </InputWithLabel>
          <InputWithLabel
            label="Email (Google Account)"
            // tooltip="Changing your login email address
            //  will mean that you will now only be able to
            //  login to your Jammer dashboard with your new email address."
          >
            <div className="input-row">
              <Form
              // onFinish={(e) => console.log(e)}
              >
                <Form.Item
                  style={{ marginRight: '80px' }}
                  initialValue={user?.email}
                  name="email"
                  // rules={[
                  //   { required: true, message: 'email is required!' },
                  //   { pattern: /@/, message: 'not email format!' },
                  // ]}
                >
                  <Input disabled />
                </Form.Item>
                {/*<ButtonStyledOutlined htmlType="submit">*/}
                {/*  Save*/}
                {/*</ButtonStyledOutlined>*/}
              </Form>
            </div>
          </InputWithLabel>
          <InputWithLabel
            label="Phone"
            tooltip="Changing your phone number will mean that your Jammer conference line will now be synced to your new number for meeting text message reminders."
          >
            <div className="input-row">
              <Form onFinish={(e) => dispatch(updateAccountProfileAction(e))}>
                <Form.Item initialValue={phone} name="phone">
                  <PhoneInputComponent
                    onChange={setPhone}
                    onValidPhone={setValid}
                  />
                </Form.Item>
                <ButtonStyledOutlined disabled={!valid} htmlType="submit">
                  Save
                </ButtonStyledOutlined>
              </Form>
            </div>
          </InputWithLabel>
          <InputWithLabel
            label="Main Link"
            tooltip="Changing your Jammer URL will mean that all your copies links will no longer work and will need to be updated."
          >
            <div className="input-row">
              <Form onFinish={(e) => dispatch(updateAccountProfileAction(e))}>
                <Form.Item
                  initialValue={user?.slug}
                  name="slug"
                  rules={[
                    { required: true, message: 'main link is required!' },
                  ]}
                >
                  <InputStyled addonBefore={appConfig.appHostName} />
                </Form.Item>
                <ButtonStyledOutlined htmlType="submit">
                  Save
                </ButtonStyledOutlined>
              </Form>
            </div>
          </InputWithLabel>
        </div>
        <div className="account-settings-page-buttons">
          <ButtonStyledOutlined onClick={deleteHandler}>
            Delete Account
          </ButtonStyledOutlined>
        </div>
      </div>
    </Dashboard>
  );
};
