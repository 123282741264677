import { AxiosError } from 'axios';
import { find, filter } from 'lodash';

export const isAxiosError = <T = HttpException>(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  e: any,
): e is AxiosError<T> => {
  return Boolean(e.isAxiosError);
};

export interface ExceptionDetail {
  message: string;
  code: string;
  path: string;
  meta?: any;
}

export type ExceptionDetails = ExceptionDetail[];

export interface HttpException {
  code: ErrorCodes;
  details: ExceptionDetails;
  message: string;
  meta: any;
}

export enum ErrorCodes {
  authTokenInvalid = 20001,
  authTokenExpired = 20000,
  validationFailed = 10000,
  authEmailConflict = 20103,
  authUnapprovedUserEmail = 20105,
  authUserEmailIsNotRegister = 20100,
  authInvalidCredential = 20102,
  authInvalidCurrentPassword = 20106,
  userSlugConflict = 20107,
  userPhoneConflict = 20108,
  authMismatchAuthMethod = 20101,
  authForgotPasswordTokenNotRegister = 20200,
  authForgotPasswordTokenExpired = 20201,
  authApproveUserEmailNotRegister = 20300,
  authChangeUserPasswordsNotMatchConfirmation = 40006,

  meetingSlugConflict = 30001,
  meetingNotFound = 30002,
  meetingEventNotFound = 30006,
  subscriptionUnauthorized = 401,

  notOrganizationMember = 40000,

  inviteUserExistInCurrentOrganization = 40001,
  invitePermission = 40002,
  inviteMismatch = 40003,
  invitationLinkDeprecated = 40004,
  userAnotherOrgError = 40005,
}

export const getFirstErrorByPathName = (
  errors: ExceptionDetails,
  name: string,
): ExceptionDetail | undefined => {
  return find(errors, { path: name });
};

export const getErrorByPathName = (
  errors: ExceptionDetails,
  name: string,
): ExceptionDetails => {
  return filter(errors, { path: name });
};
