import { Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import {
  configureStore as configureStoreToolkit,
  // SerializableStateInvariantMiddlewareOptions,
} from '@reduxjs/toolkit';
import { reducers } from '../reducers';
import { rootSaga } from '../sagas';
import { StoreState } from './interface';
// import * as ActionTypes from '../constants';

// const serializableStateOptions: SerializableStateInvariantMiddlewareOptions = {
//   ignoredActions: [
//     ActionTypes.SHOW_MODAL_DIALOG,
//     ...Object.values(ActionTypes.EDIT_MEETING_BY_ID),
//     ...Object.values(ActionTypes.FETCH_MEETING_BOOK_EMAIL_DATA),
//     ...Object.values(ActionTypes.LOAD_TIMEZONES),
//     ...Object.values(ActionTypes.DELETE_ACCOUNT),
//     ...Object.values(ActionTypes.CHANGE_USER_PASSWORD),
//     ...Object.values(ActionTypes.CHANGE_USER_EMAIL),
//     ...Object.values(ActionTypes.DELETE_ORGANIZATION_USER),
//     ...Object.values(ActionTypes.FETCH_ORGANIZATION_USERS),
//     ...Object.values(ActionTypes.CHANGE_NOTIFICATION_REMINDERS),
//     ...Object.values(ActionTypes.FETCH_BOOK_EVENT),
//     ...Object.values(ActionTypes.CANCELLATION_EVENT),
//     ...Object.values(ActionTypes.DELETE_MEETING),
//     ...Object.values(ActionTypes.SHOW_MODAL_DIALOG),
//   ],
// };

export const configureStore = (): Store<StoreState> => {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStoreToolkit({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => {
      return [
        ...getDefaultMiddleware({
          thunk: false,
          serializableCheck: false,
        }),
        sagaMiddleware,
      ];
    },
  });
  sagaMiddleware.run(rootSaga);

  return store;
};
