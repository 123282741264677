import { SessionService } from '../auth/session.service';
import { DesignApi } from './design.api';
import { DesignEditFetchDto, DesignFetchDto } from './design.dto';

export class DesignService {
  constructor(
    private readonly sessionService: SessionService,
    private readonly designApi: DesignApi,
  ) {}

  async getDesign(): Promise<DesignFetchDto | undefined> {
    const token = this.sessionService.getAccessToken();

    if (this.sessionService.isAccessTokenExpired(token)) {
      return;
    }
    return await this.designApi.getDesign(token);
  }

  async updateDesign(
    dto: DesignEditFetchDto,
  ): Promise<DesignFetchDto | undefined> {
    const token = this.sessionService.getAccessToken();

    if (this.sessionService.isAccessTokenExpired(token)) {
      return;
    }
    return await this.designApi.updateDesign(token, dto);
  }
}
export const designService = new DesignService(
  new SessionService(),
  new DesignApi(),
);
