import { put, takeLatest } from 'redux-saga/effects';
import {
  loadTimezonesAction,
  loadTimezonesErrorAction,
  loadTimezonesSuccessAction,
} from '../actions';
import {
  GetTimezonesResult,
  timezoneService,
} from '../../libs/common/timezone.service';

function* loadTimezonesSagaAction() {
  const result: GetTimezonesResult = yield timezoneService.getTimezones();
  if (result.isLeft()) {
    alert(result.value.message);
    yield put(loadTimezonesErrorAction());
    return;
  }

  yield put(loadTimezonesSuccessAction({ data: result.value }));
}

export function* timezoneWatchSaga(): Generator<any> {
  yield takeLatest(loadTimezonesAction, loadTimezonesSagaAction);
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function* timezoneRunSaga(): Generator<any> {}
