import React, { useMemo } from 'react';
import './index.scss';

interface IConferenceLinePhone {
  phone?: string | null;
  className?: string;
}

export const ConferenceLinePhone: React.FC<IConferenceLinePhone> = ({
  phone,
  className,
}) => {
  const formatPhone: string = useMemo(() => {
    if (phone && /^\+([0-9])*/g.test(phone || '')) {
      const cleaned = ('' + phone).replace(/\D/g, '');
      //Check if the input is of correct
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        //Remove the matched extension code
        //Change this to format for any country code.
        const intlCode = match[1] ? `+${match[1]} ` : '';
        return [intlCode, ' ', match[2], '-', match[3], '-', match[4]].join('');
      }
      return 'no phone';
    } else {
      return 'no phone';
    }
  }, [phone]);

  return (
    <div className={`conference-line-phone-container ${className || ''}`}>
      <div className="conference-line-title">Jammer Conference Line</div>
      <div className="conference-line-phone">{formatPhone}</div>
    </div>
  );
};
