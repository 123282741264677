import React, { useMemo } from 'react';
import { ChargeStripe } from '../../../libs/user/dtos/user.api.dto';
import { Table } from 'antd';
import moment from 'moment-timezone';

interface props {
  charges?: ChargeStripe[];
}

export const BillingTable: React.FC<props> = (props) => {
  const columns = [
    {
      title: 'DATE',
      dataIndex: 'created',
      key: 'created',
      sorter: (a: any, b: any) => a.created - b.created,
      // eslint-disable-next-line react/display-name
      render: (text: number) => (
        <span>{moment.unix(text).format('MMMM D,YYYY')}</span>
      ),
    },
    {
      title: 'PLAN',
      dataIndex: 'age',
      key: 'age',
      // eslint-disable-next-line react/display-name
      render: () => <span>Platinum Monthly</span>,
    },
    {
      title: 'PAYMENT STATUS',
      key: 'status',
      dataIndex: 'status',
      // eslint-disable-next-line react/display-name
      render: (status: string) => (
        <>
          {status === 'succeeded' ? (
            <span className="success">Paid</span>
          ) : (
            <span className="failed">Failed</span>
          )}
        </>
      ),
    },
    {
      title: 'AMOUNT (USD)',
      key: 'amount',
      dataIndex: 'amount',
      // eslint-disable-next-line react/display-name
      render: (amount: number) => <span>${amount / 100}</span>,
    },
  ];
  const formatData = useMemo(() => {
    const data = [];
    if (props.charges && props.charges?.length > 0) {
      for (let i = 0; i < props.charges?.length; i++) {
        data.push({
          ...props.charges[i],
          key: i,
        });
      }
    }
    return data;
  }, [props.charges]);

  return (
    <Table
      columns={columns}
      dataSource={formatData}
      showSorterTooltip={false}
      pagination={{ defaultPageSize: 5, position: ['bottomCenter'] }}
    />
  );
};
