import { AccessTokenDto } from '../auth/dtos/access-token.dto';
import { DesignFetchDto, DesignEditFetchDto } from './design.dto';
import BaseAxiosInstance from '../../utils/axios-config';

export class DesignApi {
  async getDesign(
    accessToken: AccessTokenDto,
  ): Promise<DesignFetchDto | undefined> {
    const response = await BaseAxiosInstance.get('/api/designs/my', {
      headers: { Authorization: `Bearer ${accessToken.token}` },
    });
    return response.data;
  }

  async updateDesign(
    accessToken: AccessTokenDto,
    dto: DesignEditFetchDto,
  ): Promise<DesignFetchDto | undefined> {
    const response = await BaseAxiosInstance.put('/api/designs/my', dto, {
      headers: { Authorization: `Bearer ${accessToken.token}` },
    });
    return response.data;
  }
}
