import { call, put, takeLatest } from 'redux-saga/effects';
import * as _ from 'lodash';
import * as queryString from 'query-string';
import { authService } from '../../libs/auth/auth.service';
import {
  approveUserEmailAction,
  approveUserEmailErrorAction,
  ApproveUserEmailParams,
  approveUserEmailSuccessAction,
  changeLoginByGoogleActionSuccess,
  ChangeLoginByGoogleParams,
  ChangeUserCredentialsParams,
  changeUserEmailAction,
  changeUserEmailErrorAction,
  changeUserEmailGoogleAction,
  changeUserEmailSuccessAction,
  changeUserPasswordAction,
  changeUserPasswordErrorAction,
  changeUserPasswordSuccessAction,
  cleanupAuthMethodAction,
  dismissModalDialogAction,
  forgotPasswordAction,
  forgotPasswordActionError,
  forgotPasswordActionSuccess,
  ForgotPasswordParams,
  getAuthMethodByEmailAction,
  getAuthMethodByEmailActionError,
  getAuthMethodByEmailActionSuccess,
  GetAuthMethodParams,
  getProfileAction,
  getProfileActionError,
  getProfileActionSuccess,
  inviteCheckUserAction,
  InviteCheckUserActionParam,
  inviteCheckUserErrorAction,
  inviteCheckUserSuccessAction,
  inviteUserAcceptAction,
  inviteUserAcceptErrorAction,
  InviteUserAcceptParam,
  inviteUserAcceptSuccessAction,
  loginByGoogleAction,
  loginByGoogleActionSuccess,
  loginByPasswordAction,
  loginByPasswordActionError,
  loginByPasswordActionSuccess,
  LoginByPasswordParams,
  logoutAction,
  logoutActionSuccess,
  resetForgotPasswordAction,
  resetForgotPasswordActionError,
  resetForgotPasswordActionSuccess,
  ResetForgotPasswordParams,
  showNotificationAction,
  signUpByPassword,
  signUpByPasswordError,
  SignUpByPasswordParams,
  signUpByPasswordSuccess,
  loginForGoogleCalendarAction,
  fireLoginGoogleCallbackAction,
  ChangeUserEmailSuccessParams,
  getSubscriptionPlansActionSuccess,
} from '../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { appHistory, routers } from '../../router';
import {
  ApproveUserEmailResult,
  ChangeUserCredentialsResult,
  ForgotPasswordResult,
  GetAuthMethodByEmailResultDto,
  GetProfileResultDto,
  LoginByGoogleResult,
  LoginByPasswordResultDto,
  ResetForgotPasswordResult,
  SingUpByPasswordResultDto,
  SubscriptionResultDto,
} from '../../libs/auth/dtos/auth.dto';
import { sessionService } from '../../libs/auth/session.service';

function* loginByPasswordSagaAction(
  action: PayloadAction<LoginByPasswordParams>,
) {
  const result: LoginByPasswordResultDto = yield authService.loginByPassword({
    email: action.payload.email,
    password: action.payload.password,
  });

  if (result.isLeft()) {
    if (
      result.value.code === 'validationError' ||
      result.value.code === 'authInvalidCredential'
    ) {
      yield put(loginByPasswordActionError({ details: result.value.details }));
    } else if (result.value.code === 'authUnapprovedUserEmail') {
      yield put(loginByPasswordActionError({ details: [] }));
      appHistory.push(routers.WAITING_TO_CONFIRM_USER_EMAIL_PAGE);
    } else {
      // fixme
      alert(result.value.message);
      yield put(loginByPasswordActionError({ details: [] }));
    }
    return;
  }

  yield put(loginByPasswordActionSuccess({ user: result.value.user }));
}

function* getAuthMethodByEmailSagaAction(
  action: PayloadAction<GetAuthMethodParams>,
) {
  const result: GetAuthMethodByEmailResultDto = yield authService.getAuthMethodByEmail(
    action.payload.email,
  );

  if (result.isLeft()) {
    if (result.value.code === 'validationError') {
      yield put(
        getAuthMethodByEmailActionError({ details: result.value.details }),
      );
    } else {
      // fixme
      alert(result.value.message);
      yield put(getAuthMethodByEmailActionError({ details: [] }));
    }
    return;
  }
  yield put(getAuthMethodByEmailActionSuccess({ method: result.value }));
  if (result.value === null) {
    appHistory.push(routers.SIGN_UP_PAGE);
  }
}

function* loginByGoogleSagaAction() {
  const result: LoginByGoogleResult = yield authService.loginByGoogle();
  if (result.isLeft()) {
    // fixme
    alert(result.value.message);
    return;
  }
  yield put(loginByGoogleActionSuccess({ user: result.value.user }));
  if (result.value.user.isFilledProfile) {
    appHistory.push(routers.ME_PAGE);
  } else {
    appHistory.push(routers.COMPLETE_PROFILE_INFO_PAGE);
  }
}

function* logoutSagaAction() {
  yield authService.logout();
  appHistory.replace(routers.LANDING_PAGE);
  yield put(logoutActionSuccess());
}

function* getSubscriptionPlansSagaAction() {
  const result: SubscriptionResultDto = yield authService.getSubscriptions();
  if (result.isLeft()) {
    yield put(getProfileActionError({}));
    if (result.value.code === 'unknown') {
      // fixme
      //why after change login in profile fire this error?
      alert(result.value.message);
    }
    if (result.value.code === 'authTokenExpired') {
      alert(result.value.message);
    }
    return;
  }
  yield put(
    getSubscriptionPlansActionSuccess({
      subscriptions: result.value.subscriptions,
    }),
  );
}

export function* getProfileSagaAction(): any {
  const result: GetProfileResultDto = yield authService.getProfile();
  if (result.isLeft()) {
    yield put(getProfileActionError({}));
    if (result.value.code === 'unknown') {
      // fixme
      //why after change login in profile fire this error?
      alert(result.value.message);
    }
    if (result.value.code === 'authTokenExpired') {
      alert(result.value.message);
    }
    return;
  }

  yield put(getProfileActionSuccess({ user: result.value.user }));
  yield getSubscriptionPlansSagaAction();
  return;
}

function* resetAuthSagaAction() {
  yield appHistory.replace(routers.LOGIN_PAGE);
}

function* signUpByPasswordSagaAction(
  action: PayloadAction<SignUpByPasswordParams>,
) {
  const result: SingUpByPasswordResultDto = yield authService.singUpByPassword({
    email: action.payload.email,
    password: action.payload.password,
    name: action.payload.name,
  });
  if (result.isLeft()) {
    if (result.value.code === 'validationError') {
      yield put(signUpByPasswordError({ details: result.value.details }));
      return;
    }

    yield put(signUpByPasswordError({ details: [] }));
    if (result.value.code === 'unknown') {
      // fixme
      alert(result.value.message);
    }
    return;
  }

  if (localStorage.getItem('iut')) {
    const result: LoginByPasswordResultDto = yield authService.loginByPassword({
      email: action.payload.email,
      password: action.payload.password,
    });
    if (result.isLeft()) {
      alert(result.value.message);
      return;
    }
    yield put(loginByPasswordActionSuccess({ user: result.value.user }));
    appHistory.push(routers.ME_PAGE);
    return;
  }

  yield put(signUpByPasswordSuccess());
  appHistory.push(routers.WAITING_TO_CONFIRM_USER_EMAIL_PAGE);
}

function* forgotPasswordSagaAction(
  action: PayloadAction<ForgotPasswordParams>,
) {
  const result: ForgotPasswordResult = yield authService.forgotPassword(
    action.payload.email,
  );
  if (result.isLeft()) {
    if (result.value.code === 'validationError') {
      yield put(forgotPasswordActionError({ details: result.value.details }));
      return;
    }
    yield put(forgotPasswordActionError({ details: [] }));
    if (result.value.code === 'unknown') {
      // fixme
      alert(result.value.message);
    }
    return;
  }

  yield put(forgotPasswordActionSuccess());
}

function* resetForgotPassword(
  action: PayloadAction<ResetForgotPasswordParams>,
) {
  const result: ResetForgotPasswordResult = yield authService.resetForgotPassword(
    {
      token: action.payload.token,
      password: action.payload.password,
    },
  );
  if (result.isLeft()) {
    if (result.value.code === 'validationError') {
      yield put(
        resetForgotPasswordActionError({ details: result.value.details }),
      );
      return;
    }
    yield put(resetForgotPasswordActionError({ details: [] }));
    if (result.value.code === 'authForgotPasswordTokenExpired') {
      alert(result.value.message);
    }
    if (result.value.code === 'authForgotPasswordTokenInvalid') {
      alert(result.value.message);
    }
    if (result.value.code === 'unknown') {
      alert(result.value.message);
    }

    return;
  }

  yield put(resetForgotPasswordActionSuccess());
}

function* approveUserEmailSagaAction(
  action: PayloadAction<ApproveUserEmailParams>,
) {
  const result: ApproveUserEmailResult = yield authService.approveUserEmail({
    token: action.payload.token,
    password: action.payload.password,
  });
  if (result.isLeft()) {
    if (
      result.value.code === 'validationError' ||
      result.value.code === 'authInvalidCredential'
    ) {
      yield put(approveUserEmailErrorAction({ details: result.value.details }));
      return;
    }
    if (result.value.code === 'unknown') {
      alert(result.value.message);
    }
    if (result.value.code === 'authApproveTokenNotRegister') {
      alert(result.value.message);
    }

    yield put(approveUserEmailErrorAction({ details: [] }));
    return;
  }

  yield put(approveUserEmailSuccessAction({ user: result.value.user }));
  if (result.value.user.isFilledProfile) {
    appHistory.push(routers.ME_PAGE);
  } else {
    appHistory.push(routers.COMPLETE_PROFILE_INFO_PAGE);
  }
}

function changeUserCredentialsApi(params: ChangeUserCredentialsParams) {
  return authService.changeUserCredentials(params);
}

function* changeUserEmailSagaAction({
  payload,
}: PayloadAction<ChangeUserEmailSuccessParams>) {
  const result: ChangeUserCredentialsResult = yield call(
    changeUserCredentialsApi,
    payload,
  );

  if (result.isLeft()) {
    if (
      result.value.code === 'validationError' ||
      result.value.code === 'authChangeUserPasswordNotMatchConfirmation' ||
      result.value.code === 'authInvalidCurrentPassword'
    ) {
      yield put(
        changeUserEmailErrorAction({
          details: result.value.details,
        }),
      );
      return;
    }

    yield put(
      changeUserEmailErrorAction({
        details: [],
      }),
    );

    if (result.value.code === 'unknown') {
      alert(result.value.message);
    }

    return;
  }

  yield put(changeUserEmailSuccessAction(payload));
  yield put(dismissModalDialogAction());

  yield put(
    showNotificationAction({
      type: 'success',
      content: 'Email was successfully changed',
      duration: null,
    }),
  );
}

function* changeUserPasswordSagaAction({
  payload,
}: PayloadAction<ChangeUserCredentialsParams>) {
  const result: ChangeUserCredentialsResult = yield call(
    changeUserCredentialsApi,
    payload,
  );

  if (result.isLeft()) {
    if (
      result.value.code === 'validationError' ||
      result.value.code === 'authChangeUserPasswordNotMatchConfirmation' ||
      result.value.code === 'authInvalidCurrentPassword'
    ) {
      yield put(
        changeUserPasswordErrorAction({
          details: result.value.details,
        }),
      );
      return;
    }

    yield put(
      changeUserPasswordErrorAction({
        details: [],
      }),
    );

    if (result.value.code === 'unknown') {
      alert(result.value.message);
    }

    return;
  }

  yield put(changeUserPasswordSuccessAction(payload));
  yield put(dismissModalDialogAction());

  yield put(
    showNotificationAction({
      type: 'success',
      content: 'Password was successfully changed',
      duration: null,
    }),
  );
}

function* changeUserEmailGoogleSagaAction({
  payload,
}: PayloadAction<ChangeLoginByGoogleParams>) {
  const result = yield authService.changeUserGoogleEmail(payload.state);

  if (result.isLeft()) {
    // fixme
    alert(result.value.message);
    return;
  }

  yield put(changeLoginByGoogleActionSuccess({ user: result.value.user }));
  if (result.value.user.isFilledProfile) {
    appHistory.push(routers.ACCOUNT_LOGIN_PAGE);
  } else {
    appHistory.push(routers.COMPLETE_PROFILE_INFO_PAGE);
  }
}

function* inviteUserAcceptSagaAction({
  payload,
}: PayloadAction<InviteUserAcceptParam>) {
  const result = yield authService.acceptUser(payload.inviteToken);
  localStorage.removeItem('iut');

  if (result.isLeft()) {
    switch (result.value.code) {
      case 'inviteDeprecatedError':
      case 'userAnotherOrgError':
        yield put(
          inviteUserAcceptErrorAction({
            message: result.value.message,
            type: 'error',
          }),
        );
        return;
    }
    return;
  }

  yield put(
    inviteUserAcceptSuccessAction({
      message: 'You success join to organization',
      type: 'success',
    }),
  );
  yield put(getProfileAction());
}

function* inviteCheckUserSagaAction({
  payload,
}: PayloadAction<InviteCheckUserActionParam>) {
  const result = yield authService.inviteCheckUser(payload.inviteToken);

  localStorage.setItem('iut', payload.inviteToken);

  if (result.isLeft()) {
    yield put(
      inviteCheckUserErrorAction({
        message: result.value.message,
        type: 'error',
      }),
    );

    appHistory.push(routers.LOGIN_PAGE);

    return;
  }

  yield put(
    inviteCheckUserSuccessAction({
      userEmail: result.value.userEmail,
      adminName: result.value.adminName,
      haveToRegistration: result.value.haveToRegistration,
    }),
  );

  if (result.value.haveToRegistration) {
    appHistory.push(routers.SIGN_UP_PAGE, {
      userEmail: result.value.userEmail,
      adminName: result.value.adminName,
    });
    return;
  }
  appHistory.push(routers.LOGIN_PAGE, {
    userEmail: result.value.userEmail,
    adminName: result.value.adminName,
  });
}

function* loginForGoogleCalendarSagaAction() {
  yield authService.loginForGoogleCalendar();
}

function* fireSignInGoogleCallbackSagaAction(action: PayloadAction<any>) {
  try {
    const response = queryString.parse(action.payload);
    const token: string = response.token as string;
    const expiredAt: number = Number(response.expired_at) as number;
    if (!_.isEmpty(action.payload)) {
      yield sessionService.saveAccessToken({
        token,
        expiredAt,
      });
    }

    const result: GetProfileResultDto = yield authService.getProfile();
    if (result.isLeft()) {
      yield put(getProfileActionError({}));
      if (result.value.code === 'unknown') {
        // fixme
        //why after change login in profile fire this error?
        alert(result.value.message);
      }
      if (result.value.code === 'authTokenExpired') {
        alert(result.value.message);
      }
      return;
    }

    yield put(getProfileActionSuccess({ user: result.value.user }));
    yield getSubscriptionPlansSagaAction();

    yield appHistory.push(routers.ME_PAGE);
  } catch (error) {
    yield put(
      inviteUserAcceptErrorAction({
        message: `The credentials you supplied were not correct or did not grant access to this resource.`,
        type: 'error',
      }),
    );
  }
}

export function* authWatchSaga(): Generator<any> {
  yield takeLatest(getAuthMethodByEmailAction, getAuthMethodByEmailSagaAction);
  yield takeLatest(loginByPasswordAction, loginByPasswordSagaAction);
  yield takeLatest(loginByGoogleAction, loginByGoogleSagaAction);
  yield takeLatest(
    fireLoginGoogleCallbackAction,
    fireSignInGoogleCallbackSagaAction,
  );
  yield takeLatest(getProfileAction, getProfileSagaAction);
  yield takeLatest(logoutAction, logoutSagaAction);
  yield takeLatest(cleanupAuthMethodAction, resetAuthSagaAction);
  yield takeLatest(signUpByPassword, signUpByPasswordSagaAction);
  yield takeLatest(forgotPasswordAction, forgotPasswordSagaAction);
  yield takeLatest(resetForgotPasswordAction, resetForgotPassword);
  yield takeLatest(approveUserEmailAction, approveUserEmailSagaAction);
  yield takeLatest(changeUserEmailAction, changeUserEmailSagaAction);
  yield takeLatest(changeUserPasswordAction, changeUserPasswordSagaAction);
  yield takeLatest(
    changeUserEmailGoogleAction,
    changeUserEmailGoogleSagaAction,
  );
  yield takeLatest(inviteUserAcceptAction, inviteUserAcceptSagaAction);
  yield takeLatest(inviteCheckUserAction, inviteCheckUserSagaAction);
  yield takeLatest(
    loginForGoogleCalendarAction,
    loginForGoogleCalendarSagaAction,
  );
}

export function* authRunSaga(): Generator<any> {
  yield put(getProfileAction());
}
