import React from 'react';
import { Form } from 'antd';
import { BaseRenderProps, FCModal } from '../../../components/modal/components';
import { delSubscription } from '../../../redux/actions';
import { ButtonStyledOutlined } from '../../../components/button/styledButtonOutlined';
import { ButtonMenuStyled } from '../../../components/button/styledButton';
import { useDispatch } from 'react-redux';

type Props = BaseRenderProps;

export const DowngradeModal: FCModal<Props> = (props) => {
  const dispatch = useDispatch();
  const onSubmitHandler = () => {
    dispatch(delSubscription());
    props.onCancel();
  };

  return (
    <div className="modal-space">
      <div className="text-header">Downgrade to basic</div>
      <div className="modal-space-description">
        Are you sure you want to downgrade to basic?
      </div>
      <Form layout="vertical" onFinish={onSubmitHandler}>
        <div className="modal-space-buttons">
          <ButtonStyledOutlined onClick={props.onCancel}>
            Cancel
          </ButtonStyledOutlined>
          <ButtonMenuStyled htmlType="submit">Downgrade</ButtonMenuStyled>
        </div>
      </Form>
    </div>
  );
};
