import React, { useEffect, useState } from 'react';
import { Dashboard } from '../../components/dashboardLayout';
import { HomeHeader } from './header';
import { useUserInfoSelector } from '../../redux/selectors/auth';
import './index.scss';
import {
  fetchMeetingsAction,
  updateMeetingsOrderAction,
} from '../../redux/actions';
import { useDispatch } from 'react-redux';
import { useMeetingsListSelect } from '../../redux/selectors/meetings';
import { MeetingItem } from '../../components/meetingItem';
import './index.scss';
import { MeetingOnListModel } from '../../models/meeting.model';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

interface MeetingOnListDnD extends MeetingOnListModel {
  order_dnd: number;
}

const reorder = (
  list: MeetingOnListDnD[],
  startIndex: number,
  endIndex: number,
): MeetingOnListDnD[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  const newArray = result.map((item, index) => {
    item.order_dnd = index;
    return item;
  });
  return newArray;
};

export const Home: React.FC = () => {
  const user = useUserInfoSelector();
  const meetings = useMeetingsListSelect();
  const [meetingsList, setMeetingsList] = useState<MeetingOnListDnD[]>([]);

  const dispatch = useDispatch();
  useEffect(() => {
    const list = meetings.data.map((item, index) => {
      return { ...item, order_dnd: index };
    });
    setMeetingsList(list);
  }, [meetings]);
  useEffect(() => {
    if (user?.isFilledProfile) {
      dispatch(fetchMeetingsAction({ page: 1 }));
    }
    // eslint-disable-next-line
  }, [dispatch, user?.isFilledProfile]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      meetingsList,
      result.source.index,
      result.destination.index,
    );
    setMeetingsList(items);
    dispatch(updateMeetingsOrderAction(meetingsList));
  };

  return (
    <Dashboard>
      <HomeHeader slug={user?.slug} />
      {meetingsList.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <ul
                className="meeting-item-list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {meetingsList.map((item, index) => (
                  <MeetingItem
                    index={index}
                    key={item.id}
                    item={item}
                    loading={meetings.isLoading}
                    loaded={meetings.isLoaded}
                  />
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </Dashboard>
  );
};
