import React from 'react';
import { ReactComponent as MobileLogo } from './Group.svg';

export const BillingMobileIcon: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <MobileLogo />
    </div>
  );
};
