import React from 'react';
import './index.scss';
import { MessageErrorIcon } from '../icons/messageError';
import { useDispatch } from 'react-redux';
import {
  enterCardModalDialogAction,
  updateCardInfo,
} from '../../redux/actions';

export const CardErrorNotification: React.FC = () => {
  const dispatch = useDispatch();
  const submitHandler = (e: any) => {
    e.preventDefault();
    dispatch(
      enterCardModalDialogAction({
        onSubmit: updateCardInfo,
        header: 'Update Card Info!',
      }),
    );
  };
  return (
    <div className="card-error-notification-container">
      <div className="card-error-notification-message-container">
        <MessageErrorIcon />
        <div className="message">
          Your recent subscription payment failed. Please update your card
          information.
        </div>
      </div>
      <div className="update-card-button" onClick={submitHandler}>
        Update
      </div>
    </div>
  );
};
