import { ExceptionDetails } from '../../../utils/errors';

export interface DomainError {
  message: string;
  code: string;
  originalError?: Error;
}

export class UnknownError implements DomainError {
  constructor(public originalError?: Error) {}
  code = 'unknown' as const;
  originalMessage = this.originalError;
  message =
    this.originalError?.message || 'Something went wrong. Unknown error';
}

export class ValidationFailedError implements DomainError {
  code = 'validationError' as const;
  message = 'Bad request';
  constructor(public details: ExceptionDetails, public originalError?: Error) {}
}
