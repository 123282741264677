import { createReducer } from '@reduxjs/toolkit';
import { dismissModalDialogAction, showModalDialogAction } from '../actions';
import { ModalTypes } from '../interfaces/modal';

export interface ModalDialogPayload {
  onCancel?(): void;
  [n: string]: any;
}

export type ModalState = {
  type: ModalTypes | null;
  payload: ModalDialogPayload | null;
};

const initialModelReducer: ModalState = {
  payload: null,
  type: null,
};

export const modelReducer = createReducer<ModalState>(
  initialModelReducer,
  (builder) => {
    builder
      .addCase(showModalDialogAction, (state, action) => {
        state.type = action.payload.type;
        state.payload = action.payload.payload;
      })
      .addCase(dismissModalDialogAction, (state) => {
        state.type = null;
        state.payload = null;
      });
  },
);
