import React, { useCallback, useEffect } from 'react';
import { Modal as ModalAnt } from 'antd';
import { useModalSelector } from '../../redux/selectors/modal';
import { useDispatch } from 'react-redux';
import { dismissModalDialogAction } from '../../redux/actions';
import './index.scss';
import { renderModalContent } from './render';
import { BaseRenderProps } from './components';
import { useThemeSelector } from '../../redux/selectors/themes';
import { CloseCrossIcon } from '../icons/closeCross';

export const ModalDialog: React.FC = () => {
  const theme = useThemeSelector();
  const modalInfo = useModalSelector();
  const dispatch = useDispatch();
  const visible = !!modalInfo.type;

  const maskDiv = document.getElementById('modal-background');

  const addStyle = useCallback(() => {
    const root = document.getElementsByClassName('ant-layout');
    if (root.length > 0 && !maskDiv && visible) {
      const filter = document.createElement('div');
      filter.setAttribute('id', 'modal-background');
      root[0].appendChild(filter);
    }
  }, [maskDiv, visible]);

  useEffect(() => {
    addStyle();
    if (maskDiv && maskDiv.parentNode && !visible) {
      maskDiv.parentNode.removeChild(maskDiv);
    }
  }, [visible, maskDiv, addStyle]);

  const onDismissDialogHandler = () => {
    if (modalInfo.payload?.onCancel) {
      modalInfo.payload.onCancel();
    }

    dispatch(dismissModalDialogAction());
  };

  const renderProps: BaseRenderProps = {
    ...modalInfo.payload,
    onCancel: onDismissDialogHandler,
  };

  return (
    <>
      {modalInfo.type && (
        <>
          <div className="close-modal" onClick={onDismissDialogHandler}>
            <CloseCrossIcon />
          </div>
          <ModalAnt
            mask={false}
            className={`j-modal-dialog ${theme.color}`}
            visible={visible}
            maskClosable={false}
            closable={false}
            //centered
            width="auto"
            footer={null}
            onCancel={onDismissDialogHandler}
          >
            {renderModalContent(modalInfo.type, renderProps)}
          </ModalAnt>
        </>
      )}
    </>
  );
};
