import { LoadableData } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import {
  fetchMeetingsAction,
  fetchMeetingsSuccessAction,
  fetchMeetingByIdToEditAction,
  fetchMeetingByIdToEditSuccessAction,
  editMeetingByIdAction,
  editMeetingByIdErrorAction,
  editMeetingByIdSuccessAction,
  createNewMeetingAction,
  createNewMeetingSuccessAction,
  createNewMeetingErrorAction,
  fetchMeetingByIdToEditErrorAction,
  clearErrorsNewMeeting,
  clearErrorsOnEditMeetingById,
  fetchMeetingBookEmailDataSuccessAction,
  changeNotificationsPolicySuccessAction,
  deleteMeetingSuccessAction,
} from '../actions';
import {
  setLoadedStatusToLoadableData,
  setLoadingStatusToLoadableData,
} from '../utils';
import {
  MeetingDataBookEmailModel,
  MeetingModel,
  MeetingOnListModel,
} from '../../models/meeting.model';
import { OffsetPaginationMetaDto } from '../../libs/common/dtos/offset-pagination.dto';
import { ExceptionDetail, ExceptionDetails } from '../../utils/errors';
import * as _ from 'lodash';

export interface ListOfMeetingsState extends LoadableData {
  data: MeetingOnListModel[];
  pagination: OffsetPaginationMetaDto;
}

interface ActiveMeetingUpdatingState extends LoadableData {
  errors: ExceptionDetails;
}

export interface MeetingCreatingState extends LoadableData {
  errors: ExceptionDetails;
}

export interface ActiveMeetingState extends LoadableData {
  value: MeetingModel | null;
  updating: ActiveMeetingUpdatingState;
}

export interface BookByEmailState extends LoadableData {
  data: MeetingDataBookEmailModel | null;
}

export interface MeetingsState {
  list: ListOfMeetingsState;
  active: ActiveMeetingState;
  creating: MeetingCreatingState;
  bookByEmailData: BookByEmailState;
}

const initialMeetingsState: MeetingsState = {
  list: {
    isLoaded: false,
    isLoading: false,
    data: [],
    pagination: { totalCount: 0, totalPages: 0 },
  },
  creating: { isLoading: false, isLoaded: false, errors: [] },
  active: {
    isLoading: false,
    isLoaded: false,
    value: null,
    updating: {
      isLoaded: false,
      isLoading: false,
      errors: [],
    },
  },
  bookByEmailData: {
    isLoading: false,
    isLoaded: false,
    data: null,
  },
};

export const meetingsReducer = createReducer<MeetingsState>(
  initialMeetingsState,
  (builder) => {
    builder
      .addCase(fetchMeetingsAction, (state) => {
        setLoadingStatusToLoadableData(state.list);
      })
      .addCase(fetchMeetingsSuccessAction, (state, action) => {
        setLoadedStatusToLoadableData(state.list);
        state.list.data = action.payload.data;
        state.list.pagination = action.payload.meta;
      })

      .addCase(fetchMeetingByIdToEditAction, (state) => {
        setLoadingStatusToLoadableData(state.active);
        state.active.value = null;
      })
      .addCase(fetchMeetingByIdToEditSuccessAction, (state, action) => {
        setLoadedStatusToLoadableData(state.active);
        state.active.value = action.payload.meeting;
      })
      .addCase(fetchMeetingByIdToEditErrorAction, (state) => {
        setLoadedStatusToLoadableData(state.active);
      })

      .addCase(editMeetingByIdAction, (state) => {
        setLoadingStatusToLoadableData(state.active.updating);
        state.active.updating.errors = [];
      })
      .addCase(editMeetingByIdSuccessAction, (state, action) => {
        setLoadedStatusToLoadableData(state.active.updating);
        if (action.payload.meeting) {
          state.active.value = action.payload.meeting;
        }
      })
      .addCase(editMeetingByIdErrorAction, (state, action) => {
        setLoadedStatusToLoadableData(state.active.updating);
        state.active.updating.errors = action.payload.details;
      })
      .addCase(changeNotificationsPolicySuccessAction, (state, action) => {
        setLoadedStatusToLoadableData(state.active.updating);
        if (state.active.value !== null) {
          state.active.value.notificationsPolicy =
            action.payload.notificationPolicy;
        }
      })
      .addCase(clearErrorsOnEditMeetingById, (state, action) => {
        // todo: move to util functions
        if (action.payload.length === 0) {
          state.active.updating.errors = [];
        } else {
          _.remove(state.active.updating.errors, (error: ExceptionDetail) =>
            _.some(action.payload, (fieldName) =>
              error.path.includes(fieldName),
            ),
          );
        }
      })

      .addCase(createNewMeetingAction, (state) => {
        setLoadingStatusToLoadableData(state.creating);
        state.creating.errors = [];
      })
      .addCase(createNewMeetingSuccessAction, (state) => {
        setLoadedStatusToLoadableData(state.creating);
      })
      .addCase(createNewMeetingErrorAction, (state, action) => {
        setLoadedStatusToLoadableData(state.creating);
        state.creating.errors = action.payload.details;
      })
      .addCase(fetchMeetingBookEmailDataSuccessAction, (state, action) => {
        setLoadingStatusToLoadableData(state.bookByEmailData);
        state.bookByEmailData.data = action.payload.bookByEmailData;
      })
      .addCase(deleteMeetingSuccessAction, (state, action) => {
        _.remove(
          state.list.data,
          (meeting) => meeting.id === action.payload.meetingId,
        );
      })
      .addCase(clearErrorsNewMeeting, (state) => {
        state.creating.errors = [];
      });
  },
);
