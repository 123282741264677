import { put, takeLatest, select } from 'redux-saga/effects';
import {
  deleteOrganizationUserAction,
  DeleteOrganizationUserParam,
  deleteOrganizationUserSuccessAction,
  fetchOrganizationUsersAction,
  checkOrganizationExistAction,
  FetchOrganizationUsersParams,
  fetchOrganizationUsersSuccessAction,
  ReSendInviteToUserParam,
  reSendOrganizationUserInviteAction,
  SendInvitesToUserParam,
  sendInvitesToUsersAction,
  sendInvitesToUsersErrorAction,
  showInviteUserSuccessModalDialogAction,
  sendInvitesToUsersSuccessAction,
} from '../actions/organization-users';
import { organizationUsersService } from '../../libs/organization-users/organization-users.service';
import { PayloadAction } from '@reduxjs/toolkit';
import { dismissModalDialogAction, showNotificationAction } from '../actions';
import { StoreState } from '../store/interface';
import { appHistory, routers } from '../../router';

function* fetchOrganizationUsersSagaAction(
  action: PayloadAction<FetchOrganizationUsersParams>,
) {
  const result = yield organizationUsersService.fetchOrganizationUsers(
    action.payload,
  );

  if (result.isLeft()) {
    if (result.value.code === 'notOrganizationMember') {
      yield put(
        checkOrganizationExistAction({
          isNotExistOrganization: true,
        }),
      );
      return;
    }

    yield put(
      showNotificationAction({
        type: 'error',
        content: result.value.message,
        duration: null,
      }),
    );
  }

  yield put(fetchOrganizationUsersSuccessAction(result.value));
}

function* deleteOrganizationUserSagaAction(
  action: PayloadAction<DeleteOrganizationUserParam>,
) {
  const result = yield organizationUsersService.deleteOrganizationUser(
    action.payload,
  );

  if (result.isLeft()) {
    alert('error');
  }

  const state: StoreState = yield select();

  const newState = state.organizationUsers.list.data.filter((item) => {
    return item.id !== action.payload.userId;
  });

  yield put(deleteOrganizationUserSuccessAction({ data: newState }));

  yield put(dismissModalDialogAction());

  yield put(
    showNotificationAction({
      type: 'success',
      content: 'User invite canceled.',
      duration: null,
    }),
  );
}

function* sendInvitesToUsersSagaAction(
  action: PayloadAction<SendInvitesToUserParam>,
) {
  const result = yield organizationUsersService.sendInvitesToUsers(
    action.payload,
  );

  if (result.isLeft()) {
    if (result.value.code === 'organizationPermissionDenied') {
      appHistory.push(routers.ORGANIZATION_USERS_PAGE);
    }

    yield put(
      sendInvitesToUsersErrorAction({
        meta: result.value.originalError.response.data.meta,
      }),
    );

    yield put(
      showNotificationAction({
        type: 'error',
        content: result.value.message,
        duration: null,
      }),
    );
    return;
  }

  yield put(sendInvitesToUsersSuccessAction());

  yield put(
    showInviteUserSuccessModalDialogAction({
      sendCount: result.value.sendCount,
    }),
  );
}

function* reSendOrganizationUserInviteSagaAction(
  action: PayloadAction<ReSendInviteToUserParam>,
) {
  const result = yield organizationUsersService.reSendUserInvite(
    action.payload,
  );

  if (result.isLeft()) {
    yield put(
      showNotificationAction({
        type: 'error',
        content: result.value.message,
        duration: null,
      }),
    );
    return;
  }

  yield put(
    showNotificationAction({
      type: 'success',
      content: 'Invite re-send success.',
      duration: null,
    }),
  );
}

export function* organizationUsersWatchSaga(): Generator<any> {
  yield takeLatest(
    fetchOrganizationUsersAction,
    fetchOrganizationUsersSagaAction,
  );
  yield takeLatest(
    deleteOrganizationUserAction,
    deleteOrganizationUserSagaAction,
  );
  yield takeLatest(sendInvitesToUsersAction, sendInvitesToUsersSagaAction);
  yield takeLatest(
    reSendOrganizationUserInviteAction,
    reSendOrganizationUserInviteSagaAction,
  );
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function* organizationUsersRunSaga(): Generator<any> {}
