import React from 'react';
import { ReactComponent as CheckLogo } from './Vector.svg';

export const LocationScheduleIcon: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CheckLogo />
    </div>
  );
};
