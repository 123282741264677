import { createAsyncAction } from '../utils';

export const MOVE_TO_NEXT_PROFILE_WIZARD_STEP = createAsyncAction(
  'MOVE_TO_NEXT_PROFILE_WIZARD_STEP',
);

export const UPDATE_ACCOUNT_PROFILE = createAsyncAction(
  'UPDATE_ACCOUNT_PROFILE',
);

export const UPDATE_AVATAR_ACCOUNT_PROFILE = createAsyncAction(
  'UPDATE_AVATAR_ACCOUNT_PROFILE',
);

export const DELETE_ACCOUNT = createAsyncAction('DELETE_ACCOUNT');
export const CHANGE_USER_EMAIL = createAsyncAction('CHANGE_USER_EMAIL');
export const CHANGE_USER_PASSWORD = createAsyncAction('CHANGE_USER_PASSWORD');
export const DELETE_USER_AVATAR = createAsyncAction('DELETE_USER_AVATAR');
export const LOGIN_FOR_GOOGLE_CALENDAR_CALLBACK = createAsyncAction(
  'LOGIN_FOR_GOOGLE_CALENDAR_CALLBACK',
);
export const LOGIN_FOR_GOOGLE_CALENDAR_MESSAGE = createAsyncAction(
  'LOGIN_FOR_GOOGLE_CALENDAR_MESSAGE',
);

export const LOGIN_GOOGLE_CALLBACK = createAsyncAction('LOGIN_GOOGLE_CALLBACK');
export const POST_PAYMENT = createAsyncAction('POST_PAYMENT');
export const BUY_SUBSCRIPTION = createAsyncAction('BUY_SUBSCRIPTION');
export const UPDATE_CARD_INFO = createAsyncAction('UPDATE_CARD_INFO');
export const UPDATE_BILLING_STAT = 'UPDATE_BILLING_STAT' as const;
export const UPDATE_CARD_STAT = 'UPDATE_CARD_STAT' as const;
export const DEL_SUBSCRIPTION = createAsyncAction('DEL_SUBSCRIPTION');

export const PUT_AVAILABILITY = createAsyncAction('PUT_AVAILABILITY');
