import React from 'react';
import './index.scss';
import Logo from './imgs/logo';

export const AppNameLogo: React.FC = () => {
  return (
    <div className="app-logo">
      <Logo />
    </div>
  );
};
