import { InputProps as InputPropsAnt } from 'antd/lib/input';
import * as React from 'react';
import './index.scss';
import { LabelWithTooltip } from '../../labelWithTooltip';

interface InputProps extends InputPropsAnt {
  label?: string;
  tooltip?: string;
  className?: string;
}

export const InputWithLabel: React.FC<InputProps> = (props) => {
  const { label, tooltip, children } = props;
  return (
    <div
      className={
        props.className
          ? `input-with-label ${props.className}`
          : 'input-with-label'
      }
    >
      {label && <LabelWithTooltip label={label} tooltip={tooltip} />}
      {children}
    </div>
  );
};
