import * as _ from 'lodash';
import { formatDateToTimeByTimezone } from '../../../libs/common/utils';
import React, { useState } from 'react';
import { useTimezoneInfoSelector } from '../../../redux/selectors/timezone';
import { useInterval } from '../../../hooks/general';
import { Select, SelectOptGroup, SelectOption } from '../index';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
//import { useUserInfoSelector } from '../../../redux/selectors/auth';
import './index.scss';

export type RenderDOMFunc = (props: any) => HTMLElement;

interface Props {
  value?: string;
  onChange?(timezone: string): void;
  size?: SizeType;
  wrapperClassName?: string;
  className?: string;
  dropdownClassName?: string;
  icon?: JSX.Element;
  getPopupContainer?: RenderDOMFunc;
}

const useDateNow = (delay: number): Date => {
  const [nowDate, setDate] = useState(new Date());
  useInterval(() => setDate(new Date()), delay);

  return nowDate;
};

export const TimezoneSelector: React.FC<Props> = (props) => {
  const timezones = useTimezoneInfoSelector();
  const dateNow = useDateNow(5000);

  const selectSize = _.isNil(props.size) ? 'middle' : props.size;

  return (
    <div className={`${props.wrapperClassName || ''}`}>
      {props.icon ? props.icon : null}
      <Select
        value={props.value}
        loading={timezones.isLoading || !timezones.isLoaded}
        bordered={false}
        onSelect={props.onChange}
        size={selectSize}
        showArrow={false}
        className={`${props.className || ''}`}
        dropdownClassName={`${props.dropdownClassName || ''}`}
        getPopupContainer={props.getPopupContainer}
      >
        {_.map(timezones.groups, (group, key) => {
          return (
            <SelectOptGroup key={key} label={key}>
              {_.map(group, (timezoneId) => {
                const timezoneInfo = timezones.data[timezoneId];
                const time = formatDateToTimeByTimezone(
                  timezoneInfo.identifier,
                  dateNow,
                  't',
                );
                return (
                  <SelectOption
                    key={timezoneInfo.identifier}
                    value={timezoneInfo.identifier}
                  >
                    <div
                      style={{
                        //width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>{timezoneInfo.standardTitle}</div>
                      <div style={{ marginLeft: 10 }} className="current-time">
                        {time}
                      </div>
                    </div>
                  </SelectOption>
                );
              })}
            </SelectOptGroup>
          );
        })}
      </Select>
    </div>
  );
};

// // FIXME
// interface TimezoneSelector2Props extends Props {
//   label: string;
// }

// export const TimezoneSelector2: React.FC<TimezoneSelector2Props> = (props) => {
//   const timezones = useTimezoneInfoSelector();
//   const dateNow = useDateNow(5000);
//   const user = useUserInfoSelector();
//   const timeFormat = user?.timeNotation === '24h' ? 'T' : 't';
//   const time = formatDateToTimeByTimezone(props.value, dateNow, timeFormat);
//   const label = (
//     <div
//       style={{
//         display: 'flex',
//         justifyContent: 'space-between',
//         width: '100%',
//       }}
//     >
//       <div>{props.label}</div>
//       <div>Current time: {time.toLowerCase()}</div>
//     </div>
//   );
//   return (
//     <>
//       <Select
//         label={label}
//         style={{ width: '100%' }}
//         value={props.value}
//         loading={timezones.isLoading || !timezones.isLoaded}
//         onSelect={props.onChange}
//       >
//         {_.map(timezones.groups, (group, key) => {
//           return (
//             <SelectOptGroup key={key} label={key}>
//               {_.map(group, (timezoneId) => {
//                 const timezoneInfo = timezones.data[timezoneId];
//                 return (
//                   <SelectOption
//                     key={timezoneInfo.identifier}
//                     value={timezoneInfo.identifier}
//                   >
//                     <div>{timezoneInfo.standardTitle}</div>
//                   </SelectOption>
//                 );
//               })}
//             </SelectOptGroup>
//           );
//         })}
//       </Select>
//     </>
//   );
// };
