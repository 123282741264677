import { createAsyncAction } from '../utils';

export const FETCH_ORGANIZATION_USERS = createAsyncAction(
  'FETCH_ORGANIZATION_USERS',
);
export const DELETE_ORGANIZATION_USER = createAsyncAction(
  'DELETE_ORGANIZATION_USER',
);
export const SEND_INVITES_TO_USERS = createAsyncAction('SEND_INVITES_TO_USERS');
export const RESEND_INVITE_TO_USER = createAsyncAction('RESEND_INVITE_TO_USER');
