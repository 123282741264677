import './index.scss';
import React, { ReactElement } from 'react';
import { Form, Select as SelectAnt } from 'antd';
import { SelectProps as SelectAntProps, SelectValue } from 'antd/lib/select';
import { Rule } from 'rc-field-form/lib/interface';
import cn from 'classnames';
import { ValidateStatus } from 'antd/lib/form/FormItem';

interface SelectProps<TValue extends SelectValue>
  extends SelectAntProps<TValue> {
  label?: string | React.ReactNode;
  rules?: Rule[];
  requiredLabel?: boolean;
  validateStatus?: ValidateStatus;
  helpMessage?: string | React.ReactNode;
  errorHelpMessage?: string;
}

const getHelpMessageByValidationStatus = (
  props: SelectProps<any>,
): React.ReactNode | string | undefined => {
  if (props.validateStatus === 'error') {
    return props.errorHelpMessage;
  }

  return props.helpMessage;
};

export function Select<TValue extends SelectValue>({
  label,
  rules,
  requiredLabel,
  validateStatus,
  errorHelpMessage,
  helpMessage,
  className,
  ...rest
}: SelectProps<TValue>): ReactElement {
  return (
    <Form.Item
      label={label}
      rules={rules}
      className={cn(className, 'j-form-select')}
      required={requiredLabel}
      help={getHelpMessageByValidationStatus({
        validateStatus,
        errorHelpMessage,
        helpMessage,
      })}
      validateStatus={validateStatus}
    >
      <SelectAnt {...rest} />
    </Form.Item>
  );
}

export const SelectOption = SelectAnt.Option;
export const SelectOptGroup = SelectAnt.OptGroup;
