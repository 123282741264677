import React from 'react';
import './index.scss';

import { Button } from '../../../../../components/button';
import { AvailableDays } from '../../../../../components/availableDays';
import { DaysOfWeek } from '../../../../../models/days-of-week.model';

interface CompleteProfileDaysProps {
  next(): void;
  onDayChange(day: DaysOfWeek): void;
  dayOfWeek: DaysOfWeek[];
}

export const CompleteProfileDays: React.FC<CompleteProfileDaysProps> = (
  props,
) => {
  const { next, onDayChange, dayOfWeek } = props;
  return (
    <>
      <div className="complete-profile-container">
        <div className="complete-profile-header">What days do you work?</div>
        <div className="complete-profile-title">
          Guests will be able to schedule meetings during these days only when
          you’re available. You can always adjust your available days later.
        </div>
        <AvailableDays
          className="complete-profile-days"
          activeDays={dayOfWeek}
          onClick={onDayChange}
        />
        <Button onClick={next}>Continue</Button>
      </div>
    </>
  );
};
