import { createAsyncAction } from '../utils';

export const FETCH_MEETINGS = createAsyncAction('FETCH_MEETINGS');
export const FETCH_MEETING_BY_ID_TO_EDIT = createAsyncAction(
  'FETCH_MEETING_BY_ID_TO_EDIT',
);
export const EDIT_MEETING_BY_ID = createAsyncAction('EDIT_MEETING_BY_ID');
export const CHANGE_MEETING_ACTIVE_BY_ID = 'CHANGE_MEETING_ACTIVE_BY_ID';
export const CREATE_NEW_MEETING = createAsyncAction('CREATE_NEW_MEETING');
export const FETCH_MEETING_BY_PUBLIC_LINK = createAsyncAction(
  'FETCH_MEETING_BY_PUBLIC_LINK',
);
export const FETCH_MEETING_SLOT_TO_BOOK_EVENT = createAsyncAction(
  'FETCH_MEETING_SLOT_TO_BOOK_EVENT',
);
export const FETCH_MEETING_BOOK_EMAIL_DATA = createAsyncAction(
  'FETCH_MEETING_BOOK_EMAIL_DATA',
);
export const CHANGE_NOTIFICATION_REMINDERS = createAsyncAction(
  'CHANGE_NOTIFICATION_REMINDERS',
);
export const SCHEDULE_MEETING_BOOK_EVENT = createAsyncAction(
  'SCHEDULE_MEETING_BOOK_EVENT',
);
export const RESCHEDULE_MEETING_BOOK_EVENT = createAsyncAction(
  'RESCHEDULE_MEETING_BOOK_EVENT',
);
export const FETCH_BOOK_COMPLETED_EVENT = createAsyncAction(
  'FETCH_BOOK_COMPLETED_EVENT',
);
export const FETCH_BOOK_EVENT = createAsyncAction('FETCH_BOOK_EVENT');
export const CANCELLATION_EVENT = createAsyncAction('CANCELLATION_EVENT');
export const RESCHEDULING_EVENT = createAsyncAction('RESCHEDULING_EVENT');
export const DELETE_MEETING = createAsyncAction('DELETE_MEETING');
export const CLEAR_ERRORS_NEW_MEETING = 'CLEAR_ERRORS_NEW_MEETING';
export const UPDATE_MEETINGS_ORDER = 'UPDATE_MEETINGS_ORDER';
