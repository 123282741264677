import * as luxon from 'luxon';
import { DaysOfWeek } from '../../models/days-of-week.model';
import { convertSecondsToHours } from '../user/utils';
import _ from 'lodash';

luxon.Settings.defaultLocale = 'en-us';

export const formatDateToTimeByTimezone = (
  timezone: string,
  date: Date,
  format: string,
): string => {
  return luxon.DateTime.fromJSDate(date).setZone(timezone).toFormat(format);
};

export const formatDateByTimezone = (timezone: string, date: Date): string => {
  return luxon.DateTime.fromJSDate(date)
    .setZone(timezone)
    .toFormat('yyyy-MM-dd');
};

// export const formatDateByTimezone = (
//   timezone: string,
//   date: Date,
// ): string => {
//   return luxon.DateTime.fromJSDate(date).
// };

export const getStartOfWeek = (
  jsDate: Date,
  locale = luxon.Settings.defaultLocale,
): Date => {
  const fd = getFirstDayOfWeek(locale);
  const date = luxon.DateTime.fromJSDate(jsDate);
  const day = date.weekday % 7; // convert to 0=sunday .. 6=saturday
  const dayAdjust = day >= fd ? -day + fd : -day + fd - 7;
  return date.plus({ days: dayAdjust }).toJSDate();
};

export const getEndOfWeek = (
  jsDate: Date,
  locale = luxon.Settings.defaultLocale,
): Date => {
  const fd = getFirstDayOfWeek(locale);
  const date = luxon.DateTime.fromJSDate(jsDate);
  const day = date.weekday % 7; // convert to 0=sunday .. 6=saturday
  const dayAdjust = day >= fd ? -day + fd : -day + fd - 7;
  return date.plus({ days: dayAdjust + 6 }).toJSDate();
};

export const plusDurationToDate = (
  date: Date,
  duration: luxon.DurationObjectUnits,
  locale = luxon.Settings.defaultLocale,
): Date => {
  return luxon.DateTime.fromJSDate(date, { locale: locale })
    .plus(duration)
    .toJSDate();
};

export const getIntervalBetweenDates = (start: Date, end: Date): Date[] => {
  const interval = luxon.Interval.fromDateTimes(start, end);
  const dates: Date[] = [];

  let cursor = interval.start.startOf('day');
  while (cursor < interval.end) {
    dates.push(cursor.toJSDate());
    cursor = cursor.plus({ days: 1 });
  }
  return dates;
};

export const isSameDate = (a: Date, b: Date): boolean => {
  return luxon.DateTime.fromJSDate(new Date(a)).hasSame(
    luxon.DateTime.fromJSDate(new Date(b)),
    'day',
  );
};

export const isDayBefore = (
  a: Date,
  b: Date,
  includeEdge?: boolean,
): boolean => {
  if (includeEdge) {
    return (
      luxon.DateTime.fromJSDate(a).startOf('day') <=
      luxon.DateTime.fromJSDate(b).startOf('day')
    );
  } else {
    return (
      luxon.DateTime.fromJSDate(a).startOf('day') <
      luxon.DateTime.fromJSDate(b).startOf('day')
    );
  }
};

export const isDayAfter = (
  a: Date,
  b: Date,
  includeEdge?: boolean,
): boolean => {
  if (includeEdge) {
    return (
      luxon.DateTime.fromJSDate(a).startOf('day') >=
      luxon.DateTime.fromJSDate(b).startOf('day')
    );
  } else {
    return (
      luxon.DateTime.fromJSDate(a).startOf('day') >
      luxon.DateTime.fromJSDate(b).startOf('day')
    );
  }
};

export const isDayInRange = (
  start: Date,
  end: Date,
  date: Date,
  includeEdge?: { start?: boolean; end?: boolean },
): boolean => {
  if (!isDayAfter(date, start, includeEdge?.start)) {
    return false;
  }

  if (!isDayBefore(date, end, includeEdge?.end)) {
    return false;
  }

  return true;
};

export const isWeekend = (date: Date): boolean => {
  const day = date.getDay();
  return day === 6 || day === 0;
};

export const getWeekdays = (
  locale = luxon.Settings.defaultLocale,
): string[] => {
  const fd = getFirstDayOfWeek(locale) - 1;
  const weekdays = luxon.Info.weekdays();
  return weekdays.slice(fd, 7).concat(weekdays.slice(0, fd));
};

/**
 * Returns a zero-based index for first day of the week, as used by the specified locale
 * e.g. Sunday (returns 0), or Monday (returns 1)
 */
const getFirstDayOfWeek = (locale: string): number => {
  // from http://www.unicode.org/cldr/data/common/supplemental/supplementalData.xml:supplementalData/weekData/firstDay
  const firstDay: Record<string, number> = {
    /*default is 1=Monday*/
    bd: 5,
    mv: 5,
    ae: 6,
    af: 6,
    bh: 6,
    dj: 6,
    dz: 6,
    eg: 6,
    iq: 6,
    ir: 6,
    jo: 6,
    kw: 6,
    ly: 6,
    ma: 6,
    om: 6,
    qa: 6,
    sa: 6,
    sd: 6,
    sy: 6,
    ye: 6,
    ag: 0,
    ar: 0,
    as: 0,
    au: 0,
    br: 0,
    bs: 0,
    bt: 0,
    bw: 0,
    by: 0,
    bz: 0,
    ca: 0,
    cn: 0,
    co: 0,
    dm: 0,
    do: 0,
    et: 0,
    gt: 0,
    gu: 0,
    hk: 0,
    hn: 0,
    id: 0,
    ie: 0,
    il: 0,
    in: 0,
    jm: 0,
    jp: 0,
    ke: 0,
    kh: 0,
    kr: 0,
    la: 0,
    mh: 0,
    mm: 0,
    mo: 0,
    mt: 0,
    mx: 0,
    mz: 0,
    ni: 0,
    np: 0,
    nz: 0,
    pa: 0,
    pe: 0,
    ph: 0,
    pk: 0,
    pr: 0,
    py: 0,
    sg: 0,
    sv: 0,
    th: 0,
    tn: 0,
    tt: 0,
    tw: 0,
    um: 0,
    us: 0,
    ve: 0,
    vi: 0,
    ws: 0,
    za: 0,
    zw: 0,
  };

  const country = locale.split('-')[1].toLowerCase();
  const dow: number | undefined = firstDay[country];
  return dow === undefined ? 1 : dow;
};

/**
 * @example
 *  convertMinutesToHoursAMPM(100); // '1:40 AM'
 *  convertMinutesToHoursAMPM(1330); // '10:10 PM'
 */
export const convertMinutesToHoursAMPM = (minutes: number): string => {
  return luxon.DateTime.fromJSDate(new Date())
    .startOf('day')
    .plus({ minutes })
    .toFormat('t')
    .toLowerCase();
};

/**
 * @example
 *  formatMinutesToHH_mm(100); // '01:40'
 *  formatMinutesToHH_mm(1330); // '22:10'
 */
export const formatMinutesToHH_mm = (minutes: number): string => {
  return luxon.DateTime.fromObject({
    minute: minutes % 60,
    hour: convertSecondsToHours(minutes),
  }).toFormat('HH:mm');
};

export const parse_hh_mm_FormatToMinutes = (
  str: string,
): number | undefined => {
  const date = luxon.DateTime.fromFormat(str, 'hh:mm');
  const minutes = date.get('minute');
  if (_.isNaN(minutes)) {
    return undefined;
  }

  const hours = date.get('hour');
  if (_.isNaN(hours)) {
    return undefined;
  }
  return hours * 60 + minutes;
};

/**
 * @example
 * formatDateToDDD(new Date('2020 09 26')); // 'September 26, 2020'
 */
export const formatDateToDDD = (date: Date): string => {
  return luxon.DateTime.fromJSDate(date).toFormat('DDD');
};

export const formatDateToYYYY_MM_DD = (date: Date): string => {
  return luxon.DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
};

export const formatDateToYYYY = (date: Date): string => {
  return luxon.DateTime.fromJSDate(date).toFormat('yyyy');
};

/**
 * @example
 * formatDateToLLLL_d(new Date('2020 09 9')); // 'September 9'
 */
export const formatDateToLLLL_d = (date: Date): string => {
  return luxon.DateTime.fromJSDate(date).toFormat('LLLL d');
};

export const getDayOfWeekByDate = (date: Date): DaysOfWeek => {
  return luxon.DateTime.fromJSDate(
    new Date(date),
  ).weekdayLong.toLowerCase() as DaysOfWeek;
};

export enum TimeFormats {
  h24padded = 'HH:mm',
  h24 = 'H:m',
  h12 = 'h:mm a',
  bookingDate = 'ccc L/d', // Wed 9/23
  bookingDateQueryString = 'yyyy-LL-dd', // 2020-09-23
  YYYY_MM = 'yyyy-LL', // 2020-09
  YYYY_MM_DD = 'yyyy-LL-dd',
}

export const formantDate = (date: Date, format: TimeFormats): string => {
  return luxon.DateTime.fromJSDate(date).toFormat(format);
};

export const formantDateString = (text: string, format = 't'): any => {
  return luxon.DateTime.fromISO(text).toUTC().toFormat(format);
};

export const formatISOToTimeString = (
  isoDateTime: string,
  duration = 0,
): string => {
  return luxon.DateTime.fromISO(isoDateTime)
    .toUTC()
    .set({
      minute: duration,
    })
    .toFormat('t');
};

export function formatISOToDDD(dateString: string): string {
  return luxon.DateTime.fromISO(dateString).toFormat('DDDD');
}

export function getTimeZone(date: string): string {
  const dateTime = luxon.DateTime.fromISO(date);
  const rezoned = dateTime.setZone('America/Los_Angeles');

  return rezoned.toString();
}

export const formantDateTimeString = (text: string): any => {
  return luxon.DateTime.fromISO(text).toUTC();
};

export const parseTimeStringByFormatsToMinutes = (
  text: string,
  formats: TimeFormats[],
): number | undefined => {
  for (const format of formats) {
    const dateTime = luxon.DateTime.fromFormat(text, format);
    if (dateTime.isValid) {
      return dateTime.get('hour') * 60 + dateTime.get('minute');
    }
  }
  return undefined;
};

export const parseDateStringByFormats = (
  text: string,
  formats: TimeFormats[],
): Date | undefined => {
  for (const format of formats) {
    const date = luxon.DateTime.fromFormat(text, format);
    if (date.isValid) {
      return date.toJSDate();
    }
  }
  return undefined;
};

/**
 * Check if argument date is past for current date.
 * @param date: Date
 * @param timezone: string
 */

export function isDateInPast(date: Date, timezone: string): boolean {
  const dateTime = luxon.DateTime;

  const dateWithTimezone = dateTime
    .local(
      dateTime.local().year,
      dateTime.local().month,
      dateTime.local().day,
      0,
      0,
      0,
    )
    .setZone(timezone);
  const d = dateTime.fromJSDate(date);

  const durationDays = d.diff(dateWithTimezone, 'day').toObject().days;

  if (!durationDays) return false;

  return durationDays < 0; //date < dateWithTimezone;
}

/**
 * Return date from utc.
 * @param date
 */
export function convertLocalDateToUTC(date: Date): Date {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0),
  );
}

/**
 * Clipboard handler
 * @param content
 */
export const clipboard = (content: string): void => {
  const div = document.createElement('div');
  div.innerHTML = content;

  function listener(e: any) {
    e.clipboardData.setData('text/html', content);
    e.clipboardData.setData('text/plain', div.innerText);
    e.preventDefault();
  }

  document.addEventListener('copy', listener);
  document.execCommand('copy');
  document.removeEventListener('copy', listener);
};
