import React, { useEffect, useState } from 'react';
import { FormWrapper } from '../../../components/form-wrapper/form-wrapper';
import { AppNameLogo } from '../../../components/logo/appNameLogo';
import { GoogleSingUpButton } from '../../../components/button/new-google-sign-up.button';
//import { useInviteNotifications } from '../../../hooks/invite-notifications';
//import { useInviteMeta } from '../common';
import { useDispatch } from 'react-redux';
import './index.scss';
import {
  // cleanupAuthMethodAction,
  loginByGoogleAction,
} from '../../../redux/actions';
import { UserLogo } from '../../../components/userLogo';
import { useUserInfoSelector } from '../../../redux/selectors/auth';
import { appHistory, routers } from '../../../router';

interface StoreProfile {
  name: string;
  avatar: string;
}

export const LoginPageComponent: React.FC = () => {
  const userProfile = useUserInfoSelector();
  const [user, setUser] = useState(false);
  const [userInfo, setUserInfo] = useState<StoreProfile>();
  useEffect(() => {
    if (userProfile) {
      appHistory.push(routers.HOME);
    }
  }, [userProfile]);
  useEffect(() => {
    const userLocalStorage = localStorage.getItem('profile');
    if (userLocalStorage) {
      setUser(true);
      setUserInfo(JSON.parse(userLocalStorage));
    }
  }, []);
  //const inviteInfo = useInviteNotifications();
  // const inviteMeta = useInviteMeta(inviteInfo);

  const dispatch = useDispatch();

  const clearStore = () => {
    localStorage.removeItem('profile');
    setUser(false);
  };
  const onSubmitByGoogleHandler = () => {
    dispatch(loginByGoogleAction());
  };

  return (
    <FormWrapper className="sign-up-by-google">
      <div className="sign-up-by-google-title">
        <AppNameLogo />
        <span>The simpliest way to meet.</span>
      </div>
      {user && userInfo && (
        <div className="login-user-info">
          <UserLogo avatar={userInfo?.avatar} />
          <span className="login-user-message">{`Welcome back, ${userInfo?.name}`}</span>
          <br />
          <span className="link" onClick={clearStore}>
            This isnt`t me
          </span>
        </div>
      )}

      <GoogleSingUpButton
        type="primary"
        htmlType="submit"
        onClick={onSubmitByGoogleHandler}
        className="sign-up-by-google-button"
      >
        {user ? <>Login In With Google</> : <>Sign Up With Google</>}
      </GoogleSingUpButton>
      {!user ? (
        <div>Sign up for free. No credit card required.</div>
      ) : (
        <div>
          Don’t have an account?{' '}
          <span className="link" onClick={clearStore}>
            Sign up.
          </span>
        </div>
      )}
    </FormWrapper>
  );
};
