import { SessionService } from '../auth/session.service';
import { OrganizationUsersApi } from './organization-users.api';
import { left } from '@sweet-monads/either';
import { UnknownError } from '../common/errors';
import {
  DeleteOrganizationUserRequestDto,
  FetchOrganizationUsersRequestDto,
  ReSendInviteToUserRequestDto,
  SendInvitesToUsersRequestDto,
} from './dtos/api.dto';
import { AccessTokenDto } from '../auth/dtos/access-token.dto';
import _ from 'lodash';
import { FindOrganizationUsersResult } from './dtos/organization-users.dto';

export class OrganizationUsersService {
  private readonly token: AccessTokenDto | null;

  constructor(
    private readonly organizationUserApi: OrganizationUsersApi,
    private readonly sessionService: SessionService,
  ) {
    this.token = this.sessionService.getAccessToken();
  }

  async fetchOrganizationUsers(
    dto: FetchOrganizationUsersRequestDto,
  ): Promise<FindOrganizationUsersResult> {
    const token = this.sessionService.getAccessToken();
    if (!token) {
      return left(new UnknownError());
    }

    let params: FetchOrganizationUsersRequestDto = {};

    if (!_.isNil(dto.sortOrder) && !_.isNil(dto.sortField)) {
      params = {
        sortOrder: dto.sortOrder,
        sortField: dto.sortField,
        ...params,
      };
    }
    if (!_.isNil(dto.status)) {
      params = {
        status: dto.status,
        ...params,
      };
    }
    if (!_.isNil(dto.searchField)) {
      params = {
        searchField: dto.searchField,
        ...params,
      };
    }
    if (!_.isNil(dto.page)) {
      params = {
        page: dto.page,
        ...params,
      };
    }

    return await this.organizationUserApi.getOrganizationUsers(token, params);
  }

  async deleteOrganizationUser(
    dto: DeleteOrganizationUserRequestDto,
  ): Promise<any> {
    const token = this.sessionService.getAccessToken();
    if (!token) {
      return left(new UnknownError());
    }
    return await this.organizationUserApi.deleteOrganizationUser(token, dto);
  }

  async sendInvitesToUsers(dto: SendInvitesToUsersRequestDto): Promise<any> {
    const token = this.sessionService.getAccessToken();
    if (!token) {
      return left(new UnknownError());
    }

    return await this.organizationUserApi.sendInvitesToUsers(token, dto);
  }

  async reSendUserInvite(dto: ReSendInviteToUserRequestDto): Promise<any> {
    if (!this.token) {
      return left(new UnknownError());
    }

    return await this.organizationUserApi.reSendUserInvite(this.token, dto);
  }
}

export const organizationUsersService = new OrganizationUsersService(
  new OrganizationUsersApi(),
  new SessionService(),
);
