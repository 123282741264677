import { SessionService } from '../auth/session.service';
import { UserApi } from './user.api';
import { left } from '@sweet-monads/either';
import {
  ChangeAvailabilityResult,
  DeleteUserAccountResult,
  MarkProfileAsCompletedResult,
  UpdateUserProfileResult,
  SubscriptionStatResult,
} from './dtos/user.dto';
import { AuthTokenExpiredError } from '../auth/errors';
import { UserAvailableTimeModel } from '../../models/user-available-time.model';
import { UserModel } from '../../models/user.model';
import { AvailabilityDto, IPayment } from '../../redux/actions';

export class UserService {
  constructor(
    private readonly sessionService: SessionService,
    private readonly userApi: UserApi,
  ) {}

  async deleteUserAccount(): Promise<DeleteUserAccountResult> {
    const token = this.sessionService.getAccessToken();

    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }
    return this.userApi.deleteUserAccount(token);
  }

  async markProfileAsCompleted(): Promise<MarkProfileAsCompletedResult> {
    const token = this.sessionService.getAccessToken();
    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }
    return this.userApi.markProfileAsCompleted(token);
  }

  async getCustomerStat(): Promise<SubscriptionStatResult> {
    const token = this.sessionService.getAccessToken();

    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }
    const response = await this.userApi.getStatSubscription(token);
    return response;
  }

  async updateCardInfo(cardToken: IPayment): Promise<any> {
    const token = this.sessionService.getAccessToken();

    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }
    const response = await this.userApi.updateCard(token, cardToken);
    return response;
  }

  async updateProfile(params: {
    timezone?: string;
    slug?: string;
    defaultTimeAvailability?: UserAvailableTimeModel;
    name?: string;
    welcomeMessage?: string;
    dateNotation?: string;
    timeNotation?: string;
    phone?: string;
  }): Promise<UpdateUserProfileResult> {
    const token = this.sessionService.getAccessToken();

    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }

    return this.userApi.updateProfile(token, params);
  }

  async getCalendarAccounts(user: UserModel | any): Promise<any> {
    const token = this.sessionService.getAccessToken();

    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }

    return this.userApi.getCalendarAccounts(token, user);
  }

  async disconnectCalendarAccount(accountId: string): Promise<any> {
    const token = this.sessionService.getAccessToken();

    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }

    return this.userApi.disconnectCalendarAccount(accountId, token);
  }

  async deleteUserAvatar(param: { removeAvatar: boolean }): Promise<any> {
    const token = this.sessionService.getAccessToken();

    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }

    return this.userApi.deleteUserAvatar(token, param);
  }

  async changeType(params: any): Promise<any> {
    const token = this.sessionService.getAccessToken();
    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }
    return this.userApi.changeType(token, params);
  }

  async postPayment(params: any): Promise<any> {
    const token = this.sessionService.getAccessToken();
    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }
    return this.userApi.postPayment(token, params);
  }

  async buySubscription(params: IPayment): Promise<any> {
    const token = this.sessionService.getAccessToken();
    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }
    return this.userApi.buySubscription(token, params);
  }

  async delSubscription(): Promise<any> {
    const token = this.sessionService.getAccessToken();
    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }
    return this.userApi.delSubscription(token);
  }

  async getStatSubscription(): Promise<any> {
    const token = this.sessionService.getAccessToken();
    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }
    return this.userApi.getStatSubscription(token);
  }

  async userUrlValidation(url: string): Promise<any> {
    const token = this.sessionService.getAccessToken();
    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }
    return await this.userApi.urlValidation(token, url);
  }

  async phoneValidation(phone: string): Promise<any> {
    const token = this.sessionService.getAccessToken();
    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }
    return await this.userApi.phoneValidation(token, phone);
  }

  async updateAvailability(
    availability: AvailabilityDto,
  ): Promise<ChangeAvailabilityResult> {
    const token = this.sessionService.getAccessToken();
    if (this.sessionService.isAccessTokenExpired(token)) {
      return left(new AuthTokenExpiredError());
    }
    return await this.userApi.updateAvailability(token, availability);
  }
}

export const userService = new UserService(new SessionService(), new UserApi());
