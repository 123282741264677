import React, { useCallback, useMemo } from 'react';
import { CalendarIcon } from '../icons/calendarStyle/calendar';
import { Dropdown, Menu } from 'antd';
import { Button } from '../button';
import { CalendarStyleType } from '../../pages/design';
import { SelectArrowDown } from '../icons/selectArrowDown/arrowDown';
import './index.scss';

interface props {
  value: CalendarStyleType;
  onChange(value: CalendarStyleType): void;
}

export const CalendarStyleSelect: React.FC<props> = (props) => {
  const CalendarStyles = useMemo(
    () => [
      { type: CalendarStyleType.week, icon: CalendarIcon },
      { type: CalendarStyleType.month, icon: CalendarIcon },
    ],
    [],
  );
  const { value, onChange } = props;
  const selectHandler = useCallback(
    (set: CalendarStyleType) => {
      onChange(set);
    },
    [onChange],
  );

  const menu = useMemo(() => {
    return (
      <Menu className="calendarStyle-dropdown">
        {CalendarStyles.map((style) => (
          <Menu.Item
            className="calendarStyle-dropdown-container"
            key={style.type}
            onClick={() => selectHandler(style.type)}
          >
            <div className="calendarStyle-input-element">
              {<style.icon />}
              <div className="calendarStyle-input-element-name">
                <div className="calendarStyle-input-header">{style.type}</div>
              </div>
            </div>
          </Menu.Item>
        ))}
      </Menu>
    );
  }, [selectHandler, CalendarStyles]);

  const getSelected = useCallback(() => {
    const style = CalendarStyles.find((elem) => elem.type === value);
    return (
      <div className="calendarStyle-input-container">
        <div className="calendarStyle-input-element">
          {style?.icon && <style.icon />}
          <div className="calendarStyle-input-element-name">
            <div className="calendarStyle-input-header">{style?.type}</div>
          </div>
        </div>
      </div>
    );
  }, [value, CalendarStyles]);

  return (
    <div className="calendarStyle-select-block">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        getPopupContainer={() => {
          const tooltipContainer = document.createElement('div');
          const root = document.getElementsByClassName('ant-layout');
          tooltipContainer.classList.add('calendarStyle-dropdown');
          return root ? root[0].appendChild(tooltipContainer) : document.body;
        }}
      >
        <Button
          className="calendarStyle-select-btn"
          onClick={(e) => e.preventDefault()}
        >
          {getSelected()}
          <SelectArrowDown />
        </Button>
      </Dropdown>
    </div>
  );
};
