import { takeLatest, put, select, all } from 'redux-saga/effects';
import {
  fetchMeetingsAction,
  fetchMeetingsSuccessAction,
  fetchMeetingsErrorAction,
  fetchMeetingByIdToEditAction,
  fetchMeetingByIdToEditSuccessAction,
  fetchMeetingByIdToEditErrorAction,
  FetchMeetingByIdToEditParams,
  editMeetingByIdAction,
  editMeetingByIdSuccessAction,
  EditMeetingByIdParams,
  createNewMeetingAction,
  createNewMeetingErrorAction,
  createNewMeetingSuccessAction,
  CreateNewMeetingParams,
  FetchMeetingParamAction,
  fetchMeetingBookEmailDataAction,
  fetchMeetingBookEmailDataSuccessAction,
  FetchMeetingBookEmailDataParams,
  changeNotificationRemindersAction,
  ChangeNotificationsPolicyParams,
  showNotificationAction,
  changeNotificationsPolicySuccessAction,
  deleteMeetingAction,
  DeleteEventParams,
  deleteMeetingSuccessAction,
  changeMeetingActiveById,
  ChangeMeetingParams,
  showNotificationSuccessAction,
  showNotificationErrorAction,
  updateMeetingsOrderAction,
} from '../actions';
import { meetingService } from '../../libs/meeting/meeting.service';
import {
  FindMyMeetingsResult,
  FindMeetingByIdToEditResult,
  UpdateMeetingByIdResult,
  CreateNewMeetingResult,
} from '../../libs/meeting/dtos/meeting.dto';
import { PayloadAction } from '@reduxjs/toolkit';
import { appHistory, routers } from '../../router';
import { StoreState } from '../store/interface';
import { MeetingOnListModel } from '../../models/meeting.model';

function* fetchMyMeetingsSagaAction({
  payload,
}: PayloadAction<FetchMeetingParamAction>) {
  const result: FindMyMeetingsResult = yield meetingService.findMyMeetings(
    payload.page,
  );
  if (result.isRight()) {
    yield put(fetchMeetingsSuccessAction(result.value));
    return;
  }

  yield put(fetchMeetingsErrorAction(null));
}

function* fetchMeetingByIdToEditSagaAction(
  action: PayloadAction<FetchMeetingByIdToEditParams>,
) {
  const result: FindMeetingByIdToEditResult = yield meetingService.findMeetingByIdToEdit(
    action.payload.id,
  );
  if (result.isRight()) {
    yield put(fetchMeetingByIdToEditSuccessAction({ meeting: result.value }));
    return;
  }

  if (result.value.code === 'meetingNotFoundError') {
    yield put(fetchMeetingByIdToEditErrorAction());
    alert(result.value.message);
    appHistory.push(routers.ME_PAGE);
    return;
  }

  yield put(fetchMeetingByIdToEditErrorAction());
  alert(result.value.message);
}

function* editMeetingByIdSagaAction(
  action: PayloadAction<EditMeetingByIdParams>,
) {
  const result: UpdateMeetingByIdResult = yield meetingService.updateById(
    action.payload.id,
    action.payload.fields,
  );
  if (result.isRight()) {
    yield put(editMeetingByIdSuccessAction({ meeting: result.value }));
    yield put(
      showNotificationSuccessAction({
        content: 'Meeting updated.',
      }),
    );
    return;
  }

  if (result.value.code === 'validationError') {
    yield all(
      result.value.details.map(function* (item) {
        yield put(showNotificationErrorAction({ content: item.message }));
      }),
    );
  }
}

function* changeMeetingActiveByIdSagaAction(
  action: PayloadAction<ChangeMeetingParams>,
) {
  const result = yield meetingService.updateActiveById(
    action.payload.id,
    action.payload.fields,
  );

  if (result.isRight()) {
    //Todo fix page request
    yield put(fetchMeetingsAction({ page: 1 }));
    return;
  }
  if (result.value.code === 'validationError') {
    yield all(
      result.value.details.map(function* (item: any) {
        yield put(showNotificationErrorAction({ content: item.message }));
      }),
    );
  } else {
    yield put(
      showNotificationErrorAction({
        content: result.value.originalError.response.data.message
          ? result.value.originalError.response.data.message
          : 'Server error. Try later',
      }),
    );
  }
}

function* createNewMeetingSagaAction(
  action: PayloadAction<CreateNewMeetingParams>,
) {
  const result: CreateNewMeetingResult = yield meetingService.createNewMeeting(
    action.payload,
  );
  if (result.isRight()) {
    yield put(createNewMeetingSuccessAction({ meeting: result.value }));
    yield put(createNewMeetingErrorAction({ details: [] }));
    appHistory.push(routers.HOME);
    yield put(
      showNotificationSuccessAction({
        content: 'New meeting created.',
      }),
    );
    return;
  }
  if (result.value.code === 'validationError') {
    yield all(
      result.value.details.map(function* (item) {
        yield put(showNotificationErrorAction({ content: item.message }));
      }),
    );
  }
}

function* fetchMeetingDataBookEmailSagaAction({
  payload,
}: PayloadAction<FetchMeetingBookEmailDataParams>) {
  const result = yield meetingService.fetchMeetingDataBookEmail(
    payload.meetingId,
  );

  if (result.isLeft()) {
    alert(result);
  }

  yield put(
    fetchMeetingBookEmailDataSuccessAction({ bookByEmailData: result.value }),
  );
}

function* changeNotificationRemindersSagaAction({
  payload,
}: PayloadAction<ChangeNotificationsPolicyParams>) {
  const result = yield meetingService.changeNotificationReminders(payload);
  // eslint-disable-next-line no-console

  if (result.isLeft()) {
    yield put(
      showNotificationAction({
        type: 'error',
        content: result.value.message,
        duration: null,
      }),
    );
  }

  const state: StoreState = yield select();
  yield put(
    changeNotificationsPolicySuccessAction({
      notificationPolicy: {
        ...state.meetings.active.value?.notificationsPolicy,
        ...payload,
      },
    }),
  );
}

function* deleteEventSagaAction({ payload }: PayloadAction<DeleteEventParams>) {
  const result = yield meetingService.deleteMeeting(payload);
  // eslint-disable-next-line no-console
  if (result.isLeft()) {
    yield put(
      showNotificationAction({
        type: 'error',
        content: result.value.message,
        duration: null,
      }),
    );
  }
  if (result.isRight()) {
    yield put(
      showNotificationSuccessAction({
        content: 'Meeting deleted.',
      }),
    );
    yield put(deleteMeetingSuccessAction({ meetingId: payload.meetingId }));
    return;
  }
}

function* updateMeetingsOrderActionSaga({
  payload,
}: PayloadAction<MeetingOnListModel[]>): any {
  const result = yield meetingService.updateMeetingsOrder(payload);
  if (result.isRight()) {
    yield put(fetchMeetingsSuccessAction(result.value));
    return;
  }

  yield put(fetchMeetingsErrorAction(null));
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function* meetingRunSaga(): Generator<any> {}
export function* meetingWatchSaga(): Generator<any> {
  yield takeLatest(fetchMeetingsAction, fetchMyMeetingsSagaAction);
  yield takeLatest(
    fetchMeetingByIdToEditAction,
    fetchMeetingByIdToEditSagaAction,
  );
  yield takeLatest(editMeetingByIdAction, editMeetingByIdSagaAction);
  yield takeLatest(changeMeetingActiveById, changeMeetingActiveByIdSagaAction);
  yield takeLatest(createNewMeetingAction, createNewMeetingSagaAction);
  yield takeLatest(
    fetchMeetingBookEmailDataAction,
    fetchMeetingDataBookEmailSagaAction,
  );
  yield takeLatest(
    changeNotificationRemindersAction,
    changeNotificationRemindersSagaAction,
  );
  yield takeLatest(deleteMeetingAction, deleteEventSagaAction);
  yield takeLatest(updateMeetingsOrderAction, updateMeetingsOrderActionSaga);
}
