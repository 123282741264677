import React from 'react';
import { useSessionAuthInfoSelector } from '../redux/selectors/auth';
import { Redirect, RouteProps, Route, RouteComponentProps } from 'react-router';
import { routers } from './routes';
import { LoaderPage } from '../components/loader/loaderPage';

type Props = RouteProps;

export const AuthenticatedRouteComponent: React.FC<Props> = (props) => {
  const session = useSessionAuthInfoSelector();
  const { children, component: Component, ...rest } = props;
  const isAuthenticated = !!session.user;
  const render = (renderProps: RouteComponentProps<any>) => {
    if (!session.isLoaded || session.isLoading) {
      return <LoaderPage />;
    } else {
      if (isAuthenticated) {
        return <>{Component ? <Component {...renderProps} /> : children}</>;
      } else {
        return (
          <Redirect
            to={{
              pathname: routers.LANDING_PAGE,
              state: { from: renderProps.location },
            }}
          />
        );
      }
    }
  };
  return <Route {...rest} render={render} />;
};

export const UnauthenticatedRouteComponent: React.FC<Props> = (props) => {
  const session = useSessionAuthInfoSelector();
  const { children, component: Component, ...rest } = props;
  const isAuthenticated = !!session.user;

  const render = (renderProps: RouteComponentProps<any>) => {
    if (!isAuthenticated) {
      if (!session.isLoaded || session.isLoading) {
        return (
          <>
            {Component ? <Component {...renderProps} /> : children}
            <LoaderPage />
          </>
        );
      } else {
        return Component ? <Component {...renderProps} /> : children;
      }
    }
    return (
      <Redirect
        to={{
          pathname: routers.ME_PAGE,
          state: { from: renderProps.location },
        }}
      />
    );
  };
  return <Route {...rest} render={render} />;
};
