import React from 'react';

const CloseIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={`${className ? className : ''}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.92608 12L4.28735 6.36127C3.91254 5.97814 3.91254 5.36179 4.28735 4.97866L4.97866 4.28735C5.36179 3.90422 5.97814 3.90422 6.36127 4.28735L12 9.92608L17.6387 4.28735C18.0219 3.90422 18.6382 3.90422 19.0213 4.28735L19.7126 4.97866C20.0958 5.36179 20.0958 5.97814 19.7126 6.36127L14.0739 12L19.7126 17.6387C20.0958 18.0219 20.0958 18.6382 19.7126 19.0213L19.0213 19.7127C18.6382 20.0958 18.0219 20.0958 17.6387 19.7127L12 14.0739L6.36127 19.7127C5.97814 20.0958 5.36179 20.0958 4.97866 19.7127L4.28735 19.0213C3.90421 18.6382 3.90421 18.0219 4.28735 17.6387L9.92608 12Z"
        fill="#CF1322"
      />
    </svg>
  );
};

export default CloseIcon;
