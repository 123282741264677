import React from 'react';
import { ReactComponent as SettingsLogo } from './settings.svg';

export const SettingsIcon: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SettingsLogo />
    </div>
  );
};
