import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './index.scss';
import { SettingsIcon } from '../icons/settings';
import ArrowDownIcon from '../icons/arrow-down';
import { Switch } from '../switch';
import { LinktTo } from '../icons/linkTo';
import { Dropdown, Menu } from 'antd';
import { appHistory, appUrl, routers } from '../../router';
import {
  changeMeetingActiveById,
  deleteMeetingAction,
} from '../../redux/actions';
import { CopyLinkButton } from '../copyLink';
import { useUserInfoSelector } from '../../redux/selectors/auth';
import { useDispatch } from 'react-redux';
import { LocationTypeEnum } from '../../models/meeting.model';
import { Draggable } from 'react-beautiful-dnd';

const LocationEnum = {
  jammer_conference: 'Jammer Conference Line',
  web_conference: 'Web conference',
  phone_call: 'Phone call',
  person: 'Person meeting',
  custom: 'Custom meeting',
};

export interface HomeListItem {
  order_dnd: number;
  id: string;
  name: string;
  color: string;
  duration: number;
  slug: string;
  is_active: boolean;
  location: {
    id: string;
    meeting_id: string;
    additional_info: string | null;
    location: string | null;
    kind: LocationTypeEnum;
  };
}

interface props {
  item: HomeListItem;
  loading?: boolean;
  loaded?: boolean;
  index: number;
}

export const MeetingItem: React.FC<props> = (props) => {
  const { item, loading, loaded, index } = props;
  const user = useUserInfoSelector();
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(item?.is_active);

  const link = useMemo(() => {
    return appUrl + '/' + user?.slug + '/' + item?.slug;
  }, [user, item]);

  useEffect(() => {
    setIsChecked(item?.is_active);
  }, [isChecked, item]);

  const redirectHandler = useCallback(() => {
    if (item?.id && window.innerWidth > 576) {
      appHistory.push(routers.GET_MEETING_EDIT(item.id));
    }
  }, [item]);

  const deleteHandler = useCallback(() => {
    if (item?.id) {
      dispatch(deleteMeetingAction({ meetingId: item?.id }));
    }
  }, [item, dispatch]);

  const switchHandler = useCallback(
    (checked: boolean, event: MouseEvent) => {
      event.stopPropagation();
      if (item?.id && item.hasOwnProperty('is_active') && !loading) {
        dispatch(
          changeMeetingActiveById({
            id: item.id,
            fields: { isActive: !item.is_active },
          }),
        );
        setIsChecked(!isChecked);
      }
    },
    [item, dispatch, loading, isChecked],
  );

  return (
    <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="meeting-item-container"
        >
          <div className="meeting-item-settings">
            <Dropdown
              overlay={
                <Menu className="dropdown-menu">
                  <Menu.Item>
                    {/*eslint-disable-next-line*/}
                <a onClick={redirectHandler}>Edit</a>
                  </Menu.Item>
                  <Menu.Item>
                    {/*eslint-disable-next-line*/}
                <a onClick={deleteHandler}>Delete</a>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomLeft"
              trigger={['click']}
              getPopupContainer={() => {
                const tooltipContainer = document.createElement('div');
                const root = document.getElementsByClassName('ant-layout');
                tooltipContainer.classList.add('dropdown-container');
                return root
                  ? root[0].appendChild(tooltipContainer)
                  : document.body;
              }}
            >
              <div className="meeting-item-settings-group">
                <SettingsIcon />
                <ArrowDownIcon />
              </div>
            </Dropdown>
          </div>

          <div
            className={`meeting-item-body${
              item?.hasOwnProperty('is_active') && !item?.is_active
                ? ' disabled'
                : ''
            }`}
          >
            <div className="meeting-item-title">
              <div
                className="meeting-item-title-name"
                onClick={redirectHandler}
              >
                <div>{item?.name}</div>
                <LinktTo link={link} />
              </div>
              <div className="meeting-item-title-subtitle">
                {item?.duration ? `${item?.duration} min, ` : null}{' '}
                {item?.location?.kind
                  ? LocationEnum[item?.location?.kind]
                  : null}
              </div>
            </div>
            <div className="meeting-item-buttons">
              <div className="meeting-item-buttons-switch">
                <Switch
                  size="small"
                  leftlabel="Allow Scheduling"
                  onClick={switchHandler}
                  checked={isChecked}
                  //defaultChecked={item?.is_active}
                  disabled={loading || !loaded}
                />
                <CopyLinkButton link={user && item?.slug ? link : undefined} />
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};
