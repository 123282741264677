import { THEMES } from '../constants/theme';
import { createReducer } from '@reduxjs/toolkit';
import { setDarkTheme, setDarkThemeSchedule } from '../actions/theme';

export interface ThemeState {
  color: THEMES;
}
const initialThemeReducer: ThemeState = {
  color: THEMES.DARK,
};

export const themeReducer = createReducer<ThemeState>(
  initialThemeReducer,
  (builder) => {
    builder
      .addCase(setDarkTheme, (state, action) => {
        localStorage.setItem('theme', action.payload.data);
        state.color = action.payload.data;
      })
      .addCase(setDarkThemeSchedule, (state, action) => {
        state.color = action.payload.data;
      });
  },
);
