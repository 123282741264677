import React from 'react';
import './form-wrapper.scss';

export interface IFormWrapper {
  className?: string;
  children?: React.ReactNode;
}

export const FormWrapper: React.FC<IFormWrapper> = (props) => {
  return (
    <div className={`form-wrapper ${props.className ? props.className : ''}`}>
      {props.children}
    </div>
  );
};
