import { AccessTokenDto } from '../auth/dtos/access-token.dto';
import { FetchNotificationDto } from '../../redux/actions';
import BaseAxiosInstance from '../../utils/axios-config';

export class RemindersApi {
  async getNotifications(
    accessToken: AccessTokenDto,
  ): Promise<FetchNotificationDto | undefined> {
    const response = await BaseAxiosInstance.get(
      '/api/users/profile/notification',
      {
        headers: { Authorization: `Bearer ${accessToken.token}` },
      },
    );
    return response.data;
  }

  async updateNotifications(
    accessToken: AccessTokenDto,
    dto: FetchNotificationDto,
  ): Promise<FetchNotificationDto | undefined> {
    const response = await BaseAxiosInstance.put(
      '/api/users/profile/notification',
      dto,
      {
        headers: { Authorization: `Bearer ${accessToken.token}` },
      },
    );
    return response.data;
  }
}
