import React, { useState } from 'react';
import { Button } from 'antd';
import './index.scss';
import { CheckIcon } from '../icons/check/ checkIcon';
import copy from 'copy-to-clipboard';

interface props {
  link?: string;
}

export const CopyLinkButton: React.FC<props> = ({ link }) => {
  const [name, setName] = useState('Copy Link');
  const [buttonClass, setButtonClass] = useState<string | undefined>(undefined);
  const handler = (event: any) => {
    event.stopPropagation();
    if (link) {
      copy(link);
      setName('Copied');
      setButtonClass('copied');
      setTimeout(() => {
        setName('Copy Link');
        setButtonClass(undefined);
      }, 3000);
    }
  };

  return (
    <div className="copy-link-button">
      <Button
        className={buttonClass}
        type="primary"
        ghost={!!!buttonClass}
        onClick={handler}
        //onClick={(e) => console.log(e)}
      >
        {buttonClass && <CheckIcon />}
        {name}
      </Button>
    </div>
  );
};
