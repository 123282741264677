import React from 'react';

const ClockIcon: React.FC<{ className?: string }> = (props) => {
  return (
    <svg
      className={`${props.className || ''}`}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3334 9.16666H10.8334V6.66666C10.8334 6.20582 10.46 5.83332 10 5.83332C9.54002 5.83332 9.16669 6.20582 9.16669 6.66666V9.99999C9.16669 10.4608 9.54002 10.8333 10 10.8333H13.3334C13.7942 10.8333 14.1667 10.4608 14.1667 9.99999C14.1667 9.53916 13.7942 9.16666 13.3334 9.16666ZM10 16.6667C6.32419 16.6667 3.33335 13.6758 3.33335 9.99999C3.33335 6.32416 6.32419 3.33332 10 3.33332C13.6759 3.33332 16.6667 6.32416 16.6667 9.99999C16.6667 13.6758 13.6759 16.6667 10 16.6667ZM10 1.66666C5.40502 1.66666 1.66669 5.40499 1.66669 9.99999C1.66669 14.595 5.40502 18.3333 10 18.3333C14.595 18.3333 18.3334 14.595 18.3334 9.99999C18.3334 5.40499 14.595 1.66666 10 1.66666Z"
        fill="#231F20"
      />
    </svg>
  );
};

export default ClockIcon;
