import React, { useEffect, useState } from 'react';
import { EventData, ScheduleDto } from '..';
import { MeetingToBookEventModel } from '../../../models/meeting.model';
import { UserLogo } from '../../../components/userLogo';
import { ArrowLeftIcon } from '../../../components/icons/arrowLeft';
import Form from 'antd/lib/form';
import './complete.scss';
import { InputWithLabel } from '../../../components/input/inputWithLabel';
import { Input } from '../../../components/input';
import { Button } from '../../../components/button';
import { EmailAndGuestField } from '../../../components/emailAndGuestField/guest-field';
import { PhoneInputComponent } from '../../../components/phoneInput';
import { ScheduleIcon } from '../../../components/icons/shedule';
import { ScheduleSelectedTime } from '../../../components/scheduleSelectedTime';
import { meetingService } from '../../../libs/meeting/meeting.service';

interface props {
  eventData: EventData;
  setEventsData(value: EventData): void;
  meetingBookInfo: MeetingToBookEventModel | null;
  scheduleHandler(dto: ScheduleDto): void;
  token?: string;
  eventId?: string;
}

export const CompleteSchedule: React.FC<props> = (props) => {
  const [completeForm] = Form.useForm();
  const [openGuests, setOpenGuests] = useState(false);
  const [guestValidation, setGuestValidation] = useState<string | null>(null);
  useEffect(() => {
    if (props.token && props.eventId) {
      meetingService
        .getRescheduleData(props.eventId, props.token)
        .then((data) => {
          completeForm.setFieldsValue({
            name: data?.name,
            email: data?.email,
            phone: data?.phone,
            guests: data?.guests,
          });
          if (data?.guests && data?.guests.length>0){
            setOpenGuests(true);
          }
        });
    }
  }, [completeForm, props.eventId, props.token]);

  const backHandler = () => {
    props.setEventsData({ ...props.eventData, isFilled: false });
  };

  const emailHandler = (value: string[]) => {
    completeForm.setFieldsValue({ emails: value });
  };

  const guestsHandler = () => {
    setOpenGuests(!openGuests);
    if (!openGuests) {
      completeForm.setFieldsValue({ guests: [] });
    }
  };

  return (
    <div className="completeSchedule-body">
      <div className="completeSchedule-header">
        <UserLogo avatar={props.meetingBookInfo?.design.logo} />
        <div className="completeSchedule-header-username">
          Confirm With {props.meetingBookInfo?.owner.name}
        </div>
        <ScheduleSelectedTime
          color={props.meetingBookInfo?.design.color}
          time={props.eventData.date}
          duration={props.meetingBookInfo?.duration}
          timezone={props.eventData.timezone}
        />
        <div
          className="completeSchedule-backButton"
          onClick={backHandler}
          style={
            props.meetingBookInfo?.design.color
              ? { color: props.meetingBookInfo?.design.color }
              : undefined
          }
        >
          <ArrowLeftIcon
            style={
              props.meetingBookInfo?.design.color
                ? { stroke: props.meetingBookInfo?.design.color }
                : undefined
            }
          />
          <div className="back-title">Back</div>
        </div>
      </div>
      <div className="completeSchedule-title">Enter Your Details</div>

      <Form
        className="completeSchedule-form"
        form={completeForm}
        onFinish={(e) => {
          props.scheduleHandler(e);
        }}
      >
        <InputWithLabel label="Name *">
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'name is required!' }]}
          >
            <Input />
          </Form.Item>
        </InputWithLabel>
        <InputWithLabel label="Email *">
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'email is required!' }]}
          >
            <Input />
          </Form.Item>
          <div
            className="add-guests"
            onClick={guestsHandler}
            style={
              props.meetingBookInfo?.design.color
                ? { color: props.meetingBookInfo?.design.color }
                : undefined
            }
          >
            {openGuests ? 'Hide Guests' : 'Add Guests'}
          </div>
        </InputWithLabel>
        {openGuests && (
          <InputWithLabel label="Guests email">
            <Form.Item name="guests" initialValue={[]}>
              <EmailAndGuestField
                onChange={emailHandler}
                color={props.meetingBookInfo?.design.color}
                setGuestValidation={setGuestValidation}
              />
            </Form.Item>
          </InputWithLabel>
        )}

        <InputWithLabel label="Phone *">
          <Form.Item
            name="phone"
            rules={[{ required: true, message: 'phone is required!' }]}
          >
            <PhoneInputComponent />
          </Form.Item>
        </InputWithLabel>
        <Button
          htmlType="submit"
          disabled={!!guestValidation}
          style={
            props.meetingBookInfo?.design.color
              ? {
                  backgroundColor: props.meetingBookInfo?.design.color,
                  borderColor: props.meetingBookInfo?.design.color,
                }
              : undefined
          }
        >
          <ScheduleIcon
            style={
              props.meetingBookInfo?.design.color
                ? { fill: props.meetingBookInfo?.design.color }
                : undefined
            }
          />
          Schedule
        </Button>
      </Form>
    </div>
  );
};
