import React from 'react';
import { Dashboard } from '../../components/dashboardLayout';
import './index.scss';
import { BillingTrial } from './trial/trial';
import { useSubscriptionSelector } from '../../redux/selectors/auth';
import { SubscriptionsEnum } from '../../models/subscription.model';
import { BillingMainPage } from './billingMain';

export const Billing: React.FC = () => {
  const subscription = useSubscriptionSelector();
  return (
    <Dashboard>
      <div className="billingPage-container">
        {subscription.typeName === SubscriptionsEnum.pro ? (
          <BillingMainPage />
        ) : (
          <BillingTrial subscription={subscription} />
        )}
      </div>
    </Dashboard>
  );
};
