import React from 'react';
import { Switch as SwitchAntd } from 'antd';
import { SwitchProps } from 'antd/lib/switch';
import './index.scss';

export interface ISwitch extends SwitchProps {
  leftlabel?: string;
  rightlabel?: string;
}

export const Switch: React.FC<ISwitch> = (props) => {
  const { leftlabel, rightlabel } = props;
  return (
    <div className="switch-container">
      {leftlabel ? (
        <span className="switch-container-label switch-container-left-label">
          {leftlabel}
        </span>
      ) : null}
      <SwitchAntd
        {...props}
        className={`switch-input ${props.className || ''}`}
      />
      {rightlabel ? (
        <span className="switch-container-label switch-container-right-label">
          {rightlabel}
        </span>
      ) : null}
    </div>
  );
};
