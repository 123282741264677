import React from 'react';
import { useDispatch } from 'react-redux';
import { QuestionOutlined } from '../../../components/icons/questionOutlined';
import { GoogleSingUpButton } from '../../../components/button/new-google-sign-up.button';
import './index.scss';
import { loginForGoogleCalendarAction } from '../../../redux/actions';

export const ConnectCalendar: React.FC = () => {
  const dispatch = useDispatch();
  return (
    <div className="connectCalendar-body">
      <div className="connectCalendar-header">
        <div className="connectCalendar-header-logo">
          <QuestionOutlined />
        </div>
        <p>No Account Connected</p>
      </div>
      <GoogleSingUpButton
        type="primary"
        htmlType="submit"
        onClick={() => dispatch(loginForGoogleCalendarAction())}
        className="sign-up-by-google-button"
      >
        Log In With Google
      </GoogleSingUpButton>
    </div>
  );
};
