import React, { useEffect } from 'react';
import { Dashboard } from '../../components/dashboardLayout';
import { useDispatch } from 'react-redux';
import {
  useCalendarAccountsSelector,
  useUserInfoSelector,
} from '../../redux/selectors/auth';
import { getCalendarAccountsAction } from '../../redux/actions';
import { AccountCalendar } from './accountCalendar/accountCalendar';
import { ConnectCalendar } from './connectCalendar/connectCalendar';
import './index.scss';

export const Calendars: React.FC = () => {
  const dispatch = useDispatch();
  const user = useUserInfoSelector();
  const calendarAccounts = useCalendarAccountsSelector();
  useEffect(() => {
    dispatch(getCalendarAccountsAction(user!));
  }, [dispatch, user]);
  return (
    <Dashboard>
      <div className="calendarConnection-page">
        <div className="calendarConnection-page-header">
          Edit your connected calender settings to sync across all your meeting
          types.
        </div>
        <div className="calendarConnection-page-body">
          {calendarAccounts.length ? (
            <AccountCalendar calendarAccounts={calendarAccounts} user={user} />
          ) : (
            <ConnectCalendar />
          )}
        </div>
      </div>
    </Dashboard>
  );
};
