import React from 'react';
import { BaseRenderProps } from '../../../components/modal/components';
import { EnterCardModalParam } from '../../../redux/actions';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CardForm, OnSubmitCardProps } from './cardForm';
import './index.scss';
import { useDispatch } from 'react-redux';

type Props = BaseRenderProps & EnterCardModalParam;

export const EnterCardModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const stripePromise = loadStripe(
    'pk_test_51HS9S6ImOw4fUmE9C7a7iW2XcEvTZMMeNdaUValiJn8K9C7YQF111rixBL1nfbp2QFDFuu3VXik3C5ofZtbKK4oG00sthdnpBE',
    { locale: 'en' },
  );

  const submitHandler = (value: OnSubmitCardProps) => {
    dispatch(
      props.onSubmit({
        token: value.token,
        type: value.type,
      }),
    );
  };
  return (
    <Elements stripe={stripePromise}>
      <div className="modal-space">
        <div className="enterCard-text-header">{props.header}</div>
        <div className="enterCard-description">Enter Payment Information</div>
        <CardForm
          onCancel={props.onCancel}
          onSubmit={submitHandler}
          buttonTitle="Upgrade"
        />
      </div>
    </Elements>
  );
};
