import React from 'react';
import { ReactComponent as SuccessLogo } from './Mask.svg';

export const MessageSuccessIcon: React.FC = () => {
  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SuccessLogo />
    </span>
  );
};
