import React, { useCallback } from 'react';
import { ReactComponent as BackArrowLogo } from './backArrow.svg';
import { appHistory } from '../../../router';

interface props {
  link?: string;
}

export const BackArrow: React.FC<props> = ({ link }) => {
  const handler = useCallback(() => {
    if (link) {
      appHistory.push(link);
    }
  }, [link]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <BackArrowLogo onClick={handler} />
    </div>
  );
};
