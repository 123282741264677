import React, { useMemo } from 'react';
import { CopyUrl } from '../../../components/input/copyUrl';
import './index.scss';
import { Button } from '../../../components/button';
import { LinktTo } from '../../../components/icons/linkTo/';
import { appUrl, routers } from '../../../router';
import copy from 'copy-to-clipboard';
import { NavLink } from 'react-router-dom';

interface props {
  slug?: string;
}

export const HomeHeader: React.FC<props> = ({ slug }) => {
  const link = useMemo(() => {
    return appUrl + '/' + slug;
  }, [slug]);
  return (
    <div className="home-header-container">
      <div className="home-user-link">
        <span>Meeting Types</span>
        {slug && (
          <>
            <CopyUrl value={slug} onClick={() => copy(link)} />
            <LinktTo link={link} />
          </>
        )}
      </div>
      <NavLink to={routers.MEETING_CREATING}>
        <Button>New Meeting Type</Button>
      </NavLink>
    </div>
  );
};
