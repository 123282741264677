import axios from 'axios';
import config from '../config';

export const serverUrl = config.default.BASE_URL;

const BaseAxiosInstance = axios.create({
  baseURL: serverUrl,
});

export default BaseAxiosInstance;
