import { DomainError } from '../common/errors';

export class UserSlugConflictError implements DomainError {
  code = 'userSlugConflictError' as const;
  message = 'This url already exist';
  details = [
    {
      message: `This url already exist`,
      code: 'string.slug.conflict',
      path: 'slug',
    },
  ];
  constructor(public originalError?: Error) {}
}

export class PhoneValidationError implements DomainError {
  constructor(public originalError?: Error) {}
  code = 'userPhoneConflict' as const;
  message = 'This users phone already exists.';
}
