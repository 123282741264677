import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { EventData } from '../index';
import { WeekSelectDay } from '../../../components/selectDayWeekView';
import { BookingRangeInfo } from '../../../redux/reducers/booking-meeting-event';
import moment, { Moment } from 'moment-timezone';
import { WeekSelectTime } from '../../../components/selectTimeWeekView';

interface props {
  color?: string | null;
  range: BookingRangeInfo;
  eventData?: EventData;
  selectEventTimeHandler(value: string): void;
  timezoneValue: string;
  getTimes(day: Moment, bookingRange: BookingRangeInfo): string[] | undefined;
}

const weekDayName = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
const month = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

export const WeekView: React.FC<props> = (props) => {
  const [week, setWeek] = useState(0);
  const [selectedDay, setSelectedDay] = useState<Moment | undefined>(undefined);

  useEffect(() => {
    const firstAvailable = props.range.dates.find(
      (item) => item.status === 'available',
    );
    if (firstAvailable) {
      setSelectedDay(moment(firstAvailable.date));
    }
  }, [props.range.dates]);

  const setDayHandler = useCallback((value) => {
    setSelectedDay(value);
  }, []);

  const selectEventTimeHandler = (value: string) => {
    props.selectEventTimeHandler(value);
  };
  const weeksCount = useMemo(() => {
    return Math.ceil((props.range.dates.length + 1) / 7);
  }, [props.range]);

  const upWeekHandler = useCallback(() => {
    if (week + 1 < weeksCount) {
      setWeek(week + 1);
    }
  }, [week, weeksCount]);

  const downWeekHandler = useCallback(() => {
    if (week > 0) {
      setWeek(week - 1);
    }
  }, [week]);

  const shiftDay = useMemo(() => {
    return moment(props.range.dates[0].date).day();
  }, [props.range]);

  const getDayStatus = useCallback(
    (value: number) => {
      if (
        props.range.dates[value + week * 7 - shiftDay] &&
        props.range.dates[value + week * 7 - shiftDay].status === 'available'
      ) {
        return true;
      } else return false;
    },
    [props.range, shiftDay, week],
  );

  const getCurrentWeekDays = useCallback(() => {
    const days = [];
    for (let i = 0; i <= 6; i++) {
      days.push({
        weekDay: weekDayName[i],
        month:
          month[
            moment(props.range.dates[0].date)
              .add(i + week * 7 - shiftDay, 'days')
              .get('month')
          ],
        day: moment(props.range.dates[0].date)
          .add(i + week * 7 - shiftDay, 'days')
          .get('date'),
        status: getDayStatus(i),
        moment: moment(props.range.dates[0].date).add(
          i + week * 7 - shiftDay,
          'days',
        ),
      });
    }
    return days;
  }, [props.range, shiftDay, week, getDayStatus]);

  return (
    <>
      <WeekSelectDay
        color={props.color}
        week={getCurrentWeekDays()}
        upWeekHandler={upWeekHandler}
        downWeekHandler={downWeekHandler}
        selectedDay={selectedDay}
        setSelectedDay={setDayHandler}
      />
      {selectedDay && (
        <WeekSelectTime
          color={props.color}
          times={props.getTimes(selectedDay, props.range)}
          setSelectedTime={selectEventTimeHandler}
        />
      )}
    </>
  );
};
