import { put, takeLatest } from 'redux-saga/effects';
import {
  fetchNotificationAction,
  FetchNotificationDto,
  fetchNotificationSuccessAction,
  updateNotificationAction,
  updateNotificationSuccessAction,
} from '../actions';
import {
  showNotificationErrorAction,
  showNotificationSuccessAction,
} from '../actions';
import { remindersService } from '../../libs/reminders/reminders.service';
import { PayloadAction } from '@reduxjs/toolkit';

function* fetchNotificationActionSaga() {
  try {
    const response = yield remindersService.getNotification();
    yield put(fetchNotificationSuccessAction(response));
  } catch (e) {
    yield put(
      showNotificationErrorAction({
        content: 'Server error, try later.',
      }),
    );
  }
}

function* updateNotificationActionSaga(
  action: PayloadAction<FetchNotificationDto>,
) {
  try {
    const response = yield remindersService.updateNotification(action.payload);
    yield put(
      showNotificationSuccessAction({
        content: 'Reminders & notifications updated.',
      }),
    );
    yield put(updateNotificationSuccessAction(response));
  } catch (e) {
    yield put(
      showNotificationErrorAction({
        content: 'Server error, try later.',
      }),
    );
  }
}

export function* remindersWatchSaga(): Generator<any> {
  yield takeLatest(fetchNotificationAction, fetchNotificationActionSaga);
  yield takeLatest(updateNotificationAction, updateNotificationActionSaga);
}
