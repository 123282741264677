import React from 'react';
import { ReactComponent as ErrorLogo } from './Mask.svg';

export const MessageErrorIcon: React.FC = () => {
  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ErrorLogo />
    </span>
  );
};
