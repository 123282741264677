import 'antd/dist/antd.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store/configure-store';
import { appHistory } from './router';
import { Router } from 'react-router';

const store = configureStore();

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Router history={appHistory}>
      <App />
    </Router>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);
