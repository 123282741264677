import React, { useCallback, useState } from 'react';
import { Tooltip, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { sessionService } from '../../libs/auth/session.service';
import {
  useProfileUpdatingInfoSelector,
  useSubscriptionPlanSelector,
} from '../../redux/selectors/auth';
import './index.scss';
import {
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons/lib';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import {
  setAvatarSizeError,
  showNotificationSuccessAction,
} from '../../redux/actions';
import { useDispatch } from 'react-redux';
import { serverUrl } from '../../utils/axios-config';
import { getFirstErrorByPathName } from '../../utils/errors';
import { deleteDesignLogoAction } from '../../redux/actions/design';
import { SubscriptionsEnum } from '../../models/subscription.model';

interface LogoCropperProps {
  logo: string | null;
  setLogo(value: string | null): void;
}

export const LogoCropper: React.FC<LogoCropperProps> = (props) => {
  const dispatch = useDispatch();
  const role = useSubscriptionPlanSelector() || SubscriptionsEnum.free;
  const { logo, setLogo } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const headers = {
    Authorization: `Bearer ${sessionService.getAccessToken()?.token}`,
  };

  const profileUpdate = useProfileUpdatingInfoSelector();
  const [loading, setLoading] = useState(false);

  const handleChange = (info: UploadChangeParam): void => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (avatarUrl: string): void => {
        setLoading(false);
        setLogo(avatarUrl);
        dispatch(
          showNotificationSuccessAction({
            content: 'Logo updated.',
            duration: 2,
          }),
        );
      });
    }
  };

  const getErrorByName = useCallback(
    (name: string) => {
      return getFirstErrorByPathName(profileUpdate.errors, name);
    },
    [profileUpdate.errors],
  );

  const onRemoveHandler = (event: any): void => {
    event.preventDefault();
    dispatch(deleteDesignLogoAction({ removeLogo: true }));
    setLogo(null);
  };

  function getBase64(img: any, callback: any): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file: File): boolean {
    const isFormat =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/gif';
    if (!isFormat) {
      dispatch(
        setAvatarSizeError({
          message: 'You can only upload JPG/PNG/GIF file!',
        }),
      );
      // Not deleting this for any case
      // dispatch(
      //   showNotificationAction({
      //     type: 'info',
      //     content: 'You can only upload JPG/PNG/GIF file!',
      //     duration: null,
      //   }),
      // );
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      dispatch(
        setAvatarSizeError({
          message: 'Image must be smaller than 5MB!',
        }),
      );
      // Not deleting this for any case
      // dispatch(
      //   showNotificationAction({
      //     type: 'info',
      //     content: 'Image must smaller than 5MB!',
      //     duration: null,
      //   }),
      // );
    }

    return isFormat && isLt2M;
  }

  const uploadButton = (
    <div className="logoPhoto-add">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div>Upload</div>
    </div>
  );

  const tooltipHandler = () => {
    if (role === SubscriptionsEnum.free) {
      setTooltipOpen(!tooltipOpen);
    }
  };

  return (
    <div className="avatar-form-item">
      <div className="uploader-block">
        {logo && role !== SubscriptionsEnum.free ? (
          <img src={logo} alt="avatar" className="profile-avatar-img" />
        ) : (
          <Tooltip
            title="Logo change is available only in the premium plan"
            placement="right"
            getPopupContainer={() => {
              const tooltipContainer = document.createElement('div');
              const root = document.getElementsByClassName('ant-layout');
              tooltipContainer.classList.add('tooltip-container');
              return root
                ? root[0].appendChild(tooltipContainer)
                : document.body;
            }}
            visible={tooltipOpen}
          >
            <div
              className="uploader-block"
              onPointerEnter={tooltipHandler}
              onPointerLeave={tooltipHandler}
            >
              <ImgCrop rotate>
                <Upload
                  name="file"
                  accept="image/*"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action={`${serverUrl}/api/designs/my`}
                  headers={headers}
                  method="PUT"
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  disabled={role === SubscriptionsEnum.free}
                >
                  {uploadButton}
                </Upload>
              </ImgCrop>
            </div>
          </Tooltip>
        )}

        {logo && role !== SubscriptionsEnum.free && (
          <div className="bg-layer">
            <div className="delete-button" onClick={onRemoveHandler}>
              <DeleteOutlined />
            </div>
          </div>
        )}
      </div>
      {/*<div className="text-note text-note-color mt-2">*/}
      {/*  JPG, GIF or PNG. Max size of 5MB.*/}
      {/*</div>*/}
      <div className="uploader-error">{getErrorByName('avatar')?.message}</div>
    </div>
  );
};
