import React from 'react';
import { QuestionTooltip } from '../questionTooltip';
import './index.scss';

interface props {
  label: string;
  tooltip?: string;
}

export const LabelWithTooltip: React.FC<props> = ({ label, tooltip }) => {
  return (
    <div className="labelWithTooltip">
      <span>{label}</span>
      {tooltip && <QuestionTooltip text={tooltip} />}
    </div>
  );
};
