import React, { useEffect, useState } from 'react';
import { showNotificationErrorAction } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import JammerSmIcon from '../../components/icons/jammer-sm';
import { UserLogo } from '../../components/userLogo';
import './index.scss';
import { NavLink } from 'react-router-dom';
import { meetingService } from '../../libs/meeting/meeting.service';
import { useParams } from 'react-router';
import { MainLinkResponseModel } from '../../models/meeting.model';
import { setDarkThemeSchedule } from '../../redux/actions/theme';
import { THEMES } from '../../redux/constants/theme';
import { LoaderPage } from '../../components/loader/loaderPage';

const LocationEnum = {
  jammer_conference: 'Conference Line',
  web_conference: 'Web conference',
  phone_call: 'Phone call',
  person: 'Person meeting',
  custom: 'Custom meeting',
};

export const MainLink: React.FC = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<MainLinkResponseModel | null>(null);
  const [loading, setLoading] = useState(true);
  const routeParams = useParams<{
    userSlug: string;
  }>();
  useEffect(() => {
    const fetchMainLink = async () => {
      setLoading(true);
      const response = await meetingService.fetchMainLink(routeParams.userSlug);
      if (response.isRight()) {
        setData(response.value);
        dispatch(
          setDarkThemeSchedule({
            data: response.value.design.isDarkMode ? THEMES.DARK : THEMES.LIGHT,
          }),
        );
        setLoading(false);
      } else {
        dispatch(
          showNotificationErrorAction({
            content: 'Server error. Try later.',
            duration: 5,
          }),
        );
      }
    };
    fetchMainLink().then();
  }, [routeParams, dispatch]);
  return (
    <div
      className="mainLink-container"
      style={
        data?.design.color ? { backgroundColor: data?.design.color } : undefined
      }
    >
      {loading && <LoaderPage />}
      <div className="mainLink-body">
        <div className="mainLink-body-header">
          <div className="logo">
            <UserLogo avatar={data?.design.logo} />
          </div>
          <p>Schedule With {data?.user.name}</p>
        </div>
        <div className="mainLink-body-content">
          {data?.meetings.data && data?.meetings.data?.length > 0 ? (
            <>
              <p className="mainLink-body-content-header">
                Choose Your Meeting
              </p>
              <ul>
                {data?.meetings.data.map((meeting) => (
                  <li key={meeting.slug}>
                    <div className="meeting-header">
                      <p className="meeting-name">{meeting.name}</p>
                      <p className="meeting-description">
                        {meeting.duration} min,{' '}
                        {meeting.location.kind &&
                          LocationEnum[meeting.location.kind]}
                      </p>
                    </div>
                    <NavLink
                      to={routeParams.userSlug + '/' + meeting.slug}
                      className="meeting-book"
                      style={
                        data?.design.color
                          ? { backgroundColor: data?.design.color }
                          : undefined
                      }
                    >
                      Book
                    </NavLink>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <h1 className="noLinks">no active links</h1>
          )}
        </div>
      </div>
      <div className="mainLink-footer">
        Powered By <JammerSmIcon />
      </div>
    </div>
  );
};
