import { LoadableData } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import {
  setLoadedStatusToLoadableData,
  setLoadingStatusToLoadableData,
} from '../utils';
import {
  fetchNotificationAction,
  fetchNotificationSuccessAction,
  updateNotificationAction,
  updateNotificationSuccessAction,
} from '../actions';

export interface NotificationModel {
  guest: {
    emailReminder: boolean;
    textReminder: boolean;
  };
  user: {
    emailReminder: boolean;
    textReminder: boolean;
    textJoinReminder: boolean;
  };
}

export interface NotificationState extends LoadableData {
  data: NotificationModel;
}

const initialNotification: NotificationState = {
  isLoaded: false,
  isLoading: false,
  data: {
    guest: {
      emailReminder: false,
      textReminder: false,
    },
    user: {
      emailReminder: false,
      textReminder: false,
      textJoinReminder: false,
    },
  },
};

export const notificationReducer = createReducer<NotificationState>(
  initialNotification,
  (builder) => {
    builder
      .addCase(fetchNotificationAction, (state) => {
        setLoadingStatusToLoadableData(state);
      })
      .addCase(fetchNotificationSuccessAction, (state, action) => {
        setLoadedStatusToLoadableData(state);
        state.data = action.payload;
      })
      .addCase(updateNotificationAction, (state) => {
        setLoadingStatusToLoadableData(state);
      })
      .addCase(updateNotificationSuccessAction, (state, action) => {
        setLoadedStatusToLoadableData(state);
        state.data = action.payload;
      });
  },
);
