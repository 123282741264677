import { left, right } from '@sweet-monads/either';
import { isAxiosError, HttpException, ErrorCodes } from '../../utils/errors';
import {
  ApproveUserEmailResult,
  ChangeUserCredentialsResult,
  ForgotPasswordResult,
  GetAuthMethodByEmailResultDto,
  GetProfileResultDto,
  LoginByPasswordDto,
  LoginByPasswordResultDto,
  LogoutResultDto,
  ResetForgotPasswordResult,
  SingUpByPasswordDto,
  SingUpByPasswordResultDto,
  SubscriptionResultDto,
} from './dtos/auth.dto';
import {
  ApproveEmailRequestDto,
  ApproveEmailResponseDto,
  ForgotPasswordRequestDto,
  ForgotPasswordResponseDto,
  GetAuthMethodByEmailRequestDto,
  GetAuthMethodByEmailResponseDto,
  GetProfileResponseDto,
  InviteUserInfoResponseDto,
  LoginByPasswordRequestDto,
  LoginByPasswordResponseDto,
  LogoutRequestDto,
  LogoutResponseDto,
  SetNewPasswordRequestDto,
  SetNewPasswordResponseDto,
  SignUpByPasswordRequestDto,
  SignUpByPasswordResponseDto,
  SubscriptionResponseDto,
} from './dtos/api.dto';
import { UnknownError, ValidationFailedError } from '../common/errors';
import {
  AuthApproveTokenNotRegisterError,
  AuthChangeUserPasswordNotMatchConfirmationError,
  AuthForgotPasswordTokenExpiredError,
  AuthForgotPasswordTokenInvalidError,
  AuthTokenExpiredError,
  AuthTokenInvalidError,
  AuthUnapprovedUserEmail,
  InvalidAuthCredentialError,
  InvalidAuthCurrentPasswordError,
  InviteMismatchError,
} from './errors';
import { AccessTokenDto } from './dtos/access-token.dto';
import { UserCredentialsRequestDto } from '../user/dtos/user.api.dto';
import * as _ from 'lodash';
import BaseAxiosInstance from '../../utils/axios-config';
import { InviteLinkDeprecatedError, UserAnotherOrgError } from './errors';

export class AuthApi {
  async loginByPassword(
    props: LoginByPasswordDto,
  ): Promise<LoginByPasswordResultDto> {
    try {
      const dto: LoginByPasswordRequestDto = props;
      const result = await BaseAxiosInstance.post<LoginByPasswordResponseDto>(
        '/api/auth/login/local',
        dto,
      );
      return right({
        token: result.data.token,
        tokenExpiredAt: result.data.tokenExpiredAt,
        user: result.data.user,
      });
    } catch (e) {
      if (!isAxiosError<HttpException>(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }
      switch (e.response.data.code) {
        case ErrorCodes.validationFailed:
          return left(new ValidationFailedError(e.response.data.details, e));
        case ErrorCodes.authInvalidCredential:
          return left(new InvalidAuthCredentialError(e));
        case ErrorCodes.authUnapprovedUserEmail:
          return left(new AuthUnapprovedUserEmail(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async singUpByPassword(
    props: SingUpByPasswordDto,
  ): Promise<SingUpByPasswordResultDto> {
    try {
      const dto: SignUpByPasswordRequestDto = {
        email: props.email,
        name: props.name,
        password: props.password,
      };
      await BaseAxiosInstance.post<SignUpByPasswordResponseDto>(
        '/api/auth/registration/local',
        dto,
      );
      return right(void 0);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }

      switch (e.response.data.code) {
        case ErrorCodes.authEmailConflict:
          return left(
            new ValidationFailedError(
              [
                {
                  path: 'email',
                  code: 'conflict',
                  message: 'This users email already exists',
                },
              ],
              e,
            ),
          );
        case ErrorCodes.validationFailed:
          return left(new ValidationFailedError(e.response.data.details, e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async getAuthMethodByEmail(
    email: string,
  ): Promise<GetAuthMethodByEmailResultDto> {
    try {
      const dto: GetAuthMethodByEmailRequestDto = { email };
      const result = await BaseAxiosInstance.post<
        GetAuthMethodByEmailResponseDto
      >('/api/auth/method', dto);
      return right(result.data.type);
    } catch (e) {
      if (!isAxiosError<HttpException>(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }
      switch (e.response.data.code) {
        case ErrorCodes.validationFailed:
          return left(new ValidationFailedError(e.response.data.details, e));
        case ErrorCodes.authUserEmailIsNotRegister:
          return right(null);
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async getProfile(token: string): Promise<GetProfileResultDto> {
    try {
      const result = await BaseAxiosInstance.get<GetProfileResponseDto>(
        '/api/users/profile',
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return right({ user: result.data });
    } catch (e) {
      if (!isAxiosError<HttpException>(e)) {
        alert('1');
        return left(new UnknownError(e));
      }
      if (!e.response) {
        alert('2');
        return left(new UnknownError(e));
      }
      switch (e.response.data.code) {
        case ErrorCodes.authTokenInvalid:
          return left(new AuthTokenInvalidError(e));
        case ErrorCodes.authTokenExpired:
          return left(new AuthTokenExpiredError(e));
        default: {
          alert('3');
          return left(new UnknownError(e));
        }
      }
    }
  }

  async getSubscriptions(token: string): Promise<SubscriptionResultDto> {
    try {
      const result = await BaseAxiosInstance.get<SubscriptionResponseDto[]>(
        '/api/subscription-plans',
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return right({ subscriptions: result.data });
    } catch (e) {
      if (!isAxiosError<HttpException>(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }
      switch (e.response.data.code) {
        case ErrorCodes.authTokenInvalid:
          return left(new AuthTokenInvalidError(e));
        case ErrorCodes.authTokenExpired:
          return left(new AuthTokenExpiredError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async logout(token: string): Promise<LogoutResultDto> {
    try {
      const dto: LogoutRequestDto = {};
      await BaseAxiosInstance.post<LogoutResponseDto>('/api/auth/logout', dto, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return right(void 0);
    } catch (e) {
      if (!isAxiosError<HttpException>(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }
      switch (e.response.data.code) {
        case ErrorCodes.authTokenInvalid:
          return left(new AuthTokenInvalidError(e));
        case ErrorCodes.authTokenExpired:
          return left(new AuthTokenExpiredError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async forgotPassword(email: string): Promise<ForgotPasswordResult> {
    try {
      const dto: ForgotPasswordRequestDto = { email };
      await BaseAxiosInstance.post<ForgotPasswordResponseDto>(
        '/api/auth/forgot_password',
        dto,
      );
      return right(void 0);
    } catch (e) {
      if (!isAxiosError<HttpException>(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }
      switch (e.response.data.code) {
        case ErrorCodes.authUserEmailIsNotRegister:
          return left(
            new ValidationFailedError(
              [
                {
                  path: 'email',
                  code: 'not-found',
                  message: 'This email does not exist',
                },
              ],
              e,
            ),
          );
        case ErrorCodes.authMismatchAuthMethod:
          return left(
            new ValidationFailedError(
              [
                {
                  path: 'email',
                  code: 'auth-mismatch-auth-method',
                  message: 'User registered by other auth method',
                },
              ],
              e,
            ),
          );
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async changeUserCredentials(
    accessToken: AccessTokenDto,
    dto: UserCredentialsRequestDto,
  ): Promise<ChangeUserCredentialsResult> {
    try {
      const cleanedDto = _.pickBy(dto, _.identity);

      const result = await BaseAxiosInstance.put(
        '/api/auth/credentials',
        cleanedDto,
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );

      return right(result.data);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }

      switch (e.response.data.code) {
        case ErrorCodes.authEmailConflict:
          return left(
            new ValidationFailedError(
              [
                {
                  path: 'email',
                  code: 'conflict',
                  message: 'This users email already exists',
                },
              ],
              e,
            ),
          );
        case ErrorCodes.validationFailed:
          return left(new ValidationFailedError(e.response.data.details, e));
        case ErrorCodes.authTokenInvalid:
          return left(new AuthTokenInvalidError(e));
        case ErrorCodes.authTokenExpired:
          return left(new AuthTokenExpiredError(e));
        case ErrorCodes.authChangeUserPasswordsNotMatchConfirmation:
          return left(new AuthChangeUserPasswordNotMatchConfirmationError(e));
        case ErrorCodes.authInvalidCurrentPassword:
          return left(new InvalidAuthCurrentPasswordError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async resetForgotPassword(params: {
    token: string;
    password: string;
  }): Promise<ResetForgotPasswordResult> {
    try {
      const dto: SetNewPasswordRequestDto = {
        password: params.password,
        token: params.token,
      };
      await BaseAxiosInstance.post<SetNewPasswordResponseDto>(
        '/api/auth/set_forgot_password',
        dto,
      );
      return right(void 0);
    } catch (e) {
      if (!isAxiosError<HttpException>(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }

      switch (e.response.data.code) {
        case ErrorCodes.validationFailed:
          return left(new ValidationFailedError(e.response.data.details, e));
        case ErrorCodes.authForgotPasswordTokenNotRegister:
          return left(new AuthForgotPasswordTokenInvalidError(e));
        case ErrorCodes.authForgotPasswordTokenExpired:
          return left(new AuthForgotPasswordTokenExpiredError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async approveUserEmail(params: {
    token: string;
    password: string;
  }): Promise<ApproveUserEmailResult> {
    try {
      const dto: ApproveEmailRequestDto = {
        token: params.token,
        password: params.password,
      };
      const result = await BaseAxiosInstance.post<ApproveEmailResponseDto>(
        '/api/auth/confirm_email',
        dto,
      );
      return right({
        token: result.data.token,
        user: result.data.user,
        tokenExpiredAt: result.data.tokenExpiredAt,
      });
    } catch (e) {
      if (!isAxiosError<HttpException>(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }

      switch (e.response.data.code) {
        case ErrorCodes.authInvalidCredential:
          return left(new InvalidAuthCredentialError(e));
        case ErrorCodes.authApproveUserEmailNotRegister:
          return left(new AuthApproveTokenNotRegisterError(e));
        case ErrorCodes.validationFailed:
          return left(new ValidationFailedError(e.response.data.details, e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async acceptUser(acceptToken: string): Promise<any> {
    try {
      const result = await BaseAxiosInstance.get<any>(
        `/api/organizations/invite/${acceptToken}/accept`,
      );
      return right(result.status);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }

      switch (e.response.data.code) {
        case ErrorCodes.invitationLinkDeprecated:
          return left(new InviteLinkDeprecatedError(e));
        case ErrorCodes.userAnotherOrgError:
          return left(new UserAnotherOrgError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async inviteCheckUser(inviteToken: string): Promise<any> {
    try {
      const result = await BaseAxiosInstance.get<InviteUserInfoResponseDto>(
        `/api/organizations/invite/${inviteToken}`,
      );
      return right(result.data);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }

      switch (e.response.data.code) {
        case ErrorCodes.inviteMismatch:
          return left(new InviteMismatchError(e));
        case ErrorCodes.invitationLinkDeprecated:
          return left(new InviteLinkDeprecatedError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }
}
