import { createAction } from '@reduxjs/toolkit';
import * as constants from '../constants/timezone';
import { TimezoneModel } from '../../models/timezone.model';

export const loadTimezonesAction = createAction(
  constants.LOAD_TIMEZONES.REQUEST,
);
interface MoveToNextProfileWizardStepParamsSuccess {
  data: TimezoneModel[];
}
export const loadTimezonesSuccessAction = createAction<
  MoveToNextProfileWizardStepParamsSuccess
>(constants.LOAD_TIMEZONES.SUCCESS);
export const loadTimezonesErrorAction = createAction(
  constants.LOAD_TIMEZONES.ERROR,
);
