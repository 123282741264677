import { DomainError } from '../../common/errors';
import { ExceptionDetails } from '../../../utils/errors';

export class InvalidAuthCredentialError implements DomainError {
  code = 'authInvalidCredential' as const;
  message = 'Invalid credentials';
  details: ExceptionDetails = [
    {
      message: 'Invalid credential',
      code: 'invalid.credential',
      path: 'password',
    },
  ];
  constructor(public originalError?: Error) {}
}

export class InvalidAuthCurrentPasswordError implements DomainError {
  code = 'authInvalidCurrentPassword' as const;
  message = 'Invalid credentials';
  details: ExceptionDetails = [
    {
      message: 'Invalid password',
      code: 'invalid.credential',
      path: 'currentPassword',
    },
  ];
  constructor(public originalError?: Error) {}
}

export class AuthTokenExpiredError implements DomainError {
  code = 'authTokenExpired' as const;
  message = 'Your session has expired. please log in';
  constructor(public originalError?: Error) {}
}

export class AuthChangeUserPasswordNotMatchConfirmationError
  implements DomainError {
  code = 'authChangeUserPasswordNotMatchConfirmation' as const;
  message = 'Password in these fields as the different';
  details: ExceptionDetails = [
    {
      message: 'Password in these fields as the different',
      code: 'invalid.credential',
      path: 'password',
    },
  ];
  constructor(public originalError?: Error) {}
}

export class AuthTokenInvalidError implements DomainError {
  code = 'authTokenInvalid' as const;
  message = '';
  constructor(public originalError?: Error) {}
}

export class AuthForgotPasswordTokenInvalidError implements DomainError {
  code = 'authForgotPasswordTokenInvalid' as const;
  message = 'Invalid link to reset password';
  constructor(public originalError?: Error) {}
}

export class AuthForgotPasswordTokenExpiredError implements DomainError {
  code = 'authForgotPasswordTokenExpired' as const;
  message = 'Your link to reset password has expired';
  constructor(public originalError?: Error) {}
}

export class AuthApproveTokenNotRegisterError implements DomainError {
  code = 'authApproveTokenNotRegister' as const;
  message = 'Your link to approve email is invalid';
  constructor(public originalError?: Error) {}
}

export class AuthUnapprovedUserEmail implements DomainError {
  code = 'authUnapprovedUserEmail' as const;
  message = '';
  constructor(public originalError?: Error) {}
}

export class InviteLinkDeprecatedError implements DomainError {
  code = 'inviteDeprecatedError' as const;
  message = 'This invitation link is deprecated';
  constructor(public originalError?: Error) {}
}

export class UserAnotherOrgError implements DomainError {
  code = 'userAnotherOrgError' as const;
  message =
    'You can not accept this invite, You are a member of other organization';
  constructor(public originalError?: Error) {}
}

export class InviteMismatchError implements DomainError {
  code = 'inviteMismatchError' as const;
  message = 'Invite mismatch';
  constructor(public originalError?: Error) {}
}
