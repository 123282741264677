import React, { DependencyList, useEffect, useRef, useState } from 'react';
import { noop } from 'lodash';

export const useInterval = (callback: () => void, delay: number): void => {
  const savedCallback = useRef(noop);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return undefined;
  }, [delay]);
};

export const useStateWithPropsUpdate = <T>(
  propsValue: T,
  deps?: DependencyList,
): [T, React.Dispatch<React.SetStateAction<T>>, Date | null] => {
  const [value, setValue] = useState(propsValue);
  const [modifiedAt, setModifiedAt] = useState<Date | null>(null);
  useEffect(() => {
    setValue(propsValue);
    setModifiedAt(null);
    // eslint-disable-next-line
  }, [propsValue, setValue, ...(deps || [])]);
  const setValueWrapper: React.Dispatch<React.SetStateAction<T>> = (value) => {
    setValue(value);
    setModifiedAt(new Date());
  };
  return [value, setValueWrapper, modifiedAt];
};
