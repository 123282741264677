import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  useMeetingToBookInfoSelector,
  useScheduleMeetingEventSelector,
} from '../../redux/selectors/meetings';
import {
  fetchMeetingByPublicLinkAction,
  scheduleMeetingBookEventAction,
} from '../../redux/actions';
import { CompleteSchedule } from './completeSchedule/complete';
import { SelectScheduleTime } from './selectTime';
import JammerSmIcon from '../../components/icons/jammer-sm';
import { NavLink } from 'react-router-dom';
import { routers } from '../../router';
import './index.scss';
import { ConfirmedSchedule } from './confirmed/confirmed';
import { LoaderPage } from '../../components/loader/loaderPage';

export interface EventData {
  date: string;
  timezone: string;
  isFilled: boolean;
}

export interface ScheduleDto {
  name: string;
  email: string;
  guests?: string[];
  phone: string;
}

export const SchedulePage: React.FC = () => {
  const meetingToBookInfo = useMeetingToBookInfoSelector();
  const scheduleMeeting = useScheduleMeetingEventSelector();
  const [eventData, setEventData] = useState<EventData | undefined>();
  const dispatch = useDispatch();
  const routeParams = useParams<{
    userSlug: string;
    meetingSlug: string;
  }>();
  // fetches meeting info
  useEffect(() => {
    if (routeParams.userSlug && routeParams.meetingSlug) {
      dispatch(
        fetchMeetingByPublicLinkAction({
          meetingSlug: routeParams.meetingSlug,
          userSlug: routeParams.userSlug,
        }),
      );
      return;
    }
  }, [routeParams.userSlug, routeParams.meetingSlug, dispatch]);

  const setScheduleTime = (value: EventData) => {
    setEventData(value);
  };

  const scheduleHandler = (e: any) => {
    dispatch(
      scheduleMeetingBookEventAction({
        ...e,
        meetingId: meetingToBookInfo.meeting?.id,
        eventStartDate: eventData?.date,
        timezone: eventData?.timezone,
      }),
    );
  };

  return (
    <>
      {scheduleMeeting.isLoading && <LoaderPage />}
      {meetingToBookInfo.isLoaded && (
        <div
          className="schedule-container"
          style={
            meetingToBookInfo.meeting?.design.color
              ? { backgroundColor: meetingToBookInfo.meeting?.design.color }
              : undefined
          }
        >
          <div className="schedule-body">
            {!!scheduleMeeting.eventId && eventData ? (
              <ConfirmedSchedule
                eventData={eventData}
                meetingBookInfo={meetingToBookInfo.meeting}
              />
            ) : eventData?.isFilled &&
              eventData.date &&
              meetingToBookInfo.meeting ? (
              <CompleteSchedule
                eventData={eventData}
                setEventsData={setScheduleTime}
                meetingBookInfo={meetingToBookInfo.meeting}
                scheduleHandler={scheduleHandler}
              />
            ) : (
              <>
                {meetingToBookInfo.meeting ? (
                  <SelectScheduleTime
                    eventData={eventData}
                    viewType={meetingToBookInfo.meeting?.design.calendarStyle}
                    setEventsData={setScheduleTime}
                    meetingBookInfo={meetingToBookInfo.meeting}
                  />
                ) : null}
              </>
            )}
          </div>
          <NavLink to={routers.LANDING_PAGE} className="schedule-footer">
            <span>Powered By</span>
            <JammerSmIcon />
          </NavLink>
        </div>
      )}
    </>
  );
};
