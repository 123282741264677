import React from 'react';

const JammerSmIcon: React.FC<{ className?: string }> = (props) => {
  return (
    <svg
      className={`${props.className || ''}`}
      width="98"
      height="14"
      viewBox="0 0 98 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.37368 8.47366C6.35334 8.47366 6.33685 8.4904 6.33673 8.51075C6.3301 9.59538 6.02804 10.3158 4.78947 10.3158C3.24023 10.3158 3.21787 9.12818 3.23613 7.94204C3.24038 7.66593 3.01825 7.44208 2.74211 7.44208H0.500001C0.223858 7.44208 0 7.66594 0 7.94208V8.54734C0 11.4947 1.8421 13.4842 4.86316 13.4842C7.88421 13.4842 9.65263 11.5684 9.65263 8.54734V1.08945C9.65263 0.813304 9.42877 0.589447 9.15263 0.589447H6.91053C6.63439 0.589447 6.41053 0.813305 6.41053 1.08945V8.43682C6.41053 8.45716 6.39403 8.47366 6.37368 8.47366Z"
        fill="#231F20"
      />
      <path
        d="M19.1268 0.589447C18.9229 0.589447 18.7395 0.713262 18.6632 0.90235L13.9824 12.5023C13.8498 12.831 14.0917 13.1894 14.4461 13.1894H16.8906C17.1015 13.1894 17.2897 13.0572 17.3611 12.8588L17.7862 11.678C17.8576 11.4796 18.0458 11.3473 18.2566 11.3473H22.4906C22.7015 11.3473 22.8897 11.4796 22.9611 11.678L23.3862 12.8588C23.4576 13.0572 23.6458 13.1894 23.8566 13.1894H26.3051C26.6584 13.1894 26.9003 12.833 26.7697 12.5047L22.1569 0.904689C22.0812 0.714364 21.8971 0.589447 21.6923 0.589447H19.1268ZM19.8143 8.39997C19.4628 8.39997 19.2211 8.04691 19.3481 7.71921L20.0053 6.02431C20.172 5.59457 20.7819 5.60002 20.9408 6.03266L21.5635 7.72756C21.6833 8.05392 21.4418 8.39997 21.0941 8.39997H19.8143Z"
        fill="#231F20"
      />
      <path
        d="M39.1396 7.54376C38.9621 7.93498 38.4064 7.93498 38.2289 7.54376L35.2068 0.882864C35.1258 0.704195 34.9477 0.589447 34.7515 0.589447H32.2579C31.9818 0.589447 31.7579 0.813305 31.7579 1.08945V12.6894C31.7579 12.9656 31.9818 13.1894 32.2579 13.1894H34.5737C34.8499 13.1894 35.0737 12.9656 35.0737 12.6894V9.52128C35.0737 8.98771 35.7976 8.82516 36.0257 9.30749L37.5173 12.4611C37.6 12.6359 37.776 12.7473 37.9693 12.7473H39.4729C39.6662 12.7473 39.8422 12.6359 39.9249 12.4611L41.4165 9.30749C41.6446 8.82516 42.3685 8.98771 42.3685 9.52127V12.6894C42.3685 12.9656 42.5923 13.1894 42.8685 13.1894H45.1106C45.3867 13.1894 45.6106 12.9656 45.6106 12.6894V1.08945C45.6106 0.813304 45.3867 0.589447 45.1106 0.589447H42.617C42.4208 0.589447 42.2427 0.704194 42.1617 0.882863L39.1396 7.54376Z"
        fill="#231F20"
      />
      <path
        d="M58.5922 7.54376C58.4147 7.93498 57.8591 7.93498 57.6816 7.54376L54.6595 0.882864C54.5784 0.704195 54.4004 0.589447 54.2042 0.589447H51.7106C51.4344 0.589447 51.2106 0.813305 51.2106 1.08945V12.6894C51.2106 12.9656 51.4344 13.1894 51.7106 13.1894H53.9527C54.2288 13.1894 54.4527 12.9656 54.4527 12.6894V9.52128C54.4527 8.98771 55.1765 8.82516 55.4047 9.30749L56.8963 12.4611C56.9789 12.6359 57.1549 12.7473 57.3482 12.7473H58.9255C59.1189 12.7473 59.2949 12.6359 59.3775 12.4611L60.8691 9.30749C61.0972 8.82516 61.8211 8.98771 61.8211 9.52127V12.6894C61.8211 12.9656 62.045 13.1894 62.3211 13.1894H64.5632C64.8394 13.1894 65.0632 12.9656 65.0632 12.6894V1.08945C65.0632 0.813304 64.8394 0.589447 64.5632 0.589447H62.0696C61.8734 0.589447 61.6954 0.704194 61.6143 0.882863L58.5922 7.54376Z"
        fill="#231F20"
      />
      <path
        d="M70.6632 12.6894C70.6632 12.9656 70.8871 13.1894 71.1632 13.1894H79.8895C80.1657 13.1894 80.3895 12.9656 80.3895 12.6894V10.521C80.3895 10.2449 80.1657 10.021 79.8895 10.021H74.4053C74.1292 10.021 73.9053 9.79717 73.9053 9.52102V8.97366C73.9053 8.69751 74.1292 8.47366 74.4053 8.47366H78.7106C78.9867 8.47366 79.2106 8.2498 79.2106 7.97366V6.02629C79.2106 5.75015 78.9867 5.52629 78.7106 5.52629H74.4053C74.1292 5.52629 73.9053 5.30243 73.9053 5.02629V4.25787C73.9053 3.98172 74.1292 3.75787 74.4053 3.75787H79.8895C80.1657 3.75787 80.3895 3.53401 80.3895 3.25787V1.08945C80.3895 0.813305 80.1657 0.589447 79.8895 0.589447H71.1632C70.8871 0.589447 70.6632 0.813305 70.6632 1.08945V12.6894Z"
        fill="#231F20"
      />
      <path
        d="M94.4523 9.86039C94.268 9.60149 94.371 9.23983 94.6408 9.07188C95.964 8.24796 96.6 6.78965 96.6 5.15788C96.6 2.2842 94.7579 0.515778 91.8842 0.515778H86.1947C85.9186 0.515778 85.6947 0.739635 85.6947 1.01578V12.6895C85.6947 12.9656 85.9186 13.1895 86.1947 13.1895H88.4368C88.7129 13.1895 88.9368 12.9656 88.9368 12.6895V10.3C88.9368 10.0238 89.1607 9.79999 89.4368 9.79999H90.2966C90.4603 9.79999 90.6136 9.88009 90.707 10.0145L92.7666 12.975C92.86 13.1094 93.0133 13.1895 93.177 13.1895H95.8516C96.2579 13.1895 96.4945 12.7306 96.259 12.3996L94.4523 9.86039ZM88.8631 4.03683C88.8631 3.76069 89.087 3.53683 89.3631 3.53683H91.5894C92.6947 3.53683 93.3579 4.05262 93.3579 5.23157C93.3579 6.33683 92.621 6.85262 91.5894 6.85262H89.3631C89.087 6.85262 88.8631 6.62876 88.8631 6.35262V4.03683Z"
        fill="#231F20"
      />
    </svg>
  );
};

export default JammerSmIcon;
