import React, { useCallback, useRef, useState } from 'react';
import './guest-field.scss';
import { CloseCrossIcon } from '../icons/closeCross';

type State = {
  items: string[] | undefined;
  value: string;
  error: string | null;
};

interface GuestFieldProps {
  color?: string | null;
  onChange?(value: string[] | undefined): void;
  value?: string[];
  setGuestValidation(value: string | null): void;
}

export const EmailAndGuestField: React.FC<GuestFieldProps> = (props) => {
  const [inputSize, setInputSize] = useState(1);
  const inputRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState<State>({
    items: [],
    value: '',
    error: null,
  });

  const isInList = useCallback(
    (email: string) => {
      return state.items?.includes(email);
    },
    [state.items],
  );

  const isEmail = (email: string) => {
    // eslint-disable-next-line
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  const isValid = useCallback(
    (email: string) => {
      let error: string | null = null;

      if (isInList(email)) {
        error = `${email} has already been added.`;
      }

      if (!isEmail(email)) {
        error = `${email} is not a valid email address.`;
      }

      if (error) {
        setState({
          ...state,
          error,
        });
        props.setGuestValidation(error);
        return false;
      }
      setState({
        ...state,
        error: null,
      });
      props.setGuestValidation(null);
      return true;
    },
    // eslint-disable-next-line
    [state, isInList],
  );

  const setValue = useCallback(
    (evt: any) => {
      const value = evt.target.value.trim();

      if (value && isValid(value)) {
        const items = props.value ? [...props.value, value] : [value];
        props.onChange && props.onChange(items);
        setState({ value: '', items, error: null });
        setInputSize(1);
      }
    },
    [props, isValid],
  );

  const handleKeyDown = useCallback(
    (evt: any) => {
      if ([32, 9, 188, 13].includes(evt.keyCode)) {
        evt.preventDefault();
        setValue(evt);
      }
    },
    [setValue],
  );

  const handleBlur = useCallback(
    (evt: any) => {
      evt.preventDefault();
      setValue(evt);
    },
    [setValue],
  );

  const handleChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      evt.persist();
      isValid(evt.target.value);
      setInputSize(evt.target.value.length);
      setState({ ...state, value: evt.target.value });
      if (evt.target.value.length === 0) {
        setState({ ...state, value: '', error: null });
      }
    },
    // eslint-disable-next-line
    [state],
  );

  const handleDelete = useCallback(
    (item: any) => {
      const items = props.value?.filter((i) => i !== item);
      if (props.onChange && item) {
        props.onChange(items);
        setState({ ...state, items, error: null });
      }
    },
    // eslint-disable-next-line
    [state],
  );

  const handlePaste = useCallback(
    (evt: any) => {
      evt.preventDefault();

      const paste = evt.clipboardData.getData('text');
      // eslint-disable-next-line

      if (isValid(paste)) {
        const items = props.value ? [...props.value, paste] : [paste];
        if (paste && props.onChange) {
          props.onChange && props.onChange(items);
          setState({
            value: '',
            items,
            error: null,
          });
        }
      }
    },
    // eslint-disable-next-line
    [state],
  );

  return (
    <>
      <div
        className="book-guests-field"
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        onClick={() => inputRef.current!.focus()}
      >
        {props.value?.map((item) => (
          <div
            className="guest-item"
            key={item}
            style={props.color ? { backgroundColor: props.color } : undefined}
          >
            {item}
            <button
              type="button"
              className="close-button"
              style={props.color ? { backgroundColor: props.color } : undefined}
              onClick={() => handleDelete(item)}
            >
              <CloseCrossIcon />
            </button>
          </div>
        ))}

        <input
          className={'input ' + (state.error && ' has-error')}
          value={state.value}
          size={inputSize}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onPaste={handlePaste}
          onBlur={handleBlur}
          ref={inputRef}
        />
      </div>
      {state.error && <p className="error">{state.error}</p>}
    </>
  );
};
