import React from 'react';
import './index.scss';
import { Button } from '../../../../../components/button';

interface CompleteProfileFinishProps {
  next: React.MouseEventHandler<HTMLElement>;
}

export const CompleteProfileFinish: React.FC<CompleteProfileFinishProps> = (
  props,
) => {
  const { next } = props;
  const itemList = [
    'Share your scheduling link with anyone you want to meet',
    'Guests pick a time and the meeting is added to your calendar',
    'Reminders are sent right before the meeting to join in one-click',
  ];
  return (
    <div className="complete-profile-container">
      <div className="complete-profile-header-finish">How It Works</div>
      <ul className="complete-profile-finish-list">
        {itemList.map((item, index) => {
          return (
            <li className="complete-profile-list-item" key={index}>
              <div className="complete-profile-list-index">{++index}</div>
              <div className="complete-profile-list-post">{item}</div>
            </li>
          );
        })}
      </ul>
      <Button onClick={next}>Continue</Button>
    </div>
  );
};
