export enum DaysOfWeek {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

export const arrayOfDaysOfWeek = [
  DaysOfWeek.sunday,
  DaysOfWeek.monday,
  DaysOfWeek.tuesday,
  DaysOfWeek.wednesday,
  DaysOfWeek.thursday,
  DaysOfWeek.friday,
  DaysOfWeek.saturday,
];
