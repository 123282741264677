import React from 'react';
import { NavLink } from 'react-router-dom';
import './index.scss';
import { routers } from '../../router';

interface props {
  days: number;
  subscription: string;
}

export const TrialEndBadge: React.FC<props> = (props) => {
  return (
    <div className="badge-trial-end-container">
      {props.days > 0 ? (
        <div className="trial-content">
          <div className="trial-title">
            Your {props.subscription} Will End In
          </div>
          <div className="trial-days">{props.days}</div>
        </div>
      ) : (
        <div className="trial-content">
          <div className="trial-title">Your {props.subscription} Has Ended</div>
        </div>
      )}
      <NavLink className="trial-button" to={routers.BILLING}>
        Upgrade Account
      </NavLink>
    </div>
  );
};
