import React, { useCallback, useState, useEffect } from 'react';
import { EventData } from '../index';
import { CalendarStyleType } from '../../design';
import { MonthView } from './monthView';
import { WeekView } from './weekView';
import { MeetingToBookEventModel } from '../../../models/meeting.model';
import { useDispatch } from 'react-redux';
import { useLoadTimeZone } from '../../../hooks/timezone';
import { fetchMeetingSlotsToBookEventAction } from '../../../redux/actions';
import {
  plusDurationToDate,
  formantDate,
  TimeFormats,
} from '../../../libs/common/utils';
import { useTimezoneInfoSelector } from '../../../redux/selectors/timezone';
import { useMeetingSlotsToBookEventInfoSelector } from '../../../redux/selectors/meetings';
import { Loader } from '../../../components/loader';
import './index.scss';
import { UserLogo } from '../../../components/userLogo';
import { TimezoneSelector } from '../../../components/select/timezone';
import EarthIcon from '../../../components/icons/earth';
import { ClockIcon } from '../../../components/icons/clock/clockIcon';
import { LocationScheduleIcon } from '../../../components/icons/locationMarkerSchedule/locationSchedule';
import { BookingRangeInfo } from '../../../redux/reducers/booking-meeting-event';
import moment, { Moment } from 'moment-timezone';

const LocationEnum = {
  jammer_conference: 'Conference Line',
  web_conference: 'Web conference',
  phone_call: 'Phone call',
  person: 'Person meeting',
  custom: 'Custom meeting',
};

interface props {
  eventData?: EventData;
  viewType: CalendarStyleType;
  setEventsData(value: EventData): void;
  meetingBookInfo: MeetingToBookEventModel | null;
}

export const SelectScheduleTime: React.FC<props> = (props) => {
  useLoadTimeZone();
  const { meetingBookInfo } = props;
  const range = useMeetingSlotsToBookEventInfoSelector();
  const dispatch = useDispatch();
  const timezones = useTimezoneInfoSelector();
  const [timezoneValue, setTimezoneValue] = useState('UTC');

  const selectEventTimeHandler = (value: string) => {
    props.setEventsData({
      date: value,
      timezone: timezoneValue,
      isFilled: true,
    });
  };

  const onChangeTimeZoneHandler = useCallback(
    (tz: string) => {
      const dateObject = new Date();
      const month = formantDate(dateObject, TimeFormats.YYYY_MM);
      if (!meetingBookInfo) {
        return;
      }

      dispatch(
        fetchMeetingSlotsToBookEventAction({
          meetingId: meetingBookInfo.id,
          timezone: tz,
          rangeFrom: new Date().toUTCString(),
          rangeTo: plusDurationToDate(new Date(month), {
            months: 2,
          }).toString(),
        }),
      );
      setTimezoneValue(tz);
    },
    [meetingBookInfo, dispatch],
  );

  useEffect(() => {
    const toArray = Object.values(timezones.data);
    if (toArray.length > 0) {
      const clientOffset = new Date().getTimezoneOffset() * -60;
      const clientCountry = moment.tz.guess().split('/')[0];
      const timeZone = toArray.filter(
        (item) =>
          item.standardOffset === +clientOffset && item.group === clientCountry,
      );
      if (timeZone[0]) {
        onChangeTimeZoneHandler(timeZone[0].identifier);
      }
    }
  }, [timezones, onChangeTimeZoneHandler]);

  const getTimes = useCallback(
    (
      day: Moment | undefined,
      bookingRange: BookingRangeInfo,
    ): string[] | undefined => {
      if (!day || !bookingRange) {
        return undefined;
      }
      const result = bookingRange.dates.find((item) =>
        moment(item.date).isSame(day, 'day'),
      );
      if (result) {
        const filter = result.slots.filter(
          (item) => item.status === 'available',
        );
        return filter.map((item) => item.startDateTime);
      }
      return undefined;
    },
    [],
  );
  return (
    <div className="selectTime-body">
      <div className="selectTime-header">
        <UserLogo avatar={props.meetingBookInfo?.design.logo} />
        <div className="selectTime-header-username">
          Schedule With {meetingBookInfo?.owner.name}
        </div>
        <div className="selectTime-header-settings">
          <div className="settings-duration">
            <ClockIcon />
            {meetingBookInfo?.duration} Minutes
          </div>
          <div className="settings-location">
            <LocationScheduleIcon />
            {meetingBookInfo?.location.kind &&
              LocationEnum[meetingBookInfo?.location.kind]}
          </div>
          <div className="settings-timezoneSelect">
            <TimezoneSelector
              value={timezoneValue}
              wrapperClassName="timezone-selector-wrapper"
              className="timezone-selector"
              dropdownClassName="timezone-selector-dropdown"
              onChange={onChangeTimeZoneHandler}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              getPopupContainer={() =>
                document.getElementsByTagName('section')[0]!
              }
              icon={<EarthIcon className="timezone-selector-icon" />}
            />
          </div>
        </div>
      </div>
      <div className="selectTime-body-content">
        <div className="selectTime-body-content-header">
          Choose Your Day & Time
        </div>
      </div>

      {!range.isLoaded ? (
        <Loader />
      ) : props.viewType === CalendarStyleType.month ? (
        <MonthView
          color={meetingBookInfo?.design.color}
          eventData={props.eventData}
          range={range}
          timezoneValue={timezoneValue}
          getTimes={getTimes}
          selectEventTimeHandler={selectEventTimeHandler}
        />
      ) : (
        <WeekView
          color={meetingBookInfo?.design.color}
          eventData={props.eventData}
          range={range}
          timezoneValue={timezoneValue}
          getTimes={getTimes}
          selectEventTimeHandler={selectEventTimeHandler}
        />
      )}
    </div>
  );
};
