import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { loadTimezonesAction } from '../redux/actions';
import { useTimezoneLoadingInfoSelector } from '../redux/selectors/timezone';

export const useLoadTimeZone = (): void => {
  const dispatch = useDispatch();
  const timezonesInfo = useTimezoneLoadingInfoSelector();
  useEffect(() => {
    if (timezonesInfo.isLoading || timezonesInfo.isLoaded) {
      return;
    }
    dispatch(loadTimezonesAction());
    // eslint-disable-next-line
  }, []);
};
