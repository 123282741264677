import React from 'react';

const JammerIcon: React.FC = () => {
  return (
    <svg
      width="133"
      height="19"
      viewBox="0 0 133 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M9.15 11.5C9.12239 11.5 9.10001 11.5228 9.09985 11.5504C9.09085 13.0224 8.68091 14 7 14C4.81967 14 4.8677 12.2669 4.89476 10.6C4.89924 10.3239 4.67614 10.1 4.4 10.1H1C0.723858 10.1 0.5 10.3239 0.5 10.6V11.6C0.5 15.6 3 18.3 7.1 18.3C11.2 18.3 13.6 15.7 13.6 11.6V1.30005C13.6 1.02391 13.3761 0.800049 13.1 0.800049H9.7C9.42386 0.800049 9.2 1.02391 9.2 1.30005V11.45C9.2 11.4777 9.17761 11.5 9.15 11.5Z"
          fill="white"
        />
        <path
          d="M26.3374 0.800049C26.1335 0.800049 25.95 0.923864 25.8737 1.11295L19.3772 17.213C19.2446 17.5416 19.4865 17.9 19.8409 17.9H23.5486C23.7594 17.9 23.9476 17.7678 24.019 17.5694L24.6809 15.7307C24.7524 15.5323 24.9405 15.4 25.1514 15.4H31.1486C31.3594 15.4 31.5476 15.5323 31.619 15.7307L32.2809 17.5694C32.3524 17.7678 32.5405 17.9 32.7514 17.9H36.4631C36.8164 17.9 37.0582 17.5436 36.9277 17.2153L30.5253 1.11529C30.4497 0.924966 30.2655 0.800049 30.0607 0.800049H26.3374ZM27.1301 11.4C26.7787 11.4 26.5369 11.047 26.6639 10.7193L27.8212 7.73491C27.9878 7.30517 28.5977 7.31062 28.7567 7.74327L29.853 10.7276C29.9729 11.054 29.7313 11.4 29.3836 11.4H27.1301Z"
          fill="white"
        />
        <path
          d="M53.4553 10.5965C53.2778 10.9877 52.7221 10.9877 52.5446 10.5965L48.2331 1.09347C48.152 0.914797 47.974 0.800049 47.7778 0.800049H44.1C43.8238 0.800049 43.6 1.02391 43.6 1.30005V17.4C43.6 17.6762 43.8238 17.9 44.1 17.9H47.6C47.8761 17.9 48.1 17.6762 48.1 17.4V12.1266C48.1 11.5931 48.8238 11.4305 49.052 11.9128L51.4646 17.0138C51.5473 17.1886 51.7233 17.3 51.9166 17.3H54.1834C54.3767 17.3 54.5527 17.1886 54.6354 17.0138L57.048 11.9128C57.2761 11.4305 58 11.5931 58 12.1266V17.4C58 17.6762 58.2238 17.9 58.5 17.9H61.9C62.1761 17.9 62.4 17.6762 62.4 17.4V1.30005C62.4 1.02391 62.1761 0.800049 61.9 0.800049H58.2222C58.026 0.800049 57.8479 0.914796 57.7669 1.09346L53.4553 10.5965Z"
          fill="white"
        />
        <path
          d="M79.8553 10.5965C79.6778 10.9877 79.1222 10.9877 78.9447 10.5965L74.6331 1.09347C74.5521 0.914797 74.374 0.800049 74.1778 0.800049H70.5C70.2239 0.800049 70 1.02391 70 1.30005V17.4C70 17.6762 70.2239 17.9 70.5 17.9H73.9C74.1761 17.9 74.4 17.6762 74.4 17.4V12.1266C74.4 11.5931 75.1239 11.4305 75.352 11.9128L77.7646 17.0138C77.8473 17.1886 78.0233 17.3 78.2166 17.3H80.5834C80.7767 17.3 80.9527 17.1886 81.0354 17.0138L83.448 11.9128C83.6761 11.4305 84.4 11.5931 84.4 12.1266V17.4C84.4 17.6762 84.6239 17.9 84.9 17.9H88.3C88.5761 17.9 88.8 17.6762 88.8 17.4V1.30005C88.8 1.02391 88.5761 0.800049 88.3 0.800049H84.6222C84.426 0.800049 84.2479 0.914796 84.1669 1.09346L79.8553 10.5965Z"
          fill="white"
        />
        <path
          d="M96.4 17.4C96.4 17.6762 96.6239 17.9 96.9 17.9H109.1C109.376 17.9 109.6 17.6762 109.6 17.4V14.1C109.6 13.8239 109.376 13.6 109.1 13.6H101.3C101.024 13.6 100.8 13.3762 100.8 13.1V12C100.8 11.7239 101.024 11.5 101.3 11.5H107.5C107.776 11.5 108 11.2762 108 11V8.00005C108 7.72391 107.776 7.50005 107.5 7.50005H101.3C101.024 7.50005 100.8 7.27619 100.8 7.00005V5.60005C100.8 5.32391 101.024 5.10005 101.3 5.10005H109.1C109.376 5.10005 109.6 4.87619 109.6 4.60005V1.30005C109.6 1.02391 109.376 0.800049 109.1 0.800049H96.9C96.6239 0.800049 96.4 1.02391 96.4 1.30005V17.4Z"
          fill="white"
        />
        <path
          d="M128.558 13.2025C128.373 12.9436 128.476 12.5815 128.752 12.4245C130.676 11.3299 131.6 9.29091 131.6 6.99995C131.6 3.09995 129.1 0.699951 125.2 0.699951H117.3C117.024 0.699951 116.8 0.923809 116.8 1.19995V17.4C116.8 17.6761 117.024 17.9 117.3 17.9H120.7C120.976 17.9 121.2 17.6761 121.2 17.4V13.7999C121.2 13.5238 121.424 13.3 121.7 13.3H123.139C123.302 13.3 123.456 13.3801 123.549 13.5144L126.451 17.6855C126.544 17.8198 126.698 17.9 126.861 17.9H130.931C131.337 17.9 131.574 17.4411 131.338 17.1101L128.558 13.2025ZM121.1 5.29995C121.1 5.02381 121.324 4.79995 121.6 4.79995H124.8C126.3 4.79995 127.2 5.49995 127.2 7.09995C127.2 8.59995 126.2 9.29995 124.8 9.29995H121.6C121.324 9.29995 121.1 9.07609 121.1 8.79995V5.29995Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="132"
            height="19"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default JammerIcon;
