import * as constants from '../constants/profile';
import {
  createAction,
  PayloadAction,
  ActionCreatorWithPayload,
} from '@reduxjs/toolkit';
import { UserAvailableTimeModel } from '../../models/user-available-time.model';
import {
  ChangeUserCredentialsModel,
  ChangeUserEmailModel,
  UserModel,
} from '../../models/user.model';
import { ExceptionDetails } from '../../utils/errors';
import { CompleteProfileStepEnum } from '../../libs/user/utils';
import { actionBuilder } from '../utils';
import { showModalDialogAction } from './modal';
import { ModalTypes } from '../interfaces/modal';
import {
  CardInfoDto,
  ChargeStripe,
  UserCredentialsRequestDto,
} from '../../libs/user/dtos/user.api.dto';
import * as ActionTypes from '../constants';
import { DaysOfWeek } from '../../models/days-of-week.model';

export interface ShowDeleteUserAccountModalDialog {
  onSubmit(): void;
}
export const showDeleteUserAccountModalDialogAction = (
  props: ShowDeleteUserAccountModalDialog,
): PayloadAction<unknown> => {
  return showModalDialogAction({
    payload: props,
    type: ModalTypes.deleteUserAccount,
  });
};

export const deleteUserAccountAction = createAction(
  constants.DELETE_ACCOUNT.REQUEST,
);

interface DeleteUserAccountParamsError {
  details: ExceptionDetails;
}
export const deleteUserAccountErrorAction = createAction<
  DeleteUserAccountParamsError
>(constants.DELETE_ACCOUNT.ERROR);

export interface ShowChangeUserCredentialsModalDialog {
  onChangeUserCredentials(dto: UserCredentialsRequestDto): void;
}

export const showChangeUserEmailModalDialogAction = (
  props: ShowChangeUserCredentialsModalDialog,
): PayloadAction<unknown> => {
  return showModalDialogAction({
    payload: props,
    type: ModalTypes.changeUserEmail,
  });
};

export const fireLoginForGoogleCalendarMessageAction = createAction<string>(
  constants.LOGIN_FOR_GOOGLE_CALENDAR_MESSAGE.SUCCESS,
);

export const fireLoginGoogleCallbackAction = createAction<string>(
  constants.LOGIN_GOOGLE_CALLBACK.REQUEST,
);

export type ChangeUserCredentialsParams = ChangeUserCredentialsModel;
type ChangeUserCredentialsParamsSuccess = ChangeUserCredentialsModel;
export type ChangeUserEmailSuccessParams = ChangeUserEmailModel & {
  flow: 'password' | 'email';
};

interface ChangeUserCredentialsParamsError {
  details: ExceptionDetails;
}

export const {
  request: changeUserEmailAction,
  success: changeUserEmailSuccessAction,
  error: changeUserEmailErrorAction,
} = actionBuilder<
  ChangeUserCredentialsParams,
  ChangeUserEmailSuccessParams,
  ChangeUserCredentialsParamsError
>(constants.CHANGE_USER_EMAIL);

export const {
  request: changeUserPasswordAction,
  success: changeUserPasswordSuccessAction,
  error: changeUserPasswordErrorAction,
} = actionBuilder<
  ChangeUserCredentialsParams,
  ChangeUserCredentialsParamsSuccess,
  ChangeUserCredentialsParamsError
>(constants.CHANGE_USER_PASSWORD);

export interface MoveToNextProfileWizardStepParams {
  slug?: string;
  timezone?: string;
  availableTime?: UserAvailableTimeModel;
  phone?: string;
}
export const moveToNextProfileWizardStepAction = createAction<
  MoveToNextProfileWizardStepParams
>(constants.MOVE_TO_NEXT_PROFILE_WIZARD_STEP.REQUEST);
interface MoveToNextProfileWizardStepParamsSuccess {
  updatedUser: UserModel | null;
  nextStep: CompleteProfileStepEnum | null;
}
export const moveToNextProfileWizardStepSuccessAction = createAction<
  MoveToNextProfileWizardStepParamsSuccess
>(constants.MOVE_TO_NEXT_PROFILE_WIZARD_STEP.SUCCESS);
interface MoveToNextProfileWizardStepParamsError {
  details: ExceptionDetails;
}
export const moveToNextProfileWizardStepErrorAction = createAction<
  MoveToNextProfileWizardStepParamsError
>(constants.MOVE_TO_NEXT_PROFILE_WIZARD_STEP.ERROR);

// UpdateAccountProfileAction
export interface UpdateAccountProfileParams {
  slug?: string;
  timezone?: string;
  name?: string;
  welcomeMessage?: string;
  locale?: string;
  dateFormat?: string;
  timeFormat?: string;
  phone?: string;
}
interface UpdateAccountProfileParamsError {
  details: ExceptionDetails;
}
interface UpdateAccountProfileParamsSuccess {
  updatedUser: UserModel | null;
}
export const {
  request: updateAccountProfileAction,
  success: updateAccountProfileSuccessAction,
  error: updateAccountProfileErrorAction,
} = actionBuilder<
  UpdateAccountProfileParams,
  UpdateAccountProfileParamsSuccess,
  UpdateAccountProfileParamsError
>(constants.UPDATE_ACCOUNT_PROFILE);

export interface DeleteUserAvatarParam {
  removeAvatar: boolean;
}
export const deleteUserAvatarAction = createAction<DeleteUserAvatarParam>(
  ActionTypes.DELETE_USER_AVATAR.REQUEST,
);

export const REMOVE_PASSWORDS_ERROR = 'REMOVE_PASSWORDS_ERROR';
export const removePasswordErrorAction = createAction(REMOVE_PASSWORDS_ERROR);
export interface IPayment {
  token: any;
  type: string;
  users?: number;
}
export const postPayment = createAction<IPayment>(
  ActionTypes.POST_PAYMENT.REQUEST,
);

export const {
  request: buySubscription,
  success: buySubscriptionSuccessAction,
  error: buySubscriptionErrorAction,
} = actionBuilder<IPayment, UpdateAccountProfileParamsSuccess, null>(
  constants.BUY_SUBSCRIPTION,
);

export const {
  request: updateCardInfo,
  success: updateCardInfoSuccessAction,
  error: updateCardInfoErrorAction,
} = actionBuilder<IPayment, UpdateAccountProfileParamsSuccess, null>(
  constants.UPDATE_CARD_INFO,
);

export const delSubscription = createAction(
  ActionTypes.DEL_SUBSCRIPTION.REQUEST,
);

export interface AvailabilityDto {
  availability: {
    hours: {
      from: number;
      to: number;
    };
    days: DaysOfWeek[];
  };
  timezone: string;
}
export interface ChangeAvailabilityParams {
  updateAvailability: AvailabilityDto;
}
interface ChangeAvailabilityParamsError {
  details: ExceptionDetails;
}
interface ChangeAvailabilityParamsSuccess {
  updatedAvailability: AvailabilityDto | null;
}
export const {
  request: changeAvailabilityAction,
  success: changeAvailabilitySuccessAction,
  error: changeAvailabilityErrorAction,
} = actionBuilder<
  ChangeAvailabilityParams,
  ChangeAvailabilityParamsSuccess,
  ChangeAvailabilityParamsError
>(constants.PUT_AVAILABILITY);

export interface EnterCardModalParam {
  onSubmit: ActionCreatorWithPayload<IPayment, string>;
  header: string;
}

export const enterCardModalDialogAction = (
  props: EnterCardModalParam,
): PayloadAction<unknown> => {
  return showModalDialogAction({
    payload: props,
    type: ModalTypes.enterCard,
  });
};

interface CongratulationsModalParams {
  expiredAt: string;
  brand: string;
  last4: string;
}

export const CongratulationsModalDialogAction = (
  props: CongratulationsModalParams,
): PayloadAction<unknown> => {
  return showModalDialogAction({
    payload: props,
    type: ModalTypes.congratulations,
  });
};

export interface BillingStat {
  charges: ChargeStripe[] | null;
  card: CardInfoDto | null;
}

export interface UpdateCardParam {
  card: CardInfoDto;
  isCardValid: boolean;
}

export const updateStat = createAction<BillingStat>(
  ActionTypes.UPDATE_BILLING_STAT,
);

export const updateCardStat = createAction<UpdateCardParam>(
  ActionTypes.UPDATE_CARD_STAT,
);
