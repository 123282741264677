import { createAction } from '@reduxjs/toolkit';
import { ModalTypes } from '../interfaces/modal';
import * as ActionTypes from '../constants';

interface ShowModalProps<T = any> {
  type: ModalTypes;
  payload: T;
  dismissDialog?: () => void;
}
export const showModalDialogAction = createAction<ShowModalProps>(
  ActionTypes.SHOW_MODAL_DIALOG,
);
export const dismissModalDialogAction = createAction(
  ActionTypes.DISMISS_MODAL_DIALOG,
);
