import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { DaysOfWeek } from '../../../models/days-of-week.model';
import * as _ from 'lodash';
import { useUserInfoAfterAuthSelector } from '../../../redux/selectors/auth';
import { routers } from '../../../router';
import { useDispatch } from 'react-redux';
import {
  loadTimezonesAction,
  moveToNextProfileWizardStepAction,
} from '../../../redux/actions';
import { ProgressComponent } from '../../../components/progress';
import { AppNameLogo } from '../../../components/logo/appNameLogo';
import { CompleteProfileHours } from './steps/hours';
import './index.scss';
import { CompleteProfileDays } from './steps/days';
import { CompleteProfilePhone } from './steps/phone';
import { CompleteProfileUrl } from './steps/url';
import { CompleteProfileFinish } from './steps/finish';
import moment from 'moment-timezone';
import { useTimezoneInfoSelector } from '../../../redux/selectors/timezone';

export const CompleteProfileInfoPage: React.FC = () => {
  const dispatch = useDispatch();
  const user = useUserInfoAfterAuthSelector();
  const timezones = useTimezoneInfoSelector();
  const [index, setIndex] = useState(1);
  const [availableTime, setAvailableTime] = useState<[number, number]>([
    user.defaultAvailabilityTime.hours.from,
    user.defaultAvailabilityTime.hours.to,
  ]);

  const [timezone, setTimezone] = useState(user.timezone);
  const [slug, setSlug] = useState(user.slug);
  const [dayOfWeek, setDayOfWeek] = useState<DaysOfWeek[]>(
    user.defaultAvailabilityTime.days,
  );
  const [phone, setPhone] = useState('');

  const getClass = useCallback(
    (value: number) => {
      const prev = index === value + 1 ? ' wizard-prev' : '';
      const active = index === value ? ' wizard-active' : '';
      return `${prev}${active}`;
    },
    [index],
  );
  useEffect(() => {
    dispatch(loadTimezonesAction());
  }, [dispatch]);

  useEffect(() => {
    const toArray = Object.values(timezones.data);
    if (toArray.length > 0) {
      const clientOffset = new Date().getTimezoneOffset() * -60;
      const clientCountry = moment.tz.guess().split('/')[0];
      const timeZone = toArray.filter(
        (item) =>
          item.standardOffset === +clientOffset && item.group === clientCountry,
      );
      if (timeZone[0]) {
        setTimezone(timeZone[0].identifier);
      }
    }
  }, [timezones, setTimezone]);

  if (user.isFilledProfile) {
    return <Redirect to={routers.ME_PAGE} />;
  }

  const onTimeChange = (value: [number, number]) => {
    const [firstTime, secondTime] = value;
    if (firstTime !== secondTime) {
      setAvailableTime(value);
    }
  };

  const onDayChange = (day: DaysOfWeek) => {
    const isActive = _.some(dayOfWeek, (el) => el === day);
    if (isActive) {
      setDayOfWeek(_.without(dayOfWeek, day));
    } else {
      setDayOfWeek([...dayOfWeek, day]);
    }
  };

  const handleNext = () => {
    setIndex(index + 1);
  };

  const handleFinish = () => {
    dispatch(
      moveToNextProfileWizardStepAction({
        timezone,
        slug,
        phone,
        availableTime: {
          hours: {
            from: availableTime[0],
            to: availableTime[1],
          },
          days: dayOfWeek,
        },
      }),
    );
  };

  return (
    <>
      <div className="container-complete-profile-info">
        <ProgressComponent
          className="profile-process-bar"
          percent={((index - 1) / 4) * 100}
        />
        <div className="complete-profile-header-logo">
          <AppNameLogo />
        </div>
        <div className={`page${getClass(1)}`}>
          <CompleteProfileHours
            className={getClass(1)}
            availableTime={availableTime}
            onTimeChange={onTimeChange}
            setTimezone={setTimezone}
            timezone={timezone}
            next={handleNext}
          />
        </div>
        <div className={`page${getClass(2)}`}>
          <CompleteProfileDays
            next={handleNext}
            onDayChange={onDayChange}
            dayOfWeek={dayOfWeek}
          />
        </div>
        <div className={`page${getClass(3)}`}>
          <CompleteProfilePhone
            next={handleNext}
            setPhone={setPhone}
            phone={phone}
          />
        </div>
        <div className={`page${getClass(4)}`}>
          <CompleteProfileUrl
            next={handleNext}
            slug={slug}
            onChange={setSlug}
          />
        </div>
        <div className={`page${getClass(5)}`}>
          <CompleteProfileFinish next={handleFinish} />
        </div>
      </div>
    </>
  );
};
