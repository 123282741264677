import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Dropdown, Layout as LayoutAntd, Menu } from 'antd';
import { LayoutProps } from 'antd/lib/layout';
import { Content, Header } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import HomeIcon from '../icons/home';
import { Redirect, useLocation, NavLink } from 'react-router-dom';
import './index.scss';
import Logo from '../logo/imgs/logo';
import ClockIcon from '../icons/clock';
import BellIcon from '../icons/bell';
import ColorPaletteIcon from '../icons/color-palette';
import CalendarIcon from '../icons/calendar';
import { Switch } from '../switch';
import { ConferenceLinePhone } from '../conferenceLine';
import ArrowDownIcon from '../icons/arrow-down';
import JammerSmIcon from '../icons/jammer-sm';
import CloseThinIcon from '../icons/close-thin';
import { useThemeSelector } from '../../redux/selectors/themes';
import { THEMES } from '../../redux/constants/theme';
import { useDispatch } from 'react-redux';
import { setDarkTheme } from '../../redux/actions/theme';
import { UserLogo } from '../userLogo';
import { useUserInfoAfterAuthSelector } from '../../redux/selectors/auth';
import {
  inviteUserAcceptAction,
  logoutModalDialogAction,
} from '../../redux/actions';
import _ from 'lodash';
import { routers } from '../../router';
import { BackArrow } from '../icons/backArrow/backArrow';
import { TrialEndBadge } from '../trialEnd';
import moment from 'moment';
import { CardErrorNotification } from '../cardNotification';

interface ILayoutProps extends LayoutProps {
  theme?: THEMES;
  className?: string;
}

export interface ILayoutInputProps {
  nameHeader?: string;
  footer?: boolean;
}

interface SiderMenu {
  path: string;
  name: string;
  icon: JSX.Element;
}

const siderMenuItems: SiderMenu[] = [
  { path: routers.HOME, name: 'Home', icon: <HomeIcon /> },
  { path: routers.AVAILABILITY, name: 'Availability', icon: <ClockIcon /> },
  { path: routers.REMINDERS, name: 'Reminders', icon: <BellIcon /> },
  { path: routers.DESIGN, name: 'Design', icon: <ColorPaletteIcon /> },
  { path: routers.CALENDARS, name: 'Calendars', icon: <CalendarIcon /> },
];

const headers = [
  { path: routers.HOME, header: 'Home' },
  { path: routers.AVAILABILITY, header: 'Availability' },
  { path: routers.REMINDERS, header: 'Reminders & Notifications' },
  { path: routers.DESIGN, header: 'Design' },
  { path: routers.CALENDARS, header: 'Calendar Connection' },
  { path: routers.BILLING, header: 'Billing' },
  { path: routers.ACCOUNT_SETTINGS_PAGE, header: 'Account Settings' },
  {
    path: routers.MEETING_CREATING,
    header: (
      <>
        <NavLink to={routers.HOME} className="back-home-arrow">
          <BackArrow link={routers.HOME} />
        </NavLink>
        Create New Meeting
      </>
    ),
  },
  {
    //Todo create arrow component
    path: '/meetings',
    header: (
      <>
        <NavLink to={routers.HOME} className="back-home-arrow">
          <BackArrow link={routers.HOME} />
        </NavLink>
        Meeting Settings
      </>
    ),
  },
  { path: routers.HOME, header: 'Home' },
];

interface IComplexLayoutProps extends ILayoutProps, ILayoutInputProps {}

export const Dashboard: React.FC<IComplexLayoutProps> = (
  props: IComplexLayoutProps,
) => {
  const theme = useThemeSelector();
  const dispatch = useDispatch();
  const [collapsedMenu, setCollapsedMenu] = useState(false);
  const user = useUserInfoAfterAuthSelector();
  const location = useLocation();

  const daysToEnd = useMemo(() => {
    return moment(user.subscription?.expiredAt).diff(
      moment(Date.now()),
      'days',
    );
  }, [user.subscription]);

  useEffect(() => {
    const iut = localStorage.getItem('iut');
    if (!_.isNil(iut) && user.isFilledProfile) {
      dispatch(
        inviteUserAcceptAction({
          inviteToken: iut,
        }),
      );
    }
  }, [user.isFilledProfile, dispatch]);

  const onClick = useCallback(() => {
    if (theme.color === THEMES.DARK) {
      dispatch(setDarkTheme({ data: THEMES.LIGHT }));
    } else {
      dispatch(setDarkTheme({ data: THEMES.DARK }));
    }
  }, [theme.color, dispatch]);

  const header = useMemo(() => {
    const result = headers.find((item) =>
      location.pathname.includes(item.path),
    );
    return result?.header;
  }, [location]);

  if (!user.isFilledProfile) {
    return (
      <Redirect
        to={{
          pathname: routers.COMPLETE_PROFILE_INFO_PAGE,
          state: { from: location },
        }}
      />
    );
  }

  return (
    <>
      {user.subscription?.hasOwnProperty('isCardValid') &&
      user.subscription?.isCardValid === false ? (
        <CardErrorNotification />
      ) : undefined}
      <LayoutAntd
        className={`dashboard ${props.className || ''}`}
        style={{ flexDirection: 'row' }}
      >
        <div
          className={`dashboard-mobile-background ${
            collapsedMenu ? 'active' : ''
          }`}
          onClick={() => setCollapsedMenu(false)}
        >
          <CloseThinIcon
            className="dashboard-mobile-background-icon"
            onClick={() => setCollapsedMenu(false)}
          />
        </div>
        <Sider
          style={{
            maxWidth: '256px',
            width: '100%',
          }}
          className={`dashboard-sider ${collapsedMenu ? 'active' : ''}`}
        >
          <div className="dashboard-sider-logo">
            <Logo />
          </div>
          <nav className="dashboard-sider-nav">
            {siderMenuItems.map((item, index) => {
              return (
                <NavLink to={item.path} key={index}>
                  {item.icon}
                  {item.name}
                </NavLink>
              );
            })}
          </nav>
          {user.subscription?.typeName === 'Trial' && (
            <TrialEndBadge
              days={daysToEnd}
              subscription={user.subscription?.typeName}
            />
          )}

          <div className="dashboard-sider-bottom">
            <div className="dashboard-sider-switch">
              <Switch
                size="small"
                rightlabel="Dark mode"
                onClick={onClick}
                checked={theme.color === THEMES.DARK}
              />
            </div>
          </div>
        </Sider>
        <LayoutAntd>
          <Header className="dashboard-header">
            <div className="dashboard-header-left-side">
              <div className="dashboard-header-left-side-head">
                <button
                  className={`hamburger hamburger--squeeze ${
                    collapsedMenu ? 'is-active' : ''
                  }`}
                  type="button"
                  onClick={() => setCollapsedMenu(!collapsedMenu)}
                >
                  <span className="hamburger-box">
                    {/*eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
                    <span className="hamburger-inner">{''}</span>
                  </span>
                </button>
                <div className="dashboard-header-logo">
                  <JammerSmIcon />
                </div>
              </div>
              <div className="dashboard-header-name">{header}</div>
            </div>
            <div className="dashboard-header-user">
              <ConferenceLinePhone
                phone={user?.twilio_phone}
                className="layout-header-user-phone"
              />
              <Dropdown
                overlay={
                  <Menu className="dropdown-menu">
                    <Menu.Item>
                      <NavLink to={routers.ACCOUNT_SETTINGS_PAGE}>
                        Settings
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item>
                      <NavLink to={routers.BILLING}>Billing</NavLink>
                    </Menu.Item>
                    <Menu.Item>
                      {/*eslint-disable-next-line*/}
                          <a
                              onClick={() =>
                                  dispatch(logoutModalDialogAction())
                              }
                          >
                        Logout
                      </a>
                    </Menu.Item>
                  </Menu>
                }
                placement="bottomRight"
                trigger={['click']}
                getPopupContainer={() => {
                  const tooltipContainer = document.createElement('div');
                  const root = document.getElementsByClassName('ant-layout');
                  tooltipContainer.classList.add('dropdown-container');
                  return root
                    ? root[0].appendChild(tooltipContainer)
                    : document.body;
                }}
              >
                <div className="dashboard-header-user-avatar">
                  <UserLogo avatar={user?.avatar} />
                  <ArrowDownIcon />
                </div>
              </Dropdown>
            </div>
          </Header>
          <div className="dashboard-mobile-path-name">{header}</div>
          <Content>{props.children}</Content>
        </LayoutAntd>
      </LayoutAntd>
    </>
  );
};
