import React from 'react';
import { BeatLoader } from 'react-spinners';
import './index.scss';

type PropsStyle = {
  style?: any;
};

export const Loader: React.FC<PropsStyle> = (props) => {
  return (
    <div className="spinner text-center" style={props.style}>
      <BeatLoader />
    </div>
  );
};
