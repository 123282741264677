import React, { useEffect } from 'react';
import { timesMonthPreview, calendar } from './previewData';
import { ArrowLeftIcon } from '../icons/arrowLeft';
import { ArrowRightIcon } from '../icons/arrowRight';

interface Props {
  color?: string | null;
  setColor(): void;
}

export const MonthPreview: React.FC<Props> = ({ color, setColor }) => {
  useEffect(() => {
    setColor();
  }, [setColor, color]);
  return (
    <div className="monthPreview-container">
      <div className="monthPreview-container-calendar">
        <div className="calendar-title">
          <ArrowLeftIcon />
          <div>February 2021</div>
          <ArrowRightIcon />
        </div>
        <div className="monthPreview-calendar">
          {calendar.map((item, index) => (
            <div className={`calendar-item ${item.style}`} key={index}>
              <p>{item.value}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="monthPreview-container-times">
        {timesMonthPreview.map((item) => (
          <div className="monthPreview-times-item" key={item}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
