import { MeetingRulesAvailabilityModel } from './meeting-rules-availability.model';
import { MeetingDateRangeModel } from './meeting-date-range.model';
import { PhoneIcon } from '../components/icons/phone/phone';
import React from 'react';
import { EarthIcon } from '../components/icons/earthWeb/earthWeb';
import { PhoneCallIcon } from '../components/icons/phoneCall/phoneCall';
import { MarkerIcon } from '../components/icons/locationMarker/marker';
import { SettingsIcon } from '../components/icons/settings';
import { DaysOfWeek } from './days-of-week.model';
import { CalendarStyleType } from '../pages/design';
import { DesignFetchDto } from '../libs/design/design.dto';

export interface AvailabilityTimeModel {
  days: DaysOfWeek[];
  hours: {
    to: number;
    from: number;
  };
}

export interface MeetingModel {
  id: string;
  description: string;
  duration: number;
  name: string;
  slug: string;
  timezone: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string | null;
  availabilityTime: AvailabilityTimeModel;
  notificationsPolicy: MeetingEventNotificationsPolicyModel;
  conferenceLine: MeetingConferenceLineModel;
  locationConfigurations: LocationConfigurationsModel;
  notificationTime: number;
  twilio_phone: string;
  calendarInvitations: {
    body: string | null;
    title: string | null;
  };

  // rulesAvailability: MeetingRulesAvailabilityModel[];
  // dateRange: MeetingDateRangeModel;
  // ownerId: string;
  // availabilityIncrement: number;
  //color: string;
}

export interface MeetingOnListModel {
  id: string;
  name: string;
  color: string;
  duration: number;
  slug: string;
  is_active: boolean;
  location: {
    id: string;
    meeting_id: string;
    additional_info: string | null;
    location: string | null;
    kind: LocationTypeEnum;
  };
  order_dnd: number;
}

export interface MeetingEditModel {
  name?: string;
  slug?: string;
  description?: string;
  timezone: string;
  isActive?: boolean;
  duration: number;
  availabilityTime: AvailabilityTimeModel;
  notificationsPolicy: MeetingEventNotificationsPolicyModel;
  locationConfigurations: LocationConfigurationsModel;
  calendarInvitations: {
    body: string | null;
    title: string | null;
  };
  //color?: string;
  //rulesAvailability?: MeetingRulesAvailabilityModel[];
  //dateRange?: MeetingDateRangeModel;
  //conferenceLine?: MeetingConferenceLineModel;
  //availabilityIncrement?: number;
}

export interface MeetingToBookEventModel {
  id: string;
  color: string;
  name: string;
  duration: number;
  dateRange: MeetingDateRangeModel;
  location: LocationConfigurationsModel;
  conferencePhone: string;
  createdAt: Date;
  owner: {
    id: string;
    name: string;
  };
  design: {
    color: string | null;
    isDarkMode: boolean;
    logo: string | null;
    calendarStyle: CalendarStyleType;
  };
}

interface MainLinkMeeting {
  id: string;
  createdAt: string;
  slug: string;
  name: string;
  duration: number;
  is_active: boolean;
  location: {
    id: string;
    meeting_id: string;
    additional_info: string | null;
    location: string | null;
    kind: LocationTypeEnum;
  };
}

export interface MainLinkResponseModel {
  user: {
    id: string;
    name: string;
    avatar: string | null;
  };
  design: DesignFetchDto;
  meetings: {
    data: MainLinkMeeting[] | null;
    meta: {
      totalCount: number;
      totalPages: number;
    };
  };
}
export type MeetingSlotStatus = 'available' | 'unavailable';

export interface MeetingDateSlotsToBookEventModel {
  timezone: string;
  days: MeetingSlotsToBookEventModel[];
}

export interface MeetingSlotsToBookEventModel {
  date: string;
  status: MeetingSlotStatus;
  slots: MeetingSlotInfoToBookEventModel[];
}

export interface MeetingSlotInfoToBookEventModel {
  startDateTime: string;
  status: MeetingSlotStatus;
}

export interface MeetingDataBookEmailModel {
  timezone: string;
  rulesAvailability: MeetingRulesAvailabilityModel[];
  dateRange: MeetingDateRangeModel;
}

export enum NotificationPolicyEnum {
  email = 'email',
  calendar = 'calendar',
}

export interface MeetingEventNotificationsPolicyModel {
  notificationType?: NotificationPolicyEnum | undefined;
  isEmailReminder?: boolean | undefined;
  isSMSReminder?: boolean | undefined;
  emailConfirmation?: string | undefined;
  emailCancellation?: string | undefined;
  calendarInvitation?: string | undefined;
}

export interface MeetingConferenceLineModel {
  phone: string;
  conferenceNotifications: number[];
}

export enum LocationKindEnum {
  inbound = 'inbound',
  outbound = 'outbound',
  physical = 'physical',
  conference = 'conference',
}

export interface LocationConfigurationsModel {
  // phoneNumber: string | null;
  additionalInfo: string | null;
  // location: string | null;
  kind: LocationTypeEnum;
}

export interface MeetingScheduleEventModel {
  // meetingId: string;
  // firstName: string;
  // lastName: string;
  // eventStartDate: string;
  // email: string;
  // phone?: string;
  // timezone: string;
  // guests?: string[];
  meetingId: string;
  eventStartDate: string;
  name: string;
  timezone: string;
  email: string;
  phone: string;
  guests?: string[];
}

export interface EventDataModel {
  event: {
    id: string;
    timezone: string;
    startDateTime: string;
    endDateTime: string;
    cancellation: Date | null;
  };
  meeting: {
    color: string;
    createdAt: Date;
    duration: number;
    dateRange: MeetingDateRangeModel;
    id: string;
    locationConfigurations: LocationConfigurationsModel;
    conferencePhone: string;
    name: string;
    slug?: string;
  };
  owner: {
    id: string;
    name: string;
  };
  participant: {
    email: string;
    name: string;
    phone: string | null;
    timezone: string;
  };
}

export enum LocationTypeEnum {
  // twilio = 'twilio',
  // web = 'web',
  // call = 'call',
  // person = 'person',
  // custom = 'custom',
  jammerConference = 'jammer_conference',
  webConference = 'web_conference',
  phoneCall = 'phone_call',
  person = 'person',
  custom = 'custom',
}

interface LocationSelectItem {
  type: LocationTypeEnum;
  name: string;
  description: string | null;
  logo: React.FC;
}

export const LocationArray: LocationSelectItem[] = [
  {
    type: LocationTypeEnum.jammerConference,
    name: 'Jammer Conference Line (no pin)',
    description: null,
    logo: PhoneIcon,
  },
  {
    type: LocationTypeEnum.webConference,
    name: 'Video/Web Conference',
    description: 'Add your URL for Zoom, Google Meet, etc.',
    logo: EarthIcon,
  },
  {
    type: LocationTypeEnum.phoneCall,
    name: 'Phone Call',
    description: 'You will call your invitee',
    logo: PhoneCallIcon,
  },
  {
    type: LocationTypeEnum.person,
    name: 'In-person Meeting',
    description: 'Set an address or place',
    logo: MarkerIcon,
  },
  {
    type: LocationTypeEnum.custom,
    name: 'Custom',
    description: 'Add your own meeting details',
    logo: SettingsIcon,
  },
];
