import React from 'react';
import { ReactComponent as SettingsLogo } from './Group.svg';

interface Props {
  style?: any;
}

export const ScheduleIcon: React.FC<Props> = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SettingsLogo style={{ ...props.style }} />
    </div>
  );
};
