import React, { useState } from 'react';
import './index.scss';
import { ArrowRightIcon } from '../icons/arrowRight';
import { ArrowLeftIcon } from '../icons/arrowLeft';
import { Moment } from 'moment-timezone';
import moment from 'moment';

interface Day {
  weekDay: string;
  month: string;
  day: number;
  status: boolean;
  moment: Moment;
}

interface props {
  color?: string | null;
  upWeekHandler(): void;
  downWeekHandler(): void;
  selectedDay?: Moment;
  setSelectedDay(value: Moment): void;
  week: Day[];
}

export const WeekSelectDay: React.FC<props> = (props) => {
  const [hover, setHover] = useState<string | undefined>(undefined);
  const hoverHandler = (item: any) => {
    setHover(item.weekDay);
  };
  const hoverHandlerOut = () => {
    setHover(undefined);
  };
  const getStyles = (item: any) => {
    const isCurrent = moment(moment(new Date())).isSame(item.moment, 'day');
    const isSelected = moment(item.moment).isSame(props.selectedDay);
    const isHover = hover === item.weekDay && item.status;

    if (props.color) {
      if (isSelected) {
        return {
          backgroundColor: props.color,
        };
      }
      if (isHover) {
        return {
          backgroundColor: props.color,
        };
      }
      if (isCurrent) {
        return {
          color: props.color,
        };
      }
    }
    return undefined;
  };
  return (
    <div className="weekDaySelect-container">
      <div className="weekDaySelect-leftArrow" onClick={props.downWeekHandler}>
        <ArrowLeftIcon />
      </div>
      <div className="weekDaySelect-body">
        {props.week.map((item) => {
          return (
            <div
              onPointerEnter={() => hoverHandler(item)}
              onPointerLeave={hoverHandlerOut}
              style={getStyles(item)}
              className={`dayCol${!item.status ? ' disable' : ''}${
                moment(moment(new Date())).isSame(item.moment, 'day')
                  ? ' currentDay'
                  : ''
              }${
                moment(item.moment).isSame(props.selectedDay)
                  ? ' selectedDay'
                  : ''
              }`}
              onClick={() =>
                item.status ? props.setSelectedDay(item.moment) : null
              }
              key={item.weekDay}
            >
              <div className="weekDay">{item.weekDay}</div>
              <div className="month">{item.month}</div>
              <div className="day">{item.day}</div>
            </div>
          );
        })}
      </div>
      <div className="weekDaySelect-rightArrow" onClick={props.upWeekHandler}>
        <ArrowRightIcon />
      </div>
    </div>
  );
};
