import { Form, Input as InputAnt } from 'antd';
import { InputProps as InputPropsAnt } from 'antd/lib/input';
import * as React from 'react';
import './index.scss';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { Rule } from 'rc-field-form/lib/interface';
import { ExceptionDetail } from '../../utils/errors';
import CheckMarkIcon from '../icons/checkMark';
import CloseIcon from '../icons/close';

interface BaseInputProps {
  label?: string;
  name?: string;
  validateStatus?: ValidateStatus;
  helpMessage?: string | React.ReactNode;
  errorHelpMessage?: string;
  rules?: Rule[];
  requiredLabel?: boolean;
}

interface InputProps extends InputPropsAnt, BaseInputProps {
  okMark?: boolean;
  errorMark?: boolean;
}

const getHelpMessageByValidationStatus = (
  props: BaseInputProps,
): React.ReactNode | string | undefined => {
  if (props.validateStatus === 'error') {
    return props.errorHelpMessage;
  }

  return props.helpMessage;
};

export const errorObjectToValidateStatus = (
  v: ExceptionDetail | undefined,
): ValidateStatus => {
  return v ? 'error' : '';
};

export const Input: React.FC<InputProps> = (props) => {
  const {
    label,
    validateStatus,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    helpMessage,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    errorHelpMessage,
    rules,
    requiredLabel,
    className,
    okMark,
    errorMark,
    ...rest
  } = props;
  return (
    <Form.Item
      rules={rules}
      help={getHelpMessageByValidationStatus(props)}
      label={label}
      validateStatus={validateStatus}
      required={requiredLabel}
      className={className || ''}
    >
      <InputAnt {...rest} />
      <div className="input-info">
        <span>{errorHelpMessage ? errorHelpMessage : null}</span>
        {okMark ? <CheckMarkIcon className="input-info-ok-icon" /> : null}
        {errorMark ? <CloseIcon className="input-info-error-icon" /> : null}
      </div>
    </Form.Item>
  );
};
