import React from 'react';
import moment from 'moment-timezone';
import './index.scss';

interface props {
  color?: string | null;
  time: string;
  duration?: number;
  timezone: string;
}

export const ScheduleSelectedTime: React.FC<props> = (props) => {
  return (
    <div className="selectedTime">
      <div
        style={props.color ? { backgroundColor: props.color } : undefined}
        className="date-selected"
      >
        {moment(props.time).format('dddd, MMMM D')}
      </div>
      <div className="time-selected">
        <div className="time-selected-time">
          {moment(props.time).utc().format('H:mm')} -{' '}
          {moment(props.time)
            .add(props.duration, 'minute')
            .utc()
            .format('H:mm A')}
        </div>
        <div className="time-selected-timezone">{props.timezone}</div>
      </div>
    </div>
  );
};
