import slugify from 'slugify';

interface AvailableHours {
  value: number;
}

export const availableHoursFromList: AvailableHours[] = [
  { value: 9 },
  { value: 10 },
  { value: 11 },
  { value: 12 },
  { value: 13 },
  { value: 14 },
  { value: 15 },
  { value: 16 },
  { value: 17 },
];

export const availableHoursToList: AvailableHours[] = [
  { value: 10 },
  { value: 11 },
  { value: 12 },
  { value: 13 },
  { value: 14 },
  { value: 15 },
  { value: 16 },
  { value: 17 },
  { value: 18 },
];

/**
 * @param {number} v hours
 * @example
 *  formatAvailableHoursFromHours(9); // 09:00
 *  formatAvailableHoursFromHours(11); // 11:00
 */
export const formatAvailableHoursFromHours = (v: number): string => {
  return `${('0' + v).slice(-2)} : 00`;
};

export const convertSecondsToHours = (v: number): number => {
  return Math.floor(v / 60);
};

export const convertHoursToMinutes = (v: number): number => {
  return Math.floor(v * 60);
};

export const convertHoursToSeconds = (v: number): number => {
  return v * 60;
};

/**
 * @return normalized url
 * @description sanitize string to url (allow lowercase only 'a-z', '0-9' or '-' characters)
 */
export const sanitizeUserSlug = (raw: string): string => {
  return slugify(raw, {
    strict: true,
    lower: true,
  });
};
/**
 * @return normalized name
 * @description sanitize string to name (allow only 'A-z','.' or space characters)
 */
export const sanitizeUserName = (raw: string): boolean => {
  // eslint-disable-next-line
  return raw.search(/[!@#$%^&*()=+_\\,?:;~\`\[\]{}|/<>0-9"']/g) > 0;
};

export enum CompleteProfileStepEnum {
  slug = 1,
  googleCalendar = 2,
  availableTime = 3,
}

export const stateMachineOfCompleteProfileStep: Record<
  CompleteProfileStepEnum,
  { nextStep: CompleteProfileStepEnum | null; computedPercent: number }
> = {
  [CompleteProfileStepEnum.slug]: {
    computedPercent: 20,
    nextStep: CompleteProfileStepEnum.googleCalendar,
  },
  [CompleteProfileStepEnum.googleCalendar]: {
    computedPercent: 55,
    nextStep: CompleteProfileStepEnum.availableTime,
  },
  [CompleteProfileStepEnum.availableTime]: {
    computedPercent: 88,
    nextStep: null,
  },
};
