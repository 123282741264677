import React from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './phoneInput.scss';

interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

export interface IPhoneInput extends PhoneInputProps {
  containerClass?: string;
  inputClass?: string;
  buttonClass?: string;
  dropdownClass?: string;
  searchClass?: string;
  onValidPhone?(value: boolean): void;
  onChange?(
    value: string,
    data: any,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string,
  ): void;
  value?: string | null;
}

export const PhoneInputComponent: React.FC<IPhoneInput> = (props) => {
  return (
    <PhoneInput
      {...props}
      country={'us'}
      containerClass={`phone-input-container ${
        props.containerClass ? props.containerClass : ''
      }`}
      inputClass={`phone-input-input ${
        props.inputClass ? props.inputClass : ''
      }`}
      buttonClass={`phone-input-button ${
        props.buttonClass ? props.buttonClass : ''
      }`}
      dropdownClass={`phone-input-dropdown ${
        props.dropdownClass ? props.dropdownClass : ''
      }`}
      searchClass={`phone-input-search ${
        props.searchClass ? props.searchClass : ''
      }`}
      onChange={props.onChange}
      isValid={(value, country: any) => {
        if (value) {
          const count = (country.format.match(/\./g) || []).length;
          const result = count === value.length;
          props.onValidPhone && props.onValidPhone(result);
          return result;
        }
        props.onValidPhone && props.onValidPhone(false);
        return false;
      }}
    />
  );
};
