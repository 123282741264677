import { FetchNotificationDto } from '../../redux/actions';
import { SessionService } from '../auth/session.service';
import { RemindersApi } from './reminders.api';

export class RemindersService {
  constructor(
    private readonly sessionService: SessionService,
    private readonly remindersApi: RemindersApi,
  ) {}

  async getNotification(): Promise<FetchNotificationDto | undefined> {
    const token = this.sessionService.getAccessToken();

    if (this.sessionService.isAccessTokenExpired(token)) {
      return;
    }
    return await this.remindersApi.getNotifications(token);
  }

  async updateNotification(
    dto: FetchNotificationDto,
  ): Promise<FetchNotificationDto | undefined> {
    const token = this.sessionService.getAccessToken();

    if (this.sessionService.isAccessTokenExpired(token)) {
      return;
    }
    return await this.remindersApi.updateNotifications(token, dto);
  }
}

export const remindersService = new RemindersService(
  new SessionService(),
  new RemindersApi(),
);
