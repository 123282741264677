import { select, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import {
  ShowNotificationParam,
  showNotificationAction,
  showNotificationSuccessAction,
  showNotificationErrorAction,
  MessageParams,
} from '../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { MessageSuccessIcon } from '../../components/icons/messageSuccess';
import { MessageErrorIcon } from '../../components/icons/messageError';
import React from 'react';
import { StoreState } from '../store/interface';

function showNotificationSagaAction({
  payload,
}: PayloadAction<ShowNotificationParam>) {
  message.open({
    ...payload,
    duration: payload.duration || 3.5,
  });
}

function* showNotificationSuccessSagaAction({
  payload,
}: PayloadAction<MessageParams>) {
  const state: StoreState = yield select();
  message.success({
    ...payload,
    type: 'success',
    icon: <MessageSuccessIcon />,
    duration: payload.duration || 3.5,
    className: `message-success-container ${state.theme.color}`,
  });
}

function* showNotificationErrorSagaAction({
  payload,
}: PayloadAction<MessageParams>) {
  const state: StoreState = yield select();
  message.open({
    ...payload,
    type: 'error',
    icon: <MessageErrorIcon />,
    duration: payload.duration || 3.5,
    className: `message-error-container ${state.theme.color}`,
  });
}

export function* notificationsWatchSaga(): Generator<any> {
  yield takeLatest(showNotificationAction, showNotificationSagaAction);
  yield takeLatest(
    showNotificationSuccessAction,
    showNotificationSuccessSagaAction,
  );
  yield takeLatest(
    showNotificationErrorAction,
    showNotificationErrorSagaAction,
  );
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function* notificationsRunSaga(): Generator<any> {}
