import { DaysOfWeek } from '../../models/days-of-week.model';
import { LocationTypeEnum, MeetingEditModel } from '../../models/meeting.model';
import { CalendarInvitation } from '../../redux/actions';

export interface MeetingSettingsForm {
  name: string;
  isActive?: boolean;
  description: string;
  url: string;
  duration: number;
  timezone: string;
  hours: [number, number];
  days: DaysOfWeek[];
  notificationsPolicy: {
    isEmailReminder: boolean;
    isSMSReminder: boolean;
  };
  location: {
    additionalInfo: string;
    kind: LocationTypeEnum;
  };
  calendarInvitations: CalendarInvitation;
}

export const meetingSettingsFormMapper = (
  value: MeetingSettingsForm,
  initial: MeetingEditModel,
): MeetingEditModel => {
  const mapped = {
    isActive: initial.isActive,
    name: value.name,
    description: value.description ? value.description : '',
    duration: value.duration,
    timezone: value.timezone,
    availabilityTime: {
      days: value.days,
      hours: {
        to: value.hours[1],
        from: value.hours[0],
      },
    },
    notificationsPolicy: {
      isEmailReminder: value.notificationsPolicy.isEmailReminder,
      isSMSReminder: value.notificationsPolicy.isSMSReminder,
    },
    locationConfigurations: value.location,
    calendarInvitations: value.calendarInvitations,
  };
  return value.url !== initial.slug ? { ...mapped, slug: value.url } : mapped;
};
