import { createAction } from '@reduxjs/toolkit';
import { SET_THEME, THEMES, SET_THEME_SCHEDULE } from '../constants/theme';

interface SetThemeParams {
  data: THEMES;
}
export const setDarkTheme = createAction<SetThemeParams>(SET_THEME);
export const setDarkThemeSchedule = createAction<SetThemeParams>(
  SET_THEME_SCHEDULE,
);
