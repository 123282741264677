import React from 'react';
import './index.scss';

interface props {
  props?: any;
}

export const Badge: React.FC<props> = (props) => {
  return (
    <div className="badge" {...props}>
      {props.children}
    </div>
  );
};
