import React, { Dispatch, SetStateAction, useState } from 'react';
import './index.scss';
import { Button } from '../../../../../components/button';
import { Input } from '../../../../../components/input';
import appConfig from '../../../../../constants/appConfig';
import { userService } from '../../../../../libs/user/user.service';

interface CompleteProfileUrlProps {
  next(): void;
  slug: string;
  onChange: Dispatch<SetStateAction<string>>;
}

export const CompleteProfileUrl: React.FC<CompleteProfileUrlProps> = (
  props,
) => {
  const { next, onChange, slug } = props;

  const [valid, setValid] = useState<boolean>(true);

  const changeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
    const isValidResponse = await userService.userUrlValidation(e.target.value);
    setValid(isValidResponse.valid);
  };

  return (
    <div className="complete-profile-container">
      <div className="complete-profile-header">
        What URL do you want for your scheduling link?
      </div>
      <div className="complete-profile-title">
        Used for hosting all your sheduling links. You’ll be able to send your
        URL to people so they can schedule meetings with you.
      </div>
      <Input
        value={slug}
        onChange={changeHandler}
        addonBefore={appConfig.appHostName}
        minLength={4}
        maxLength={20}
        className="url-input"
        okMark={valid}
        errorMark={!valid}
      />

      <Button onClick={next} disabled={!valid}>
        Continue
      </Button>
    </div>
  );
};
