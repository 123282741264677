import React from 'react';
import { Loader } from './index';
import './index.scss';

export const LoaderPage: React.FC = () => {
  return (
    <div className="loader-page">
      <Loader />
    </div>
  );
};
