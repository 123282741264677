import React from 'react';
import { Button, ButtonProps } from '../button';
import './new-google-sign-up-button.scss';
import GoogleIcon from '../icons/google';

export type GoogleSignUp = ButtonProps & {
  googleIconClassName?: string;
};

export const GoogleSingUpButton: React.FC<GoogleSignUp> = (props) => {
  return (
    <Button
      {...props}
      className={`google-sing-up-button ${
        props.className ? props.className : ''
      }`}
    >
      <GoogleIcon
        className={`google-sing-up-button--logo ${
          props.googleIconClassName ? props.googleIconClassName : ''
        }`}
      />
      <span>{props.children}</span>
    </Button>
  );
};
