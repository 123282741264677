import * as _ from 'lodash';
import { left, right } from '@sweet-monads/either';
import { AccessTokenDto } from '../auth/dtos/access-token.dto';
import {
  ChangeAvailabilityResult,
  DeleteUserAccountResult,
  MarkProfileAsCompletedResult,
  UpdateUserProfileResult,
} from './dtos/user.dto';
import { ErrorCodes, isAxiosError } from '../../utils/errors';
import { UnknownError, ValidationFailedError } from '../common/errors';
import { AuthTokenExpiredError, AuthTokenInvalidError } from '../auth/errors';
import {
  UpdateProfileRequestDto,
  UpdateProfileResponseDto,
} from './dtos/user.api.dto';
import { UserAvailableTimeModel } from '../../models/user-available-time.model';
import { UserSlugConflictError, PhoneValidationError } from './errors';
import { UserModel } from '../../models/user.model';
import { DeleteUserAvatarRequestDto } from '../auth/dtos/api.dto';
import BaseAxiosInstance from '../../utils/axios-config';
import { AvailabilityDto, IPayment } from '../../redux/actions';

export class UserApi {
  async markProfileAsCompleted(
    accessToken: AccessTokenDto,
  ): Promise<MarkProfileAsCompletedResult> {
    try {
      await BaseAxiosInstance.put('/api/users/profile/complete', undefined, {
        headers: { Authorization: `Bearer ${accessToken.token}` },
      });
      return right(void 0);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }

      switch (e.response.data.code) {
        case ErrorCodes.authTokenInvalid:
          return left(new AuthTokenInvalidError(e));
        case ErrorCodes.authTokenExpired:
          return left(new AuthTokenExpiredError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async deleteUserAccount(
    accessToken: AccessTokenDto,
  ): Promise<DeleteUserAccountResult> {
    try {
      const result = await BaseAxiosInstance.delete(
        '/api/users/profile/delete',
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );

      return right(result.data);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }

      switch (e.response.data.code) {
        case ErrorCodes.validationFailed:
          return left(new ValidationFailedError(e.response.data.details, e));
        case ErrorCodes.authTokenInvalid:
          return left(new AuthTokenInvalidError(e));
        case ErrorCodes.authTokenExpired:
          return left(new AuthTokenExpiredError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async updateProfile(
    accessToken: AccessTokenDto,
    params: {
      timezone?: string;
      slug?: string;
      defaultTimeAvailability?: UserAvailableTimeModel;
      name?: string;
      welcomeMessage?: string;
      dateNotation?: string;
      timeNotation?: string;
      phone?: string;
    },
  ): Promise<UpdateUserProfileResult> {
    try {
      const dto: UpdateProfileRequestDto = {
        defaultAvailabilityTime: params.defaultTimeAvailability,
        slug: params.slug,
        timezone: params.timezone,
        name: params.name,
        welcomeMessage: params.welcomeMessage,
        dateNotation: params.dateNotation,
        timeNotation: params.timeNotation,
        phone: params.phone,
      };
      const cleanedDto = _.omitBy(dto, _.isUndefined);
      if (_.isEmpty(cleanedDto)) {
        return right(null);
      }
      const result = await BaseAxiosInstance.put<UpdateProfileResponseDto>(
        '/api/users/profile',
        cleanedDto,
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );
      return right({ user: result.data });
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e.response.data));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }

      switch (e.response.data.code) {
        case ErrorCodes.userPhoneConflict:
          return left(new PhoneValidationError(e));
        case ErrorCodes.userSlugConflict:
          return left(new UserSlugConflictError(e));
        case ErrorCodes.validationFailed:
          return left(new ValidationFailedError(e.response.data.details, e));
        case ErrorCodes.authTokenInvalid:
          return left(new AuthTokenInvalidError(e));
        case ErrorCodes.authTokenExpired:
          return left(new AuthTokenExpiredError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async getCalendarAccounts(
    accessToken: AccessTokenDto,
    user: UserModel,
  ): Promise<any> {
    try {
      const result = await BaseAxiosInstance.get<any>(
        '/api/calendar_accounts',
        {
          data: { user: user.id },
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );

      return right(result);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }
      switch (e.response.data.code) {
        case ErrorCodes.userSlugConflict:
          return left(new UserSlugConflictError(e));
        case ErrorCodes.validationFailed:
          return left(new ValidationFailedError(e.response.data.details, e));
        case ErrorCodes.authTokenInvalid:
          return left(new AuthTokenInvalidError(e));
        case ErrorCodes.authTokenExpired:
          return left(new AuthTokenExpiredError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async disconnectCalendarAccount(
    accountId: string,
    accessToken: AccessTokenDto,
  ): Promise<any> {
    try {
      const result = await BaseAxiosInstance.delete<any>(
        `/api/calendar_accounts/${accountId}`,
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );

      return right(result.status);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }
      switch (e.response.data.code) {
        case ErrorCodes.userSlugConflict:
          return left(new UserSlugConflictError(e));
        case ErrorCodes.validationFailed:
          return left(new ValidationFailedError(e.response.data.details, e));
        case ErrorCodes.authTokenInvalid:
          return left(new AuthTokenInvalidError(e));
        case ErrorCodes.authTokenExpired:
          return left(new AuthTokenExpiredError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async deleteUserAvatar(
    accessToken: AccessTokenDto,
    dto: DeleteUserAvatarRequestDto,
  ): Promise<any> {
    try {
      const result = await BaseAxiosInstance.put<UpdateProfileResponseDto>(
        '/api/users/profile',
        dto,
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );
      return right(result.status);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }
    }
  }
  async changeType(accessToken: AccessTokenDto, params: any): Promise<any> {
    try {
      const result = await BaseAxiosInstance.patch(
        '/api/subscriptions',
        { typeName: params.payload.type },
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );
      return right(result.status);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }
    }
  }
  async postPayment(accessToken: AccessTokenDto, params: any): Promise<any> {
    try {
      const result = await BaseAxiosInstance.post(
        '/api/subscriptions/payment',
        params,
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );
      return right(result.data);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }
    }
  }

  async buySubscription(
    accessToken: AccessTokenDto,
    params: IPayment,
  ): Promise<any> {
    try {
      const result = await BaseAxiosInstance.post(
        '/api/subscriptions/buySubscription',
        {
          source: params.token.id,
          typeName: params.type,
        },
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );
      return right(result.data);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }
    }
  }

  async delSubscription(accessToken: AccessTokenDto): Promise<any> {
    try {
      const result = await BaseAxiosInstance.delete(
        '/api/subscriptions/delSubscription',
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );
      return right(result.data);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }
    }
  }

  async getStatSubscription(accessToken: AccessTokenDto): Promise<any> {
    try {
      const result = await BaseAxiosInstance.get('/api/subscriptions/stat', {
        headers: { Authorization: `Bearer ${accessToken.token}` },
      });
      return right(result.data);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }
    }
  }

  async updateCard(
    accessToken: AccessTokenDto,
    cardToken: IPayment,
  ): Promise<any> {
    try {
      const result = await BaseAxiosInstance.put(
        '/api/subscriptions/card',
        { cardToken: cardToken.token.id },
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );
      return right(result.data);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }
    }
  }

  async urlValidation(
    accessToken: AccessTokenDto,
    url: string,
  ): Promise<{ valid: boolean }> {
    try {
      const result = await BaseAxiosInstance.post<any>(
        '/api/users/profile/check-slug',
        { slug: url },
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );
      if (right(result.status)) {
        return { valid: true };
      } else return { valid: false };
    } catch (e) {
      return { valid: false };
    }
  }

  async phoneValidation(
    accessToken: AccessTokenDto,
    phone: string,
  ): Promise<{ valid: boolean }> {
    try {
      const result = await BaseAxiosInstance.post<any>(
        '/api/users/profile/check-phone',
        { phone },
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );
      if (right(result.status)) {
        return { valid: true };
      } else return { valid: false };
    } catch (e) {
      return { valid: false };
    }
  }

  async updateAvailability(
    accessToken: AccessTokenDto,
    availability: AvailabilityDto,
  ): Promise<ChangeAvailabilityResult> {
    try {
      const result = await BaseAxiosInstance.put<any>(
        '/api/users/availability',
        availability,
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );
      return right(result.data);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }

      switch (e.response.data.code) {
        case ErrorCodes.validationFailed:
          return left(new ValidationFailedError(e.response.data.details, e));
        case ErrorCodes.authTokenInvalid:
          return left(new AuthTokenInvalidError(e));
        case ErrorCodes.authTokenExpired:
          return left(new AuthTokenExpiredError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }
}
