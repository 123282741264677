import { createAsyncAction } from '../utils';

// auth
export const GET_AUTH_METHOD = createAsyncAction('GET_AUTH_METHOD');
export const CLEANUP_AUTH_METHOD = 'CLEANUP_AUTH_METHOD' as const;
export const CHANGE_AUTH_METHOD = 'CHANGE_AUTH_METHOD' as const;
export const LOGIN_BY_PASSWORD = createAsyncAction('LOGIN_BY_PASSWORD');
export const LOGOUT = createAsyncAction('LOGOUT');
export const LOGIN_BY_GOOGLE = createAsyncAction('LOGIN_BY_GOOGLE');
export const CHANGE_USER_EMAIL_BY_GOOGLE = createAsyncAction(
  'CHANGE_USER_EMAIL_BY_GOOGLE',
);
export const CHANGE_IS_REMEMBER_ME = 'CHANGE_IS_REMEMBER_ME';
export const GET_PROFILE = createAsyncAction('GET_PROFILE');
export const GET_SUBSCRIPTION_PLANS = createAsyncAction(
  'GET_SUBSCRIPTION_PLANS',
);
export const SIGN_UP_BY_PASSWORD = createAsyncAction('SIGN_UP_BY_PASSWORD');
export const FORGOT_PASSWORD = createAsyncAction('FORGOT_PASSWORD');
export const CLEANUP_FORGOT_PASSWORD_INFO = 'RESET_FORGOT_PASSWORD_INFO';
export const RESET_FORGOT_PASSWORD = createAsyncAction('RESET_FORGOT_PASSWORD');
export const APPROVE_USER_EMAIL = createAsyncAction('APPROVE_USER_EMAIL');

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION' as const;
export const SHOW_NOTIFICATION_SUCCESS = 'SHOW_NOTIFICATION_SUCCESS' as const;
export const SHOW_NOTIFICATION_ERROR = 'SHOW_NOTIFICATION_ERROR' as const;

export const INVITE_ACCEPT = createAsyncAction('INVITE_ACCEPT');
export const INVITE_CHECK_USER = createAsyncAction('INVITE_CHECK_USER');

export const LOGIN_FOR_GOOGLE_CALENDAR = createAsyncAction(
  'LOGIN_FOR_GOOGLE_CALENDAR',
);
export const GET_CALENDAR_ACCOUNTS = createAsyncAction('GET_CALENDAR_ACCOUNTS');
export const DISCONNECT_CALENDAR_ACCOUNT = createAsyncAction(
  'DISCONNECT_CALENDAR_ACCOUNT',
);

export * from './profile';
export * from './timezone';
export * from './modal';
export * from './meeting';
export * from './organization-users';
export * from './design';
export * from './notification';
