import React, { useState } from 'react';
import {
  CardExpiryElement,
  CardNumberElement,
  CardCvcElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useThemeSelector } from '../../../redux/selectors/themes';
import { useDispatch } from 'react-redux';
import { showNotificationErrorAction } from '../../../redux/actions';
import { SubscriptionsEnum } from '../../../models/subscription.model';
import { Button } from '../../../components/button';
import './index.scss';
import { LabelWithTooltip } from '../../../components/labelWithTooltip';
import { Token } from '@stripe/stripe-js';

export interface OnSubmitCardProps {
  token: Token | undefined;
  type: string;
}

interface props {
  onCancel(): void;
  onSubmit(value: OnSubmitCardProps): void;
  buttonTitle: string;
}

export const CardForm: React.FC<props> = (props) => {
  const { onCancel } = props;
  const stripe = useStripe();
  const elements: any = useElements();
  const theme = useThemeSelector();
  const dispatch = useDispatch();

  const card_number_options = {
    style: {
      base: {
        width: '300px',
        fontSize: '14px',
        color: theme.color === 'dark' ? '#FFFFFF' : '#595959',
        '::placeholder': {
          color: theme.color === 'dark' ? '#BFBFBF' : '#595959',
        },
      },
    },
  };

  const [validation, setValidation] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const onChangeHandler = (e: any) => {
    if (e.complete) {
      setValidation({ ...validation, [e.elementType]: true });
    } else {
      setValidation({ ...validation, [e.elementType]: false });
    }
  };

  const submitHandler = async (e: any) => {
    e.preventDefault();
    setValidation({
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    });
    if (!stripe || !elements) {
      return;
    }
    const cardElement: any = elements.getElement(CardNumberElement);
    const { error, token } = await stripe.createToken(cardElement);
    if (!error) {
      props.onSubmit({
        token,
        type: SubscriptionsEnum.pro,
      });
    } else {
      dispatch(
        showNotificationErrorAction({
          content: 'Stripe service error. Try later.',
          duration: 2,
        }),
      );
    }
    onCancel();
  };
  return (
    <div className="enterCard-container">
      <LabelWithTooltip label="Card Number" />
      <CardNumberElement
        options={card_number_options}
        onChange={onChangeHandler}
      />

      <div className="enterCard-inputs">
        <div className="inputs-item">
          <LabelWithTooltip label="Expiration Date" />
          <CardExpiryElement
            onChange={onChangeHandler}
            options={card_number_options}
          />
        </div>
        <div className="inputs-item">
          <LabelWithTooltip label="CVV" />
          <CardCvcElement
            onChange={onChangeHandler}
            options={card_number_options}
          />
        </div>
      </div>

      <Button
        onClick={submitHandler}
        disabled={
          !(
            validation.cardNumber &&
            validation.cardExpiry &&
            validation.cardCvc
          )
        }
      >
        {props.buttonTitle}
      </Button>
    </div>
  );
};
