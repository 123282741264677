import { DomainError } from '../common/errors';

export class InvalidOrganizationInviteError implements DomainError {
  code = 'inviteOrganizationUser' as const;
  message =
    'You are trying to invite user who is member of current organisation';
  constructor(public originalError?: Error) {}
}

export class NotOrganizationMemberError implements DomainError {
  code = 'notOrganizationMember' as const;
  message = 'You are not a member of organization';
  constructor(public originalError?: Error) {}
}

export class OrganizationPermissionError implements DomainError {
  code = 'organizationPermissionDenied' as const;
  message =
    'You dont have enough permissions to invite users to current organization';
  constructor(public originalError?: Error) {}
}
