import { CalendarStyleType } from '../../pages/design';
import { LoadableData } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import {
  setLoadedStatusToLoadableData,
  setLoadingStatusToLoadableData,
} from '../utils';
import {
  fetchDesignAction,
  fetchDesignSuccessAction,
  updateDesignAction,
  updateDesignSuccessAction,
} from '../actions/design';

export interface DesignState extends LoadableData {
  data: DesignModel;
}

export interface DesignModel {
  color: string;
  logo: string;
  calendarStyle: CalendarStyleType;
  isDarkMode: boolean;
}

const initialDesignReducer: DesignState = {
  isLoaded: false,
  isLoading: false,
  data: {
    color: '',
    logo: '',
    calendarStyle: CalendarStyleType.week,
    isDarkMode: true,
  },
};

export const designReducer = createReducer<DesignState>(
  initialDesignReducer,
  (builder) => {
    builder
      .addCase(fetchDesignAction, (state) => {
        setLoadingStatusToLoadableData(state);
      })
      .addCase(fetchDesignSuccessAction, (state, action) => {
        setLoadedStatusToLoadableData(state);
        state.data = action.payload;
      })
      .addCase(updateDesignAction, (state) => {
        setLoadingStatusToLoadableData(state);
      })
      .addCase(updateDesignSuccessAction, (state, action) => {
        setLoadedStatusToLoadableData(state);
        state.data = action.payload;
      });
  },
);
