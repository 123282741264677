import React, { useEffect } from 'react';
import { Dashboard } from '../../components/dashboardLayout';
import Form from 'antd/lib/form';
import { InputWithLabel } from '../../components/input/inputWithLabel';
import { appHistory, routers } from '../../router';
import { ButtonMenuStyled } from '../../components/button/styledButton';
import './index.scss';
import { Switch } from '../../components/switch';
import { useSubscriptionPlanSelector } from '../../redux/selectors/auth';
import { SubscriptionsEnum } from '../../models/subscription.model';
import { useDispatch } from 'react-redux';
import {
  fetchNotificationAction,
  updateNotificationAction,
} from '../../redux/actions';
import { useNotificationsSelector } from '../../redux/selectors/notification';
import { Loader } from '../../components/loader';

export const Reminders: React.FC = () => {
  const role = useSubscriptionPlanSelector();
  const dispatch = useDispatch();
  const notifications = useNotificationsSelector();
  useEffect(() => {
    dispatch(fetchNotificationAction(null));
  }, [dispatch]);
  return (
    <Dashboard>
      {notifications.isLoading ? (
        <Loader />
      ) : (
        <div className="reminders-page-container">
          <Form
            onFinish={(e) => {
              dispatch(updateNotificationAction(e));
            }}
          >
            <div className="reminders-page-body">
              <div className="reminders-page-body-header">
                Edit your reminders and notifications and apply updates to all
                your meeting types.
              </div>
              <InputWithLabel
                label="You"
                tooltip="Settings for you to receive reminders before all scheduled meeting using a Jammer scheduling page."
              >
                <div className="reminders-switch-group">
                  <div className="row">
                    <Form.Item
                      name={['user', 'emailReminder']}
                      valuePropName="checked"
                      initialValue={notifications.data.user.emailReminder}
                    >
                      <Switch size="small" />
                    </Form.Item>
                    <span>
                      Email reminder 30 minutes before a meeting starts with the
                      meeting location details
                    </span>
                  </div>
                  <div className="row">
                    <Form.Item
                      name={['user', 'textReminder']}
                      valuePropName="checked"
                      initialValue={
                        role === SubscriptionsEnum.free
                          ? false
                          : notifications.data.user.textReminder
                      }
                    >
                      <Switch
                        size="small"
                        disabled={
                          role === SubscriptionsEnum.free ? true : false
                        }
                      />
                    </Form.Item>
                    <span>
                      Text reminder 5 minutes before a meeting starts with the
                      meeting location details
                    </span>
                  </div>
                  <div className="row">
                    <Form.Item
                      name={['user', 'textJoinReminder']}
                      valuePropName="checked"
                      initialValue={
                        role === SubscriptionsEnum.free
                          ? false
                          : notifications.data.user.textJoinReminder
                      }
                    >
                      <Switch
                        size="small"
                        disabled={
                          role === SubscriptionsEnum.free ? true : false
                        }
                      />
                    </Form.Item>
                    <span>
                      Text notification when someone joins your conference line
                      before you
                    </span>
                  </div>
                </div>
              </InputWithLabel>
              <InputWithLabel
                label="Your Guests"
                tooltip="Settings for your guests to receive reminders before all scheduled meeting using a Jammer scheudling page."
              >
                <div className="reminders-switch-group">
                  <div className="row">
                    <Form.Item
                      name={['guest', 'emailReminder']}
                      valuePropName="checked"
                      initialValue={notifications.data.guest.emailReminder}
                    >
                      <Switch size="small" />
                    </Form.Item>
                    <span>
                      Email reminder 30 minutes before a meeting starts with the
                      meeting location details
                    </span>
                  </div>
                  <div className="row">
                    <Form.Item
                      name={['guest', 'textReminder']}
                      valuePropName="checked"
                      initialValue={
                        role === SubscriptionsEnum.free
                          ? false
                          : notifications.data.guest.textReminder
                      }
                    >
                      <Switch
                        size="small"
                        disabled={
                          role === SubscriptionsEnum.free ? true : false
                        }
                      />
                    </Form.Item>
                    <span>
                      Text reminder 5 minutes before a meeting starts with the
                      meeting location details
                    </span>
                  </div>
                </div>
              </InputWithLabel>
            </div>
            <div className="reminders-page-buttons">
              <span onClick={() => appHistory.push(routers.HOME)}>Cancel</span>
              <ButtonMenuStyled htmlType="submit">
                Save & Update All Meeting Types
              </ButtonMenuStyled>
            </div>
          </Form>
        </div>
      )}
    </Dashboard>
  );
};
