import React, { useEffect } from 'react';
import { Dashboard } from '../../components/dashboardLayout';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  editMeetingByIdAction,
  fetchMeetingByIdToEditAction,
} from '../../redux/actions';
import { useActiveMeetingSelect } from '../../redux/selectors/meetings';
import { Loader } from '../../components/loader';
import { SettingsForm } from './form';

export const MeetingSettings: React.FC = () => {
  const dispatch = useDispatch();
  const activeMeeting = useActiveMeetingSelect();
  const routeParams = useParams<{ meetingId: string }>();

  useEffect(() => {
    dispatch(fetchMeetingByIdToEditAction({ id: routeParams.meetingId }));
  }, [dispatch, routeParams.meetingId]);

  const submitHandler = (e: any) => {
    dispatch(editMeetingByIdAction({ id: routeParams.meetingId, fields: e }));
  };

  return (
    <Dashboard>
      {activeMeeting.isLoaded && activeMeeting.value ? (
        <SettingsForm
          activeMeeting={activeMeeting.value}
          submitHandler={submitHandler}
          submitName="Save Changes"
        />
      ) : (
        <Loader />
      )}
    </Dashboard>
  );
};
