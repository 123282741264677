import React from 'react';
import './index.scss';
import JammerIcon from '../../components/icons/jammer';

export const NotFoundPage: React.FC = () => {
  return (
    <div className="error-404-container">
      <div className="error-wrapper">
        <JammerIcon />
        <h1>404</h1>
        <div className="message">Page not found.</div>
      </div>
    </div>
  );
};
