import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Loader } from '../components/loader';
import { fireLoginGoogleCallbackAction } from '../redux/actions';
import { useDispatch } from 'react-redux';

export const Redirect: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    (() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dispatch(fireLoginGoogleCallbackAction(props?.location?.search));
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
      <Loader style={{ margin: '0 auto' }} />
    </div>
  );
};
