import { LoadableData } from '../interfaces';
import { createAction, Draft, PayloadActionCreator } from '@reduxjs/toolkit';

export type AsyncAction = {
  readonly REQUEST: string;
  readonly SUCCESS: string;
  readonly ERROR: string;
  readonly CLEAR_ERRORS: string;
};

export const createAsyncAction = (namespace: string): AsyncAction => {
  return {
    REQUEST: `${namespace}/REQUEST`,
    SUCCESS: `${namespace}/SUCCESS`,
    ERROR: `${namespace}/ERROR`,
    CLEAR_ERRORS: `${namespace}/CLEAR_ERRORS`,
  } as const;
};

export const setLoadingStatusToLoadableData = (
  v: Draft<LoadableData>,
): void => {
  v.isLoaded = false;
  v.isLoading = true;
};

export const setLoadedStatusToLoadableData = (v: Draft<LoadableData>): void => {
  v.isLoaded = true;
  v.isLoading = false;
};

/**
 * @example
 *  interface IRequest { id: string }
 *  interface IResponse { user: { id: string, name: string} }
 *  interface IError { code: string }
 *
 *  const {
 *    request: requestAction,
 *    success: successAction,
 *    error: errorAction
 *  } = actionBuilder<IRequest, IResponse, IError>(AsyncAction)
 */
export const actionBuilder = <TReq, TRes, TError>(
  action: AsyncAction,
): {
  request: PayloadActionCreator<TReq>;
  success: PayloadActionCreator<TRes>;
  error: PayloadActionCreator<TError>;
} => {
  return {
    request: createAction<TReq>(action.REQUEST),
    success: createAction<TRes>(action.SUCCESS),
    error: createAction<TError>(action.ERROR),
  };
};
