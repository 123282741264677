export const week = [
  {
    weekDay: 'SUN',
    month: 'FEB',
    day: '1',
    style: ' disable',
  },
  {
    weekDay: 'MON',
    month: 'FEB',
    day: '2',
    style: ' current',
  },
  {
    weekDay: 'TUE',
    month: 'FEB',
    day: '3',
    style: ' selected',
  },
  {
    weekDay: 'WED',
    month: 'FEB',
    day: '4',
    style: '',
  },
  {
    weekDay: 'THU',
    month: 'FEB',
    day: '5',
    style: '',
  },
  {
    weekDay: 'FRI',
    month: 'FEB',
    day: '6',
    style: '',
  },
  {
    weekDay: 'SAT',
    month: 'FEB',
    day: '7',
    style: ' disable',
  },
];

export const timesWeekPreview = [
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
];

export const timesMonthPreview = [
  '9:00 AM',
  '10:00 AM',
  '11:00 AM',
  '12:00 AM',
  '1:00 PM',
];

export const calendar = [
  {
    value: 'S',
    style: '',
  },
  {
    value: 'M',
    style: '',
  },
  {
    value: 'T',
    style: '',
  },
  {
    value: 'W',
    style: '',
  },
  {
    value: 'T',
    style: '',
  },
  {
    value: 'F',
    style: '',
  },
  {
    value: 'S',
    style: '',
  },
  {
    value: '',
    style: '',
  },
  {
    value: '1',
    style: 'current',
  },
  {
    value: '2',
    style: '',
  },
  {
    value: '3',
    style: '',
  },
  {
    value: '4',
    style: '',
  },
  {
    value: '5',
    style: '',
  },
  {
    value: '6',
    style: 'unavailable',
  },
  {
    value: '7',
    style: 'unavailable',
  },
  {
    value: '8',
    style: '',
  },
  {
    value: '9',
    style: '',
  },
  {
    value: '10',
    style: '',
  },
  {
    value: '11',
    style: 'selected',
  },
  {
    value: '12',
    style: '',
  },
  {
    value: '13',
    style: 'unavailable',
  },
  {
    value: '14',
    style: 'unavailable',
  },
  {
    value: '15',
    style: '',
  },
  {
    value: '16',
    style: '',
  },
  {
    value: '17',
    style: '',
  },
  {
    value: '18',
    style: '',
  },
  {
    value: '19',
    style: '',
  },
  {
    value: '20',
    style: 'unavailable',
  },
  {
    value: '21',
    style: 'unavailable',
  },
  {
    value: '22',
    style: '',
  },
  {
    value: '23',
    style: '',
  },
  {
    value: '24',
    style: '',
  },
  {
    value: '25',
    style: '',
  },
  {
    value: '26',
    style: '',
  },
  {
    value: '27',
    style: 'unavailable',
  },
  {
    value: '28',
    style: 'unavailable',
  },
  {
    value: '29',
    style: '',
  },
  {
    value: '30',
    style: '',
  },
  {
    value: '',
    style: '',
  },
  {
    value: '',
    style: '',
  },
  {
    value: '',
    style: '',
  },
  {
    value: '',
    style: '',
  },
];
