import React from 'react';
import './index.scss';

const GoogleIcon: React.FC<{ className?: string }> = (props) => {
  return (
    <svg
      className={`${
        props.className ? `${props.className} google-logo` : 'google-logo'
      }`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1213 13.8297H12.6967V10.8312H23.3883C23.457 11.2661 23.5 11.7126 23.5 12.2719C23.5 15.4646 22.3554 18.1696 20.3894 20.0769C18.4225 21.9849 15.6017 23.125 12.1967 23.125C5.70243 23.125 0.5 18.1328 0.5 12C0.5 5.86724 5.70243 0.875 12.1967 0.875C15.1433 0.875 17.6424 1.83284 19.6196 3.43158L17.0456 5.79048C14.7323 3.91846 11.6019 3.74656 9.0259 4.82012C6.30788 5.95285 4.13771 8.50037 4.13771 12C4.13771 16.3452 7.77448 19.8406 12.1967 19.8406C17.3194 19.8406 19.2976 16.3125 19.6145 14.4119L19.7116 13.8297H19.1213Z"
        fill="#231F20"
        stroke="#231F20"
      />
    </svg>
  );
};

export default GoogleIcon;
