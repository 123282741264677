import { DomainError, ValidationFailedError } from '../common/errors';

export class MeetingSlugConflictError extends ValidationFailedError {
  constructor(originalError?: Error) {
    super(
      [
        {
          message: `This url already exist`,
          code: 'string.slug.conflict',
          path: 'slug',
        },
      ],
      originalError,
    );
  }
}

export class MeetingNotFoundError implements DomainError {
  code = 'meetingNotFoundError' as const;
  message = `This meeting doesn't exist`;
  constructor(public originalError?: Error) {}
}

export class MeetingEventNotFoundError implements DomainError {
  code = 'meetingEventNotFoundError' as const;
  message = `Sorry, the event you're looking for can't be found.`;
  constructor(public originalError?: Error) {}
}

export class MeetingUnauthorizedError implements DomainError {
  code = 'meetingUnauthorizedError' as const;
  message = `Notifications only available for "Pro" or "Team" plan users`;
  constructor(public originalError?: Error) {}
}
