import React, { useState } from 'react';
import './index.scss';
import { ChromePicker, ColorResult } from 'react-color';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons/lib';
import { useSubscriptionPlanSelector } from '../../redux/selectors/auth';
import { SubscriptionsEnum } from '../../models/subscription.model';
import { Tooltip } from 'antd';

interface Props {
  color?: string | null;
  onChange?(value: string | undefined): void;
}

export const ColorPicker: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);
  const role = useSubscriptionPlanSelector() || SubscriptionsEnum.free;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const openHandler = (event: any) => {
    event.preventDefault();
    if (role !== SubscriptionsEnum.free) {
      setOpen(!open);
    }
  };

  const onRemoveHandler = (event: any): void => {
    event.preventDefault();
    if (props.onChange) {
      props.onChange('');
    }
  };

  const colorHandle = (selectedColor: ColorResult) => {
    if (props.onChange) {
      props.onChange(selectedColor.hex);
    }
  };

  const tooltipHandler = () => {
    if (role === SubscriptionsEnum.free) {
      setTooltipOpen(!tooltipOpen);
    }
  };

  return (
    <div
      className="colorPicker-item"
      style={props.color ? { backgroundColor: props.color } : undefined}
    >
      <Tooltip
        title="Brand color change is available only in the premium plan"
        placement="right"
        getPopupContainer={() => {
          const tooltipContainer = document.createElement('div');
          const root = document.getElementsByClassName('ant-layout');
          tooltipContainer.classList.add('tooltip-container');
          return root ? root[0].appendChild(tooltipContainer) : document.body;
        }}
        visible={tooltipOpen}
      >
        <div
          className="colorPicker-body"
          onPointerEnter={tooltipHandler}
          onPointerLeave={tooltipHandler}
        >
          {!props.color && (
            <div
              className="add-color"
              onClick={openHandler}
              style={
                role === SubscriptionsEnum.free
                  ? { cursor: 'not-allowed' }
                  : undefined
              }
            >
              <PlusOutlined />
              <p>Choose</p>
            </div>
          )}

          {props.color && (
            <div className="colorPicker-edit">
              <div className="delete-button">
                <DeleteOutlined onClick={onRemoveHandler} />
              </div>
              <div className="delete-button">
                <PlusOutlined onClick={openHandler} />
              </div>
            </div>
          )}
        </div>
      </Tooltip>
      {open ? (
        <div className="colorPicker-popover">
          <div className="colorPicker-cover" onClick={openHandler} />
          <ChromePicker
            color={props.color ? props.color : undefined}
            onChange={colorHandle}
          />
        </div>
      ) : null}
    </div>
  );
};
