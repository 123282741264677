import React from 'react';
import { RouterComponent } from './router/routing';
import { ModalDialog } from './components/modal';
import './styles/theme.scss';
import './styles/index.scss';

//MOBILE VIEWPORT FIX
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
const vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

function App(): JSX.Element {
  return (
    <>
      <RouterComponent />
      <ModalDialog />
    </>
  );
}

export default App;
