import React, { useCallback, useState } from 'react';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { sessionService } from '../../libs/auth/session.service';
import {
  useProfileUpdatingInfoSelector,
  useUserInfoSelector,
} from '../../redux/selectors/auth';
import './index.scss';
import {
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons/lib';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import {
  deleteUserAvatarAction,
  setAvatarSizeError,
  getProfileActionSuccess,
  showNotificationSuccessAction,
  //showNotificationAction,
} from '../../redux/actions';
import { useDispatch } from 'react-redux';
import { serverUrl } from '../../utils/axios-config';
import { getFirstErrorByPathName } from '../../utils/errors';

export const AvatarCropper: React.FC = () => {
  const dispatch = useDispatch();

  const headers = {
    Authorization: `Bearer ${sessionService.getAccessToken()?.token}`,
  };

  const profileUpdate = useProfileUpdatingInfoSelector();
  const avatar = useUserInfoSelector()?.avatar;

  const [avatarUrl, setAvatar] = useState(avatar);
  const [loading, setLoading] = useState(false);

  const handleChange = (info: UploadChangeParam): void => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (avatarUrl: string): void => {
        setAvatar(avatarUrl);
        setLoading(false);
        dispatch(getProfileActionSuccess({ user: info.file.response }));
        dispatch(
          showNotificationSuccessAction({
            content: 'Avatar upload.',
            duration: 2,
          }),
        );
      });
    }
  };

  const getErrorByName = useCallback(
    (name: string) => {
      return getFirstErrorByPathName(profileUpdate.errors, name);
    },
    [profileUpdate.errors],
  );

  const onRemoveHandler = (event: any): void => {
    event.preventDefault();
    setAvatar('');
    dispatch(deleteUserAvatarAction({ removeAvatar: true }));
  };

  function getBase64(img: any, callback: any): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file: File): boolean {
    const isFormat =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/gif';
    if (!isFormat) {
      dispatch(
        setAvatarSizeError({
          message: 'You can only upload JPG/PNG/GIF file!',
        }),
      );
      // Not deleting this for any case
      // dispatch(
      //   showNotificationAction({
      //     type: 'info',
      //     content: 'You can only upload JPG/PNG/GIF file!',
      //     duration: null,
      //   }),
      // );
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      dispatch(
        setAvatarSizeError({
          message: 'Image must be smaller than 5MB!',
        }),
      );
      // Not deleting this for any case
      // dispatch(
      //   showNotificationAction({
      //     type: 'info',
      //     content: 'Image must smaller than 5MB!',
      //     duration: null,
      //   }),
      // );
    }

    return isFormat && isLt2M;
  }

  const uploadButton = (
    <div className="logoPhoto-add">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div>Upload</div>
    </div>
  );

  return (
    <div className="avatar-form-item">
      <div className="uploader-block">
        <ImgCrop rotate>
          {avatarUrl ? (
            <img src={avatarUrl} alt="avatar" className="profile-avatar-img" />
          ) : (
            <Upload
              name="file"
              accept="image/*"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={`${serverUrl}/api/users/profile`}
              headers={headers}
              method="PUT"
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {uploadButton}
            </Upload>
          )}
        </ImgCrop>
        {avatarUrl && (
          <div className="bg-layer">
            <div className="delete-button" onClick={onRemoveHandler}>
              <DeleteOutlined />
            </div>
          </div>
        )}
      </div>
      {/*<div className="text-note text-note-color mt-2">*/}
      {/*  JPG, GIF or PNG. Max size of 5MB.*/}
      {/*</div>*/}
      <div className="uploader-error">{getErrorByName('avatar')?.message}</div>
    </div>
  );
};
