import React, { useState } from 'react';
import './index.scss';
import { Button } from '../../../../../components/button';
import { PhoneInputComponent } from '../../../../../components/phoneInput';
import { userService } from '../../../../../libs/user/user.service';
import { useDispatch } from 'react-redux';
import { showNotificationErrorAction } from '../../../../../redux/actions';

interface CompleteProfilePhoneProps {
  next(): void;
  phone: string;
  setPhone?(
    value: string,
    data: any,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string,
  ): void;
}

export const CompleteProfilePhone: React.FC<CompleteProfilePhoneProps> = (
  props,
) => {
  const { next, setPhone, phone } = props;
  const [valid, setValid] = useState(false);
  const dispatch = useDispatch();

  const nextHandler = async () => {
    const validationResponse = await userService.phoneValidation(phone);
    if (!validationResponse.valid) {
      dispatch(
        showNotificationErrorAction({
          content: 'Phone is exist. Enter another phone.',
          duration: 3,
        }),
      );
    } else {
      next();
    }
  };
  return (
    <div className="complete-profile-container">
      <div className="complete-profile-header">
        What is your mobile phone number?
      </div>
      <div className="complete-profile-title">
        Used for creating your pinless conference line and receiving meeting
        text reminders.
      </div>
      <div className="complete-profile-phone-container">
        <PhoneInputComponent
          containerClass="complete-profile-phone"
          onChange={setPhone}
          onValidPhone={setValid}
          value={phone}
        />
      </div>

      <Button onClick={nextHandler} disabled={!valid}>
        Continue
      </Button>
    </div>
  );
};
