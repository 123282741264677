import React from 'react';

const CalendarIcon: React.FC<{ className?: string }> = (props) => {
  return (
    <svg
      className={`${props.className || ''}`}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83333 13.3333C5.83333 12.875 6.20833 12.5 6.66667 12.5C7.125 12.5 7.5 12.875 7.5 13.3333C7.5 13.7917 7.125 14.1667 6.66667 14.1667C6.20833 14.1667 5.83333 13.7917 5.83333 13.3333ZM10 12.5H13.3333C13.7917 12.5 14.1667 12.875 14.1667 13.3333C14.1667 13.7917 13.7917 14.1667 13.3333 14.1667H10C9.54167 14.1667 9.16667 13.7917 9.16667 13.3333C9.16667 12.875 9.54167 12.5 10 12.5ZM15 16.6667H5C4.54083 16.6667 4.16667 16.2925 4.16667 15.8333V10.8333H15.8333V15.8333C15.8333 16.2925 15.4592 16.6667 15 16.6667ZM5 5.00001H5.83333V5.83334C5.83333 6.29167 6.20833 6.66667 6.66667 6.66667C7.125 6.66667 7.5 6.29167 7.5 5.83334V5.00001H12.5V5.83334C12.5 6.29167 12.875 6.66667 13.3333 6.66667C13.7917 6.66667 14.1667 6.29167 14.1667 5.83334V5.00001H15C15.4592 5.00001 15.8333 5.37417 15.8333 5.83334V9.16667H4.16667V5.83334C4.16667 5.37417 4.54083 5.00001 5 5.00001ZM15 3.33334H14.1667V2.50001C14.1667 2.04167 13.7917 1.66667 13.3333 1.66667C12.875 1.66667 12.5 2.04167 12.5 2.50001V3.33334H7.5V2.50001C7.5 2.04167 7.125 1.66667 6.66667 1.66667C6.20833 1.66667 5.83333 2.04167 5.83333 2.50001V3.33334H5C3.62167 3.33334 2.5 4.45501 2.5 5.83334V15.8333C2.5 17.2117 3.62167 18.3333 5 18.3333H15C16.3783 18.3333 17.5 17.2117 17.5 15.8333V5.83334C17.5 4.45501 16.3783 3.33334 15 3.33334Z"
        fill="#231F20"
      />
    </svg>
  );
};

export default CalendarIcon;
