import { useSelector } from './common';
import {
  ApproveEmailInfo,
  ForgotPasswordInfo,
  InviteUserInfo,
  OnboardingAuthInfo,
  ResetForgotPasswordInfo,
} from '../reducers/auth';
import { UserModel } from '../../models/user.model';
import {
  SessionProfileCredentialsChangingState,
  SessionProfileDeletingState,
  SessionProfileUpdatingState,
  SessionState,
} from '../reducers/session';
import { SubscriptionsEnum } from '../../models/subscription.model';
import { BillingStat } from '../actions';

export const useOnboardingAuthInfoSelector = (): OnboardingAuthInfo => {
  return useSelector((state) => state.auth.onboarding);
};

export const useSessionAuthInfoSelector = (): SessionState => {
  return useSelector((state) => state.session);
};

export const useUserInfoSelector = (): UserModel | null => {
  return useSelector((state) => state.session.user);
};

export const useBillingInfo = (): BillingStat => {
  return useSelector((state) => state.session.billingStat);
};

export const useSubscriptionSelector = (): any => {
  return useSelector((state) => state.session?.user?.subscription);
};

export const useSubscriptionPlanSelector = ():
  | SubscriptionsEnum
  | undefined => {
  return useSelector((state) => state.session.user?.subscription?.typeName);
};

export const useCalendarAccountsSelector = (): any => {
  return useSelector((state) => state.session.calendarAccounts);
};

/**
 * A wrapper function for the useUserInfoSelector so we don't have to check no null exception
 */
export const useUserInfoAfterAuthSelector = (): UserModel => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useSelector((state) => state.session.user)! || {};
};

export const useProfileUpdatingInfoSelector = (): SessionProfileUpdatingState => {
  return useSelector((state) => state.session.profileUpdating);
};

export const useProfileDeletingInfoSelector = (): SessionProfileDeletingState => {
  return useSelector((state) => state.session.profileDeleting);
};

export const useProfileCredentialsInfoSelector = (): SessionProfileCredentialsChangingState => {
  return useSelector((state) => state.session.profileCredentials);
};

export const useForgotPasswordInfoSelector = (): ForgotPasswordInfo => {
  return useSelector((state) => state.auth.forgotPassword);
};

export const useResetForgotPasswordInfoSelector = (): ResetForgotPasswordInfo => {
  return useSelector((state) => state.auth.resetForgotPassword);
};

export const useApproveEmailInfoSelector = (): ApproveEmailInfo => {
  return useSelector((state) => state.auth.approveEmail);
};

export const useInviteUserInfoSelector = (): InviteUserInfo => {
  return useSelector((state) => state.auth.inviteUser);
};

export const useIsRememberMeSelector = (): boolean => {
  return useSelector((state) => state.session.isRememberMe);
};
