export const routers = {
  LOGIN_PAGE: '/login',
  SIGN_UP_PAGE: '/sign-up',
  GOOGLE_CALLBACK_PAGE: '/auth-google-callback',
  ME_PAGE: '/home',
  LANDING_PAGE: '/',
  FORGOT_PASSWORD_PAGE: '/forgot-password',
  SET_FORGOT_PASSWORD_PAGE: '/reset-forgot-password',
  CONFIRM_USER_EMAIL_PAGE: '/approve-account',
  WAITING_TO_CONFIRM_USER_EMAIL_PAGE: '/approve-account-waiting',
  COMPLETE_PROFILE_INFO_PAGE: '/compile-profile-info',

  ACCOUNT_LINK_PAGE: '/account-link',
  ACCOUNT_LOGIN_PAGE: '/account-login',
  ACCOUNT_HAS_BEEN_DELETED: '/account-has-been-deleted',

  MEETING_EDITING: '/meetings/:meetingId/edit',
  BOOKING_PAGE: '/:userSlug/:meetingSlug',
  BOOKING_DETAILS_PAGE: '/:userSlug/:meetingSlug/:dateTime',
  BOOKING_COMPLETE_EVENT: '/:userSlug/:meetingSlug/invitees/:eventId',
  ORGANIZATION_USERS_PAGE: '/organization/users',
  ORGANIZATION_INVITATIONS_PAGE: '/organization/invitations',
  ORGANIZATION_INVITATIONS_NEW: '/organization/invitations/new',
  INVITING: '/inviting/:invitingToken',
  EVENT_CANCELLATION: '/cancellation/:eventId',
  EVENT_RESCHEDULING: '/rescheduling/:userSlug/:meetingSlug/:eventId/:token',
  EVENT_RESCHEDULING_DETAILS: '/rescheduling/:eventId/:dateTime',
  CALENDAR_CONNECTIONS: '/calendar-connections',
  CALENDAR_CONNECTIONS_DISCONNECT: '/calendar-connections/:accountId',

  // GET_ICS_FILE: '/meetings/d/:eventId',
  REDIRECT_GOOGLE_CALLBACK: '/redirect',
  ERROR_PAGE: '/error-page',
  //backend endpoints
  GOOGLE_CALENDAR_CALLBACK: '/api/auth/login/google',
  //new routes
  HOME: '/home',
  AVAILABILITY: '/availability',
  REMINDERS: '/reminders',
  DESIGN: '/design',
  CALENDARS: '/calendars',
  BILLING: '/billing',
  ACCOUNT_SETTINGS_PAGE: '/account-settings',
  USER_BOOKINGS_PAGE: '/:userSlug/',
  NOT_FOUND: '/notFound',
  GET_MEETING_EDIT: (id: string): string => `/meetings/${id}/edit`,
  MEETING_CREATING: '/meetings/new',
};
