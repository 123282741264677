import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';
import { routers } from './routes';
import { AuthenticatedRouteComponent } from './auth-router';
import { Redirect } from './redirect';
import { AnimatedSwitch } from 'react-router-transition';
import { LoginPageComponent } from '../pages/auth/login';
import { CompleteProfileInfoPage } from '../pages/auth/complete-profile-info';
import { useThemeSelector } from '../redux/selectors/themes';
import { Home } from '../pages/home';
import { Availability } from '../pages/availability';
import { Reminders } from '../pages/reminders/';
import { Design } from '../pages/design';
import { Calendars } from '../pages/calendars';
import { AccountSettings } from '../pages/accountSettings';
import { Billing } from '../pages/billing';
import { MeetingSettings } from '../pages/meetingSettings';
import { MainLink } from '../pages/mainLink';
import { SchedulePage } from '../pages/schedule';
import { useEffect } from 'react';
import { THEMES } from '../redux/constants/theme';
import { setDarkTheme } from '../redux/actions/theme';
import { useDispatch } from 'react-redux';
import { NewMeeting } from '../pages/newMeeting';
import { NotFoundPage } from '../pages/notFound';
import { ReschedulePage } from '../pages/reschedule';

export const RouterComponent: React.FC = () => {
  const theme = useThemeSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    const localTheme = localStorage.getItem('theme');
    if (localTheme && theme.color !== localTheme) {
      if (localTheme === THEMES.LIGHT) {
        dispatch(setDarkTheme({ data: THEMES.LIGHT }));
      } else {
        dispatch(setDarkTheme({ data: THEMES.DARK }));
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Layout className={theme.color}>
      <Switch>
        <AuthenticatedRouteComponent
          path={routers.MEETING_CREATING}
          component={NewMeeting}
        />
        <AuthenticatedRouteComponent
          path={routers.MEETING_EDITING}
          component={MeetingSettings}
        />
        {/*<Route path={routers.GET_ICS_FILE} />*/}
        <Route path={routers.BOOKING_PAGE} component={SchedulePage} exact />
        <Route
          path={routers.REDIRECT_GOOGLE_CALLBACK}
          exact
          component={Redirect}
        />
        <Route path={[routers.COMPLETE_PROFILE_INFO_PAGE, routers.ME_PAGE]}>
          <AnimatedSwitch
            atEnter={{ offset: 100 }}
            atLeave={{ offset: -100 }}
            atActive={{ offset: 0 }}
            mapStyles={(style) => ({
              transform: `translateY(${style.offset}%)`,
            })}
            className="route-wrapper"
          >
            <AuthenticatedRouteComponent
              path={routers.COMPLETE_PROFILE_INFO_PAGE}
              component={CompleteProfileInfoPage}
            />
            <AuthenticatedRouteComponent path={routers.HOME} component={Home} />
          </AnimatedSwitch>
        </Route>
        <AuthenticatedRouteComponent
          path={routers.AVAILABILITY}
          component={Availability}
          exact
        />
        <AuthenticatedRouteComponent
          path={routers.REMINDERS}
          component={Reminders}
          exact
        />
        <AuthenticatedRouteComponent path={routers.DESIGN} component={Design} />
        <AuthenticatedRouteComponent
          path={routers.CALENDARS}
          component={Calendars}
          exact
        />
        <AuthenticatedRouteComponent
          path={routers.ACCOUNT_SETTINGS_PAGE}
          component={AccountSettings}
          exact
        />
        <AuthenticatedRouteComponent
          path={routers.BILLING}
          strict
          component={Billing}
          exact
        />
        <Route
          path={routers.LANDING_PAGE}
          component={LoginPageComponent}
          exact
        />
        <Route path={routers.NOT_FOUND} component={NotFoundPage} exact />
        <Route
          path={routers.EVENT_RESCHEDULING}
          component={ReschedulePage}
          exact
        />
        <Route path={routers.USER_BOOKINGS_PAGE} component={MainLink} exact />
        <Route component={NotFoundPage} />
      </Switch>
    </Layout>
  );
};
