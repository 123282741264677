import React from 'react';
import { useThemeSelector } from '../../../redux/selectors/themes';
import { ReactComponent as DarkVisaLogo } from './dark_visa.svg';
import { ReactComponent as LightVisaLogo } from './light_visa.svg';
import { ReactComponent as CardDefaultLogo } from './credit-card-svgrepo-com.svg';
import './index.scss';

interface props {
  brand?: string;
}

export const CardLogo: React.FC<props> = (props) => {
  const theme = useThemeSelector();
  const render = () => {
    if (props.brand === 'Visa') {
      if (theme.color === 'dark') {
        return <DarkVisaLogo />;
      } else {
        return <LightVisaLogo />;
      }
    } else {
      return <CardDefaultLogo className="default-card" />;
    }
  };
  return <>{render()}</>;
};
