import { AccessTokenDto } from '../auth/dtos/access-token.dto';
import {
  CancellationEventRequestDto,
  ChangeNotificationsPolicyRequest,
  CreateMeetingEventResponse,
  CreateNewMeetingRequest,
  CreateNewMeetingResponse,
  CreateScheduleMeetingEventRequest,
  DeleteMeetingDto,
  FetchBookEventByIdRequestDto,
  FetchBookEventRequestDto,
  FetchingMeetingsResponseDto,
  FetchMeetingByIdResponseDto,
  FetchMeetingDataBookEmailResponse,
  FetchMeetingEventInfoResponse,
  FetchMeetingInfoBySlugRequest,
  FetchMeetingInfoBySlugResponse,
  FetchMeetingSlotsRequest,
  FetchMeetingSlotsResponse,
  ReschedulingEventRequestDto,
  UpdateMeetingByIdRequest,
  UpdateMeetingByIdResponse,
  FetchMeetingsBySlugRequest,
} from './dtos/api.dto';
import {
  CreateMeetingScheduleEventResult,
  CreateNewMeetingResult,
  DeleteMeetingResult,
  FetchMeetingDataBookEmailResult,
  FetchMeetingEventInfoResult,
  FetchMeetingSlotsToBookEventResult,
  FetchMeetingToBookEventResult,
  FindMeetingByIdToEditResult,
  FindMyMeetingsResult,
  RescheduleEventResult,
  UpdateMeetingByIdResult,
  FetchMainLinkResult,
  RescheduleDataResponseDto,
} from './dtos/meeting.dto';
import { Either, left, right } from '@sweet-monads/either';
import { OffsetPaginationDto } from '../common/dtos/offset-pagination.dto';
import { UnknownError, ValidationFailedError } from '../common/errors';
import * as _ from 'lodash';
import { ErrorCodes, isAxiosError } from '../../utils/errors';
import { AuthTokenExpiredError, AuthTokenInvalidError } from '../auth/errors';
import {
  MeetingEventNotFoundError,
  MeetingNotFoundError,
  MeetingSlugConflictError,
  MeetingUnauthorizedError,
} from './errors';
import { formantDate, TimeFormats } from '../common/utils';
import BaseAxiosInstance from '../../utils/axios-config';
import {
  MainLinkResponseModel,
  MeetingOnListModel,
} from '../../models/meeting.model';

export class MeetingApi {
  async findMyMeetings(
    accessToken: AccessTokenDto,
    page?: number,
  ): Promise<FindMyMeetingsResult> {
    try {
      const result = await BaseAxiosInstance.get<
        OffsetPaginationDto<FetchingMeetingsResponseDto>
      >('/api/meetings/my', {
        params: {
          page,
        },
        headers: { Authorization: `Bearer ${accessToken.token}` },
      });
      return right(result.data);
    } catch (e) {
      return left(new UnknownError(e));
    }
  }

  async findMeetingId(
    accessToken: AccessTokenDto,
    meetingId: string,
  ): Promise<FindMeetingByIdToEditResult> {
    try {
      const result = await BaseAxiosInstance.get<FetchMeetingByIdResponseDto>(
        `/api/meetings/${meetingId}`,
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );
      return right(result.data);
    } catch (e) {
      return this.meetingNotFoundErrorHandler<FindMeetingByIdToEditResult>(e);
    }
  }

  async updateMeetingId(
    accessToken: AccessTokenDto,
    meetingId: string,
    dto: UpdateMeetingByIdRequest,
  ): Promise<UpdateMeetingByIdResult> {
    try {
      const cleanedDto = _.omitBy(dto, _.isUndefined);
      if (_.isEmpty(cleanedDto)) {
        return right(null);
      }
      const result = await BaseAxiosInstance.put<UpdateMeetingByIdResponse>(
        `/api/meetings/${meetingId}`,
        cleanedDto,
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );
      return right(result.data);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }

      switch (e.response.data.code) {
        case ErrorCodes.validationFailed:
          return left(new ValidationFailedError(e.response.data.details, e));
        case ErrorCodes.authTokenInvalid:
          return left(new AuthTokenInvalidError(e));
        case ErrorCodes.authTokenExpired:
          return left(new AuthTokenExpiredError(e));
        case ErrorCodes.meetingSlugConflict:
          return left(new MeetingSlugConflictError(e));
        case ErrorCodes.meetingNotFound:
          return left(new MeetingNotFoundError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async updateMeetingsOrder(
    accessToken: AccessTokenDto,
    dto: MeetingOnListModel[],
  ): Promise<FindMyMeetingsResult> {
    try {
      const result = await BaseAxiosInstance.put<
        OffsetPaginationDto<FetchingMeetingsResponseDto>
      >(`/api/meetings/my`, dto, {
        headers: { Authorization: `Bearer ${accessToken.token}` },
      });
      return right(result.data);
    } catch (e) {
      return this.meetingNotFoundErrorHandler<FindMeetingByIdToEditResult>(e);
    }
  }

  async createNewMeeting(
    accessToken: AccessTokenDto,
    dto: CreateNewMeetingRequest,
  ): Promise<CreateNewMeetingResult> {
    try {
      const result = await BaseAxiosInstance.post<CreateNewMeetingResponse>(
        `/api/meetings/my`,
        dto,
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );
      return right(result.data);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }

      switch (e.response.data.code) {
        case ErrorCodes.validationFailed:
          return left(new ValidationFailedError(e.response.data.details, e));
        case ErrorCodes.authTokenInvalid:
          return left(new AuthTokenInvalidError(e));
        case ErrorCodes.authTokenExpired:
          return left(new AuthTokenExpiredError(e));
        case ErrorCodes.meetingSlugConflict:
          return left(new MeetingSlugConflictError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async findByPublicLink(
    dto: FetchMeetingInfoBySlugRequest,
  ): Promise<FetchMeetingToBookEventResult> {
    try {
      const result = await BaseAxiosInstance.get<
        FetchMeetingInfoBySlugResponse
      >(`/api/meetings/view/${dto.userSlug}/${dto.meetingSlug}`);
      return right({
        ...result.data.meeting,
        owner: result.data.user,
        design: result.data.design,
      });
    } catch (e) {
      return left(new UnknownError(e));
    }
  }

  async getRescheduleData(
    eventId: string,
    token: string,
  ): Promise<RescheduleDataResponseDto | undefined> {
    try {
      const result = await BaseAxiosInstance.get<
        RescheduleDataResponseDto | undefined
      >(`/api/meetings/events/${eventId}/${token}`);
      return result.data;
    } catch (e) {}
  }

  async createReScheduleMeetingEvent(
    dto: CreateScheduleMeetingEventRequest,
    eventId: string,
    token: string,
  ): Promise<CreateMeetingScheduleEventResult> {
    try {
      const result = await BaseAxiosInstance.post<CreateMeetingEventResponse>(
        `/api/meetings/events/${eventId}/rescheduling/${token}`,
        dto,
      );
      return right(result.data);
    } catch (e) {
      return left(new UnknownError(e));
    }
  }

  async fetchMainLink(
    dto: FetchMeetingsBySlugRequest,
  ): Promise<FetchMainLinkResult> {
    try {
      const result = await BaseAxiosInstance.get<MainLinkResponseModel>(
        `/api/meetings/view/${dto.userSlug}`,
      );
      return right(result.data);
    } catch (e) {
      return left(new UnknownError(e));
    }
  }

  async fetchMeetingSlotsToBookEvent(
    dto: FetchMeetingSlotsRequest,
  ): Promise<FetchMeetingSlotsToBookEventResult> {
    const from = formantDate(dto.rangeFrom, TimeFormats.YYYY_MM_DD);
    const to = formantDate(dto.rangeTo, TimeFormats.YYYY_MM_DD);
    try {
      const result = await BaseAxiosInstance.get<FetchMeetingSlotsResponse>(
        `/api/meetings/${dto.meetingId}/booking_range?range_from=${from}&range_to=${to}&timezone=${dto.timezone}`,
      );
      return right(result.data);
    } catch (e) {
      return left(new UnknownError(e));
    }
  }

  async fetchMeetingDataBookEmail(
    accessToken: AccessTokenDto,
    meetingId: string,
  ): Promise<FetchMeetingDataBookEmailResult> {
    try {
      const result = await BaseAxiosInstance.get<
        FetchMeetingDataBookEmailResponse
      >(`/api/meetings/${meetingId}/book_by_email`, {
        headers: { Authorization: `Bearer ${accessToken.token}` },
      });
      return right(result.data);
    } catch (e) {
      return left(new UnknownError(e));
    }
  }

  async changeNotificationReminders(
    accessToken: AccessTokenDto,
    dto: ChangeNotificationsPolicyRequest,
  ): Promise<any> {
    try {
      const result = await BaseAxiosInstance.post<any>(
        '/api/meetings/event/notifications',
        dto,
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );

      return right(result.data);
    } catch (e) {
      return this.meetingNotFoundErrorHandler<Error>(e);
    }
  }

  async createScheduleMeetingEvent(
    dto: CreateScheduleMeetingEventRequest,
  ): Promise<CreateMeetingScheduleEventResult> {
    try {
      const result = await BaseAxiosInstance.post<CreateMeetingEventResponse>(
        `/api/meetings/${dto.meetingId}/events`,
        dto,
      );
      return right(result.data);
    } catch (e) {
      return left(new UnknownError(e));
    }
  }

  async fetchBookEventInfo({
    userSlug,
    meetingSlug,
    eventId,
  }: FetchBookEventRequestDto): Promise<FetchMeetingEventInfoResult> {
    try {
      const result = await BaseAxiosInstance.get<FetchMeetingEventInfoResponse>(
        `/api/meetings/view/${userSlug}/${meetingSlug}/events/${eventId}`,
      );

      return right(result.data);
    } catch (e) {
      switch (e.response.data.code) {
        case ErrorCodes.meetingEventNotFound:
          return left(new MeetingEventNotFoundError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async fetchBookEventById({ id }: FetchBookEventByIdRequestDto): Promise<any> {
    try {
      const result = await BaseAxiosInstance.get<FetchMeetingEventInfoResponse>(
        `/api/meetings/events/${id}`,
      );

      return right(result.data);
    } catch (e) {
      switch (e.response.data.code) {
        case ErrorCodes.meetingEventNotFound:
          return left(new MeetingEventNotFoundError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async cancellationEvent({
    id,
    reason,
  }: CancellationEventRequestDto): Promise<FetchMeetingEventInfoResult> {
    try {
      const result = await BaseAxiosInstance.post<
        FetchMeetingEventInfoResponse
      >(`/api/meetings/events/${id}/cancellations`, { reason });

      return right(result.data);
    } catch (e) {
      switch (e.response.data.code) {
        case ErrorCodes.meetingEventNotFound:
          return left(new MeetingEventNotFoundError(e));
        case ErrorCodes.validationFailed:
          return left(new ValidationFailedError(e.response.data.details, e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async reschedulingEvent(
    dto: ReschedulingEventRequestDto,
  ): Promise<RescheduleEventResult> {
    try {
      const result = await BaseAxiosInstance.post(
        `/api/meetings/events/${dto.eventId}/rescheduling`,
        { reason: dto.reason, eventStartDate: dto.eventStartDate },
      );
      return right(result.data);
    } catch (e) {
      switch (e.response.data.code) {
        case ErrorCodes.meetingEventNotFound:
          return left(new MeetingEventNotFoundError(e));
        case ErrorCodes.validationFailed:
          return left(new ValidationFailedError(e.response.data.details, e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async deleteEvent(
    accessToken: AccessTokenDto,
    dto: DeleteMeetingDto,
  ): Promise<DeleteMeetingResult> {
    try {
      const result: boolean = await BaseAxiosInstance.delete(
        `/api/meetings/${dto.meetingId}`,
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );
      return right(result);
    } catch (e) {
      switch (e.response.data.code) {
        case ErrorCodes.meetingEventNotFound:
          return left(new MeetingEventNotFoundError(e));
        case ErrorCodes.validationFailed:
          return left(new ValidationFailedError(e.response.data.details, e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async meetingNotFoundErrorHandler<T>(e: Error): Promise<Either<any, any>> {
    if (!isAxiosError(e)) {
      return left(new UnknownError(e));
    }
    if (!e.response) {
      return left(new UnknownError(e));
    }
    switch (e.response.status) {
      case ErrorCodes.validationFailed:
        return left(new ValidationFailedError(e.response.data.details, e));
      case ErrorCodes.authTokenInvalid:
        return left(new AuthTokenInvalidError(e));
      case ErrorCodes.authTokenExpired:
        return left(new AuthTokenExpiredError(e));
      case ErrorCodes.meetingNotFound:
        return left(new MeetingNotFoundError(e));
      case ErrorCodes.subscriptionUnauthorized:
        return left(new MeetingUnauthorizedError(e));
      default:
        return left(new UnknownError(e));
    }
  }
}
