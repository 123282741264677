import React, { useCallback } from 'react';
import './index.scss';
import { CalendarStyleType } from '../../pages/design';
import { UserLogo } from '../userLogo';
import {
  useSubscriptionPlanSelector,
  useUserInfoSelector,
} from '../../redux/selectors/auth';
import { ClockIcon } from '../icons/clock/clockIcon';
import { LocationScheduleIcon } from '../icons/locationMarkerSchedule/locationSchedule';
import EarthIcon from '../icons/earth';
import { MonthPreview } from './month';
import { WeekPreview } from './week';
import JammerSmIcon from '../icons/jammer-sm';
import { SubscriptionsEnum } from '../../models/subscription.model';

interface props {
  style: CalendarStyleType;
  color?: string | null;
  darkMode: boolean;
  logo: string | null;
}

export const PreviewDesign: React.FC<props> = (props) => {
  const user = useUserInfoSelector();
  const role = useSubscriptionPlanSelector() || SubscriptionsEnum.free;

  const setColor = useCallback(() => {
    const container = document.querySelectorAll('.preview-component-container');
    for (let i = 0; i < container.length; i++) {
      if (props.color) {
        (container[i] as HTMLInputElement).style.backgroundColor = props.color;
      } else {
        (container[i] as HTMLInputElement).style.removeProperty(
          'background-color',
        );
      }
    }

    const current = document.querySelectorAll(
      '.weekPreview-calendar-day.current',
    );
    for (let i = 0; i < current.length; i++) {
      if (props.color) {
        (current[i] as HTMLInputElement).style.color = props.color;
      } else {
        (current[i] as HTMLInputElement).style.removeProperty('color');
      }
    }

    const selected = document.querySelectorAll(
      '.weekPreview-calendar-day.selected',
    );
    for (let i = 0; i < selected.length; i++) {
      if (props.color) {
        (selected[i] as HTMLInputElement).style.backgroundColor = props.color;
      } else {
        (selected[i] as HTMLInputElement).style.removeProperty(
          'background-color',
        );
      }
    }

    const selectedMonth = document.querySelectorAll(
      '.calendar-item.selected p',
    );

    for (let i = 0; i < selectedMonth.length; i++) {
      if (props.color) {
        (selectedMonth[i] as HTMLInputElement).style.backgroundColor =
          props.color;
      } else {
        (selectedMonth[i] as HTMLInputElement).style.removeProperty(
          'background-color',
        );
      }
    }
  }, [props.color]);

  return (
    <div
      className={`preview-component-container${
        props.darkMode ? ' dark' : ' light'
      }`}
    >
      <div className="preview-component">
        <div className="preview-header">
          <UserLogo
            avatar={role !== SubscriptionsEnum.free ? props.logo : null}
          />
          <div className="selectTime-header-username">
            Schedule With {user?.name}
          </div>
          <div className="selectTime-header-settings">
            <div className="settings-duration">
              <ClockIcon />
              15 Minutes
            </div>
            <div className="settings-location">
              <LocationScheduleIcon />
              Conference Line
            </div>
            <div className="settings-timezoneSelect">
              <EarthIcon className="timezone-selector-icon" />
              <div className="timezone-name">UTC</div>
            </div>
          </div>
        </div>
        <div className="preview-body">
          <div className="body-title">Choose Your Day & Time</div>
          {props.style === 'month' ? (
            <MonthPreview color={props.color} setColor={setColor} />
          ) : (
            <WeekPreview color={props.color} setColor={setColor} />
          )}
        </div>
      </div>
      <div className="preview-footer">
        <span>Powered By</span>
        <JammerSmIcon />
      </div>
    </div>
  );
};
