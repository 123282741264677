import _ from 'lodash';

const environment = process.env.NODE_ENV || 'development';

export default _.extend(
  {
    environment,
  },
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require(`${__dirname}/env/${environment}`),
);
