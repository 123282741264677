import { createReducer } from '@reduxjs/toolkit';
import {
  forgotPasswordAction,
  forgotPasswordActionError,
  forgotPasswordActionSuccess,
  getAuthMethodByEmailAction,
  getAuthMethodByEmailActionError,
  getAuthMethodByEmailActionSuccess,
  loginByGoogleActionSuccess,
  loginByPasswordAction,
  loginByPasswordActionError,
  loginByPasswordActionSuccess,
  logoutActionSuccess,
  cleanupAuthMethodAction,
  cleanupForgotPasswordInfo,
  signUpByPassword,
  signUpByPasswordError,
  signUpByPasswordSuccess,
  resetForgotPasswordAction,
  resetForgotPasswordActionError,
  resetForgotPasswordActionSuccess,
  approveUserEmailAction,
  approveUserEmailErrorAction,
  approveUserEmailSuccessAction,
  changeAuthMethodAction,
  inviteUserAcceptErrorAction,
  inviteCheckUserSuccessAction,
  inviteUserAcceptSuccessAction,
  inviteCheckUserErrorAction,
} from '../actions';
import { AuthMethodType } from '../../models/auth.model';
import { LoadableData } from '../interfaces';
import { ExceptionDetails } from '../../utils/errors';
import {
  setLoadedStatusToLoadableData,
  setLoadingStatusToLoadableData,
} from '../utils';

export interface OnboardingAuthInfo extends LoadableData {
  method: AuthMethodType | null | undefined;
  errors: ExceptionDetails;
  email: string;
}

export interface ForgotPasswordInfo extends LoadableData {
  errors: ExceptionDetails;
  isSuccess: boolean;
}

export interface ResetForgotPasswordInfo extends LoadableData {
  errors: ExceptionDetails;
  isSuccess: boolean;
}

export interface ApproveEmailInfo extends LoadableData {
  errors: ExceptionDetails;
  isSuccess: boolean;
}

export interface InviteUserInfo {
  accept: {
    type: null | string;
    message: string;
  };
  status: {
    adminName: null | string;
    haveToRegistration: boolean;
    userEmail: null | string;
  };
}

export interface AuthState {
  onboarding: OnboardingAuthInfo;
  forgotPassword: ForgotPasswordInfo;
  resetForgotPassword: ResetForgotPasswordInfo;
  approveEmail: ApproveEmailInfo;
  inviteUser: InviteUserInfo;
}

export const initialAuthReducer: AuthState = {
  onboarding: {
    email: '',
    errors: [],
    isLoaded: false,
    isLoading: false,
    method: undefined,
  },
  forgotPassword: {
    isLoading: false,
    isLoaded: false,
    errors: [],
    isSuccess: false,
  },
  resetForgotPassword: {
    isLoaded: false,
    isLoading: false,
    errors: [],
    isSuccess: false,
  },
  approveEmail: {
    isLoading: false,
    isLoaded: false,
    isSuccess: false,
    errors: [],
  },
  inviteUser: {
    accept: {
      type: null,
      message: '',
    },
    status: {
      adminName: null,
      haveToRegistration: true,
      userEmail: null,
    },
  },
};

export const authReducer = createReducer<AuthState>(
  initialAuthReducer,
  (builder) =>
    builder
      .addCase(getAuthMethodByEmailAction, (state, action) => {
        state.onboarding.method = undefined;
        state.onboarding.email = action.payload.email;
        state.onboarding.errors = [];
        setLoadingStatusToLoadableData(state.onboarding);
      })
      .addCase(getAuthMethodByEmailActionSuccess, (state, action) => {
        state.onboarding.method = action.payload.method;
        setLoadedStatusToLoadableData(state.onboarding);
      })
      .addCase(getAuthMethodByEmailActionError, (state, action) => {
        state.onboarding.errors = action.payload.details;
        setLoadedStatusToLoadableData(state.onboarding);
      })

      .addCase(changeAuthMethodAction, (state, action) => {
        state.onboarding.method = action.payload.method;
      })

      .addCase(cleanupAuthMethodAction, (state) => {
        state.onboarding.method = undefined;
        state.onboarding.email = '';
        state.onboarding.errors = [];
      })

      .addCase(loginByGoogleActionSuccess, (state) => {
        state.onboarding.method = undefined;
        state.onboarding.email = '';
      })

      .addCase(loginByPasswordAction, (state) => {
        state.onboarding.errors = [];
        setLoadingStatusToLoadableData(state.onboarding);
      })
      .addCase(loginByPasswordActionError, (state, action) => {
        state.onboarding.errors = action.payload.details;
        setLoadedStatusToLoadableData(state.onboarding);
      })
      .addCase(loginByPasswordActionSuccess, (state) => {
        state.onboarding.method = undefined;
        state.onboarding.email = '';
        setLoadedStatusToLoadableData(state.onboarding);
      })

      .addCase(logoutActionSuccess, (state) => {
        state.onboarding.method = undefined;
        state.onboarding.email = '';
      })

      .addCase(signUpByPassword, (state) => {
        setLoadingStatusToLoadableData(state.onboarding);
      })
      .addCase(signUpByPasswordSuccess, (state) => {
        setLoadedStatusToLoadableData(state.onboarding);
      })
      .addCase(signUpByPasswordError, (state, action) => {
        setLoadedStatusToLoadableData(state.onboarding);
        state.onboarding.errors = action.payload.details;
      })
      .addCase(forgotPasswordAction, (state) => {
        setLoadingStatusToLoadableData(state.forgotPassword);
        state.forgotPassword.errors = [];
      })
      .addCase(forgotPasswordActionError, (state, action) => {
        setLoadedStatusToLoadableData(state.forgotPassword);
        state.forgotPassword.errors = action.payload.details;
      })
      .addCase(forgotPasswordActionSuccess, (state) => {
        setLoadedStatusToLoadableData(state.forgotPassword);
        state.forgotPassword.isSuccess = true;
      })

      .addCase(cleanupForgotPasswordInfo, (state) => {
        state.forgotPassword.isSuccess = false;
      })

      .addCase(resetForgotPasswordAction, (state) => {
        setLoadingStatusToLoadableData(state.resetForgotPassword);
        state.resetForgotPassword.errors = [];
        state.forgotPassword.isSuccess = false;
      })
      .addCase(resetForgotPasswordActionError, (state, action) => {
        setLoadedStatusToLoadableData(state.resetForgotPassword);
        state.resetForgotPassword.errors = action.payload.details;
      })
      .addCase(resetForgotPasswordActionSuccess, (state) => {
        setLoadedStatusToLoadableData(state.resetForgotPassword);
        state.resetForgotPassword.isSuccess = true;
      })

      .addCase(approveUserEmailAction, (state) => {
        setLoadingStatusToLoadableData(state.approveEmail);
        state.approveEmail.errors = [];
        state.approveEmail.isSuccess = false;
      })
      .addCase(approveUserEmailErrorAction, (state, action) => {
        setLoadedStatusToLoadableData(state.approveEmail);
        state.approveEmail.errors = action.payload.details;
      })
      .addCase(approveUserEmailSuccessAction, (state) => {
        setLoadedStatusToLoadableData(state.approveEmail);
        state.approveEmail.isSuccess = true;
      })
      .addCase(inviteCheckUserSuccessAction, (state, action) => {
        state.inviteUser.status = action.payload;
      })
      .addCase(inviteUserAcceptErrorAction, (state, action) => {
        state.inviteUser.accept = action.payload;
      })
      .addCase(inviteUserAcceptSuccessAction, (state, action) => {
        state.inviteUser.accept = action.payload;
      })
      .addCase(inviteCheckUserErrorAction, (state, action) => {
        state.inviteUser.accept = action.payload;
      }),
);
