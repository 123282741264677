import React from 'react';
import { ModalTypes } from '../../redux/interfaces/modal';
import { BaseRenderProps } from './components';
import { LogoutModal } from '../dashboardLayout/modals/logout';
import { SetLocationModal } from './setLocation/setLocal';
import { DeleteUserAccountModal } from './deleteUserAccount';
import { EnterCardModal } from '../../pages/billing/modals/enterCard';
import { CongratulationsModal } from '../../pages/billing/modals/congratulations';
import { DowngradeModal } from '../../pages/billing/modals/downgrade';
import { CalendarInvitationModal } from '../../pages/meetingSettings/modals/calendarInvitation';

// TODO: have to change 'any' type to 'BaseRenderProps'
const componentsByModalType: Record<ModalTypes, React.FC<any>> = {
  [ModalTypes.meetingDateRange]: LogoutModal,
  [ModalTypes.meetingTimezone]: LogoutModal,
  [ModalTypes.meetingRuleAvailability]: LogoutModal,
  [ModalTypes.eventNotifications]: LogoutModal,
  [ModalTypes.bookByEmail]: LogoutModal,
  [ModalTypes.deleteUserAccount]: LogoutModal,
  [ModalTypes.changeUserEmail]: LogoutModal,
  [ModalTypes.changeUserPassword]: LogoutModal,
  [ModalTypes.deleteOrganizationPendingUser]: LogoutModal,
  [ModalTypes.deleteOrganizationAcceptedUser]: LogoutModal,
  [ModalTypes.inviteSendSuccess]: LogoutModal,
  [ModalTypes.meetingLocation]: LogoutModal,
  [ModalTypes.calendarConnectionFailed]: LogoutModal,
  [ModalTypes.successEventModal]: LogoutModal,
  [ModalTypes.deleteMeeting]: LogoutModal,
  //newModals
  [ModalTypes.logout]: LogoutModal,
  [ModalTypes.setLocation]: SetLocationModal,
  [ModalTypes.deleteUserAccount]: DeleteUserAccountModal,
  [ModalTypes.enterCard]: EnterCardModal,
  [ModalTypes.congratulations]: CongratulationsModal,
  [ModalTypes.downgradeSubscription]: DowngradeModal,
  [ModalTypes.setCalendarInvitation]: CalendarInvitationModal,
};

export const renderModalContent = (
  type: ModalTypes,
  props: BaseRenderProps,
): React.FunctionComponentElement<any> => {
  // todo: lazy loading

  const Component = componentsByModalType[type];
  return React.createElement(Component, { ...props, key: type });
};
