import React from 'react';
import moment from 'moment-timezone';
import { Button } from '../../../components/button';
import { CheckIcon } from '../../../components/icons/check/ checkIcon';
import './congratulations.scss';

interface props {
  expiredAt: string;
  brand: string;
  last4: string;
  onCancel(): void;
}

export const CongratulationsModal: React.FC<props> = (props) => {
  const { expiredAt, brand, last4, onCancel } = props;
  return (
    <div className="modal-space">
      <div className="congratulations-container">
        <div className="congratulations-logo">
          <CheckIcon />
        </div>
        <div className="congratulations-title">Congratulations!</div>
        <div className="congratulations-subtitle">You’ve upgraded to Pro!</div>
        <div className="congratulations-content">
          Your subscription will renew on:
          <br />
          <strong>{moment(expiredAt).format('MMMM D, YYYY')}</strong> using your{' '}
          <strong>{brand}</strong> ending in <strong>{last4}</strong>
        </div>
        <Button onClick={onCancel}>Ok! Thanks!</Button>
      </div>
    </div>
  );
};
