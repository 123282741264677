import React from 'react';
import { ReactComponent as CheckLogo } from './check.svg';

export const CheckIcon: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CheckLogo />
    </div>
  );
};
