import { AccessTokenDto } from '../auth/dtos/access-token.dto';
import { left, right } from '@sweet-monads/either';
import { UnknownError } from '../common/errors';
import {
  DeleteOrganizationUserRequestDto,
  FetchOrganizationUsersRequestDto,
  FetchOrganizationUsersResponseDto,
  ReSendInviteToUserRequestDto,
  SendInvitesToUsersRequestDto,
} from './dtos/api.dto';
import {
  FindOrganizationUsersResult,
  OrganizationDataDto,
} from './dtos/organization-users.dto';
import { ErrorCodes, isAxiosError } from '../../utils/errors';
import { AuthTokenExpiredError, AuthTokenInvalidError } from '../auth/errors';
import {
  InvalidOrganizationInviteError,
  NotOrganizationMemberError,
  OrganizationPermissionError,
} from './errors';
import BaseAxiosInstance from '../../utils/axios-config';

export class OrganizationUsersApi {
  async getOrganizationUsers(
    accessToken: AccessTokenDto,
    dto: FetchOrganizationUsersRequestDto,
  ): Promise<FindOrganizationUsersResult> {
    try {
      const result = await BaseAxiosInstance.get<
        OrganizationDataDto<FetchOrganizationUsersResponseDto>
      >(`/api/organizations/members`, {
        params: { ...dto },
        headers: { Authorization: `Bearer ${accessToken.token}` },
      });
      return right(result.data);
    } catch (e) {
      switch (e.response.data.code) {
        case ErrorCodes.authTokenInvalid:
          return left(new AuthTokenInvalidError(e));
        case ErrorCodes.authTokenExpired:
          return left(new AuthTokenExpiredError(e));
        case ErrorCodes.notOrganizationMember:
          return left(new NotOrganizationMemberError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async deleteOrganizationUser(
    accessToken: AccessTokenDto,
    dto: DeleteOrganizationUserRequestDto,
  ): Promise<any> {
    try {
      const result = await BaseAxiosInstance.post<any>(
        `/api/organizations/members/delete`,
        {
          ...dto,
        },
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );
      return right(result.status);
    } catch (e) {
      return left(new UnknownError(e));
    }
  }

  async sendInvitesToUsers(
    accessToken: AccessTokenDto,
    dto: SendInvitesToUsersRequestDto,
  ): Promise<any> {
    try {
      const result = await BaseAxiosInstance.post<any>(
        `/api/organizations/members`,
        {
          ...dto,
        },
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );
      return right(result.data);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }

      switch (e.response.data.code) {
        case ErrorCodes.authTokenInvalid:
          return left(new AuthTokenInvalidError(e));
        case ErrorCodes.authTokenExpired:
          return left(new AuthTokenExpiredError(e));
        case ErrorCodes.notOrganizationMember:
          return left(new NotOrganizationMemberError(e));
        case ErrorCodes.inviteUserExistInCurrentOrganization:
          return left(new InvalidOrganizationInviteError(e));
        case ErrorCodes.invitePermission:
          return left(new OrganizationPermissionError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }

  async reSendUserInvite(
    accessToken: AccessTokenDto,
    dto: ReSendInviteToUserRequestDto,
  ): Promise<any> {
    try {
      const result = await BaseAxiosInstance.post<any>(
        `/api/organizations/invite/${dto.memberId}/resend`,
        {
          ...dto,
        },
        {
          headers: { Authorization: `Bearer ${accessToken.token}` },
        },
      );
      return right(result.status);
    } catch (e) {
      if (!isAxiosError(e)) {
        return left(new UnknownError(e));
      }
      if (!e.response) {
        return left(new UnknownError(e));
      }

      switch (e.response.data.code) {
        case ErrorCodes.authTokenInvalid:
          return left(new AuthTokenInvalidError(e));
        case ErrorCodes.authTokenExpired:
          return left(new AuthTokenExpiredError(e));
        case ErrorCodes.notOrganizationMember:
          return left(new NotOrganizationMemberError(e));
        default:
          return left(new UnknownError(e));
      }
    }
  }
}
