import React from 'react';
import { ReactComponent as LinkLogo } from './linkTo.svg';
import './index.scss';

interface props {
  link?: string;
}

export const LinktTo: React.FC<props> = ({ link }) => {
  return (
    <a
      className="linkTo-logo"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(event) => event.stopPropagation()}
    >
      <LinkLogo />
    </a>
  );
};
