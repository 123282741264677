import { put, takeLatest } from 'redux-saga/effects';
import {
  cancellationEventAction,
  CancellationEventParams,
  cancellationEventSuccessAction,
  fetchBookCompletedEventAction,
  fetchBookCompletedEventSuccessAction,
  fetchBookErrorAction,
  fetchBookEventAction,
  FetchBookEventInfoParams,
  FetchBookEventParams,
  fetchBookEventSuccessAction,
  fetchMeetingByPublicLinkAction,
  fetchMeetingByPublicLinkErrorAction,
  FetchMeetingByPublicLinkParams,
  fetchMeetingByPublicLinkSuccessAction,
  fetchMeetingSlotsToBookEventAction,
  fetchMeetingSlotsToBookEventErrorAction,
  FetchMeetingSlotsToBookEventParams,
  fetchMeetingSlotsToBookEventSuccessAction,
  reschedulingEventAction,
  ReschedulingEventParams,
  scheduleMeetingBookEventAction,
  ScheduleMeetingBookEventParams,
  scheduleMeetingBookEventSuccessAction,
  showNotificationAction,
  showNotificationErrorAction,
  scheduleMeetingBookEventErrorAction,
  rescheduleMeetingBookEventAction,
  RescheduleMeetingBookEventParams,
  rescheduleMeetingBookEventSuccessAction,
  rescheduleMeetingBookEventErrorAction,
} from '../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { meetingService } from '../../libs/meeting/meeting.service';
import {
  FetchMeetingSlotsToBookEventResult,
  FetchMeetingToBookEventResult,
} from '../../libs/meeting/dtos/meeting.dto';
import { appHistory, routers } from '../../router';
import { setDarkThemeSchedule } from '../actions/theme';
import { THEMES } from '../constants/theme';

function* fetchMeetingByPublicLinkSagaAction(
  action: PayloadAction<FetchMeetingByPublicLinkParams>,
) {
  const result: FetchMeetingToBookEventResult = yield meetingService.findByPublicLink(
    {
      meetingSlug: action.payload.meetingSlug,
      userSlug: action.payload.userSlug,
    },
  );
  if (result.isRight()) {
    yield put(fetchMeetingByPublicLinkSuccessAction(result.value));
    yield put(
      setDarkThemeSchedule({
        data: result.value.design.isDarkMode ? THEMES.DARK : THEMES.LIGHT,
      }),
    );
    return;
  }
  yield put(fetchMeetingByPublicLinkErrorAction());

  appHistory.push(routers.NOT_FOUND);
  switch (result.value.code) {
    case 'meetingNotFoundError':
    case 'validationError':
      yield put(showNotificationErrorAction({ content: 'Meeting not found' }));
      return;

    case 'unknown':
      yield put(showNotificationErrorAction({ content: result.value.message }));
      return;
  }
}

function* fetchMeetingSlotsToBookEventSagaAction(
  action: PayloadAction<FetchMeetingSlotsToBookEventParams>,
) {
  const result: FetchMeetingSlotsToBookEventResult = yield meetingService.fetchMeetingSlotsToBookEvent(
    {
      timezone: action.payload.timezone,
      rangeTo: new Date(action.payload.rangeTo),
      rangeFrom: new Date(action.payload.rangeFrom),
      meetingId: action.payload.meetingId,
    },
  );
  if (result.isRight()) {
    yield put(fetchMeetingSlotsToBookEventSuccessAction(result.value));
    return;
  }

  yield put(fetchMeetingSlotsToBookEventErrorAction());
  alert(result.value.message);
}

function* scheduleMeetingBookEventSagaAction(
  action: PayloadAction<ScheduleMeetingBookEventParams>,
) {
  const result = yield meetingService.createScheduleMeetingEvent(
    action.payload,
  );

  if (result.isLeft()) {
    yield put(scheduleMeetingBookEventErrorAction());
    yield put(
      showNotificationErrorAction({
        content: result.value.originalError.response.data.message
          ? result.value.originalError.response.data.message
          : 'Server error. Try later',
      }),
    );
    return;
  }

  yield put(
    scheduleMeetingBookEventSuccessAction({
      eventId: result.value.id,
    }),
  );
}

function* fetchBookCompletedEventSataAction(
  action: PayloadAction<FetchBookEventInfoParams>,
) {
  const result = yield meetingService.fetchBookEventInfo(action.payload);

  if (result.isLeft()) {
    yield put(fetchBookErrorAction(result.value));
    return;
  }
  yield put(fetchBookCompletedEventSuccessAction(result.value));
}

function* fetchBookEventSagaAction(
  action: PayloadAction<FetchBookEventParams>,
) {
  const result = yield meetingService.fetchBookEvent(action.payload);
  if (result.isLeft()) {
    switch (result.value.code) {
      case 'meetingEventNotFoundError':
        yield put(fetchBookErrorAction(result.value));
        break;
      default:
        yield put(
          showNotificationAction({
            type: 'error',
            content: result.value.message,
            duration: null,
          }),
        );
        break;
    }
  }
  yield put(fetchBookEventSuccessAction(result.value));
}

function* cancellationEventSagaAction(
  action: PayloadAction<CancellationEventParams>,
) {
  const result = yield meetingService.cancellationEvent(action.payload);

  if (result.isLeft()) {
    yield put(
      showNotificationAction({
        type: 'error',
        content: result.value.message,
        duration: null,
      }),
    );
    return;
  }

  yield put(
    cancellationEventSuccessAction({
      success: true,
    }),
  );

  appHistory.push({
    pathname: '/dashboard',
    state: {
      cancelled: true,
    },
  });

  yield put(
    showNotificationAction({
      type: 'success',
      content: 'Cancelled',
      duration: null,
    }),
  );
}

function* reschedulingEventSageAction(
  action: PayloadAction<ReschedulingEventParams>,
) {
  const result = yield meetingService.reschedulingEvent(action.payload);

  if (result.isLeft()) {
    yield put(
      showNotificationAction({
        type: 'error',
        content: result.value.message,
        duration: null,
      }),
    );
    return;
  }

  appHistory.push({
    pathname: '/dashboard',
    state: {
      cancelled: true,
    },
  });

  yield put(
    showNotificationAction({
      type: 'success',
      content: 'Rescheduled',
      duration: null,
    }),
  );
}

function* rescheduleMeetingBookEventActionSaga(
  action: PayloadAction<RescheduleMeetingBookEventParams>,
) {
  const { eventId, token, ...data } = action.payload;
  const result = yield meetingService.createReScheduleMeetingEvent(
    data,
    eventId,
    token,
  );

  if (result.isLeft()) {
    yield put(rescheduleMeetingBookEventErrorAction());
    yield put(
      showNotificationErrorAction({
        content: result.value.originalError.response.data.message
          ? result.value.originalError.response.data.message
          : 'Server error. Try later',
      }),
    );
    return;
  }

  yield put(
    rescheduleMeetingBookEventSuccessAction({
      eventId: result.value.id,
    }),
  );
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function* bookingMeetingEventRunSaga(): Generator<any> {}
export function* bookingMeetingEventWatchSaga(): Generator<any> {
  yield takeLatest(
    fetchMeetingByPublicLinkAction,
    fetchMeetingByPublicLinkSagaAction,
  );
  yield takeLatest(
    fetchMeetingSlotsToBookEventAction,
    fetchMeetingSlotsToBookEventSagaAction,
  );
  yield takeLatest(
    scheduleMeetingBookEventAction,
    scheduleMeetingBookEventSagaAction,
  );
  yield takeLatest(
    rescheduleMeetingBookEventAction,
    rescheduleMeetingBookEventActionSaga,
  );
  yield takeLatest(
    fetchBookCompletedEventAction,
    fetchBookCompletedEventSataAction,
  );
  yield takeLatest(fetchBookEventAction, fetchBookEventSagaAction);
  yield takeLatest(cancellationEventAction, cancellationEventSagaAction);
  yield takeLatest(reschedulingEventAction, reschedulingEventSageAction);
}
