import React from 'react';
import { ReactComponent as CalendarLogo } from '../../../components/icons/Google_Calendar.svg';
import { CloseCrossIcon } from '../../../components/icons/closeCross/';
// import { Switch } from '../../../components/switch';
// import { LabelWithTooltip } from '../../../components/labelWithTooltip';
import './index.scss';
import { useDispatch } from 'react-redux';
import { UserModel } from '../../../models/user.model';
import { disconnectCalendarAccountAction } from '../../../redux/actions';

interface ICalendarAccount {
  email: string;
  id: string;
}
interface Props {
  calendarAccounts: ICalendarAccount[];
  user: UserModel | null;
}

export const AccountCalendar: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  return (
    <>
      {props.calendarAccounts
        ? props.calendarAccounts.map((account: any) => (
            <div className="accountCalendar-container" key={account.email}>
              <div className="accountCalendar-header">
                <div className="accountCalendar-header-account">
                  <div className="logo">
                    <CalendarLogo />
                  </div>
                  <div className="calendar-account">
                    <span className="account-gmail">{account.email}</span>
                    <span className="account-login">Login Email</span>
                  </div>
                </div>
                <div
                  className="accountCalendar-header-disconnect"
                  onClick={() =>
                    dispatch(
                      disconnectCalendarAccountAction({
                        accountId: account.id,
                        user: props.user!,
                      }),
                    )
                  }
                >
                  <CloseCrossIcon />
                  <span>Disconnect</span>
                </div>
              </div>
              {/*<div className="accountCalendar-footer">*/}
              {/*<div className="accountCalendar-footer-switch-container">*/}
              {/*  <Switch size="small" />*/}
              {/*  <LabelWithTooltip*/}
              {/*    label="Check For Conflicts"*/}
              {/*    tooltip="Set the calendar(s) to check for conflicts
              to prevent double bookings. This is mainly used when you have
              more than one calendar you schedule meetings on. Example: personal calendar and business calendar"*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div className="accountCalendar-footer-switch-container">*/}
              {/*  <Switch size="small" />*/}
              {/*  <LabelWithTooltip*/}
              {/*    label="Add To Calendar"*/}
              {/*    tooltip="This is the calendar you would like to add
               new meeting invitations to as they're scheduled.
                You can only add meeting invitations to one calendar."*/}
              {/*  />*/}
              {/*</div>*/}
              {/*</div>*/}
            </div>
          ))
        : null}
    </>
  );
};
