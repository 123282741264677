import { useSelector } from './common';
import {
  ActiveMeetingState,
  ListOfMeetingsState,
  MeetingCreatingState,
} from '../reducers/meetings';
import {
  BookingRangeInfo,
  MeetingInfo,
  ScheduledEvent,
  ScheduledMeetingEventInfo,
} from '../reducers/booking-meeting-event';

export const useMeetingsListSelect = (): ListOfMeetingsState => {
  return useSelector((store) => store.meetings.list);
};

export const useActiveMeetingSelect = (): ActiveMeetingState => {
  return useSelector((store) => store.meetings.active);
};

export const useNewMeetingSelect = (): MeetingCreatingState => {
  return useSelector((store) => store.meetings.creating);
};

export const useMeetingToBookInfoSelector = (): MeetingInfo => {
  return useSelector((store) => store.bookingMeetingEvent.meetingInfo);
};

export const useMeetingSlotsToBookEventInfoSelector = (): BookingRangeInfo => {
  return useSelector((store) => store.bookingMeetingEvent.bookingRangeInfo);
};

export const useScheduleMeetingEventSelector = (): ScheduledEvent => {
  return useSelector((store) => store.bookingMeetingEvent.scheduledEvent);
};
export const useEventInfoSelector = (): ScheduledMeetingEventInfo => {
  return useSelector((store) => store.bookingMeetingEvent.eventInfo);
};
